import React, {Fragment} from 'react';
import Button from "../../../form/Button";
import {Link} from "react-router-dom";

function GuestQuestions(props) {
  const {questions, user, openedIndex, toggleQuestionHandler} = props;
  return (
      <Fragment>
        <div className={`faq-questions`}>
          <div className={`faq-questions__wrap`}>
            {questions[user][props.currentLanguage].map((item, i) => {
              return (
                  <div key={item.id} className={`faq-questions__block ${openedIndex === item.id ? 'open' : ''}`}>
                    <div>
                      <h3 className={`faq-questions__question`}
                          onClick={(e) => toggleQuestionHandler(e, item.id)}>{item.question}</h3>
                      <p className={`faq-questions__answer`} dangerouslySetInnerHTML={{__html: item.answer}}/>
                    </div>
                  </div>
              )
            })}
          </div>
        </div>
        <div className={`faq__btn`}>
          <Button>
            <Link to={{pathname: "/signin"}}>JOIN NOW</Link>
          </Button>
        </div>
      </Fragment>
  )
}

export default GuestQuestions
