import React from "react";
import Title from "../../../parts/Title";
import Button from "../../../form/Button";
import done_img from "../../../../../images/cabinet/popup_lock.svg";

const Blocked = (props) => {
  const {t, closePopup} = props;
  return (
      <div className={`popup attention change-phone-number`}>
        <div className={`popup__close`} onClick={()=>closePopup()}></div>
        <div className={`container`}>
          <div className={`popup__wrap`}>
            <div className={`attention-img`}>
              <img src={done_img} alt="done pic"/>
            </div>
            <Title title={t('shop.attention.title')} className={`white white-v2`} size={170}/>
            <p className={`popup__text`}>{t('cabinet.change_phone_popup.text1')}</p>
            <p className={`popup__text`}>{t('cabinet.change_phone_popup.text2')}</p>
            <Button className={`popup__btn`} handleClick={()=>closePopup()}>
              {t('btns.close')}
            </Button>
          </div>
        </div>
      </div>

  );
};

export default Blocked;
