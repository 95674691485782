import React, { useState } from 'react'
// import PropTypes from 'prop-types'
import Provider from "./Provider";

//Parts
import MainSlides from './parts/MainSlides';
import BottomContainer from './parts/HowToBottom';



function HowTo(props) {

    const [current, changePage] = useState(0);

    const userLang = navigator.language || navigator.userLanguage; 

    return (
        <Provider>
            <div className={'how-to-page'}>
                <MainSlides />
                <BottomContainer/>
            </div>
        </Provider>
    )
}


export default HowTo

