import React, { useEffect } from "react";
import Title from "../../../../../parts/Title";
import { Link } from "react-router-dom";
import Button from "../../../../../form/Button";
import done_img from "../../../../../../../images/open-tasks/survey/survey-done.png";

const Done = (props) => {
  const currentUser = localStorage.getItem("user.me")
    ? JSON.parse(localStorage.getItem("user.me"))
    : null;

  useEffect(() => {
    // if (props.showPopup) props.openPopup()
  }, []);

  return (
    <div className={`survey-done`}>
      <Title title={props.t("open_task.done")} size={170} />
      <div className={`survey-done__wrap`}>
        <div className={`survey-done__img`}>
          <img src={done_img} alt="done pic" />
        </div>
        <p className={`survey-done__thank-text`}>
          {props.t("open_task.survey.tnx")}
        </p>
        <p className={`survey-done__prize-text`}>
          {props.points} {props.t("open_task.prize")}
        </p>
        {currentUser && currentUser["newUserRefillAllowed"] ? (
          <Button
            className={`survey-done__btn`}
            handleClick={props.handleShowRefillPopup}
          >
            {props.t("btns.check_balance")}
          </Button>
        ) : (
          <Button className={`survey-done__btn`}>
            <Link to={"/cabinet/points"}>{props.t("btns.check_balance")}</Link>
          </Button>
        )}
      </div>
    </div>
  );
};

export default Done;
