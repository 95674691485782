import React, {PureComponent} from "react";
import {connect} from "react-redux";
import {Link, NavLink, withRouter} from "react-router-dom";
import logo from "../../../images/w2g-logo.svg";
import logo_mob from "../../../images/w2g-logo-mob.svg";
import LanguageSelector from "./LanguageSelector";
import {logout} from "../../actions/auth";
import {closeMenu, toggleMenu} from "../../actions/menuActions";

class Header extends PureComponent {
  constructor(props) {
    super();
    this.state = {
    };
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) this.props.closeMenu();
    if (this.props.isLogged && this.props.location.pathname === '/') {
      this.props.history.push('/tasks')
    }
  }

  logout = () => {
    this.props.logout();
    this.props.closeMenu();
    this.props.history.push("/signin");
  };

  handlePrivateRouteClick = route => {
    this.props.history.push(route);
    this.props.closeMenu();
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && this.props.menuOpened) {
      this.props.closeMenu();
    }
  };

  render() {
    const {activeSection, menuOpened} = this.props;
    let currentClass = 'normal'; //activeSection === 0 ? 'white' : 'normal';
    return (
        <div className={`header ${currentClass}`} ref={this.wrapperRef}>
          <div className={`container header-wrap ${menuOpened ? "is-active" : ""}`}>
            <div className="header-logo">
              <Link to={`${this.props.isLogged ? '/tasks' : '/'}`} onClick={this.props.closeMenu}>
                <img className={`header-logo__logo`}
                     src={logo}
                     alt="company logo"
                />
                <img className={`header-logo__logo-mob`}
                     src={logo_mob}
                     alt="company logo"
                />
              </Link>
            </div>
            <div className="header-hamburger hamburger">
              <div className={`header-hamburger__wrap`}
                   onClick={this.props.toggleMenu}>
                {/*<div className={`header-hamburger__inner`}></div>*/}
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <div className={`header-nav`}>
              <nav>
                {this.props.isLogged ? (
                    <NavLink activeClassName="selected"
                             className={`header-nav__link`}
                             exact
                             to={{pathname: "/tasks"}}
                             onClick={this.props.closeMenu}>
                      Select Task <span className={`triangle-menu`}></span>
                    </NavLink >
                ) : (
                    <button
                        className="header-nav__link header-nav__btn-link"
                        onClick={() => this.handlePrivateRouteClick("/signin")}
                    >
                      Select Task<span className={`triangle-menu`}></span>
                    </button>
                )}
                <NavLink
                    activeClassName="selected"
                    className={`header-nav__link`}
                    exact
                    to={{pathname: "/shop"}}
                    onClick={this.props.closeMenu}>
                  Shop <span className={`triangle-menu`}></span>
                </NavLink >
                {/*{this.props.isLogged && <NavLink*/}
                    {/*activeClassName="selected"*/}
                    {/*className={`header-nav__link`}*/}
                    {/*exact*/}
                    {/*to={{pathname: "/howto"}}*/}
                    {/*onClick={this.props.closeMenu}>*/}
                  {/*How to <span className={`triangle-menu`}></span>*/}
                {/*</NavLink >}*/}
                {/*<NavLink activeClassName="selected"*/}
                         {/*className={`header-nav__link`}*/}
                         {/*exact*/}
                         {/*to={{pathname: "/rating"}}*/}
                         {/*onClick={this.props.closeMenu}>*/}
                  {/*Rating <span className={`triangle-menu`}></span>*/}
                {/*</NavLink >*/}
                {this.props.isLogged && <NavLink activeClassName="selected"
                         className={`header-nav__link`}
                         exact
                         to={{pathname: "/faq"}}
                         onClick={this.props.closeMenu}>
                  FAQ <span className={`triangle-menu`}></span>
                </NavLink >}
                {this.props.isLogged && <NavLink activeClassName="selected"
                         className={`header-nav__link`}
                         exact
                         to={{pathname: "/rules"}}
                         onClick={this.props.closeMenu}>
                  T&C <span className={`triangle-menu`}></span>
                </NavLink >}
                <NavLink activeClassName="selected"
                         className={`header-nav__link`}
                         to={{pathname: "/cabinet"}}
                         onClick={this.props.closeMenu}>
                  My Cabinet <span className={`triangle-menu`}></span>
                </NavLink >
                {this.props.isLogged && <button className={`header-nav__link header-nav__btn-link`} onClick={this.logout}>
                  Log Out <span className={`triangle-menu`}></span>
                </button>}
                <LanguageSelector/>
              </nav>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged,
  menuOpened: state.menu.menuOpened,
  activeSection: state.common.activeSection
});

const mapDispatchToProps = dispatch => ({
  toggleMenu: () => dispatch(toggleMenu()),
  closeMenu: () => dispatch(closeMenu()),
  logout: () => dispatch(logout())
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Header)
);
