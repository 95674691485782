import React, {Fragment} from "react";
import Dropdown from "../../../../../form/Dropdown";
import Form from "../../../../../form/Form";
import FormBtns from "./FormBtns";
import Checkbox from "../../../../../form/Checkbox";
import Textarea from "../../../../../form/Textarea";
import Radio from "../../../../../form/Radio";

const SurveyQuestions = (props) => {
  return (
    <Form handleSubmit={props.setQuizSubmit}>
      <div className={`survey-form__col`}>
        {/*{props.questions && Array.isArray(props.questions) && props.questions.map((el, idx) => {*/}
        {/*return (*/}
        {/*<div className={`survey-form__field`} key={el.question_id}>*/}
        {/*<p className={`survey-form__label`}>*/}
        {/*<span className={`survey-form__count-label`}>{idx+1}</span>*/}
        {/*{el.description}*/}
        {/*</p>*/}
        {/*<Dropdown name={el.question_id}*/}
        {/*validate={[{required: true}]}*/}
        {/*dataOptions={el.answers.map(answer=>({ label: answer.name, value: answer.id }))}*/}
        {/*minMenuHeight={200}*/}
        {/*menuHeight={200}*/}
        {/*placeholder={'Select'}/>*/}
        {/*</div>*/}
        {/*)*/}
        {/*})}*/}
        {props.questions && Array.isArray(props.questions) && [props.questions[0]].map((el) => {
          return (
            <div className={`survey-form__field`} key={el.question_id}>
              <p className={`survey-form__label`}>
                <span className={`survey-form__count-label`}>1</span>
                {el.description.split("?")[0]}? <br/> {el.description.split("?")[1]}
              </p>
              <Dropdown name={el.question_id}
                        validate={[{
                          required: true,
                          message: props.t('open_task.sobranie.choice_error')
                        }]}
                        dataOptions={el.answers.map(answer => ({label: answer.name, value: answer.id}))}
                        minMenuHeight={200}
                        menuHeight={200}
                        onChange={(e) => props.selectBrand(e, '0')}
                        placeholder={'Select'}/>
            </div>
          )
        })}
        {props.questions && Array.isArray(props.questions) && [props.questions[1]].map((el) => {
          let options = props.choiceBrand && el.answers.filter(item => {
            return item.brands.map(brand => brand.id).includes(props.choiceBrand.value)
          }).map(answer => ({label: answer.name, value: answer.id}));
          return (
            <div className={`survey-form__field`} key={el.question_id}>
              <p className={`survey-form__label`}>
                <span className={`survey-form__count-label`}>2</span>
                {el.description.split("?")[0]}? <br/> {el.description.split("?")[1]}
              </p>
              <Dropdown name={el.question_id}
                        validate={[{
                          required: true,
                          message: props.t('open_task.sobranie.choice_error')
                        }]}
                        dataOptions={options || []}
                        minMenuHeight={200}
                        menuHeight={200}
                        onChange={(e) => props.selectFormat(e, '1')}
                        placeholder={'Select'} disabled={!props.choiceBrand}
                        value={props.currentFormat}
              />
            </div>
          )
        })}
        {props.questions && Array.isArray(props.questions) && [props.questions[2]].map((el) => {
          let options = props.choiceFormat && el.answers.filter(item => {
            return item.name != props.choiceBrand.label
          }).map(answer => ({label: answer.name, value: answer.id}));
          return (
            <div className={`survey-form__field`} key={el.question_id}>
              <p className={`survey-form__label`}>
                <span className={`survey-form__count-label`}>3</span>
                {el.description.split("?")[0]}? <br/> {el.description.split("?")[1]}
              </p>
              <Dropdown name={el.question_id}
                        validate={[{
                          required: true,
                          message: props.t('open_task.sobranie.choice_error')
                        }]}
                        dataOptions={options || []}
                        minMenuHeight={200}
                        menuHeight={200}
                        onChange={(e) => props.selectBrand(e, '2')}
                        placeholder={'Select'} disabled={!props.choiceFormat}/>
            </div>
          )
        })}
        {props.questions && Array.isArray(props.questions) && [props.questions[3]].map((el) => {
          return (
            <div className={`survey-form__field`} key={el.question_id}>
              <p className={`survey-form__label`}>
                <span className={`survey-form__count-label`}>4</span>
                {el.description}
              </p>
              <Dropdown name={el.question_id}
                        validate={[{
                          required: true,
                          message: props.t('open_task.sobranie.choice_error')
                        }]}
                        dataOptions={el.answers.map(answer => ({label: answer.name, value: answer.id}))}
                        minMenuHeight={200}
                        menuHeight={200}
                        onChange={(e) => props.selectHandler(e, '3')}
                        placeholder={'Select'}/>
            </div>
          )
        })}
        {props.questions && Array.isArray(props.questions) && [props.questions[4]].map((el) => {
          return (
            <div className={`survey-form__field`} key={el.question_id}>
              <p className={`survey-form__label`}>
                <span className={`survey-form__count-label`}>5</span>
                {el.description}
              </p>
              <Dropdown name={el.question_id}
                        validate={[{
                          required: true,
                          message: props.t('open_task.sobranie.choice_error')
                        }]}
                        dataOptions={el.answers.map(answer => ({label: answer.name, value: answer.id}))}
                        minMenuHeight={200}
                        menuHeight={200}
                        onChange={(e) => props.selectHandler(e, '4')}
                        placeholder={'Select'}/>
            </div>
          )
        })}
        {props.selectArr && props.selectArr.length === 5 &&
        (<Fragment>
          {props.questions && Array.isArray(props.questions) && [props.questions[5]].map((el) => {
            return (
              <div className={`survey-form__field ${props.errorBuy ? 'error' : ''}`} key={el['question_id']}>
                <p className={`survey-form__label`}>
                  <span className={`survey-form__count-label`}>6</span>
                  {el.description}
                </p>
                <div className={`survey-form__multi-checkbox`}>
                  {el['answers'].sort((a, b) => {
                    return a.id - b.id
                  }).map((answer, i) => {
                    return <Checkbox key={answer.id} id={answer.id}
                                     name={'Buy'}
                                     label={answer.name}
                                     data-name={el['answers'].length === i + 1 ? 'comment' : null}
                                     onChange={(e) => props.handleCheckConsider(e, el['question_id'])}/>
                  })}
                </div>
                <Textarea name={`commentBuy`} maxLength={250}
                          placeholder={props.t('open_task.sobranie.textarea_placeholder')}
                          disabled={!props.fieldBuy}
                          onChange={props.textAreaHandler}/>
                <div className={'error-element'}>{props.errorBuy}</div>
              </div>
            )
          })}
          {props.questions && Array.isArray(props.questions) && [props.questions[6]].map((el) => {
            return (
              <div className={`survey-form__field radio-btn ${props.errorRadio6 ? 'error' : ''}`}
                   key={el['question_id']}>
                <p className={`survey-form__label`}>
                  <span className={`survey-form__count-label`}>7</span>
                  {el.description}
                </p>
                <Radio name={'Radio6'}
                       className={`radio`}
                       onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                  // validate={[{
                  //   required: true,
                  //   message: props.t('open_task.sobranie.choice_error')
                  // }]}
                >
                  {el['answers'].sort((a, b) => {
                    return a.id - b.id
                  }).map((answer, i) => {
                    return <option key={answer.id}
                                   value={answer.id}
                                   data-name={el['answers'].length === i + 1 ? 'comment' : null}
                                   id={answer.id}
                                   label={answer.name}/>
                  })}
                </Radio>
                <Textarea name={`commentRadio6`} maxLength={250}
                          placeholder={props.t('open_task.sobranie.textarea_placeholder')}
                          disabled={!props.fieldRadio6}
                          onChange={props.textAreaHandler}/>
                <div className={'error-element'}>{props.errorRadio6}</div>
              </div>
            )
          })}
          {props.questions && Array.isArray(props.questions) && [props.questions[7]].map((el) => {
            return (
              <div className={`survey-form__field radio-btn ${props.errorRadio7 ? 'error' : ''}`}
                   key={el['question_id']}>
                <p className={`survey-form__label`}>
                  <span className={`survey-form__count-label`}>8</span>
                  {el.description}
                </p>
                <Radio name={'Radio7'}
                       className={`radio`}
                       onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                  // validate={[{
                  //   required: true,
                  //   message: props.t('open_task.sobranie.choice_error')
                  // }]}
                >
                  {el['answers'].sort((a, b) => {
                    return a.id - b.id
                  }).map((answer) => {
                    return <option key={answer.id} value={answer.id} id={answer.id} label={answer.name}/>
                  })}
                </Radio>
                <div className={'error-element'}>{props.errorRadio7}</div>
              </div>
            )
          })}
          {props.questions && Array.isArray(props.questions) && [props.questions[8]].map((el) => {
            return (
              <div className={`survey-form__field full-width ${props.errorunemployed ? 'error' : ''}`}
                   key={el['question_id']}>
                <p className={`survey-form__label`}>
                  <span className={`survey-form__count-label`}>9</span>
                  {el.description}
                </p>
                <Radio name={'unemployed'}
                       onChange={(e) => props.handleToggleQuiz(e, el['question_id'])}
                  // validate={[{
                  //   required: true,
                  //   message: props.t('open_task.sobranie.choice_error')
                  // }]}
                >
                  <option value={el['answers'][0].id} id="unemployed0" label={el['answers'][0].name}/>
                  <option value={el['answers'][1].id} id="unemployed1" label={el['answers'][1].name}/>
                  <option value={el['answers'][2].id} id="unemployed2" label={el['answers'][2].name}/>
                  <option value={el['answers'][3].id} id="unemployed3" label={el['answers'][3].name}/>
                </Radio>
                <div className={'error-element'}>{props.errorunemployed}</div>
              </div>
            )
          })}
          {(props.unemployed && (props.unemployed.answer_text === 'unemployed0' ||
            props.unemployed.answer_text === 'unemployed1')) &&
          (props.questions && Array.isArray(props.questions) && [props.questions[9]].map((el) => {
            return (
              <div className={`survey-form__field ${props.errorPosition ? 'error' : ''}`} key={el['question_id']}>
                <p className={`survey-form__label`}>
                  <span className={`survey-form__count-label`}>10</span>
                  {el.description}
                </p>
                <div className={`survey-form__multi-checkbox`}>
                  {el['answers'].sort((a, b) => {
                    return a.id - b.id
                  }).map((answer, i) => {
                    return <Checkbox key={answer.id} id={answer.id}
                                     name={'Position'}
                                     label={answer.name}
                                     data-name={el['answers'].length === i + 1 ? 'comment' : null}
                                     onChange={(e) => props.handleCheckConsider(e, el['question_id'])}/>
                  })}
                </div>
                <Textarea name={`commentPosition`} maxLength={250}
                          placeholder={props.t('open_task.sobranie.textarea_placeholder')}
                          disabled={!props.fieldPosition}
                          onChange={props.textAreaHandler}/>
                <div className={'error-element'}>{props.errorPosition}</div>
              </div>
            )
          }))}
          {(props.unemployed && (props.unemployed.answer_text === 'unemployed0' ||
            props.unemployed.answer_text === 'unemployed1')) && (props.questions && Array.isArray(props.questions) &&
            [props.questions[10]].map((el) => {
              return (
                <div className={`survey-form__field radio-btn ${props.errorRadio10 ? 'error' : ''}`}
                     key={el['question_id']}>
                  <p className={`survey-form__label`}>
                    <span className={`survey-form__count-label`}>11</span>
                    {el.description}
                  </p>
                  <Radio name={'Radio10'}
                         className={`radio`}
                         onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                    // validate={[{
                    //   required: true,
                    //   message: props.t('open_task.sobranie.choice_error')
                    // }]}
                  >
                    {el['answers'].sort((a, b) => {
                      return a.id - b.id
                    }).map((answer) => {
                      return <option key={answer.id} value={answer.id} id={answer.id} label={answer.name}/>
                    })}
                  </Radio>
                  <div className={'error-element'}>{props.errorRadio10}</div>
                </div>
              )
            }))}
        </Fragment>)}
      </div>
      <FormBtns {...props} />
    </Form>
  );
};

export default SurveyQuestions;
