import React, {useEffect} from 'react';
import LangHOC from "../HoC/LangHOC";
import presentation from "./../../../images/w2g-logo.svg";
import SvgIcon from "./svg/SvgIcon";

const ComingSoon = (props) => {
  const {t} = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setWrapperClassName("open-notify");
  }, []);

  return (
      <section className={`coming-soon`}>
        <div className={`container`}>
          <div className={`coming-soon__logo`}>
            <img src={presentation} alt="presentation"/>
          </div>
          <div className={`coming-soon-presentation`}>
            <h3 className={`coming-soon-presentation__title`}>
              საინტერესო<br/>
              ცვლილებები იწყება!
            </h3>
            <div className={`coming-soon-presentation__image`}>
              <SvgIcon/>
            </div>
          </div>
          <h2 className="coming-soon__title">
            ᲓᲐᲕᲘᲙᲔᲢᲔᲗ <br/>
            ᲒᲐᲜᲐᲮᲚᲔᲑᲔᲑᲘᲡᲐᲗᲕᲘᲡ
          </h2>
          <p className="coming-soon__sub-title">
            პლატფორმა დაბრუნდება<br/>
            განახლებული ვერსიით.
          </p>
          <p className="coming-soon__sub-title">
            ჩვენ შეგატყობინებთ სიახლეებს!
          </p>
        </div>
      </section>
  )
};

export default LangHOC(ComingSoon)
