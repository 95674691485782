import React, {Component} from "react";
import {connect} from "react-redux";
// import Tasks from "./Tasks/Tasks";
import {setActiveMainSection, setWrapperClassName} from "../../../actions/common";
import LangHOC from "../../HoC/LangHOC";
// import FullpageScroll from "./Guest/FullpageScroll";
import FullpageScroll from "./Guest/FullpageScrollV2";

class MainPage extends Component {
  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  render() {
    // let content
    // if (!this.props.isLogged) {
    //   content = (
    //       <FullpageScroll {...this.props}/>
    // )
    // } else {
    //   content = <Tasks {...this.props}/>
    // }
    return (
        <div className={`main-page`}>
          {/*{content}*/}
          <FullpageScroll {...this.props}/>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged
});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
  setActiveMainSection: (section) => dispatch(setActiveMainSection(section)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(MainPage));
