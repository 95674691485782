import * as types from "../constants/ActionTypes";
import defaultAxios from "../axios";

export const getCigaretteBrandList = () => (
    dispatch => {
      return defaultAxios.get('/api/info/variables/brands ')
          .then(response => {
            if (response.data) {
              let brandList = [];
              response.data.data.data.filter(brand=>brand.active !== 0).sort((a, b) => {
                if(a.brand.toLowerCase() < b.brand.toLowerCase()) return -1;
                if(a.brand.toLowerCase() > b.brand.toLowerCase()) return 1;
                return 0;
              }).map(item => brandList.push({label: item.brand, value: item.id}));
              dispatch(setCigaretteBrandList(brandList));
            }
          })
          .catch(error => dispatch(setCigaretteBrandList([])));
    }
);

export const getCigaretteFormatList = () => (
    dispatch => {
      return defaultAxios.get('/api/info/variables/formats')
          .then(response => {
            if (response.data) {
              let formatList = [];
              response.data.data.data.filter(format=>format.active !== 0).sort((a, b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              }).map(item => formatList.push({label: item.name, value: item.id}));
              dispatch(setCigaretteFormatList(formatList));
            }
          })
          .catch(error => dispatch(setCigaretteBrandList([])));
    }
);

export const setCigaretteBrandList = (brandList) => ({
  type: types.SET_CIGARETTE_BRAND_LIST,
  brandList
});

export const setCigaretteFormatList = (formatList) => ({
  type: types.SET_CIGARETTE_FORMAT_LIST,
  formatList
});
