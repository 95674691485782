/*
const questionsArrWinston = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Winston SS Caster?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Sweet tipping",
          name_en: "Sweet tipping"
        },
        {
          id: "1",
          name: "Unique Caster Blend",
          name_en: "Unique Caster Blend"
        },
        {
          id: "2",
          name: "LSS technology (Less Smoke Smell)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "3",
          name: "Super Slims Format",
          name_en: "Super Slims Format"
        },
        {
          id: "4",
          name: "Tar Level",
          name_en: "Tar Level"
        },
        {
          id: "5",
          name: "Recommended price 7 Gel",
          name_en: "Recommended price 7 Gel"
        },
        {
          id: "6",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "7",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "How do you like the unique Caster Blend of Winston SS Caster?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "How do you like the sweet tipping characteristic of Winston SS Caster?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "Are you going to purchase Winston SS Caster on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "Not",
          name_en: "Not"
        },
        {
          id: "2",
          name: "Don’t know",
          name_en: "Don’t know"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "What is the main reason preventing you from regular consumption Winston SS Caster?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "6", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "Don’t know", name_en: "Don’t know"},
        {id: "8", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია Winston SS Caster-ის მომხმარებელი ხართ:",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "ტკბილი ფილტრი",
          name_en: "Sweet tipping"
        },
        {
          id: "1",
          name: "უნიკალური Caster Blend-ი",
          name_en: "Unique Caster Blend"
        },
        {
          id: "2",
          name: "LSS ტექნოლოგია (თამბაქოს კვამლის ნაკლები სუნი)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "3",
          name: "სუპერ სლიმს ფორმატი",
          name_en: "Super Slims Format"
        },
        {
          id: "4",
          name: "ნიკოტინის შემცველობა",
          name_en: "Tar Level"
        },
        {
          id: "5",
          name: "რეკომენდებული ფასი 7 ლარი",
          name_en: "Recommended price 7 Gel"
        },
        {
          id: "6",
          name: "არ ვიცი",
          name_en: "Don’t know"
        },
        {
          id: "7",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "როგორ მოგწონთ Winston SS Caster-ის უნიკალური Caster Blend?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ მოგწონთ Winston SS Caster-ის ტკბილი ფილტრი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "აპირებთ თუ არა Winston SS Caster-ის რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "Not"
        },
        {
          id: "2",
          name: "არ ვიცი",
          name_en: "Don’t know"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც Winston SS Caster-ი არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "6", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "8", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდის იმიჯი", name_en: "Brand image"},
        {id: "1", name: "სიგარეტის ფორმატი", name_en: "Cigarette format"},
        {id: "2", name: "ნიკოტინის შემცველობა", name_en: "Tar level"},
        {id: "3", name: "ფასი", name_en: "Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};

const questionsWinstonV2 = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Winston KS Blue with special edition pack?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Tar level: 6 mg./cig.",
          name_en: "Tar level: 6 mg./cig."
        },
        {
          id: "1",
          name: "King Size format",
          name_en: "King Size format"
        },
        {
          id: "2",
          name: "Firm Tech Filter",
          name_en: "Firm Tech Filter"
        },
        {
          id: "3",
          name: "Premium Tobacco Quality from an international brand",
          name_en: "Premium Tobacco Quality from an international brand"
        },
        {
          id: "4",
          name: "Limited Edition Pack Design",
          name_en: "Limited Edition Pack Design"
        },
        {
          id: "5",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "6",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "How do you like the premium Tobacco Taste of Winston KS Blue?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "How do you like Winston KS Blue with a new special edition pack?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "Are you going to purchase Winston KS Blue with special edition pack on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "No",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "What is the main reason preventing you from regular consumption Winston KS Blue with special edition pack?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "6", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "Don’t know", name_en: "Don’t know"},
        {id: "8", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია ახალი ლიმიტირებული შეფუთვის შეთავაზების - ვინსტონ კლასიკი ლურჯის მომხმარებელი ხართ?",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "სიძლიერე 6 მგ",
          name_en: "Tar level: 6 mg./cig."
        },
        {
          id: "1",
          name: "კლასიკური ფორმატი",
          name_en: "King Size format"
        },
        {
          id: "2",
          name: "მყარი ფილტრი",
          name_en: "Firm Tech Filter"
        },
        {
          id: "3",
          name: "პრემიალური თამბაქოს ნაზავი საერთაშორისო ბრენდისგან",
          name_en: "Premium Tobacco Quality from an international brand"
        },
        {
          id: "4",
          name: "ლიმიტირებული კოლოფის დიზაინი",
          name_en: "Limited Edition Pack Design"
        },
        {
          id: "5",
          name: "არ ვიცი",
          name_en: "Don’t know"
        },
        {
          id: "6",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "როგორ მოგწონთ ვინსტონ ლურჯის პრემიალური თამბაქოს გემო?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ მოგწონთ ვინსტონ ლურჯის ახალი ლიმიტირებული შეფუთვა?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "აპირებთ თუ არა ვინსტონ ლურჯის ლიმიტირებული შეფუთვით რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც ვინსტონ ლურჯი ლიმიტირებული შეფუთვით არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "6", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "8", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდის იმიჯი", name_en: "Brand image"},
        {id: "1", name: "სიგარეტის ფორმატი", name_en: "Cigarette format"},
        {id: "2", name: "ნიკოტინის შემცველობა", name_en: "Tar level"},
        {id: "3", name: "ფასი", name_en: "Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};
*/
const questionsWinstonV3 = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Winston Super Slims?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "How many cigarettes do you smoke per day?",
      "answers": [
        {
          id: "0",
          name: "1-5",
          name_en: "1-5"
        },
        {
          id: "1",
          name: "5-10",
          name_en: "5-10"
        },
        {
          id: "2",
          name: "10-20",
          name_en: "10-20"
        },
        {
          id: "3",
          name: "Over 20",
          name_en: "Over 20"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Premium tobacco blend",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "SS Format",
          name_en: "SS Format"
        },
        {
          id: "2",
          name: "LSS technology (Less Smoke Smell)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "3",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "4",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "3",
      "description": "How do you like the Premium tobacco blend of Winston Super Slims?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "How do you like the format of Winston Super Slims?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "5",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "6",
      "description": "Are you going to purchase Winston Super Slims  on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "No",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason preventing you from regular consumption Winston Super Slims?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "3", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "4", name: "Don’t know", name_en: "Don’t know"},
        {id: "5", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "8",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია ვინსტონ სუპერ სლიმის მომხმარებელი ხართ?",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რამდენ ღერ სიგარეტს ეწევით დღეში?",
      "answers": [
        {
          id: "0",
          name: "1-დან 5-მდე",
          name_en: "1-5"
        },
        {
          id: "1",
          name: "5-დან 10-მდე",
          name_en: "5-10"
        },
        {
          id: "2",
          name: "10-დან 20-მდე",
          name_en: "10-20"
        },
        {
          id: "3",
          name: "20-ზე მეტს",
          name_en: "Over 20"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "პრემიუმ თამბაქოს ნაზავი",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "სუპერ სლიმ ფორმატი",
          name_en: "SS Format"
        },
        {
          id: "2",
          name: "LSS ტექნოლოგია (თამბაქოს კვამლის ნაკლები სუნი)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "3",
          name: "არ ვიცი",
          name_en: "Don’t know"
        },
        {
          id: "4",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ მოგწონთ ვინსტონ სუპერ სლიმის პრემიუმ თამბაქოს ნაზავი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "როგორ მოგწონთ ვინსტონ სუპერ სლიმის ფორმატი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "5",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "6",
      "description": "აპირებთ თუ არა ვინსტონ სუპერ სლიმის რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც ვინსტონ სუპერ სლიმსი არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "3", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "4", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "5", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "8",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდის იმიჯი", name_en: "Brand image"},
        {id: "1", name: "სიგარეტის ფორმატი", name_en: "Cigarette format"},
        {id: "2", name: "ნიკოტინის შემცველობა", name_en: "Tar level"},
        {id: "3", name: "ფასი", name_en: "Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};

export {questionsWinstonV3}
