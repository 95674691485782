import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Title from "../../parts/Title";
import Button from "../../form/Button";
import LangHOC from "../../HoC/LangHOC";
import {setUserRulesConfirm, setWrapperClassName} from "../../../actions/common";
import Terms from "../Terms";
import Form from "../../form/Form";
import Checkbox from "../../form/Checkbox";
import defaultAxios from "../../../axios";
import {logout, setFirstLoginStatus} from "../../../actions/auth";

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const NotifyRulesPopup = (props) => {
  const dispatch = useDispatch();
  const {firstLoginStatus} = useSelector((state) => ({...state.auth}));
  const {rulesConfirm} = useSelector((state) => ({...state.common}));
  const prevAmount = usePrevious({firstLoginStatus, rulesConfirm});
  const history = useHistory();
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    return () => {
      const upClassName = props.wrapperClass.split("open-notify").pop();
      dispatch(setWrapperClassName(upClassName));
      dispatch(setFirstLoginStatus(false));
    }
  }, []);

  useEffect(() => {
    const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
    if (firstLoginStatus && firstLoginStatus !== prevAmount.firstLoginStatus) {
      if (currentUser && currentUser.hasOwnProperty('rules_v2') && currentUser['rules_v2'] === "0") {
        openPopup()
      }
    } else if (currentUser && currentUser.hasOwnProperty('rules_v2') && currentUser['rules_v2'] === "0") {
      openPopup()
    } else if (rulesConfirm && rulesConfirm !== prevAmount.rulesConfirm) {
      dispatch(setUserRulesConfirm(null));
      openPopup()
    }
  }, [firstLoginStatus, rulesConfirm]);

  const openPopup = () => {
    window.scrollTo(0, 0);
    setPopup(true);
    const upClassName = ["open-notify", props.wrapperClass].filter(e => !!e).join(" ");
    props.setWrapperClassName(upClassName);
  };

  const closePopup = () => {
    setPopup(false);
    const upClassName = props.wrapperClass.split("open-notify").pop();
    dispatch(setWrapperClassName(upClassName));
    dispatch(logout());
    history.push('/');
  };

  const handleRulesConfirm = (fd) => {
    defaultAxios.post('/api/rules/v2', {}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "bearer " + localStorage.getItem("token")
      }
    })
        .then(response => {
          if (response?.data?.data?.data) {
            setPopup(false);
            const upClassName = props.wrapperClass.split("open-notify").pop();
            dispatch(setWrapperClassName(upClassName));
            dispatch(setUserRulesConfirm(null));
            const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
            if (currentUser && currentUser.hasOwnProperty('rules_v2') && currentUser['rules_v2'] === "0") {
              currentUser.rules_v2 = '1';
              localStorage.setItem('user.me', JSON.stringify(currentUser));
            }
            // defaultAxios.get('/api/front/v2/user-profile', {
            //   headers: {
            //     "Accept": "application/json",
            //     "Content-Type": "application/json",
            //     "Authorization": "Bearer " + localStorage.getItem("token")
            //   }
            // }).then(response => {
            //   if (response.data) {
            //     localStorage.setItem('user.me', JSON.stringify(response.data));
            //   }
            // }).catch(error => {});
          }
        })
        .catch(error => {});
  };

  if (popup) {
    return (
      <div className={`notify-prize-popup notify-rules-popup`}>
        <div className={`popup`}>
          <div className={`popup__close`} onClick={() => closePopup()}></div>
          <div className={`container`}>
            <div className={`popup__wrap`}>
              <Title title={props.t('signup.rules_title')} size={208} />
              <div className={`rules`}>
                <div className={`rules__text`}>
                  <Terms/>
                </div>
                <Form handleSubmit={() => handleRulesConfirm()}>
                  <Checkbox name={'new_rules_agreement'}
                            id={'rules_confirmation_popup'}
                            label={props.t('signup.rules_checkbox')}
                            validate={[{
                              required: true,
                              message: props.t('errors.req_field')}]}/>
                  <div className={`auth__btns`}>
                    <Button type={`submit`}>{props.t('btns.continue')}</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
};

export default LangHOC(NotifyRulesPopup)
