import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../../parts/Title";
import {setWrapperClassName} from "../../../../actions/common";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
// import BackgroundImage from "../../../svgItems/BackgroundImage";
import defaultAxios from "../../../../axios";
import {setCrmUserData, setFirstRegFromSms, setLogin, setUserConfirm, setUserPhone} from "../../../../actions/auth";
import LangHOC from "../../../HoC/LangHOC";
import BlockUser from "./BlockUser";
import LinkPhonePopup from "../../../parts/NotifyRefillPopup/LinkPhonePopup";
import CodeValidate from "./CodeValidate";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      serverErrors: null,
      phone: null,
      password: null,
      captcha: null,
      userName: null,
      phoneError: {},
      crmUser: null
    };
    this.recaptchaRef = React.createRef();
    this.recaptchaSecRef = React.createRef();
  }

  componentDidMount() {
    this.props.setWrapperClassName("auth-container");
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isLogged && this.props.isLogged && this.state.step === 0) this.props.history.push('/cabinet/profile');
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  handleSubmit = async (formData) => {
    let asyncTokenCaptha = null;
    if (this.state.serverErrors) asyncTokenCaptha = await this.recaptchaSecRef.current.executeAsync();
    // const asyncTokenCaptha = await this.recaptchaRef.current.executeAsync();

    const { phone, captcha, password } = this.state;
    let newCaptha = asyncTokenCaptha || captcha;
    const fd = new FormData();
    fd.append('password', password);
    fd.append('phone', phone);
    fd.append('g-recaptcha-response', newCaptha);
    defaultAxios.post('/api/auth/v2/signin', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    })
        .then(response => {
          if ((response.data.required_empty_fields &&
              Object.keys(response.data.required_empty_fields).length > 0) /*|| response.data.idUrl === null*/) {
            this.props.setUserConfirm(response.data);
            this.props.history.push('/confirm-profile')
          } else if (response.status === 202) {
            if (response.data && response.data.errors && response.data.errors.hasOwnProperty("error_id")) {
              let errorCode = Number(response.data.errors.error_id);
              if (errorCode === 3) {
                this.setState({
                  step: 3
                })
              } else if (errorCode === 4) {
                this.setState({
                  step: 2
                })
              } else {
                this.recaptchaSecRef.current.reset();
                this.setState({
                  serverErrors: response.data.errors,
                });
              }
            }
          } else {
            this.props.setLogin(response.data.token, response.data.expiration);
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({'event':'login' } )
            if (this.props.currentRouteLink) {
              this.props.history.push(this.props.currentRouteLink)
            } else {
              this.props.history.push('/tasks')
            }

          }
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.hasOwnProperty("error_id")) {
            // this.recaptchaRef.current.reset();
            let errorCode = Number(error.response.data.errors.error_id);
            if (errorCode === 3) {
              this.setState({
                step: 3
              })
            } else if (errorCode === 4) {
              this.setState({
                step: 2
              })
            } else {
              this.recaptchaSecRef.current.reset();
              this.setState({
                serverErrors: error.response.data.errors,
              });
            }
          }
        })
  };

  handleSubmitPhone = async () => {
    const {phone} = this.state;
    const asyncTokenCaptha = await this.recaptchaRef.current.executeAsync();
    if (asyncTokenCaptha) {
      defaultAxios.post('/api/front/v2/is-data-exists', {"phone": phone}, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
          .then(response => {
            if (response.data.phone) {
              // if (checkUser) { // old logic
              //   this.props.setFirstRegFromSms(true)
              // }
              this.setState({
                captcha: asyncTokenCaptha,
                step: 1,
                userName: response.data.name || 'User'
              });
            } else {
              defaultAxios.get('/api/v2/check-phone?phone=995'+phone, {
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                }
              }).then((res) =>{
                if (res.status === 200) {
                  const crmUserData = res?.data[0]?.data;
                  if (crmUserData) this.props.setCrmUserData({...crmUserData, phone: phone});
                  this.setState({
                    step: 'code-validate',
                    crmUser: crmUserData
                  })
                }
              });

              // this.setState({
              //   'phoneError': {phone: 'error'}
              // });
              // if (checkUser) { // old logic
              //   this.props.setUserPhone(phone);
              //   this.setState({
              //     step: 'from-sms'
              //   });
              // }

              // block signup
              //this.props.setUserPhone(phone);
              // this.props.history.push('/signup')
            }
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              this.recaptchaRef.current.reset();
            }
          });
    } else {
      this.recaptchaRef.current.reset();
    }
  };

  handleChangePhone = (value) => {
    if (Object.keys(this.state.phoneError).length > 0) {
      this.setState({
        phoneError: {}
      })
    }
    this.setState({
      phone: value
    })
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  handleChangeCaptcha = (value) => {
    this.setState({
      captcha: value
    })
  };

  render() {
    const {step, phone, crmUser} = this.state;
    return (
        <div className={`auth signin step-${step}`}>
          <Title title={this.props.t('signin.title')} />
          { step === 0 && <Step0 handleSubmit={this.handleSubmitPhone}
                                 handleChangePhone={this.handleChangePhone}
                                 handleChangeCaptcha={this.handleChangeCaptcha}
                                 recaptchaRef={this.recaptchaRef}
                                 {...this.state} {...this.props} /> }
          { step === 1 && <Step1 handleSubmit={this.handleSubmit}
                                 handleChangePassword={this.handleChange}
                                 handleChangeCaptcha={this.handleChangeCaptcha}
                                 recaptchaRef={this.recaptchaSecRef}
                                 {...this.state} {...this.props}/> }
          { step === 2 && <Step2 {...this.state} {...this.props}/> }
          { step === 3 && <BlockUser {...this.state} {...this.props}/> }
          { step === 'code-validate' && <CodeValidate {...this.props} phone={phone} crmUser={crmUser}/> }
          { step === 'from-sms' && <LinkPhonePopup {...this.props}/>}
          {/*<BackgroundImage />*/}
        </div>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged,
  currentRouteLink: state.common.currentRouteLink
});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
  setLogin: (token, expiration) => dispatch(setLogin(token, expiration)),
  setUserConfirm: (token) => dispatch(setUserConfirm(token)),
  setUserPhone: (phone) => dispatch(setUserPhone(phone)),
  setFirstRegFromSms: (status) => dispatch(setFirstRegFromSms(status)),
  setCrmUserData: (data) => dispatch(setCrmUserData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(SignIn));
