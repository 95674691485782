import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../parts/Title";
import Button from "../../form/Button";
import refill_img from "../../../../images/notify-icon-2.png";
import LangHOC from "../../HoC/LangHOC";
import {setWrapperClassName} from "../../../actions/common";

class AfterRegFirstLog extends Component {
  state = {
    popup: false
  };

  componentDidMount() {
    // this.openPopup()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.firstRegFromSms  && (this.props.firstLoginStatus && this.props.firstLoginStatus !== prevProps.firstLoginStatus)) {
      const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
      if (currentUser && currentUser.hasOwnProperty('status') && currentUser.status &&
          currentUser.status.find(el=>el.id == 1) && currentUser.hasOwnProperty('newUserRefillAllowed') &&
          currentUser['newUserRefillAllowed'] && !currentUser.points.find(el=>el.activity.name_en.toLowerCase() === 'survey')) {
        this.openPopup()
      }
    }
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  openPopup = () => {
    window.scrollTo(0, 0);
    this.setState({
      popup: true
    }, () => {
      this.props.setWrapperClassName("open-notify");
    })
  };

  closePopup = () => {
    this.setState({
      popup: false
    }, () => {
      this.props.setWrapperClassName("");
    })
  };

  render() {
    const {popup} = this.state;
    const {t} = this.props;
    if (popup) {
      return (
          <div className={`notify-refill-popup`}>
            <div className={`popup`}>
              <div className={`popup__close`} onClick={this.closePopup}></div>
              <div className={`container`}>
                <div className={`popup__wrap`}>
                  <img className={`popup__img`} src={refill_img} alt="done pic"/>
                  <Title title={t('notify_refill_popup.after_reg.title')} className={'white'} size={200}/>
                  <p className={`popup__text`}>{t('notify_refill_popup.after_reg.text')}</p>
                  <Button className={`popup__btn`} handleClick={this.closePopup}>{t('btns.agree')}</Button>
                </div>
              </div>
            </div>
          </div>
      )
    }
    return null
  }
}

const mapStateToProps = state => ({
  firstLoginStatus: state.auth.firstLoginStatus,
  firstRegFromSms: state.auth.firstRegFromSms,
});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className))
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(AfterRegFirstLog))
