import React, {Fragment} from "react";
import Form from "../../../../form/Form";
import Radio from "../../../../form/Radio";
import FormBtns from "../Survey/parts/FormBtns";
import pres_img_1 from "../../../../../../images/open-tasks/survey/square_CF_Icon.svg";
import pres_img_2 from "../../../../../../images/open-tasks/survey/square_FF_Icon.svg";
import pres_img_3 from "../../../../../../images/open-tasks/survey/square_icon_sweet_tipping.svg";
import pres_img_4 from "../../../../../../images/open-tasks/survey/square_LSS_Icon.svg";
import pres_img_5 from "../../../../../../images/open-tasks/survey/square_icon_tobacco.svg";

const SurveyQuestions = (props) => {
  return (
      <Form handleSubmit={props.setQuizSubmit} serverErrorQuantity={props.serverErrorQuantity}>
        <div className={`survey-form__col`}>
          {
            props.questions && Array.isArray(props.questions) && props.questions.length > 0 && (
                <Fragment>
                  {
                    props.questions.map((el, i) => {
                      let image_path;
                      if (i === 0) {
                        image_path = pres_img_1
                      } else if (i === 1) {
                        image_path = pres_img_2
                      } else if (i === 2) {
                        image_path = pres_img_3
                      } else if (i === 3) {
                        image_path = pres_img_4
                      } else if (i === 4) {
                        image_path = pres_img_5
                      }
                      return (
                          <div className={`survey-form__field radio-btn ${props['errorRadio'+i] ? 'error' : ''}`} key={el['question_id']}>
                            <p className={`survey-form__label`}>
                              <span className={`survey-form__count-label`}>{i+1}</span>
                              {el.description}
                            </p>
                            <div className={`survey-form__presentation-image image-height image-square-size`}>
                              <img src={image_path} alt="presentation"/>
                            </div>
                            <Radio name={'Radio'+i}
                                   className={`radio`}
                                   onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                            >
                              {el['answers'].sort((a, b) => {
                                return a.id - b.id
                              }).map((answer) => {
                                return <option key={answer.id}
                                               value={answer.id}
                                               id={answer.id}
                                               label={answer.name}/>
                              })}
                            </Radio>
                            <div className={'error-element'}>{props['errorRadio'+i]}</div>
                          </div>
                      )
                    })
                  }
                </Fragment>
            )
          }
        </div>
        {props.questionsError && <div className={'error-element'}>{props.t('open_task.sobranie.global_error')}</div>}
        {props.serverSubmitAnswersError && <div className={'error-element'}>{props.t('open_task.sobranie.global_submit_answer_error')}</div>}
        <FormBtns {...props} />
      </Form>
  );
};

export default SurveyQuestions;
