import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../parts/Title";
import Button from "../../form/Button";
import refill_img from "../../../../images/notify-icon-3.png";
import LangHOC from "../../HoC/LangHOC";
import {setWrapperClassName} from "../../../actions/common";

class AfterSurveyPopup extends Component {
  state = {
    popup: false
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.finish_prof_surv && this.props.finish_prof_surv !== prevProps.finish_prof_surv) {
      this.openPopup();
    }
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  openPopup = () => {
    window.scrollTo(0, 0);
    this.setState({
      popup: true
    }, () => {
      this.props.setWrapperClassName("open-notify");
    })
  };

  closePopup = () => {
    this.setState({
      popup: false
    }, () => {
      this.props.setWrapperClassName("");
      this.props.history.push('/tasks');
    })
  };

  render() {
    const {popup} = this.state;
    const {t} = this.props;
    if (popup) {
      return (
        <div className={`notify-refill-popup`}>
          <div className={`popup`}>
            <div className={`popup__close`} onClick={this.closePopup}></div>
            <div className={`container`}>
              <div className={`popup__wrap`}>
                <img className={`popup__img`} src={refill_img} alt="done pic"/>
                <Title title={t('notify_refill_popup.after_surv.title')} className={'white'} size={200}/>
                <p className={`popup__text`}>{t('notify_refill_popup.after_surv.text0')}</p>
                <p className={`popup__text`}>{t('notify_refill_popup.after_surv.text1')}</p>
                <p className={`popup__text`}>{t('notify_refill_popup.after_surv.text2')}</p>
                <Button className={`popup__btn`} handleClick={this.closePopup}>{t('btns.next_task')}</Button>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = state => ({
  finish_prof_surv: state.task.finish_prof_surv,
});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className))
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(AfterSurveyPopup))
