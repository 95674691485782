import React from "react";
import Title from "../../../../../parts/Title";
import PropTypes from "prop-types";
import FormUpload from "./FormUpload";

const UploadPhoto = ({
  t,
  defaultImg,
  surveyId,
  surveyDescription,
  setIsServerError,
  onSave,
}) => {
  return (
    <div className={`photo-pack-wrap`}>
      <div className={`survey-task-description`}>
        <Title
          title={t("open_task.photo_pack.pending_desc")}
          className={`page-title`}
          size={204}
        />
        <div className={`open-tasks__caption`}>
          <p className={`open-tasks__text`}>{surveyDescription}</p>
        </div>
      </div>
      <FormUpload
        surveyId={surveyId}
        t={t}
        onSave={onSave}
        defaultImg={defaultImg}
        setIsServerError={setIsServerError}
      />
    </div>
  );
};

UploadPhoto.propTypes = {
  defaultImg: PropTypes.string.isRequired,
  surveyId: PropTypes.number.isRequired,
  surveyDescription: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default UploadPhoto;
