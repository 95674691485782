import React, { useEffect, useMemo, useState } from "react";
import Done from "./Survey/parts/Done";
import ErrorResponse from "../../PageNotFound/ErrorResponse";
import SurveyQuestions from "./parts/SurveyQuestions";
import Title from "../../../parts/Title";
import SurveyInfo from "./Survey/parts/SurveyInfo";
import PropTypes from "prop-types";
import PageNotFound from "../../PageNotFound/PageNotFound";
import { useSurveys } from "../../../../hooks/useSurveys";
import { saveSurveyResults } from "../../../../api/requests";
import { useIsSurveyAvailable } from "../../../../hooks/useIsSurveyAvailable";
import { useHistory } from "react-router-dom";
import SurveyPopup from "../../MainPage/AllTasks/parts/SurveyPopup";
import { useUrlParams } from "../../../../hooks/useUrlParams";
import { tasksUrl } from "../../../../constants/path";

const ShortSurvey = ({ t, currentLanguage }) => {
  const history = useHistory();
  const { urlParams } = useUrlParams();

  const {
    shortSurvey: survey,
    isLoading: isGetSurveysLoading,
    isError: isGetSurveysError,
  } = useSurveys();

  const {
    isAvailable,
    shouldShowPopup,
    status,
    isLoading: isCheckSurveyAvailableLoading,
    isError: isCheckSurveyAvailableError,
  } = useIsSurveyAvailable(survey?.id);

  const [step, setStep] = useState(0);
  const [isSurveySaveError, setIsSurveySaveError] = useState(false);
  const [shouldShowUnavailablePopup, setShouldShowUnavailablePopup] =
    useState(false);

  const isError = useMemo(() => {
    return (
      isGetSurveysError || isCheckSurveyAvailableError || isSurveySaveError
    );
  }, [isGetSurveysError, isCheckSurveyAvailableError, isSurveySaveError]);

  const isLoading = useMemo(() => {
    return isGetSurveysLoading || isCheckSurveyAvailableLoading;
  }, [isGetSurveysLoading, isCheckSurveyAvailableLoading]);

  useEffect(() => {
    if (!shouldShowPopup || urlParams.notShowPopup) return;

    setShouldShowUnavailablePopup(true);
  }, [shouldShowPopup]);

  const closePopupHandler = () => {
    setShouldShowUnavailablePopup(false);
    if (shouldShowUnavailablePopup) history.push(tasksUrl);
    else history.push(`${tasksUrl}?notShowPopup=true`);
  };

  const onEndSurvey = (answers) => {
    const result = {
      lang: currentLanguage,
      survey_id: survey.id,
      survey_answers: answers,
    };

    saveSurveyResults(result, survey.type)
      .then(() => {
        setStep(1);
      })
      .catch((err) => {
        console.dir(err);
        setIsSurveySaveError(true);
      });
  };

  if (isLoading) return <></>;
  else if (isError) return <ErrorResponse />;
  else if (!survey || (!isAvailable && !shouldShowUnavailablePopup))
    return <PageNotFound />;

  return (
    <>
      <div className={`survey survey-short`}>
        {step === 0 && (
          <>
            <div className={`survey-task-description`}>
              <Title title={t("open_task.task_desc")} size={170} />
              <div className={`survey-wrap`}>
                <SurveyInfo t={t} />
              </div>
            </div>
            <div className={`survey-form survey-form-short`}>
              <SurveyQuestions
                surveyId={survey.id}
                onEndSurvey={onEndSurvey}
                allQuestions={survey.allQuestions}
                mainQuestions={survey.mainQuestions}
                relatedQuestions={survey.relatedQuestions}
                lang={currentLanguage}
                t={t}
              />
            </div>
          </>
        )}
        {step === 1 && <Done t={t} points={survey.points} />}
      </div>
      {shouldShowUnavailablePopup && (
        <SurveyPopup
          t={t}
          status={status}
          onClose={closePopupHandler}
          points={survey.points}
        />
      )}
    </>
  );
};

ShortSurvey.propTypes = {
  t: PropTypes.func.isRequired,
  currentLanguage: PropTypes.oneOf(["en", "ge"]).isRequired,
};

export default ShortSurvey;
