import React from "react";
import Title from "../../../parts/Title";
import FormBtns from "./FormBtns";
import ImagePreview from "./ImagePreview";

const Preview = (props) => {
  return (
      <div className={`photo-pack-wrap`}>
        <Title title={props.t('open_task.photo_pack.task_desc')} className={`page-title--v2`} size={170}/>
        <div className={`text-content`}>
          <p className={`text`}>{props.t('open_task.photo_pack.text1')}</p>
          <p className={`text`}>{props.t('open_task.photo_pack.text2')}</p>
        </div>
        <ImagePreview {...props}/>
        <FormBtns {...props}/>
      </div>
  )
};

export default Preview
