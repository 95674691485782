import React, {useState, useEffect, useRef} from "react";
import Title from "../../../parts/Title";
import Upload from "../../../form/Upload";
import Button from "../../../form/Button";
import {Link} from "react-router-dom";
import product_camel from "../../../../../images/open-tasks/survey/camel_compact.png";
import product_sob from "../../../../../images/open-tasks/survey/sobranie_research_v3.png";
import product_winston from "../../../../../images/open-tasks/survey/winston_research_v2.png";
import {getBase64} from "../../../../utils";
import Form from "../../../form/Form";
import defaultAxios from "../../../../axios";
import x_icon from "../../../../../images/open-tasks/icon-x.png";
// import victory_icon from "../../../../../images/open-tasks/survey/victory_icon.svg";
import {useDispatch, useSelector} from "react-redux";
import {setTaskStatus, setTaskStep} from "../../../../actions/taskActions";

const UploadPhoto = (props) => {
  const dispatch = useDispatch();
  const {taskVersion} = useSelector((state) => ({...state.task}));
  const [loading, setLoader] = useState(false);
  const [photo, setUploadPhoto] = useState(null);
  const [error, setError] = useState(null);
  const photoIdImg = useRef();
  const [defaultImg, setDefaultImg] = useState(null);

  useEffect(() => {
    if (taskVersion && taskVersion === "combo_v7") {
      setDefaultImg(product_sob)
    } else if (taskVersion && taskVersion === "combo_v10") {
      setDefaultImg(product_camel)
    } else if (taskVersion && taskVersion === "combo_v9") {
      setDefaultImg(product_winston)
    }
  }, [taskVersion]);

  const uploadPhotoHandle = (event) => {
    const photoInp = event.target.files[0];
    if (photoInp && isValidFileType(photoInp.type)) {
      getBase64(photoInp, (result) => {
        setError(null);
        if (!photoIdImg) return false;
        photoIdImg.current.src = result;
        setUploadPhoto(photoInp)
      });
    } else if (!isValidFileType(photoInp.type)) {
      setError('Photo must be a file of type: jpg, png, jpeg');
    } else if ((photoInp.size/(1024*1024)).toFixed(2) > 10) {
      setError('Photo may not be greater than 10000 kilobytes');
    } else {
      if (!photoIdImg) return false;
      photoIdImg.current.src = defaultImg;
    }
  };

  const quizSubmit = (formData) => {
    setLoader(true);
    const fd = new FormData();
    fd.append('photo', photo);
    defaultAxios.post('/api/combo-task/v2/photo', fd, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
        .then(res => {
          if (res?.data?.data) {
            if (props.taskStatus) {
              props.changeStep('upload-done');
              dispatch(setTaskStatus(null));
            } else {
              props.changeStep(2);
            }
          }
          setLoader(false);
        })
        .catch(err=>{
          setLoader(false);
        })
  };

  const isValidFileType = (fName) => {
    const extensionImages = ['image/jpeg', 'image/png', 'image/x-png'];
    return extensionImages.find(el => el === fName);
  };

  const handleRemovePhoto = () => {
    photoIdImg.current.src = defaultImg;
    setUploadPhoto(null);
  };

  return (
      <div className={`photo-pack-wrap`}>
        <Title title={props.t('open_task.photo_pack.pending_desc')} className={`page-title--v2`} size={170}/>
        {/*<div className={`photo-pack__icon-wrap`}>*/}
          {/*<img src={victory_icon} alt="victory"/>*/}
        {/*</div>*/}
        <div className={`text-content`}>
          {taskVersion === "combo_v7" && <p className={`text`}>{props.t('open_task.photo_pack.preview_sobr3')}</p>}
          {taskVersion === "combo_v10" && <p className={`text`}>{props.t('open_task.photo_pack.preview_camel4')}</p>}
          {taskVersion === "combo_v9" && <p className={`text`}>{props.t('open_task.photo_pack.preview_winston3')}</p>}
        </div>
        <div className={`photo-pack-image photo-pack-upload ${photo ? 'upload' : ''}`}>
          <div className={`photo-pack-upload__wrap`}>
            <button type={`button`} className={`photo-pack-upload__close`} onClick={handleRemovePhoto}>
              <img src={x_icon} alt="icon-x"/>
            </button>
            <img src={defaultImg} alt="product" ref={photoIdImg}/>
          </div>
        </div>
        <Form handleSubmit={quizSubmit} serverErrorQuantity={0}>
          <div className={`photo-pack-form__btns`}>
            {!photo ? (
                <Upload name={'pack_img'}
                        onChange={uploadPhotoHandle}
                        text={props.t('btns.upload_photo')}
                        className={`btn btn--v2`}
                />) : (
                <Button className={`btn--blue btn--v2`} type={'submit'} disabled={loading}>
                  {props.t('btns.submit')}
                </Button>
            )}
            <Button className={`btn--blue btn--v2`}>
              <Link to={{pathname: "/tasks"}}>
                {props.t('btns.back')}
              </Link>
            </Button>
            {error && <div className={'error-element'}>{error}</div>}
          </div>
        </Form>
      </div>
  )
};

export default UploadPhoto
