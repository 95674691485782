import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import Form from "../../../form/Form";
import Input from "../../../form/Input";
import Button from "../../../form/Button";
import defaultAxios from "../../../../axios";
import ReCAPTCHA from "react-google-recaptcha";
import {googleRecaptchaKey} from "../../../../constants/Variables";
import {setUserCode, setUserPhone} from "../../../../actions/auth";

function CodeValidate(props) {
  const {t, phone, crmUser, history} = props;
  const [code, setCode] = useState(null);
  const [captcha, setCaptcha] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const recaptchaRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(()=>{
    fetchData()
  }, []);

  async function fetchData() {
    try {
      const asyncTokenCaptha = await recaptchaRef.current.executeAsync();
      let newCaptha = asyncTokenCaptha || captcha;
      const fd = new FormData();
      fd.append('confirmation_phone', phone);
      fd.append('g-recaptcha-response', newCaptha);
      if (newCaptha) {
        let response = await defaultAxios.post('/api/front/v2/create-user', fd, {
          headers: {
            'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
            'Accept': 'application/json'
          }
        });
        if (response.status === 200) {
          recaptchaRef.current.reset();
        } else {
          recaptchaRef.current.reset();
        }
      } else {
        recaptchaRef.current.reset();
      }
    } catch (e) {
      console.error(e);
    }
  }

  const handleSubmit = () => {
    checkCode()
  };

  const handleChange = (e) => {
    setCodeError(null);
    setCode(e.target.value);
  };

  const handleChangeCaptcha = (value) => {
    setCaptcha(value)
  };

  const checkCode = () => {
    defaultAxios.post('api/front/v2/is-token-exist', {"token": code}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
        .then(response => {
          if (!(response?.data?.isset)) {
            setCodeError({confirmation_code: t('errors.verification_code')});
          } else {
            if (!crmUser) {
              dispatch(setUserPhone(phone));
              history.push('/signup');
            } else {
              dispatch(setUserCode(code));
              history.push('/confirm-profile');
            }
          }
        })
        .catch(error => {});
  };
  return (
      <div className={`auth__wrap`}>
        <div className={`auth__desc-wrap`}>
          <div className={`auth-desc`}>{t('cabinet.update_phone.text')}</div>
        </div>
        <Form handleSubmit={handleSubmit}>
          <div className={`auth__form`}>
            <Input name="confirmation_code"
                   placeholder={t('signup.code_placeholder')}
                   maxLength={6}
                   onChange={handleChange}
                   serverErrors={codeError}
                   validate={[
                     {required: true, message: t('errors.verification_code')},
                     {minLength: 6, message: t('errors.code_field')}
                   ]}
            />
            <div className="auth__captcha-wrapper">
              <ReCAPTCHA
                  sitekey={googleRecaptchaKey}
                  onChange={handleChangeCaptcha}
                  ref={recaptchaRef}
                  size="invisible"
              />
            </div>
            <div className={`auth__btns`}>
              <Button type={`submit`}>{t('btns.enter')}</Button>
            </div>
          </div>
        </Form>
      </div>
  )
}

export default CodeValidate
