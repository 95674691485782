import React, {Fragment} from 'react';
import Button from "../../../form/Button";
import Title from "../../../parts/Title";

function Step6 (props) {
  const handleGoTask = () => {
    props.setLogin(props.userConfirmationData.token, props.userConfirmationData.expiration);
    props.history.push('/tasks');
  };
  return (
      <Fragment>
        <Title title={props.t('confirm_profile.old_user_title')} size={208}/>
        <div className={`signup__wrap`}>
        <div className={`auth__form-wrap`}>
          <div className={`signup-finish`}>
            <p className={`signup-finish__title`}>{props.t('signup.finish_title')}, {props.user.firstname}!</p>
            <p className={`signup-finish__text`}>{props.t('confirm_profile.old_user_text1')}</p>
            <p className={`signup-finish__text`}>{props.t('confirm_profile.old_user_text2')}</p>
            <p className={`signup-finish__text`}>{props.t('confirm_profile.old_user_text3')}</p>
            <Button type={'button'} handleClick={handleGoTask}>{props.t('btns.select_task')}</Button>
          </div>
        </div>
        </div>
      </Fragment>
  )
}

export default Step6
