import React from "react";
import Button from "../../form/Button";
import mobile from "../../../../images/shop/mobile.png";
import {formatDate} from "../../../utils";

const PrizeMobileRefill = (props) => {
  const {orderMobileHandler, prize, mapDailyAmount, type_value} = props;
  return (
      <div className={`shop-prize `}>
        <div className={`prize-image`}>
          <img className={`prize-image__image`} src={mobile} alt="prize mobile refill"/>
          <div className={`prize-image__cloud`}>
            <span className={`prize-image__amount`}>{(prize.points) || 0}</span>
            <span className={`prize-image__text`}>points</span>
          </div>
        </div>
        <p className={`prize-type`}>{prize.value.split(' ')[1]} {props.t('shop.prize_type_mobile')}</p>
        <p className={`prize-amount`}>{props.t('shop.prize_amount')}: {(prize.amount) || 0}</p>
        <Button disabled={(/*type_value === prize.value &&*/ props.loading) || props.user_points<prize.points || prize.amount <= '0'} className={`prize-btn`}
                handleClick={()=>orderMobileHandler(prize['prize_activity_id'], 'mobile', prize.value.split(' ')[1], prize.value)}>
          {props.t('btns.order')}
        </Button>
        {/*<div className={`prize-reward-stock`}>*/}
          {/*<p className={`prize-reward-stock__title`}>{props.t('shop.reward_stock')} {mapDailyAmount[prize.value]} {props.t('shop.reward_stock_1')}</p>*/}
          {/*<p className={`prize-reward-stock__time`}>*/}
            {/*{formatDate(prize['date_reward_stock_refiled'].split(' ')[0])} {props.currentLanguage === 'en'?'at':null} {prize['date_reward_stock_refiled'].split(' ')[1]} {props.currentLanguage === 'ge'?'სთ-ზე':null}*/}
          {/*</p>*/}
        {/*</div>*/}
      </div>
  )
};

export default PrizeMobileRefill
