import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom';
import Button from "../../../form/Button";
import { Context } from "../Provider";
import LangHOC from "../../../HoC/LangHOC";

function HowToBottom(props) { 

    const { currentPage, updatePage, aCallback } = useContext(Context);
    const [active, setActive] = useState(false);

    const history = useHistory();

    //Number of slides
    const steps = 11;

    const onClick = () => {
        setActive(true);
        setTimeout(()=> {setActive(false)}, 4000)
        if((currentPage + 1) < steps) {
            updatePage(currentPage + 1);
        } else {
            history.push("/");
        }
    };

    return (
        <div className={'how-to-page__bottom'}>
            <Button handleClick={onClick} disabled={active}>
                {currentPage !== (steps-1) ? props.t('howTo.btnNext') : props.t('howTo.btnFinish')}
            </Button>
        </div>
    )
}

export default LangHOC(HowToBottom)
