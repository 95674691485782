import React, {useState} from "react";
import Title from "../../../parts/Title";
import Button from "../../../form/Button";
import Form from "../../../form/Form";
import Checkbox from "../../../form/Checkbox";
import Terms from "../../../parts/Terms";

const RulesPopup = (props) => {
  const {closePopup, handlecheckboxRules} = props;
  const [checkRules, setCheckRules] = useState(false);

  const handleCheckRules = (e) => {
    setCheckRules(e.target.checked)
  };

  const handleClosePopup = () => {
    if (checkRules) {
      handlecheckboxRules();
      closePopup();
    }
  };

  return (
      <div className={`notify-prize-popup rules-popup`}>
        <div className={`popup`}>
          <div className={`popup__close`} onClick={() => closePopup()}></div>
          <div className={`container`}>
            <div className={`popup__wrap rules-terms`}>
              <Title title={props.t('signup.rules_title')} size={208}/>
              <div className={`signup-rules`}>
                <div className={`signup-rules__text`}>
                  <Terms/>
                </div>
                <Form handleSubmit={handleClosePopup}>
                  <Checkbox name={'new_rules_agreement'}
                            onChange={(e) => handleCheckRules(e)}
                            id={'signup_rules_confirmation_popup'}
                            label={props.t('signup.rules_checkbox')}
                            validate={[{
                              required: true,
                              message: props.t('errors.req_field')
                            }]}/>
                  <div className={`auth__btns`}>
                    <Button type={`submit`}>{props.t('btns.continue')}</Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
};

export default RulesPopup
