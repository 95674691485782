import * as types from "../constants/ActionTypes";

const initialState = {
  first_research_status: null,
  second_research_status: null,
  third_research_status: null,
  finish_prof_surv: false,
  taskPending: null,
  taskTake: null,
  taskTakeInfo: null,
  taskStep: null,
  taskStatus: null,
  taskVersion: null,
};

export default function task(state = initialState, action) {
  switch (action.type) {
    case types.SET_TASK_PENDING:
      return {
        ...state,
        taskPending: action.task_pending,
      };
    case types.SET_TASK_TAKE:
      return {
        ...state,
        taskTake: action.task_take,
      };
    case types.SET_TASK_TAKE_INFO:
      return {
        ...state,
        taskTakeInfo: action.task_take_info,
      };
    case types.SET_TASK_STEP:
      return {
        ...state,
        taskStep: action.step,
      };
    case types.SET_TASK_STATUS:
      return {
        ...state,
        taskStatus: action.status,
      };
    case types.SET_TASK_VERSION:
      return {
        ...state,
        taskVersion: action.version,
      };
    case types.SET_STATUS_FIRST_RESEARCH:
      return {
        ...state,
        first_research_status: action.status,
      };
    case types.SET_STATUS_SECOND_RESEARCH:
      return {
        ...state,
        second_research_status: action.status,
      };
    case types.SET_STATUS_THIRD_RESEARCH:
      return {
        ...state,
        third_research_status: action.status,
      };
    case types.SET_FINISH_PROFILING_SURVEY:
      return {
        ...state,
        finish_prof_surv: action.status,
      };
    default:
      return state;
  }
}
