import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import Blocked from "./BlockedPopup";
import {setWrapperClassName} from "../../../../actions/common";
import ProfileForm from "./ProfileForm";
import UpdatePhone from "./updatePhone";

const Profile = (props) => {
  const {t} = props;
  const {wrapperClass} = useSelector((state) => ({...state.common}));
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const [openChangePhoneForm, setOpenForm] = useState(false);
  const [showUserPhone, setUsedPhone] = useState(false);

  const openUpdatePhonePopup = (showUsedPhone) => {
    setUsedPhone(showUsedPhone);
      togglePhoneForm(true);
      const upClassName = ["change-phone-popup", wrapperClass].filter(e => !!e).join(" ");
      dispatch(setWrapperClassName(upClassName));
  };

  const closePopup = () => {
    setPopup(false);
    const upClassName = wrapperClass.split("open-popup").pop();
    dispatch(setWrapperClassName(upClassName));
  };

  const togglePhoneForm = (status) => {
    setOpenForm(status);
  };

  const showBlockedPopup = (wrapClass) => {
    const upClassName = wrapperClass.split(wrapClass).pop();
    const updateClassName = ["open-popup", upClassName].filter(e => !!e).join(" ");
    dispatch(setWrapperClassName(updateClassName));
    setPopup(true);
  };

  return (
      <div className={`profile`}>
        {!openChangePhoneForm && <ProfileForm t={t} openPopup={openUpdatePhonePopup}/>}
        {openChangePhoneForm &&
        <UpdatePhone
            t={t}
            togglePhoneForm={togglePhoneForm}
            showUserPhone={showUserPhone}
            showBlockedPopup={showBlockedPopup}
        />}
        {popup && <Blocked t={t} closePopup={closePopup}/>}
      </div>
  );
};

export default Profile
