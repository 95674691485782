import { z } from 'zod'

export const surveyQuestionService = survey => {
    const answerSchema = z.object({
        layout: z.union([z.literal('answer'), z.literal('wysiwyg')]),
        key: z.string(),
        attributes: z.object({
            answer_image: z.string().optional(),
            answer_en: z.string(),
            answer_ge: z.string().nullable(),
        }),
    })

    const questionSchema = z.object({
        layout: z.union([z.literal('checkbox'), z.literal('scale'), z.literal('image'), z.literal('radio_button')]),
        key: z.string(),
        attributes: z.object({
            related_question: z.string().nullable().optional(),
            related_answer: z.string().nullable().optional(),
            answer: z.array(answerSchema).optional(),
            question_en: z.string(),
            question_ge: z.string().nullable(),
            question_image: z.string().optional(),
            has_custom_answer: z.boolean().optional(),
        }),
    })

    const shortSurveyResultSchema = z.object({
        id: z.number(),
        points: z.string(),
        allQuestions: z.array(questionSchema).nonempty(),
        mainQuestions: z.array(questionSchema).nonempty(),
        relatedQuestions: z.array(questionSchema),
        type: z.literal('short'),
    });

    const longSurveyResultSchema = z.object({
        id: z.number(),
        points: z.string(),
        allQuestions: z.array(questionSchema).nonempty(),
        mainQuestions: z.array(questionSchema).nonempty(),
        relatedQuestions: z.array(questionSchema),
        type: z.literal('long'),
        descriptionEn: z.string(),
        descriptionGe: z.string().nullable(),
        imagePath: z.string(),
    });

    const customAnswer = answerKey => ({
        layout: 'answer',
        key: answerKey,
        attributes: {
            answer_en: 'Your answer',
            answer_ge: 'შენი პასუხი',
        },
    })

    const allQuestions = survey.details.map(question =>
        question.attributes.has_custom_answer
            ? {
                  ...question,
                  attributes: {
                      ...question.attributes,
                      answer: [...question.attributes.answer, customAnswer(question.key)],
                  },
              }
            : question,
    )

    const mainQuestions = []
    const relatedQuestions = []

    for (let question of allQuestions) {
        if (question.attributes.related_question && question.attributes.related_answer) {
            relatedQuestions.push(question)
        } else {
            mainQuestions.push(question)
        }
    }
    const result = {
        type: survey.type,
        id: survey.id,
        descriptionEn: survey.description_en,
        descriptionGe: survey.description_ge,
        imagePath: survey.image_path,
        points: survey.points,
        allQuestions,
        mainQuestions,
        relatedQuestions,
    }

    const res =
        survey.type === 'short'
            ? shortSurveyResultSchema.safeParse(result)
            : longSurveyResultSchema.safeParse(result)

    if (res.success) return res.data

    console.dir(res.error.format())
    throw new Error('Survey validation data failed')
};
