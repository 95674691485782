import React, {Component} from "react"
import PageNotFound from "../page/PageNotFound/PageNotFound";
import {withRouter} from "react-router-dom";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error, errorInfo})
  }

  render() {
    console.log('RENDERING ERROR BOUNDARY WITH STATE:', this.state);
    if (this.state.hasError) {
      return <PageNotFound/>
    } else {
      return this.props.children
    }
  }

}

export default withRouter(ErrorBoundary)
