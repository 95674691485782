import React from 'react';
import {Link} from "react-router-dom";
import Button from "../../../form/Button";

function Finish (props) {
  return (
      <div className={`auth__wrap`}>
        <div className={`auth__desc-wrap`}>
          <div className={`auth-desc`}>
            {props.t('new_password.success_desc')}
          </div>
        </div>
        <div className={`auth__form`}>
          <div className={`auth__btns`}>
            <Button><Link to={{pathname: "/signin"}}>{props.t('btns.signin')}</Link></Button>
          </div>
        </div>
      </div>
  )
}

export default Finish
