export const getUserFromLS = () => {
  const storedUser = localStorage.getItem("user.me");
  const storedUserExists = storedUser !== null;

  return storedUserExists ? JSON.parse(storedUser) : null;
};

export const getUserBlockedPopupFromLS = () => {
  const storedUserBlockedPopup = localStorage.getItem("block_popup");
  const storedUserBlockedPopupExists = storedUserBlockedPopup !== null;

  return storedUserBlockedPopupExists
    ? JSON.parse(storedUserBlockedPopup)
    : null;
};

export const getUserConfirmedPopupFromLS = () => {
  const storedUserConfirmPopup = localStorage.getItem("confirm_popup");
  const storedUserConfirmPopupExists = storedUserConfirmPopup !== null;

  return storedUserConfirmPopupExists
    ? JSON.parse(storedUserConfirmPopup)
    : null;
};

export const getIsUserHasImageIdModerationStatusFromLS = () => {
  const currentUser = getUserFromLS();

  return !currentUser?.status
    ? false
    : currentUser.status.some((el) => el.id === 1) &&
        currentUser.hasOwnProperty("imageIdModerationStatus") &&
        !currentUser.imageIdModerationStatus &&
        (currentUser.IdUrl === "not provided" || !currentUser.IdUrl);
};

export const getUserIdObjFromLS = () => {
  const currentUser = getUserFromLS();

  return !currentUser ? {} : { ["userId" + currentUser.id]: true };
};

export const setUserBlockedPopup = () => {
  const userBlockedPopup = getUserBlockedPopupFromLS();
  const userIdObj = getUserIdObjFromLS();
  const modifyObj = !!userBlockedPopup
    ? { ...userBlockedPopup, ...userIdObj }
    : userIdObj;

  localStorage.setItem("block_popup", JSON.stringify(modifyObj));
};

export const setUserConfirmedPopup = () => {
  const userConfirmedPopup = getUserConfirmedPopupFromLS();
  const userIdObj = getUserIdObjFromLS();
  const modifyObj = !!userConfirmedPopup
    ? { ...userConfirmedPopup, ...userIdObj }
    : userIdObj;

  localStorage.setItem("confirm_popup", JSON.stringify(modifyObj));
};
