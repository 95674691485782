import React from "react";
import PrizeMobileRefill from "./PrizeMobileRefill";
import PrizeGift from "./PrizeGift";
import Slider from "react-slick";
import {Link} from "react-router-dom";

const ShopChoose = (props) => {
  const mapDailyAmount = {
    'refill 5': 20,
    'refill 10': 5,
    'refill 20': 5,
    'voucher 20 G': 5,
    'voucher 50 G': 5,
    'voucher 100 G': 2
  };
  let slides = props.prizes && props.prizes.length > 0 && props.prizes.sort((a, b) => {
    const aArr = a.value.split(' ');
    const bArr = b.value.split(' ');
    if (aArr[0] < bArr[0]) return -1;
    if (aArr[0] > bArr[0]) return 1;
    return a.points - b.points;
  }).map(prize => {
    if (prize.value.split(' ')[0] === 'refill') {
      // if (prize.value.indexOf(5) >= 0) return false;
      // if (prize.value.indexOf(10) >= 0) return false;
      if (prize.value.indexOf(20) >= 0) return false;
      return <PrizeMobileRefill key={prize['prize_activity_id']} {...props} prize={prize} mapDailyAmount={mapDailyAmount}/>
    } else if (prize.value.split(' ')[0] === 'voucher' || (prize.value.split(' ')[1] && prize.value.split(' ')[1] === 'TKT')) {
      // let lifestyleActivity = null;
      // let updatePrize = Object.assign({}, prize);
      // if (props.user && props.user['points'] && props.user['points'].length > 0) {
      //   lifestyleActivity = props.user['points'].find(item => {
      //     return item.activity['name_en'].toLowerCase().indexOf('survey_w_231') > -1
      //   });
      // }
      // if (lifestyleActivity && prize.points == "100") {
      //   updatePrize.points = '50'
      // }
      if (prize.value.indexOf('voucher') >= 0) {
        if (prize.value.indexOf(20) >= 0) return false;
        if (prize.value.indexOf(50) >= 0) return false;
        if (prize.value.indexOf(100) >= 0) return false;
      } else if (prize.value.indexOf('TKT') >= 0) {}

      return <PrizeGift key={prize['prize_activity_id']} prize={prize} {...props} mapDailyAmount={mapDailyAmount}/>
    } else {
      return null
    }
  });
  const settings = {
    draggable: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false
        }
      }
    ]
  };
  return (
      <div className={`shop-choose`}>
        {/*<div className={`shop-choose-attention`}><p>{props.t('shop.shop_attention')} <Link to={'/rules'}>{props.t('shop.shop_attention_link')}</Link> {props.t('shop.shop_attention_1')}</p></div>*/}
        {props.prizes != null && props.prizes.length > 0 &&
        <Slider {...settings}>
          {slides}
        </Slider>}
      </div>
  )
};

export default ShopChoose
