import PopupPhotoIdAttention from "../../Tasks/PopupPhotoIdAttention";
import PopupCheckLimit from "../../Tasks/PopupCheckLimit";
import TryAgain from "../../../OpenTasks/PhotoPack/parts/TryAgain";
import Blocked from "../../../OpenTasks/PhotoPack/parts/Blocked";
import Finished from "../../../OpenTasks/PhotoPack/parts/Finished";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  LONG_SURVEY_STATUS,
  SURVEY_UNAVAILABLE_STATUS,
} from "../../../../../constants/Tasks";
import { useHistory } from "react-router-dom";
import { setWrapperClassName } from "../../../../../actions/common";
import { useDispatch, useSelector } from "react-redux";
import { longSurveyUrl } from "../../../../../constants/path";

const SurveyPopup = ({ status, onClose, onPhotoUpload, points, t }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const wrapperClass = useSelector((state) => state.common.wrapperClass);

  useEffect(() => {
    const upClassName = ["open-popup", "open-survey-popup", wrapperClass]
      .filter((e) => !!e)
      .join(" ");
    dispatch(setWrapperClassName(upClassName));
  }, [dispatch]);

  const closePopupHandler = () => {
    if (onClose) onClose();
    const upClassName = wrapperClass.split("open-popup").pop();
    dispatch(setWrapperClassName(upClassName));
  };

  return (
    <>
      {status === SURVEY_UNAVAILABLE_STATUS.id_need_approve && (
        <PopupPhotoIdAttention t={t} closePopup={closePopupHandler} />
      )}
      {status ===
        SURVEY_UNAVAILABLE_STATUS.completed_the_available_number_of_surveys && (
        <PopupCheckLimit t={t} closePopup={closePopupHandler} />
      )}
      {status === LONG_SURVEY_STATUS.photo_rejected && (
        <TryAgain
          t={t}
          closePopup={closePopupHandler}
          handleComboTask={() => {
            if (onPhotoUpload) onPhotoUpload();
            else history.push(`${longSurveyUrl}?notShowPopup=true`);
          }}
        />
      )}
      {status === LONG_SURVEY_STATUS.unfinished && (
        <Blocked t={t} closePopup={closePopupHandler} />
      )}
      {status === LONG_SURVEY_STATUS.finished && (
        <Finished t={t} closePopup={closePopupHandler} points={points} />
      )}
    </>
  );
};

SurveyPopup.propTypes = {
  status: PropTypes.oneOf([
    SURVEY_UNAVAILABLE_STATUS.id_need_approve,
    SURVEY_UNAVAILABLE_STATUS.completed_the_available_number_of_surveys,
    LONG_SURVEY_STATUS.photo_rejected,
    LONG_SURVEY_STATUS.unfinished,
    LONG_SURVEY_STATUS.finished,
  ]).isRequired,
  onClose: PropTypes.func,
  onPhotoUpload: PropTypes.func,
  points: PropTypes.string,
  t: PropTypes.func.isRequired,
};

export default SurveyPopup;
