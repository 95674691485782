import React from "react";
import Title from "../../../../parts/Title";
import { Link } from "react-router-dom";
import Button from "../../../../form/Button";
import done_img from "../../../../../../images/open-tasks/survey/survey-done.png";

const UploadDone = (props) => {
  return (
    <div className={`open-tasks-finish`}>
      <Title
        title={props.t("open_task.done")}
        className={`page-title--v2`}
        size={170}
      />
      <div className={`open-tasks-finish__wrap`}>
        <div className={`photo-pack-finish__img`}>
          <img src={done_img} alt="done pic" />
        </div>
        <p className={`photo-pack-finish__text`}>
          {props.t("open_task.photo_pack.finish.uploadDoneText1")}
          {props.points} points
        </p>
        <p
          className={`photo-pack-finish__text`}
          dangerouslySetInnerHTML={{
            __html: props.t("open_task.photo_pack.finish.uploadDoneText2"),
          }}
        ></p>
        <Button className={`open-tasks-finish__btn`}>
          <Link to={"/tasks"}>{props.t("btns.got_it")}</Link>
        </Button>
      </div>
    </div>
  );
};

export default UploadDone;
