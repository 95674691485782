import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setActiveMainSection,
  setWrapperClassName,
} from "../../../../actions/common";
import LangHOC from "../../../HoC/LangHOC";
import Tasks from "./Tasks";

class TasksV2Container extends Component {
  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  render() {
    return (
      <div className={`main-page`}>
        <Tasks {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogged: state.auth.isLogged,
});

const mapDispatchToProps = (dispatch) => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
  setActiveMainSection: (section) => dispatch(setActiveMainSection(section)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LangHOC(TasksV2Container));
