import React from "react"
import {connect} from "react-redux"
import {setActiveLanguage} from "../../actions/common";

const LanguageSelector = props => {
  const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    props.setActiveLanguage(lang);
  };

    return (
        <ul className="language-selector" title={'Language'}>
          {props.languages.map((lang) => {
            return (
                <li key={lang} className={lang === props.currentLanguage ? 'active' : ''}>
                  <button onClick={() => changeLanguage(lang)}>{lang}</button>
                </li>
            )
          })}
        </ul>
    )

}

const mapStateToProps = state => ({
  languages: state.common.languages,
  currentLanguage: state.common.currentLanguage
});

const mapDispatchToProps = dispatch => ({
  setActiveLanguage: (lang) => dispatch(setActiveLanguage(lang))
});

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(LanguageSelector));
