import React, {useState, useEffect} from "react";
import product_camel from "../../../../../images/open-tasks/survey/camel_compact.png";
import product_sob from "../../../../../images/open-tasks/survey/sobranie_research_v3.png";
import product_winston from "../../../../../images/open-tasks/survey/winston_research_v2.png";

const ImagePreview = (props) => {
  const [productImg, setProductImg] = useState(null);

  useEffect(() => {
    if (props.taskVersion && props.taskVersion === "combo_v7") {
      setProductImg(product_sob)
    } else if (props.taskVersion && props.taskVersion === "combo_v10") {
      setProductImg(product_camel)
    } else if (props.taskVersion && props.taskVersion === "combo_v9") {
      setProductImg(product_winston)
    }
  }, [props.taskVersion]);

  return (
    <div className={`photo-pack-image`}>
      <div className={`photo-pack-image__wrap`}>
        <div className={`photo-pack-image__product-img ${props.taskVersion === "combo_v10" ? 'camel-size' : ''}`}>
          <img src={productImg} alt="product"/>
        </div>
        <div className="photo-pack-image__content">
          <p className="photo-pack-image__title">{props.t('open_task.photo_pack.points_reward')}: </p>
          <p className="photo-pack-image__text">25</p>
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;
