import React from "react";
import Title from "../../../../../parts/Title";
import SurveyInfo from "./SurveyInfo";
import SurveyQuestions from "./SurveyQuestions";
import ErrorBoundary from "../../../../../parts/ErrorBoundary";

const SurveyTask = (props) => {
    return (
        <div className={`survey-task-description`}>
          <Title title={props.t('open_task.task_desc')} size={170}/>
          <div className={`survey-wrap`}>
            <SurveyInfo {...props}/>
            <div className={`survey-form`}>
              <ErrorBoundary>
                <SurveyQuestions {...props}/>
              </ErrorBoundary>
            </div>
          </div>
        </div>
    );
}

export default SurveyTask;
