import React from 'react';
import {Link} from "react-router-dom";
import Button from "../../../form/Button";

function Finish (props) {
  return (
      <div className={`feedback-finish`}>
        <p className={`feedback-finish__title`}>{props.t('feedback.success.title')}</p>
        <p className={`feedback-finish__text`}>{props.t('feedback.success.text')}</p>
        <div className={`feedback-finish__btn`}>
          <Button><Link to={{pathname: "/faq"}}>{props.t('btns.visit')}</Link></Button>
        </div>

      </div>
  )
}

export default Finish
