const getValueByPath = function getValueByPath(obj, path, def) {
  var stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== "string") return path;

    // Create new array
    var output = [];

    // Split to an array with dot notation
    path.split(".").forEach(function (item) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key);
        }
      });
    });

    return output;
  };

  // Get the path as an array
  path = stringToPath(path);

  // Cache the current object
  var current = obj;

  // For each item in the path, dig into the object
  for (var i = 0; i < path.length; i++) {
    // If the item isn't found, return the default (or null)
    if (!current[path[i]]) return def;

    // Otherwise, update the current  value
    current = current[path[i]];
  }

  if (typeof current === "object" && current !== null) {
    current = "incorrect pathname";
  }

  return current;
};

/*function getValueByPath(obj, path, wrong) {
  const splitPath = path.split('.');
  if (!splitPath.length) return obj[splitPath]
  let current
  if (splitPath.reduce((obj, level) => obj && obj[level], obj)) {
    current = splitPath.reduce((obj, level) => obj && obj[level], obj)
    if (typeof current === 'object' && current !== null) {
      current = 'incorrect pathname'
    }
  } else {
    current = wrong
  }
  return current
}*/

const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

const replaceStr = (str, index) => {
  if (!str) return;
  let re = new RegExp(index, "g");
  return `${str.replace(re, ` <strong>${index}</strong>`)}`;
};

const replaceStrBySymbol = (str, sym1, sym2) => {
  if (!str) return;
  if (!sym1 || !sym2) return str;
  let EditString = `${str.replaceAll(sym1, `${sym1}<br/><em>`)}`;
  return `${EditString.replaceAll(sym2, `${sym2}</em><br/>`)}`;
};

const replaceAllStrBySymbol = (str, sym1, sym2) => {
  if (!str) return;
  if (!sym1 || !sym2) return str;
  let EditString = `${str.replaceAll(sym1, ` <br/><em>${sym1}`)}`;
  return `${EditString.replaceAll(sym2, `${sym2}</em><br/>`)}`;
};

const replaceAllStrBySymbolBold = (str, sym1, sym2) => {
  if (!str) return;
  if (!sym1 || !sym2) return str;
  let EditString = `${str.replaceAll(sym1, ` <strong>${sym1}`)}`;
  return `${EditString.replaceAll(sym2, `${sym2}</strong>`)}`;
};

const formatDate = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};

const axiosHeaders = (contentType) => ({
  headers: {
    ...(!!contentType && {
      "Content-Type": `${contentType}; boundary=WebAppBoundary`,
    }),
    Accept: "application/json",
    Authorization: "bearer " + localStorage.getItem("token"),
  },
});

export {
  getValueByPath,
  getBase64,
  replaceStr,
  replaceAllStrBySymbol,
  formatDate,
  replaceAllStrBySymbolBold,
  replaceStrBySymbol,
  axiosHeaders,
};
