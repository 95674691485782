import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../../parts/Title";
import defaultAxios from "../../../../axios";
import {
  setStatusFirstResearch,
  setStatusSecondResearch, setStatusThirdResearch, setTaskStatus, setTaskStep, setTaskVersion,
} from "../../../../actions/taskActions";
import {setWrapperClassName} from "../../../../actions/common";
import PopupCheckLimit from "../Tasks/PopupCheckLimit";
import Button from "../../../form/Button";
import {Link} from "react-router-dom";
import pres_1 from "../../../../../images/surv-1.svg";
import pres_2 from "../../../../../images/surv-2.svg";
import pres_3 from "../../../../../images/surv-3.svg";
import pres_4 from "../../../../../images/surv-4.svg";
import TryAgain from "../../OpenTasks/PhotoPack/parts/TryAgain";
import Blocked from "../../OpenTasks/PhotoPack/parts/Blocked";
import Finished from "../../OpenTasks/PhotoPack/parts/Finished";
import PopupPhotoIdAttention from "../Tasks/PopupPhotoIdAttention";
import {axiosHeaders} from "../../../../utils";

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: null,
      selectTask: null,
      retail_auditTask: null,
      survey: null,
      research_v2: null,
      research_v3: null,
      research_v4: null,
      popup: false,
      photoIdStatus: null,
      imageIdModerationStatus: null,
      comboStatus: null,
      comboVersion: null
    };
  }

  componentDidMount() {
    this.props.setWrapperClassName("wrapper-logged");

    const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
    let imageIdModerationStatus = (currentUser && currentUser.status && currentUser.status.find(el => el.id == 1)) &&
        (currentUser.hasOwnProperty('imageIdModerationStatus') && !currentUser.imageIdModerationStatus) &&
        (currentUser.IdUrl === 'not provided' || !currentUser.IdUrl);
    this.setState({
      imageIdModerationStatus
    });

    imageIdModerationStatus && defaultAxios.get('/api/survey-search-tasks/v2/canTakeResearch?survey_type=id_upload', axiosHeaders()).then(res => {
      this.setState({
        photoIdStatus: !res.data.data
      })
    }).catch(err => {
    });

    defaultAxios.get('/api/survey-search-tasks/v2/canTakeResearch?survey_type=survey_w_236', axiosHeaders()).then(res => {
      this.props.setStatusFirstResearch(res.data.data);
      this.setState({
        research_v2: res.data.data === 'id_need_approve' ? res.data.data : !res.data.data
      })
    }).catch(err => {
    });

    // defaultAxios.get('/api/survey-search-tasks/v2/canTakeResearch?survey_type=survey_w_236', axiosHeaders()).then(res => {
    //   this.props.setStatusSecondResearch(res.data.data);
    //   this.setState({
    //     research_v3: res.data.data === 'id_need_approve' ? res.data.data : !res.data.data
    //   })
    // }).catch(err => {
    // });

    defaultAxios.get('/api/survey-search-tasks/v2/canTakeResearch?survey_type=survey_w_237', axiosHeaders()).then(res => {
      this.props.setStatusThirdResearch(res.data.data);
      this.setState({
        research_v4: res.data.data === 'id_need_approve' ? res.data.data : !res.data.data
      })
    }).catch(err => {
    });

    defaultAxios.get('/api/tasks/v2/user-tasks', axiosHeaders())
        .then(res => {
          if (res.data && res.data.data && res.data.data.tasks) {
            let retail_audit = res.data.data.tasks.filter(el => el['task_type'] === 'retail_audit');
            let survey = res.data.data.tasks.find(el => el['name_en'].toLowerCase() === 'survey');
            this.setState({
              retail_auditTask: retail_audit,
              survey: survey
            })
          }
          if (res.data && res.data.data && res.data.data.task_count) {
            let taskLimit = res.data.data.task_count.find(count => count['count_tasks']);
            this.setState({
              limit: taskLimit && taskLimit.count_tasks ? taskLimit.count_tasks : null
            })
          }
        })
        .catch(err => {
        });

    if (currentUser) {
      this.comboTaskData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
    if (this.props.meInfo && this.props.meInfo !== prevProps.meInfo && JSON.parse(localStorage.getItem("user.me")) &&
        (JSON.parse(localStorage.getItem("user.me")).hasOwnProperty('imageIdModerationStatus') &&
            !JSON.parse(localStorage.getItem("user.me")).imageIdModerationStatus) &&
        (JSON.parse(localStorage.getItem("user.me")).IdUrl === 'not provided' || !JSON.parse(localStorage.getItem("user.me")).IdUrl)) {
      let imageIdModerationStatus = currentUser && currentUser.status && currentUser.status.find(el => el.id == 1);
      this.setState({
        imageIdModerationStatus
      });

      imageIdModerationStatus && defaultAxios.get('/api/survey-search-tasks/v2/canTakeResearch?survey_type=id_upload', axiosHeaders()).then(res => {
        this.setState({
          photoIdStatus: !res.data.data
        })
      }).catch(err => {
      });
    }
    if (this.props.meInfo && this.props.meInfo !== prevProps.meInfo && currentUser) {
      this.comboTaskData();
    }
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  photoIdHandler = () => {
    this.props.history.push('/open-tasks/photo-id')
  };

  surveyHandler = (task) => {
    if ((this.state.research_v2 === 'id_need_approve' && /*this.state.research_v3 === 'id_need_approve' &&*/ this.state.research_v4 === 'id_need_approve') && task === 'research') {
      const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
      this.props.setWrapperClassName(upClassName);
      this.setState({
        popup: 'id_need_approve'
      });
      return false
    } else if ((this.state.research_v2 && /*this.state.research_v3 &&*/ this.state.research_v4) && task === 'research') {
      return false
    } else if (this.state.limit >= 8) {
      const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
      this.props.setWrapperClassName(upClassName);
      this.setState({
        popup: 'limit'
      });
      return false
    } else if (task === 'research') {
      this.props.history.push('/open-tasks/research')
    } else if (task === 'survey') {
      this.props.history.push('/open-tasks/survey')
    }
  };

  closePopup = () => {
    this.setState({
      popup: false,
      selectTask: null
    }, () => {
      const upClassName = this.props.wrapperClass.split("open-popup").pop();
      this.props.setWrapperClassName(upClassName);
    })
  };

  handleComboTask = () => {
    const {comboStatus, comboVersion} = this.state;
    if (comboStatus === 'photo') {
      this.props.setTaskStep(0);
      this.props.setTaskVersion(comboVersion);
      this.props.history.push(`/open-tasks/photo-pack`);
    } else if (comboStatus === 'photo_check') {
      this.props.setTaskStep('upload-done');
      this.props.history.push(`/open-tasks/photo-pack`);
    } else if (comboStatus === 'questions') {
      this.props.setTaskStep(2);
      this.props.setTaskVersion(comboVersion);
      this.props.history.push(`/open-tasks/photo-pack`);
    } else if (comboStatus === 'photo_rejected') {
      this.props.setTaskStep(1);
      this.props.setTaskVersion(comboVersion);
      this.props.setTaskStatus('photo_rejected');
      this.props.history.push(`/open-tasks/photo-pack`);
    } else if (comboStatus === 'id_need_approve') {
      const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
      this.props.setWrapperClassName(upClassName);
      this.setState({popup: 'id_need_approve'});
    }
  };

  comboTaskData = () => {
    let url = `/api/combo-task/v2/status`;
    defaultAxios.get(url, axiosHeaders())
        .then(res => {
          if (res?.data?.data) {
            this.setState({
              comboStatus: res.data.version === 'combo_v10' ? res.data.data : null,
              comboVersion: res.data.version === 'combo_v10' ? res.data.version : null
            });

            if (res.data.data === 'photo_rejected') {
              const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
              this.props.setWrapperClassName(upClassName);
              this.setState({popup: 'try-again'});
            } else if (res.data.data === 'unfinished') {
              const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
              if (currentUser) {
                const userBlockedPopup = localStorage.getItem("block_popup") ? JSON.parse(localStorage.getItem("block_popup")) : null;
                if (!(userBlockedPopup && userBlockedPopup.hasOwnProperty('userId'+currentUser.id))) {
                  const obj =  {
                    ['userId'+currentUser.id]: true
                  };
                  const modifyObj = userBlockedPopup ? Object.assign(userBlockedPopup, obj) : obj;
                  localStorage.setItem('block_popup', JSON.stringify(modifyObj));
                  const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
                  this.props.setWrapperClassName(upClassName);
                  this.setState({popup: 'blocked'});
                }
              } else {
                const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
                this.props.setWrapperClassName(upClassName);
                this.setState({popup: 'blocked'});
              }
            } else if (res.data.data === 'finished') {
              const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
              if (currentUser) {
                const userConfirmPopup = localStorage.getItem("confirm_popup") ? JSON.parse(localStorage.getItem("confirm_popup")) : null;
                if (!(userConfirmPopup && userConfirmPopup.hasOwnProperty('userId'+currentUser.id))) {
                  const obj =  {
                    ['userId'+currentUser.id]: true
                  };
                  const modifyObj = userConfirmPopup ? Object.assign(userConfirmPopup, obj) : obj;
                  localStorage.setItem('confirm_popup', JSON.stringify(modifyObj));
                  const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
                  this.props.setWrapperClassName(upClassName);
                  this.setState({popup: 'finished'});
                }
              } else {
                const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
                this.props.setWrapperClassName(upClassName);
                this.setState({popup: 'finished'});
              }
            }
          }
        })
        .catch(err => {
        })
  };

  render() {

    const {survey, research_v2, research_v3, research_v4, popup, comboStatus, photoIdStatus, imageIdModerationStatus} = this.state;
    const {t} = this.props;
    let progressComboTask = comboStatus === null || (comboStatus && (comboStatus === 'need_finish_profiling'|| comboStatus === 'false' || comboStatus === 'finished' || comboStatus === 'unfinished'));
    const progressShortTask = research_v2 !== 'id_need_approve' && /*research_v3 !== 'id_need_approve' &&*/ research_v4 !== 'id_need_approve' ? research_v2 && /*research_v3 &&*/ research_v4 : false;
    return (
        <div className={`main-tasks`}>
          <div className={`logged`}>
            <div className={`section active`}>
              <div className={`share-opinion`}>
                <Title title={t('main_page.logged.section2.title')} className={`page-title--v2`} size={243}/>
                <div className={`share-opinion__wrap`}>
                  <button className={`card grey`} onClick={() => this.surveyHandler('survey')} disabled={survey}>
                    <div className={`card__wrap`}>
                      <div className={`card__content`}>
                        <p className={`card__title`}>{t('main_page.tasks.profiling_survey')}</p>
                        <p className={`card__text`}>{t('main_page.logged.section2.cardText1')}</p>
                        <p className={`card__reward`}><strong>+15</strong> {t('main_page.tasks.points')}</p>
                      </div>
                      <div className={`card__image`}>
                        <img src={pres_1} alt=""/>
                      </div>
                    </div>
                  </button>
                  <button className={`card yellow`} onClick={() => this.photoIdHandler()} disabled={!imageIdModerationStatus || photoIdStatus}>
                    <div className={`card__wrap`}>
                      <div className={`card__content`}>
                        <p className={`card__title`}>{t('main_page.tasks.photo_id')}</p>
                        <p className={`card__text`}>1 {t('main_page.logged.section2.cardText3')}</p>
                        <p className={`card__reward`}><strong>+15</strong> {t('main_page.tasks.points')}</p>
                      </div>
                      <div className={`card__image`}>
                        <img src={pres_2} alt=""/>
                      </div>
                    </div>
                  </button>
                  <button className={`card blue`} onClick={() => this.surveyHandler('research')}
                          disabled={true /*!survey || progressShortTask*/}>
                    <div className={`card__wrap`}>
                      <div className={`card__content`}>
                        <p className={`card__title`}>{t('main_page.logged.section2.cardTitle1')}</p>
                        <p className={`card__text`}>{t('main_page.logged.section2.cardText1')}</p>
                        <p className={`card__reward`}><strong>+15</strong> {t('main_page.tasks.points')}</p>
                      </div>
                      <div className={`card__image`}>
                        <img src={pres_3} alt=""/>
                      </div>
                    </div>
                  </button>
                  <button className={`card pink`} onClick={() => this.handleComboTask()} disabled={progressComboTask}>
                    <div className={`card__wrap`}>
                      <div className={`card__content`}>
                        <p className={`card__title`}>{t('main_page.logged.section2.cardTitle2')}</p>
                        <p className={`card__text`}>{t('main_page.logged.section2.cardText2')}</p>
                        <p className={`card__reward`}><strong>+25</strong> {t('main_page.tasks.points')}</p>
                      </div>
                      <div className={`card__image`}>
                        <img src={pres_4} alt=""/>
                      </div>
                    </div>
                  </button>
                </div>
                <div className={`btns-wrap`}>
                  <Button className={'btn--v2'}>
                    <Link to={{pathname: "/cabinet/points"}}>{t('btns.check_balance')}</Link>
                  </Button>
                  <Button className={'btn--v2'}>
                    <Link to={{pathname: "/shop"}}>{t('btns.bonus_shop')}</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>

          {popup === 'id_need_approve' && <PopupPhotoIdAttention {...this.props} closePopup={this.closePopup}/>}
          {popup === 'limit' && <PopupCheckLimit {...this.props} closePopup={this.closePopup}/>}
          {popup === 'try-again' && <TryAgain {...this.props} closePopup={this.closePopup} handleComboTask={this.handleComboTask}/>}
          {popup === 'blocked' && <Blocked {...this.props} closePopup={this.closePopup}/>}
          {popup === 'finished' && <Finished {...this.props} closePopup={this.closePopup}/>}
        </div>
    );
  }
}

const mapStateToProps = state => ({
  wrapperClass: state.common.wrapperClass,
  meInfo: state.auth.meInfo
});

const mapDispatchToProps = dispatch => ({
  setTaskStep: (step) => dispatch(setTaskStep(step)),
  setTaskStatus: (status) => dispatch(setTaskStatus(status)),
  setTaskVersion: (ver) => dispatch(setTaskVersion(ver)),
  setStatusFirstResearch: (status) => dispatch(setStatusFirstResearch(status)),
  setStatusSecondResearch: (status) => dispatch(setStatusSecondResearch(status)),
  setStatusThirdResearch: (status) => dispatch(setStatusThirdResearch(status)),
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
