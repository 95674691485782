export const LONG_SURVEY_STATUS = {
  photo: "photo", // first step
  questions: "questions", // second step
  photo_check: "photo_check", // moderation
  photo_rejected: "photo_rejected", // did not pass moderation for the first time
  unfinished: "unfinished", // did not pass moderation for the second time
  finished: "finished", // photo passed moderation, survey finished
};

export const SURVEY_UNAVAILABLE_STATUS = {
  id_need_approve: "id_need_approve",
  survey_result_already_exists: "survey_result_already_exists",
  need_finish_profiling: "need_finish_profiling",
  completed_the_available_number_of_surveys:
    "completed_the_available_number_of_surveys",
};
