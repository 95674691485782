import defaultAxios from "../axios";
import {
  getLongSurveyStatusApiPath,
  getUserActiveSurveysApiPath,
  getUserTasksApiPath,
  getSaveSurveyApiPath,
  getIsSurveyAvailableApiPath,
  getSaveLongSurveyImageApiPath,
  isUserCanTakeVerificationApiPath,
} from "./constants";
import { axiosHeaders } from "../utils";

export const getUserActiveSurveys = () =>
  defaultAxios.get(getUserActiveSurveysApiPath, axiosHeaders());

export const saveSurveyResults = (survey, type) =>
  defaultAxios.post(
    getSaveSurveyApiPath(type),
    survey,
    axiosHeaders("application/json")
  );

export const saveLongSurveyImage = (id, fd) =>
  defaultAxios.post(
    getSaveLongSurveyImageApiPath(id),
    fd,
    axiosHeaders("multipart/form-data")
  );

export const getIsSurveyAvailable = (id) =>
  defaultAxios.get(getIsSurveyAvailableApiPath(id), axiosHeaders());

export const getLongSurveyStatus = (id) =>
  defaultAxios.get(getLongSurveyStatusApiPath(id), axiosHeaders());

export const getUserTasks = () =>
  defaultAxios.get(getUserTasksApiPath, axiosHeaders());

export const getIsUserCanTakeVerification = () =>
  defaultAxios.get(isUserCanTakeVerificationApiPath, axiosHeaders());
