import React from 'react';

function LoggedQuestions(props) {
  const {questions, user, openedIndex, toggleQuestionHandler} = props;
  return (
      <div className={`faq-questions`}>
      <div className={`faq-questions__wrap`}>
        <h3 className={`faq-questions__category`}>{props.t('faq.category0')}</h3>
        {questions[user][props.currentLanguage]['points'].map((item, i) => {
          return (
              <div key={item.id} className={`faq-questions__block ${openedIndex === item.id ? 'open' : ''}`}>
                <div>
                  <h3 className={`faq-questions__question`}
                      onClick={(e) => toggleQuestionHandler(e, item.id)}>{item.question}</h3>
                  <p className={`faq-questions__answer`} dangerouslySetInnerHTML={{__html: item.answer}}/>
                </div>
              </div>
          )
        })}
        <h3 className={`faq-questions__category`}>{props.t('faq.category1')}</h3>
        {questions[user][props.currentLanguage]['rewards'].map((item, i) => {
          return (
              <div key={item.id} className={`faq-questions__block ${openedIndex === item.id ? 'open' : ''}`}>
                <div>
                  <h3 className={`faq-questions__question`}
                      onClick={(e) => toggleQuestionHandler(e, item.id)}>{item.question}</h3>
                  <p className={`faq-questions__answer`} dangerouslySetInnerHTML={{__html: item.answer}}/>
                </div>
              </div>
          )
        })}
        <h3 className={`faq-questions__category`}>{props.t('faq.category2')}</h3>
        {Object.values(questions[user][props.currentLanguage]['tasks']).map((item, i) => {
          return (
              <div key={item.id} className={`faq-questions__block ${openedIndex === item.id ? 'open' : ''}`}>
                <div>
                  <h3 className={`faq-questions__question`}
                      onClick={(e) => toggleQuestionHandler(e, item.id)}>{item.question}</h3>
                  <p className={`faq-questions__answer`} dangerouslySetInnerHTML={{__html: item.answer}}/>
                </div>
              </div>
          )
        })}
      </div></div>
  )
}

export default LoggedQuestions
