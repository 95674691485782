import React, { Component } from "react";
import PropTypes from "prop-types";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Form extends Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    handleSubmit: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    autoComplete: "off",
    className: "",
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      validating: false,
      errorsSum: undefined,
      form: {},
      validateStatus: {},
    };
  }

  componentDidMount() {
    if (this.props.children.length) {
      this.props.children.map((item) => {
        if (item && item.props?.validate) {
          const { validateStatus } = this.state;

          validateStatus[item.props.name] = 0;

          this.setState({
            validateStatus,
          });
        }
      });
    }
  }

  handleChangeField = (data, name) => {
    const { form } = this.state;

    if (typeof data == "object" && data != null && data.target) {
      form[data.target.name] = data.target.checked
        ? data.target.checked
        : data.target.value;
    } else {
      form[name] = data;
    }

    this.setState({
      form,
      validating: false,
    });
  };

  handleSubmit = (e) => {
    e && e.preventDefault();

    this.setState({
      validating: true,
    });

    return sleep(400).then(() => {
      if (this.checkValidation()) {
        this.setState({
          validating: false,
        });
        const formData = new FormData(this.form.current);
        this.props.handleSubmit(formData);
      }
    });
  };
  handleValidating = (name, errorsCount) => {
    const { validateStatus } = this.state;
    let errorsSum = 0;
    validateStatus[name] = errorsCount;

    Object.keys(validateStatus).map((item) => {
      errorsSum += validateStatus[item];
    });

    this.setState({
      errorsSum: this.props.serverErrorQuantity
        ? errorsSum - this.props.serverErrorQuantity
        : errorsSum,
    });
  };

  checkValidation = () => {
    if (
      this.state.errorsSum === undefined &&
      this.props.hasOwnProperty("serverErrorQuantity") &&
      this.props.serverErrorQuantity === 0
    ) {
      return true;
    } else {
      return this.state.errorsSum === 0;
    }
  };

  renderWrappedChildren(children) {
    return React.Children.map(children, (child) => {
      if (!child || !child.props) {
        // !child.props
        return child;
      }

      if (child.props.children) {
        if (child.props.validate) {
          return React.cloneElement(child, {
            children: this.renderWrappedChildren(child.props.children),
            handleChangeField: this.handleChangeField,
            validating: this.state.validating,
            handleValidating: this.handleValidating,
          });
        } else {
          return React.cloneElement(child, {
            children: this.renderWrappedChildren(child.props.children),
          });
        }
      }

      if (child.props.validate) {
        return React.cloneElement(child, {
          handleChangeField: this.handleChangeField,
          validating: this.state.validating,
          handleValidating: this.handleValidating,
        });
      } else {
        return React.cloneElement(child);
      }
    });
  }

  render() {
    const { validating } = this.state;
    const childrenWithProps = this.renderWrappedChildren(this.props.children);

    return (
      <form
        onSubmit={this.handleSubmit}
        ref={this.form}
        autoComplete={this.props.autoComplete}
        className={`${this.props.className} ${validating ? "form-error" : ""}`}
      >
        {childrenWithProps}
      </form>
    );
  }
}

export default Form;
