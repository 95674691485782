import ErrorResponse from "../../PageNotFound/ErrorResponse";
import React from "react";
import PageNotFound from "../../PageNotFound/PageNotFound";
import { getIsUserHasImageIdModerationStatusFromLS } from "../../../../utils/storage";
import { useIsUserHasPhotoIdStatus } from "../../../../hooks/useIsUserHasPhotoIdStatus";
import PhotoId from "./PhotoId";

const PhotoVerification = ({ t, currentLanguage }) => {
  const isUserHasImageIdModerationStatus =
    getIsUserHasImageIdModerationStatusFromLS();

  const { isError, isLoading, isUserHasPhotoIdStatus } =
    useIsUserHasPhotoIdStatus(isUserHasImageIdModerationStatus);

  if (isLoading) return <></>;
  else if (isError) return <ErrorResponse />;
  else if (!isUserHasImageIdModerationStatus || isUserHasPhotoIdStatus)
    return <PageNotFound />;
  else return <PhotoId t={t} currentLanguage={currentLanguage} />;
};

export default PhotoVerification;
