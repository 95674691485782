import React from 'react';

const BalancePoints = (props) => {
  const { balance } = props;
  return (
      <div className={`points-balance`}>
        <p className={`points-balance__count`}>{balance}</p>
        {/*<p className={`points-balance__text`}></p>*/}
      </div>
  )
}

export default BalancePoints
