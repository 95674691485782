import React from "react";
import Title from "../../../parts/Title";
import Button from "../../../form/Button";
import {Link} from "react-router-dom";

const PopupMobileAttention = (props) => {
  const {closePopup} = props;
  return (
      <div className={`shop-popup`}>
        <div className={`shop-popup__close`} onClick={closePopup}></div>
        <div className={`container`}>
          <div className={`shop-popup__wrap`}>
            <Title title={props.t('shop.attention.title')} className={`white`} size={208}/>
            <p className={`shop-popup__text`}>{props.t('shop.attention.text0')}</p>
            <p className={`shop-popup__text`}>{props.t('shop.attention.text1')}</p>
            <p  className={`shop-popup__text`}>{props.t('shop.attention.text2')}</p>
            <Button className={`shop-popup__btn`}><Link to={{pathname: "/cabinet/profile"}}>{props.t('btns.profile')}</Link></Button>
          </div>
        </div>
      </div>
  )
};

export default PopupMobileAttention
