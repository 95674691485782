import { useEffect, useState } from "react";
import { getUserTasks } from "../api/requests";

export const useIsProfilingFinished = () => {
  const [isProfilingFinished, setIsProfilingFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getUserTasks()
      .then((res) => {
        setIsProfilingFinished(
          res?.data?.data?.tasks?.some(
            (el) => el["name_en"].toLowerCase() === "survey"
          )
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        console.dir(err);
      });
  }, []);

  return { isProfilingFinished, isLoading, isError };
};
