import React from "react";
import {Link} from "react-router-dom";
import LangHOC from "../HoC/LangHOC";

function Footer(props) {
  return (
      <div className="footer">
        <div className="container footer-wrap">
          <div className={`footer-title`}>
            {props.t('footer.title')}
          </div>
          <div className={`footer-contact`}>
            {props.t('footer.text0')} <a href="tel:0800000055" className='footer-contact__phone'>0800 000 055</a> <br/> {props.t('footer.text1')}
          </div>
          <div className={`footer-nav`}>
            <Link to={{pathname: "/feedback"}}>
              {props.t('footer.email_us')}
            </Link>
            <Link to={{pathname: "/faq"}}>
              {props.t('footer.faq')}
            </Link>
          </div>
        </div>
      </div>
  );
}

export default LangHOC(Footer);
