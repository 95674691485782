import React from "react";
import {Link} from "react-router-dom";
import Button from "../../../../../form/Button";

const FormBtns = (props) => {
  return (
      <div className={`survey-form__btns`}>
        <Button className={`btn--blue`}>
          <Link to={{pathname: "/tasks"}}>
            {props.t('btns.keep_browser')}
          </Link>
        </Button>
        <Button type={`submit`} disabled={props.loading}>
          {props.t('btns.complete')}
        </Button>
      </div>
  );
};

export default FormBtns;
