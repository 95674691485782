import { surveyQuestionService } from "../components/page/OpenTasks/Research/surveyQuestionService";
import { useEffect, useState } from "react";
import { getUserActiveSurveys } from "../api/requests";

export const useSurveys = () => {
  const [shortSurvey, setShortSurvey] = useState();
  const [longSurvey, setLongSurvey] = useState();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    getUserActiveSurveys()
      .then((res) => {
        const short = res.data?.data?.find((survey) => survey.type === "short");
        const long = res.data?.data?.find((survey) => survey.type === "long");

        setShortSurvey(short ? surveyQuestionService(short) : null);
        setLongSurvey(long ? surveyQuestionService(long) : null);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.dir(err);
        setIsError(true);
      });
  }, []);

  return { shortSurvey, longSurvey, isError, isLoading };
};
