/*
const questionsArrSobranie = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Sobranie Blacks/ Sobranie Whites ?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Premium tobacco blend",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "KS SS Format",
          name_en: "KS SS Format"
        },
        {
          id: "2",
          name: "LSS technology (Less Smoke Smell)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "3",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "4",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "How do you like the Rich Tobacco Taste of Sobranie Blacks/ Sobranie Whites ?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "How do you like the KS SS format of Sobranie Blacks /Sobranie Whites?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "Are you going to purchase Sobranie Blacks /Sobranie Whites  on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "Not",
          name_en: "Not"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "What is the main reason preventing you from regular consumption Sobranie Blacks /Sobranie Whites?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "6", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "Don’t know", name_en: "Don’t know"},
        {id: "8", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია წვრილი სობრანიეს მომხმარებელი ხართ?",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "პრემიუმ თამბაქოს ნაზავი",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "სუპერ კომპაქტური ფორმატი",
          name_en: "KS SS Format"
        },
        {
          id: "2",
          name: "LSS ტექნოლოგია (თამბაქოს კვამლის ნაკლები სუნი)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "3",
          name: "არ ვიცი",
          name_en: "Don’t know"
        },
        {
          id: "4",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "როგორ მოგწონთ წვრილი სობრანიეს თამბაქოს გემო?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ მოგწონთ წვრილი Sobranie-ს სუპერ კომპაქტური ფორმატი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?(ჩაწერეთ)",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "აპირებთ თუ არა წვრილი Sobranie-ს რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "Not"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც წვრილი სობრანიე არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "6", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "8", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდის იმიჯი", name_en: "Brand image"},
        {id: "1", name: "სიგარეტის ფორმატი", name_en: "Cigarette format"},
        {id: "2", name: "ნიკოტინის შემცველობა", name_en: "Tar level"},
        {id: "3", name: "ფასი", name_en: "Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};

const questionsSobV2 = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Sobranie Rich?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Premium tobacco blend",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "Rich Tobacco Taste",
          name_en: "Rich Tobacco Taste"
        },
        {
          id: "2",
          name: "Recessed (Airstream) charcoal filter",
          name_en: "Recessed (Airstream) charcoal filter"
        },
        {
          id: "3",
          name: "8mg Tar level",
          name_en: "8mg Tar level"
        },
        {
          id: "4",
          name: "LSS technology (Less Smoke Smell)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "5",
          name: "Black CP",
          name_en: "Black CP"
        },
        {
          id: "6",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "7",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "How do you like the Rich Tobacco Taste of Sobranie Rich?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "How do you like Sobranie Rich’s black cigarette paper?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "Are you going to purchase Sobranie Rich on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "No",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "What is the main reason preventing you from regular consumption Sobranie Rich?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "6", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "Don’t know", name_en: "Don’t know"},
        {id: "8", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია Sobranie Rich -ის მომხმარებელი ხართ?",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "პრემიუმ თამბაქოს ნაზავი",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "თამბაქოს ძლიერი გემო",
          name_en: "Rich Tobacco Taste"
        },
        {
          id: "2",
          name: "ჩაღრმავებული ნახშირის ფილტრი",
          name_en: "Recessed (Airstream) charcoal filter"
        },
        {
          id: "3",
          name: "8მგ კუპრის შემცველობა",
          name_en: "8mg Tar level"
        },
        {
          id: "4",
          name: "LSS ტექნოლოგია (თამბაქოს კვამლის ნაკლები სუნი)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "5",
          name: "შავი სიგარეტის ქაღალდი",
          name_en: "Black CP"
        },
        {
          id: "6",
          name: "არ ვიცი",
          name_en: "Don’t know"
        },
        {
          id: "7",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "როგორ მოგწონთ Sobranie Rich-ის თამბაქოს გემო?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ მოგწონთ Sobranie  Rich-ის შავი სიგარეტის ქაღალდი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?(ჩაწერეთ)",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "აპირებთ თუ არა Sobranie Rich-ის რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც Sobranie Rich-ი არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "6", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "8", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდის იმიჯი", name_en: "Brand image"},
        {id: "1", name: "სიგარეტის ფორმატი", name_en: "Cigarette format"},
        {id: "2", name: "ნიკოტინის შემცველობა", name_en: "Tar level"},
        {id: "3", name: "ფასი", name_en: "Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};
*/

const questionsSobV3 = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Sobranie KS?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "How many cigarettes do you smoke per day?",
      "answers": [
        {
          id: "0",
          name: "1-5",
          name_en: "1-5"
        },
        {
          id: "1",
          name: "5-10",
          name_en: "5-10"
        },
        {
          id: "2",
          name: "10-20",
          name_en: "10-20"
        },
        {
          id: "3",
          name: "Over 20",
          name_en: "Over 20"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Premium tobacco blend",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "KS Format",
          name_en: "KS Format"
        },
        {
          id: "2",
          name: "LSS technology (Less Smoke Smell)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "3",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "4",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "3",
      "description": "How do you like the Rich Tobacco Taste of Sobranie KS ?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "How do you like the format of Sobranie KS?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "5",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "6",
      "description": "Are you going to purchase Sobranie KS on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "No",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason preventing you from regular consumption Sobranie KS?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "6", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "Don’t know", name_en: "Don’t know"},
        {id: "8", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "8",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია კლასიკური ფორმატის სობრანიეს მომხმარებელი ხართ?",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რამდენ ღერ სიგარეტს ეწევით დღეში?",
      "answers": [
        {
          id: "0",
          name: "1-დან 5-მდე",
          name_en: "1-5"
        },
        {
          id: "1",
          name: "5-დან 10-მდე",
          name_en: "5-10"
        },
        {
          id: "2",
          name: "10-დან 20-მდე",
          name_en: "10-20"
        },
        {
          id: "3",
          name: "20-ზე მეტს",
          name_en: "Over 20"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "პრემიუმ თამბაქოს ნაზავი",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "კლასიკური ფორმატი",
          name_en: "KS Format"
        },
        {
          id: "2",
          name: "LSS ტექნოლოგია (თამბაქოს კვამლის ნაკლები სუნი)",
          name_en: "LSS technology (Less Smoke Smell)"
        },
        {
          id: "3",
          name: "არ ვიცი",
          name_en: "Don’t know"
        },
        {
          id: "4",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ მოგწონთ კლასიკური ფორმატის სობრანიეს თამბაქოს გემო?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "როგორ მოგწონთ  Sobranie-ს კლასიკური ფორმატი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "5",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?(ჩაწერეთ)",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "6",
      "description": "აპირებთ თუ არა კლასიკური ფორმატის Sobranie-ს რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც კლასიკური ფორმატის სობრანიე არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "6", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "8", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "8",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდის იმიჯი", name_en: "Brand image"},
        {id: "1", name: "სიგარეტის ფორმატი", name_en: "Cigarette format"},
        {id: "2", name: "ნიკოტინის შემცველობა", name_en: "Tar level"},
        {id: "3", name: "ფასი", name_en: "Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};

export {questionsSobV3}
