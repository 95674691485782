import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
// import BackgroundImage from "../../../svgItems/BackgroundImage";
import Button from "../../../form/Button";
import Title from "../../../parts/Title";

function Step5 (props) {
  return (
      <Fragment>
        <Title title={props.t('confirm_profile.success_title')} size={208}/>
        <div className={`signup__wrap`}>
        <div className={`auth__form-wrap`}>
          <div className={`signup-finish`}>
            <p className={`signup-finish__title`}>{props.t('signup.finish_title')}!</p>
            <p className={`signup-finish__text`}>{props.t('signup.finish_text')}</p>
            <Button type={'button'}><Link to={{pathname: "/signin"}}>{props.t('btns.signin')}</Link></Button>
          </div>
        </div>
        </div>
        {/*<BackgroundImage/>*/}
      </Fragment>
  )
}

export default Step5
