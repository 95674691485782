import React, { useState } from "react";
import LangHOC from "../HoC/LangHOC";

const Range = (props) => {
  const { onChange = () => {}, labels = true, statusText = false } = props;
  const step = 1;
  const min = 1;
  const max = 5;
  const [rangeValue, setRangeValue] = useState(3);
  const [rangeStatus, setRangeStatus] = useState(false);

  const handleChangeRange = (e) => {
    setRangeStatus(true);
    setRangeValue(Number(e.target.value));
    onChange(e.target.name, Number(e.target.value));
  };

  const handleLabelClick = (e, name) => {
    setRangeStatus(true);
    setRangeValue(Number(e.target.dataset.id));
    onChange(name, Number(e.target.dataset.id));
  };

  const rangeLabels = () => {
    return (
      <ul
        className={`range-labels range-labels--${max} ${
          !rangeStatus ? "inactive" : ""
        }`}
      >
        {[...Array(max)].map((item, idx) => {
          return (
            <li
              key={idx}
              className={`${idx + 1 === rangeValue ? "active" : ""}`}
              data-id={idx + 1}
              onClick={(e) => handleLabelClick(e, props.name)}
            >
              {idx + 1}
            </li>
          );
        })}
      </ul>
    );
  };

  const rangeStatusText = () => {
    return (
      <p className={`range-status-text`}>
        {!rangeStatus
          ? props.t(`open_task.sobranie.range_status_default`)
          : props.t(`open_task.sobranie.range_status_${rangeValue}`)}
      </p>
    );
  };
  // console.log(props)
  return (
    <div className={`range-wrapper ${!rangeStatus ? "inactive" : ""}`}>
      {labels && rangeLabels()}
      {statusText && rangeStatusText()}
      <input
        type="range"
        name={props.name}
        className={`custom-range ${
          rangeValue !== null ? "range-style-" + rangeValue : ""
        }`}
        min={min}
        max={max}
        step={step}
        value={rangeValue}
        onChange={(e) => handleChangeRange(e)}
      />
    </div>
  );
};

export default LangHOC(Range);
