import React from 'react'
import LangHOC from "../../HoC/LangHOC";
import Topic from "./parts/topic";
import terms from "./terms.json";
import annex from "./annex.json";

function Rules(props) { 

    return (
        <div className={'rules-page container'}>
            <div className="rules-page__container">
                <Topic topic={terms}/>
                <Topic topic={annex}/>
            </div>
        </div>
    )
}

export default LangHOC(Rules)
