import React from 'react';
import Form from "../../../form/Form";
import Button from "../../../form/Button";
import Input from "../../../form/Input";
import NumberFormatInput from "../../../form/NumberFormatInput";
// import ReCAPTCHA from "react-google-recaptcha";
// import {googleRecaptchaKey} from "../../../../constants/Variables";

function Step2(props) {
  const {nextStep, handleChangePhone, handleChangeField, /*getCode, handleChangeCaptcha, recaptchaRef*/} = props;
  const phoneErr = Object.keys(props.phone_veritifyError).length > 0 ? props.phone_veritifyError : props.phoneError;
  return (
      <div className={`signup__wrap`}>
        <div className={`auth__form-wrap`}>
          <Form handleSubmit={nextStep}>
            <div className={`auth-row`}>
              <div className={`auth-col`}>
                <NumberFormatInput name={"phone"}
                                   mask="_"
                                   format={'+995 ###-##-##-##'}
                                   allowEmptyFormatting
                                   onChange={handleChangePhone}
                                   serverErrors={phoneErr}
                                   validate={[
                                     {
                                       required: true,
                                       message: props.t('errors.phone')
                                     },
                                     {
                                       minLength: 9
                                     }
                                   ]}
                                   disabled={true}
                                   value={props.userPhone || props.phone} />
                {props.userPhone && <input type='hidden' name='phone' value={props.userPhone}/>}
              </div>
              <div className={`auth-col`}>
                {/*<Button className={`btn--blue form-btn`} handleClick={getCode} disabled={props.loading}>*/}
                  {/*{props.t('signup.valid_code')}*/}
                {/*</Button>*/}

                <Input name="email"
                       placeholder={props.t('signup.email_placeholder')}
                       serverErrors={props.emailError}
                       onChange={handleChangeField}
                       autoComplete={'new-password'}
                       validate={[
                         {required: true,
                           message: props.t('errors.email')},
                         {email: true, message: props.t('errors.email')}
                       ]}
                />
              </div>
            </div>
            {/*<div className={`auth-row`}>*/}
              {/*<div className={`auth-col`}>*/}
                {/*<Input name="confirmation_code"*/}
                       {/*placeholder={props.t('signup.code_placeholder')}*/}
                       {/*maxLength={6}*/}
                       {/*onChange={handleChangeField}*/}
                       {/*serverErrors={props.confirmation_codeError}*/}
                       {/*validate={[*/}
                         {/*{required: true,*/}
                           {/*message: props.t('errors.verification_code')},*/}
                         {/*{minLength: 6,*/}
                           {/*message: props.t('errors.code_field')}*/}
                       {/*]}*/}
                {/*/>*/}
              {/*</div>*/}
              {/*<div className={`auth-col`}>*/}
                {/**/}
              {/*</div>*/}
            {/*</div>*/}
            <div className={`auth-row`}>
              <div className={`auth-col`}>
                <Input name="password"
                       type={"password"}
                       placeholder={props.t('signup.password_placeholder')}
                       autoComplete={'new-password'}
                       validate={[
                         {required: true,
                           message: props.t('errors.password')},
                         {minLength: 8, message: props.t('errors.password_min')}
                       ]}
                />
              </div>
              <div className={`auth-col`}>
                <Input name="password_repeat"
                       type={"password"}
                       placeholder={props.t('signup.repasword_placeholder')}
                       validate={[
                         {required: true,
                         message: props.t('errors.password')},
                         {sameAsField: 'password', message: props.t('errors.password_repeat')}
                       ]}
                />
              </div>
            </div>
            {/*<div className="auth__captcha-wrapper">*/}
              {/*<ReCAPTCHA*/}
                  {/*sitekey={googleRecaptchaKey}*/}
                  {/*onChange={handleChangeCaptcha}*/}
                  {/*ref={recaptchaRef}*/}
                  {/*size="invisible"*/}
              {/*/>*/}
            {/*</div>*/}
            <div className={`auth__btns`}>
              <Button type={`submit`}>
                {props.t('btns.next')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
  )
}

export default Step2
