import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../../parts/Title";
import {setWrapperClassName} from "../../../../actions/common";
import RulesConfirm from "./RulesConfirm";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import {getCities} from "../../../../actions/citiesActions";
import defaultAxios from "../../../../axios";
import LangHOC from "../../../HoC/LangHOC";
import debounce from 'lodash/debounce';
import Block from "./Block";
import Step6 from "./Step6";
import RulesPopup from "./RulesPopup";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: null,
      step: 0,
      cityList: [],
      formValues: null,
      phone: null,
      pidError: {},
      emailError: {},
      phoneError: {},
      confirmation_codeError: {},
      phone_veritifyError: {},
      serverErrors: null,
      loading: false,
      popup: false,
      checkbox_rules: false,
      // needRegistration: false // for users from db need_temp
    };
    this.getCode = debounce(this.getCode, 1000, {leading: true, trailing:false});
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.props.getCities();
    if (this.props.userPhone) {
      this.setState({
        phone: this.props.userPhone
      })
    }
    this.props.setWrapperClassName("auth-container");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cities !== prevProps.cities) {
      this.setState({
        cityList: this.props.cities
      });
    }
    if (!prevProps.isLogged && this.props.isLogged && this.state.step === 0) this.props.history.push('/cabinet/profile');
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  handleNextStep = formData => {
    let object = {};
    formData.forEach(function(value, key){
      // if (key === "phone") {
      //   value = value.substring(5).replace(/[-_]/g, '')
      // }
      // else if (key === "email" && !value) {
      //   return
      // }
      object[key] = value;
    });

    if (this.state.step === 0) {
      this.checkExists({"pid": object.pid})
    }
    else if (this.state.step === 1) {
      let existObject = {
        "email": object.email,
        "phone": object.phone
      };
      // this.checkCode(object['confirmation_code']);
      // this.checkPhoneFromNeedReg(object.phone); //for users from db  need_temp
      this.checkExists(existObject);
    }
    else {
      this.setState({
        step: this.state.step + 1
      })
    }

    this.setState({
      formValues: Object.assign({}, this.state.formValues, object)
    })
  };

  handleSubmit = async (formData) => {
    const {captcha} = this.state;
    const locale = localStorage.getItem('language') || "en";
    const asyncTokenCaptha = await this.recaptchaRef.current.executeAsync();
    let newCaptha = asyncTokenCaptha || captcha;
    let newFormData = formData;
    newFormData.append('locale', locale);
    for ( var key in this.state.formValues ) {
      newFormData.append(key, this.state.formValues[key]);
    }
    newFormData.delete('g-recaptcha-response');
    newFormData.append('g-recaptcha-response', newCaptha);

    defaultAxios.post('/api/front/v2/create-user', newFormData, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json'

      }
    })
        .then(response => {
          // if (response.status === 201) {
          //   this.setState({
          //     step: 5
          //   })
          // } else {
          //   this.setState({
          //     step: 4
          //   })
          // }
            this.setState({
              step: 6
            });
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({'event':'registration' } )
        })
        .catch(error => {
          this.recaptchaRef.current.reset();
        })
  };

  checkExists = (obj) => {
    defaultAxios.post('/api/front/v2/is-data-exists', obj, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
        .then(response => {
          if (response.data.pid) {
            this.setState({
              'pidError': {'pid': this.props.t('errors.pid_used')}
            })
          } else if (response.data.email || response.data.phone) {
            if (response.data.email) {
              this.setState({
                'emailError': {email: this.props.t('errors.email_used')}
              })
            }
            if (response.data.phone) {
              this.setState({
                'phoneError': {phone: this.props.t('errors.phone_used')}
              })
            }
          } else {
            if (this.state.confirmation_codeError && Object.keys(this.state.confirmation_codeError).length > 0) return false;
            // if (this.state.step === 1 && this.state.needRegistration !== true) return false; // for users from db need_temp
            this.setState({
              step: this.state.step + 1
            })
          }
        })
        .catch(error => {
        });
  };

  checkPhoneFromNeedReg = (phone) => {
    defaultAxios.get('/api/v2/check-phone?phone=995'+phone, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
        .then(response => {
          if (response.data && response.data.data) {
            this.setState({
              needRegistration: true
            })
          } else {
            this.setState({
              needRegistration: false,
              phoneError: {phone: this.props.t('errors.no_phone_db')}
            })
          }
        })
        .catch(error => {
          this.setState({
            needRegistration: false,
            phoneError: {phone: this.props.t('errors.no_phone_db')}
          })
        });
  };

  handleChangeField = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: undefined
    })
  };

  handleChangePhone = (value) => {
    if (Object.keys(this.state.phone_veritifyError).length > 0) {
      this.setState({
        phone_veritifyError: {}
      })
    } else if (Object.keys(this.state.phoneError).length > 0) {
      this.setState({
        phoneError: {}
      })
    }
    this.setState({
      phone: value,
      // needRegistration: false // for users from db need_temp
    })
  };

  getCode = async () => {
    if (!this.state.phone || this.state.phone.length < 9) {
      this.setState({
        phone_veritifyError: {"phone_veritify": this.props.t('errors.phone')}
      });
      this.recaptchaRef.current.reset();
      return false;
    }
    this.setState({loading: true});
    const asyncTokenCaptha = await this.recaptchaRef.current.executeAsync();
    const {captcha} = this.state;
    let newCaptha = asyncTokenCaptha || captcha;
    const locale = localStorage.getItem('language') || "en";
    let fd = new FormData();
    fd.append('locale', locale);
    fd.append('confirmation_phone', this.state.phone);
    fd.append('g-recaptcha-response', newCaptha);
    if (newCaptha) {
    defaultAxios.post('/api/front/v2/create-user', fd, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json'

      }
    })
        .then(response => {
          this.recaptchaRef.current.reset();
          this.setState({loading: false})
        })
        .catch(error => {
          this.recaptchaRef.current.reset();
          this.setState({loading: false})
        })
    } else {
      this.recaptchaRef.current.reset();
      this.setState({loading: false})
    }
  };

  checkCode = (code) => {
    defaultAxios.post('api/front/v2/is-token-exist', {"token": code}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
        .then(response => {
          if (!response.data.isset) {
            this.setState({
              'confirmation_codeError': {phone: this.props.t('errors.verification_code')}
            })
          }
        })
        .catch(error => {});
  };

  handleChangeCaptcha = (value) => {
    this.setState({
      captcha: value
    })
  };

  handlecheckboxRules = () => {
    this.setState({
      checkbox_rules: true
    })
  };

  openPopup = () => {
    window.scrollTo(0, 0);
    this.setState({
      popup: true,
      checkbox_rules: false
    }, () => {
      const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
      this.props.setWrapperClassName(upClassName);
    })
  };

  closePopup = () => {
    window.scrollTo(0, 0);
    this.setState({
      popup: false
    }, () => {
      const upClassName = this.props.wrapperClass.split("open-popup").pop();
      this.props.setWrapperClassName(upClassName);
    })
  };

  render() {
    const {step, popup, formValues} = this.state;
    let currentTitle = step === 'block' ? this.props.t('signup.title') : step === 6 || step === 5 || step === 4 ?  `${this.props.t('signup.title')}. Finish` :/*step === 0 ? this.props.t('signup.rules_title') :*/
        `${this.props.t('signup.title')}. ${this.props.t('signup.step')} ${step}`;
    const firstName = formValues != null && typeof formValues === 'object' && formValues.hasOwnProperty('firstname') ? formValues.firstname : '';
    return (
        <div className={`auth signup step-${step}`}>
          <Title title={currentTitle} size={208} />
          { step === 'block' && <Block {...this.props}/>}
          { popup && <RulesPopup closePopup={this.closePopup}
                                 handlecheckboxRules={this.handlecheckboxRules} {...this.props}/> }
          {/*{ step === 0 && <RulesConfirm nextStep={this.handleNextStep} {...this.props}/> }*/}
          { step === 0 && <Step1 nextStep={this.handleNextStep}
                                 handleChangeField={this.handleChangeField}
                                 {...this.state} {...this.props}/> }
          { step === 1 && <Step2 nextStep={this.handleNextStep}
                                 handleChangePhone={this.handleChangePhone}
                                 handleChangeField={this.handleChangeField}
                                 // getCode={this.getCode}
                                 // recaptchaRef={this.recaptchaRef}
                                 // handleChangeCaptcha={this.handleChangeCaptcha}
                                 {...this.state} {...this.props}/> }
          { step === 2 && <Step3 handleSubmit={this.handleSubmit}
                                 openPopup={this.openPopup}
                                 recaptchaRef={this.recaptchaRef}
                                 handleChangeCaptcha={this.handleChangeCaptcha}
                                 {...this.state} {...this.props}/> }
          { step === 4 && <Step4 nextStep={this.handleNextStep} {...this.props}/> }
          { step === 5 && <Step5 nextStep={this.handleNextStep} {...this.props}/> }
          { step === 6 && <Step6 userName={firstName} {...this.props} /> }
        </div>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged,
  userPhone: state.auth.userPhone,
  cities: state.cities.cities,
  wrapperClass: state.common.wrapperClass
});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
  getCities: () => dispatch(getCities())
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(SignUp));
