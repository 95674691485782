import React, { useContext } from 'react';
import AwesomeSlider from 'react-awesome-slider';
// import AwesomeSliderStyles from 'react-awesome-slider/src/styles';
import { Context } from "../Provider";
import LangHOC from "../../../HoC/LangHOC";


//Images(GIF steps)
import step1 from '../../../../../images/how-to/step1.gif';
import step2 from '../../../../../images/how-to/step2.gif';
import step3 from '../../../../../images/how-to/step3.gif';
import step4 from '../../../../../images/how-to/step4.gif';
import step5 from '../../../../../images/how-to/step5.png';
import step6_1 from '../../../../../images/how-to/step6-1.gif';
import step6_2 from '../../../../../images/how-to/step6-2.gif';
import step7 from '../../../../../images/how-to/step7.gif';
import step8 from '../../../../../images/how-to/step8.gif';
import step9 from '../../../../../images/how-to/step9.gif';
import step10 from '../../../../../images/how-to/step10.png';
import step11 from '../../../../../images/how-to/step11.png';

function MainSlides(props) {

    const { currentPage, updatePage, aCallback } = useContext(Context);

    const stepsData = [
        {
            title: props.t('howTo.slides.slide1.title'),
            description: props.t('howTo.slides.slide1.description'),
            image: step1,
            static: false
        },
        {
            title: props.t('howTo.slides.slide2.title'),
            description: props.t('howTo.slides.slide2.description'),
            image: step2,
            static: false
        },
        {
            title: props.t('howTo.slides.slide3.title'),
            description: props.t('howTo.slides.slide3.description'),
            image: step3,
            static: false
        },
        {
            title: props.t('howTo.slides.slide4.title'),
            description: props.t('howTo.slides.slide4.description'),
            image: step4,
            static: false
        },
        {
            title: props.t('howTo.slides.slide5.title'),
            description: props.t('howTo.slides.slide5.description'),
            additional: props.t('howTo.slides.slide5.additional'),
            image: step5,
            static: true
        },
        {
            title: props.t('howTo.slides.slide6.title'),
            description: props.t('howTo.slides.slide6.description'),
            image: step6_1,
            image2: step6_2,
            static: false
        },
        {
            title: props.t('howTo.slides.slide7.title'),
            description: props.t('howTo.slides.slide7.description'),
            image: step7,
            static: false
        },
        {
            title: props.t('howTo.slides.slide8.title'),
            description: props.t('howTo.slides.slide8.description'),
            additional: '   ',
            image: step8,
            static: true
        },
        {
            title: props.t('howTo.slides.slide9.title'),
            description: props.t('howTo.slides.slide9.description'),
            image: step9,
            static: false
        },
        {
            title: props.t('howTo.slides.slide10.title'),
            description: props.t('howTo.slides.slide10.description'),
            additional: props.t('howTo.slides.slide10.additional'),
            image: step10,
            static: true
        },
        {
            title: props.t('howTo.slides.slide11.title'),
            description: props.t('howTo.slides.slide11.description'),
            additional: '   ',
            image: step11,
            static: true
        }
    ];

    return (
        <div className={'how-to-page__slider'}>
            <AwesomeSlider
                bullets={false}
                organicArrows={false}
                fillParent={true}
                infinite={false}
                mobileTouch={false}
                selected={currentPage < stepsData.length ? currentPage : stepsData.length - 1}
            >
                {stepsData.map((step, index) => {
                    return (
                    <div className="how-to-page__step-container" key={index}>
                        <div className="page-title">
                            <p className={'page-title__text'}>{index + 1 + ". " + step.title}</p>
                            <span className="page-title__divide"></span>
                        </div>
                        <p className="how-to-page__sub-title" dangerouslySetInnerHTML={ { __html : step.description} }>
                        </p>
                        <div className={step.static ? "how-to-page__image-wrapper static-image" : "how-to-page__image-wrapper "}>
                            <img src={step.image} className={step.image2 ? 'how-to-page__step-image step-image-1' : 'how-to-page__step-image'} alt=""/>
                            {step.image2 ? (<img src={step.image2} className="how-to-page__step-image step-image-2" alt=""/>) : ''}
                        </div>
                    {step.additional ? (<p className="how-to-page__sub-title" dangerouslySetInnerHTML={ { __html : step.additional} }></p>) : ''}
                    </div>
                    )
                })}
            </AwesomeSlider>
        </div>
    )
}

export default LangHOC(MainSlides)




