import React from 'react';

const CollectedPoints = (props) => {
  const itemShow = props.expandPoints ? props.user['points'].length : props.itemsToShowCollectPoints;
  return (
      <div className={`points-collected`}>
        <div className={`cabinet-list`}>
          {props.user['points'] && props.user['points'].length > 0 && props.user['points'].sort((a, b) => {
            return  new Date(b.created_at) - new Date(a.created_at)
          }).slice(0, itemShow).map((item, i) => {
            if (item.activity['name_en'].toLowerCase() === 'shop') return false;
            return (
                <div className={`cabinet-list__item`} key={i}>
                  <div className={`cabinet-list__col`}>
                    <span className={`points-collected__points`}>{item.points < 0 ? item.points : `+${item.points}`}</span>
                  </div>
                  <div className={`cabinet-list__col`}>
                    <span className={`points-collected__name`}>
                      {item.activity['name_en'].toLowerCase().indexOf('survey') > -1 ? 'Survey' :
                        item.activity['name_en'] === 'id_upload' ? props.t('main_page.tasks.photo_id') :
                          item.activity['name_en'] === 'in_store_audit' ? props.t('main_page.tasks.in_store_audit'):
                              item.activity['name_en'] === 'combo_task' ? props.t('main_page.tasks.combo_task'):
                            item.activity['name_en'] === 'photo_shooting' ? props.t('open_task.photo_shooting.type') : item.activity[`name_${props.currentLanguage}`]}
                    </span>
                    <span className={`points-collected__time`}>{props.t('cabinet.submitted')} {item['created_at']}</span>
                  </div>
                </div>
            )
          })}
        </div>
        <div className={`points-more`}>
          <hr/>
          <span className={`more ${props.expandPoints?'active':''}`}
                onClick={(e)=>props.showMore(e)}>{props.t('cabinet.expand')}<span className={`triangle`}></span>
          </span>
        </div>
      </div>
  )
}

export default CollectedPoints
