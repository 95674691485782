import React, {useState, useEffect, useRef} from "react";
import Title from "../../../parts/Title";
import NumberFormatInput from "../../../form/NumberFormatInput";
import Button from "../../../form/Button";
import Input from "../../../form/Input";
import Form from "../../../form/Form";
import defaultAxios from "../../../../axios";
import ReCAPTCHA from "react-google-recaptcha";
import {googleRecaptchaKey} from "../../../../constants/Variables";
import {Link} from "react-router-dom";

const UpdatePhone = (props) => {
  const {t, togglePhoneForm, showUserPhone, showBlockedPopup} = props;
  const [captcha, setCaptcha] = useState(null);
  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState({});
  const [codeError, setCodeError] = useState({});
  const [sendStatus, setStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [formStatus, setStatusForm] = useState(false);
  const recaptchaRef = useRef();
  const timerDuration = 30000;
  const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;

  const handleSubmit = (formData) => {
    setLoader(true);
    setPhoneError({});
    setCodeError({});
    let fd = {};
    formData.forEach(function(value, key) {
      if (key === "phone") {
        value = value.substring(5).replace(/[-_]/g, '');
      }
      fd[key] = value;
    });
    fd.email = currentUser.email;
    defaultAxios.post('/api/v2/cabinet/user-cabinet-update', fd, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
        .then(response => {
          if (response?.data) {
            setLoader(false);
            setStatusForm(true);
          }
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty('phone') && Array.isArray(error.response.data.errors.phone)) {
              if (error.response.data.errors.phone.find(el => {
                    return el.toLowerCase().indexOf('you can update phone number once a month') > -1})) {
                setPhoneError({
                  'phone': t('errors.blocked_change_phone')
                });
                backToProfile();
                showBlockedPopup('change-phone-popup');
              } else if (error.response.data.errors.phone.some(el => {
                return el.toLowerCase().indexOf('the phone has already been taken') > -1})) {
                setPhoneError({
                  'phone': t('errors.phone_used')
                });
              } else if (error.response.data.errors.phone.some(el => {
                return el.toLowerCase().indexOf('you cant use the phone number, write your administrator') > -1})) {
                setPhoneError({
                  'phone': t('errors.phone_registered')
                });
              } else {
                setPhoneError({
                  'phone': t('errors.phone_used')
                });
              }
            }
            if (error.response.data.errors.hasOwnProperty('validation_code')) {
              setCodeError({
                'validation_code': t('errors.wrong_code')
              });
            }
          }
          setLoader(false);
        })
  };

  const handleChangePhone = (value) => {
    setPhone(value);
    setPhoneError({});
  };

  const handleChangeCaptcha = (value) => {
    setCaptcha(value)
  };

  const getCode = async () => {
    setStatus(true);
    const asyncTokenCaptha = await recaptchaRef?.current?.executeAsync();
    let newCaptha = asyncTokenCaptha || captcha;
    const locale = localStorage.getItem('language') || "en";
    const phoneNumber = phone || currentUser.phone;
    let fd = new FormData();
    fd.append('locale', locale);
    fd.append('confirmation_phone', phoneNumber);
    fd.append('g-recaptcha-response', newCaptha);
    if (newCaptha && phoneNumber && phoneNumber.length === 9) {
      defaultAxios.post('/api/front/v2/create-user', fd, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
          'Accept': 'application/json'

        }
      })
          .then(response => {
            recaptchaRef.current.reset();
            setTimeout(() => setStatus(false), timerDuration);

          })
          .catch(error => {
            recaptchaRef.current.reset();
            setStatus(false);
          })
    } else {
      recaptchaRef.current.reset();
      setStatus(false);
      setPhoneError({
        'phone': t('errors.phone')
      });
    }
  };

  const backToProfile = () => {
    togglePhoneForm(false);
  };

  return (
      <>
        <Title title={t('cabinet.update_phone.title')} size={208}/>
        <Form className={`update-phone-form ${formStatus ? 'success-form' : ''}`} handleSubmit={handleSubmit}>
          <NumberFormatInput name={'phone'}
                             mask="_"
                             format={'+995 ###-##-##-##'}
                             onChange={handleChangePhone}
                             serverErrors={phoneError}
                             allowEmptyFormatting
                             validate={[{required: true, message: t('errors.phone')}]}
                             placeholder={t('cabinet.profile.phone')}
                             disabled={showUserPhone || formStatus}
                             value={showUserPhone ? currentUser.phone : null}
          />
          <Button type={'button'} className={!sendStatus ? 'btn--blue' : ''} handleClick={getCode} disabled={sendStatus}>{t('btns.veritify_code')}</Button>
          <div className="auth__captcha-wrapper">
            <ReCAPTCHA
                sitekey={googleRecaptchaKey}
                onChange={handleChangeCaptcha}
                ref={recaptchaRef}
                size="invisible"
            />
          </div>
          <p className={`update-phone-form__text`}>{t('cabinet.update_phone.text')}</p>
          <Input name="validation_code"
                 placeholder='Code from SMS'
                 maxLength={6}
                 serverErrors={codeError}
                 validate={[
                   {required: true, message: t('errors.verification_code')},
                   {minLength: 6, message: t('errors.code_field')}
                 ]}
                 icon={codeError !== null && typeof codeError === 'object' && codeError.hasOwnProperty('validation_code') ? 'warning' : formStatus ? 'success' : ''}
                 disabled={formStatus}
          />
          <p className={`success-msg`}>{t('cabinet.update_phone.success_msg')}</p>

          <div className={`profile-submit`}>
            {
              formStatus ? <Button handleClick={backToProfile} type={'button'}>{t('btns.save_back')}</Button> :
                  <Button type={'submit'} disabled={loader}>{t('btns.confirm')}</Button>
            }
          </div>

          <Link to={{pathname: "/feedback"}}>{t('cabinet.update_phone.not_received_code')}</Link>
        </Form>
      </>
  )
};

export default UpdatePhone
