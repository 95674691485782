import React, {useRef} from 'react';
// import card_id_logo from "../../../../../images/auth/id-card.svg";
import Form from "../../../form/Form";
import Button from "../../../form/Button";
import Input from "../../../form/Input";
import DatePickerInput from "../../../form/DatePicker";
import Radio from "../../../form/Radio";
// import Upload from "../../../form/Upload";
import Title from "../../../parts/Title";
// import {getBase64} from "../../../../utils";

function Step2 (props) {
  const {nextStep, handleChangeField, userConfirmationData} = props;
  // const photoIdImg = useRef(null);

  // const uploadPhoto = (event) => {
  //   const photo = event.target.files[0];
  //   if (photo && isValidFileType(photo.type)) {
  //     getBase64(photo, (result) => {
  //       if (!photoIdImg) return false;
  //       const photoImg = photoIdImg.current;
  //       photoImg.src = result;
  //     });
  //   } else {
  //     if (!photoIdImg) return false;
  //     photoIdImg.current.src = card_id_logo
  //   }
  // };
  //
  // const isValidFileType = (fName) => {
  //   const extensionImages = ['image/jpeg', 'image/png', 'image/bmp', 'image/x-png'];
  //   return extensionImages.find(el=>el == fName);
  // };
  //
  // const userUrlId = props.user.IdUrl !== 'not provided' && (props.userConfirmationData.required_empty_fields &&
  //   props.userConfirmationData.required_empty_fields.hasOwnProperty('IdUrl'));
  return (
      <React.Fragment>
        <Title title={`Profile Update`} size={208}/>
        <div className={`signup__wrap`}>
          <div className={`auth__form-wrap`}>
            <Form handleSubmit={nextStep}>
              <div className={`auth-row`}>
                {/*{userUrlId &&*/}
                {/*<div className={`auth-col`}>*/}
                  {/*<div className={`auth-group-wrap`}>*/}
                    {/*<div className={`signup-image`}>*/}
                      {/*<img src={card_id_logo}*/}
                           {/*ref={photoIdImg}*/}
                           {/*alt="card id"*/}
                      {/*/>*/}
                    {/*</div>*/}
                    {/*<Upload name={'userID'}*/}
                            {/*text={props.t('signup.photo_upload_id')}*/}
                            {/*onChange={uploadPhoto}*/}
                            {/*validate={[*/}
                              {/*{required: true, message: props.t('errors.upload_photo')},*/}
                              {/*{check_size: true, message: 'The user id may not be greater than 10000 kilobytes'},*/}
                              {/*{check_type: true}*/}
                            {/*]}*/}
                            {/*value={props.userID}/>*/}
                  {/*</div>*/}
                {/*</div>*/}
                {/*}*/}
                <div className={`auth-col old-user`}>
                  <div className={`auth-group-wrap`}>
                    <div className={`radio-content`}>
                      <p className={`radio-content__label`}>{props.t('signup.old_enough')}</p>
                      <Radio
                          name="old_enough"
                          validate={[{
                            exactValue: '1',
                            message: props.t('errors.req_field')
                          }]}>
                        <option value="1" id="checkbox_2-1"
                                label={props.t('btns.yes')}/>
                        <option value="0" id="checkbox_2-2"
                                label={props.t('btns.no')}/>
                      </Radio>
                    </div>
                    <Input name={'firstname'}
                           maxLength={60}
                           validate={[
                             {required: true,
                               message: props.t('errors.firstname')},
                             {onlyLatinGeo: true}
                           ]}
                           placeholder={props.t('signup.firstname_placeholder')}
                           disabled={!!props.user.firstname}
                           value={props.user.firstname}/>
                    {(!userConfirmationData.token && !!props.user.firstname) && <input type="hidden" name="firstname" value={props.user.firstname}/>}
                    <Input name={'lastname'}
                           maxLength={60}
                           validate={[
                             {required: true,
                               message: props.t('errors.lastname')},
                             {onlyLatinGeo: true}
                           ]}
                           placeholder={props.t('signup.lastname_placeholder')}
                           disabled={!!props.user.lastname}
                           value={props.user.lastname}/>
                    {(!userConfirmationData.token && !!props.user.lastname) && <input type="hidden" name="lastname" value={props.user.lastname}/>}
                  </div>
                </div>
              </div>
              <div className={`auth-row`}>
                <div className={`auth-col`}>
                  <div className={`auth-group-wrap`}>
                    <Input name={'pid'}
                           maxLength={11}
                           onChange={handleChangeField}
                           validate={[
                             {required: true,
                               message: props.t('errors.pid')},
                             {onlyNumbers: true},
                             {minLength: 11}
                           ]}
                           serverErrors={props.pidError}
                           placeholder={props.t('signup.pid_placeholder')}
                           disabled={!!props.user.pid}
                           value={props.user.pid}/>
                    {(!userConfirmationData.token && !!props.user.pid) && <input type="hidden" name="pid" value={props.user.pid}/>}
                  </div>
                </div>
                <div className={`auth-col`}>
                  <div className={`auth-group-wrap`}>
                    <DatePickerInput name={'birth_date'}
                                     validate={[
                                       {required: true,
                                         message: props.t('errors.birth_date')},
                                       {under18: true,
                                         message: props.t('errors.18old')}
                                     ]}
                                     placeholder={props.t('signup.birthdate_placeholder')} date={props.user.birth_date}/>
                  </div>
                </div>
              </div>
              <div className={`auth__btns`}>
                <Button type={`submit`}>{props.t('btns.next')}</Button>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
  )
}

export default Step2
