import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

class Dropdown extends Component {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    validating: PropTypes.bool,
    validate: PropTypes.array,
    handleChange: PropTypes.func,
    handleValidating: PropTypes.func,
    clearable: PropTypes.bool,
    menuHeight: PropTypes.number,
    minMenuHeight: PropTypes.number
  };

  static defaultProps = {
    clearable: false,
    placeholder: null,
    onChange: () => {}
  };

  constructor(props) {
    super();
    this.state = {
      removeSelected: true,
      stayOpen: false,
      value: props.value || '',
      errors: {}
    }
  }

  handleSelectChange = (value) => {
    this.setState({ value });
    this.props.onChange(value);

    if (this.props.handleChangeField) {
      this.props.handleChangeField(value, this.props.name)
    }


    // if (this.props.validating === true) {
      if (this.props.validate) {
        this.handleValidation(value);
      }
    // }
  };

  handleValidation = (value) => {
    const {errors} = this.state;

    this.props.validate && this.props.validate.map(item => {
      if (item.hasOwnProperty('required')) {
        if (!value) {
          errors.required = item.message || 'The value is required'
        } else {
          delete errors.required;
        }
      }
      this.setState({
        errors
      })
    });

    if (this.props.handleValidating) {
      this.props.handleValidating(this.props.name, Object.keys(errors).length);
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.validating === true && this.props.validating !== prevProps.validating) {
      if (this.props.validate) {
        this.handleValidation(this.state.value);
      }
    }
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value
      });
    }
  }

  render () {
    const { stayOpen, value } = this.state;
    const options = this.props.dataOptions;
    return (
        <div className={"select-wrapper" + (Object.keys(this.state.errors).length ? " has-error" : "")}>
          <Select
              name={this.props.name}
              placeholder={this.props.placeholder}
              closeOnSelect={!stayOpen}
              isDisabled={this.props.disabled}
              multi={false}
              onChange={this.handleSelectChange}
              options={options}
              removeSelected={this.state.removeSelected}
              simpleValue
              value={value}
              clearable={this.props.clearable}
              searchable={this.props.searchable}
              selectedValue={value}
              className={'react-select-container'}
              classNamePrefix="react-select"
              menuPlacement={'auto'}
              maxMenuHeight={this.props.menuHeight}
              minMenuHeight={this.props.minMenuHeight}
          />
          {Object.keys(this.state.errors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.state.errors[item]}</div>
          ))}
        </div>
    );
  }
}

export default Dropdown;
