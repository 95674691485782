import React from "react";
import Title from "../../../../parts/Title";
import {Link} from "react-router-dom";
import Button from "../../../../form/Button";
import done_img from "../../../../../../images/open-tasks/attention.png";

const Blocked = (props) => {
  return (
      <div className={`popup attention photo-pack-popup`}>
        <div className={`popup__close`} onClick={()=>props.closePopup()}></div>
        <div className={`container`}>
          <div className={`popup__wrap`}>
            <div className={`attention-img`}>
              <img src={done_img} alt="done pic"/>
            </div>
            <Title title={props.t('shop.attention.title')} className={`white white-v2`} size={170}/>
            <p className={`popup__text`}>{props.t('open_task.photo_pack.attention.text2')}</p>
            <p className={`popup__text`}>{props.t('open_task.photo_pack.attention.text3')}</p>
            <Button className={`open-tasks-finish__btn`}>
              <Link to={'/feedback'}>{props.t('btns.contact_us')}</Link>
            </Button>
          </div>
        </div>
      </div>

  );
};

export default Blocked;
