import * as types from "../constants/ActionTypes";
import defaultAxios from "../axios";

export const setLogin = (token, expiration) => (dispatch) => {
  localStorage.setItem("token", token);
  localStorage.setItem("expiration", expiration);

  dispatch(setMe());
  dispatch(setLoginState(true));
};

/*export const setMe = () => (
    dispatch => {
      const token = localStorage.getItem('token');

      defaultAxios.get('/api/front/users/me ', {
        headers: {"Authorization": `Bearer ${token}`}
      })
          .then(response => {
            if (response.data) {
              localStorage.setItem('user.me', JSON.stringify(response.data.data));
              dispatch(setMeState(true));
            }
          })
          .catch(error => {
          });
    }
);*/

export const setMe = () => (dispatch) => {
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };
  const url = `/api/front/v2/user-profile`;
  defaultAxios
    .get(url, config)
    .then((response) => {
      if (response.data) {
        localStorage.setItem("user.me", JSON.stringify(response.data));
        dispatch(setMeState(true));
        dispatch(setFirstLoginStatus(true));
      }
    })
    .catch((error) => {});
};

export const checkLogin = () => (dispatch) => {
  if (localStorage.getItem("token")) {
    const currentTime = Math.round(new Date() / 1000);
    const expireTime = +localStorage.getItem("expiration");

    if (currentTime < expireTime) {
      dispatch(setLoginState(true));
      return true;
    } else {
      dispatch(logout());
      return false;
    }
  } else {
    return false;
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("expiration");
  localStorage.removeItem("user.me");
  dispatch(setLoginState(false));
  dispatch(setMeState(false));
  dispatch(setFirstLoginStatus(false));
};

export const setUserConfirm = (userData) => ({
  type: types.SET_CONFIRM_DATA,
  userData: userData,
});

export const setLoginState = (state) => ({
  type: types.SET_LOGIN_STATE,
  state,
});

export const setFirstLoginStatus = (status) => ({
  type: types.SET_FIST_LOGIN_STATUS,
  status,
});

export const setMeState = (state) => ({
  type: types.SET_ME_STATE,
  state,
});

export const setUserPhone = (userPhone) => ({
  type: types.SET_USER_PHONE,
  userPhone: userPhone,
});

export const setFirstRegFromSms = (status) => ({
  type: types.SET_FIRST_REG_FROM_SMS,
  status,
});

export const setCrmUserData = (crmData) => ({
  type: types.SET_CRM_USER_DATA,
  crmData
});

export const setUserCode = (code) => ({
  type: types.SET_USER_CODE,
  userCode: code
});
