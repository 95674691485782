import React, { useState } from 'react'
import SurveyQuestion from './SurveyQuestion'
import PropTypes from 'prop-types'
import Button from '../../../../form/Button'

const SurveyQuestions = ({ onEndSurvey, allQuestions, mainQuestions, relatedQuestions, t, lang }) => {
    const [renderQuestions, setRenderQuestions] = useState(() => mainQuestions)
    const [formErrors, setFormErrors] = useState(null)

    const additionalQuestionHandler = (currentAnswerKey, currentQuestionKey, type) => {
        //search if we have additional questions for this answer
        const currentRelatedQuestions = relatedQuestions.filter(
            el =>
                el.attributes.related_question === currentQuestionKey &&
                el.attributes.related_answer === currentAnswerKey,
        )

        //if  there is no addition question
        if (currentRelatedQuestions.length === 0 && type !== 'radio') {
            console.log('no need to render new')
            return
        }

        // attributes.related_question && question.attributes.related_answer

        const isItSameAnswer = renderQuestions.some(el => el.attributes?.related_answer === currentAnswerKey)

        const isRadioHasRelatedQuestion =
            type === 'radio' && renderQuestions.some(el => el.attributes?.related_question === currentQuestionKey)

        if (!isItSameAnswer && !isRadioHasRelatedQuestion && currentRelatedQuestions.length === 0) {
            console.log('no need to render new')
            return
        }
        if (isItSameAnswer && type === 'checkbox') {
            console.log('renderQuestions', renderQuestions)
            console.log('check box must clear')
            const newRenderArray = renderQuestions.filter(el => el.attributes.related_answer !== currentAnswerKey)
            setRenderQuestions(newRenderArray)
            return
        }
        if (isItSameAnswer || (isRadioHasRelatedQuestion && !isItSameAnswer && currentRelatedQuestions.length === 0)) {
            console.log('clear render of related')
            const currentIndexInMainQuestions = mainQuestions.findIndex(el => el.key === currentQuestionKey)

            if (currentIndexInMainQuestions === -1) {
                setRenderQuestions(mainQuestions)
                return
            }

            const currentIndexInRenderArray = renderQuestions.findIndex(el => el.key === currentQuestionKey)
            const partArray = mainQuestions.slice(currentIndexInMainQuestions + 1)
            const newRenderArray = renderQuestions.slice(0, currentIndexInRenderArray + 1).concat(partArray)
            setRenderQuestions(newRenderArray)
            return
        }
        if (isItSameAnswer || (isRadioHasRelatedQuestion && !isItSameAnswer && currentRelatedQuestions.length !== 0)) {
            console.log('need clear related and add new related')
            const currentIndexInMainQuestions = mainQuestions.findIndex(el => el.key === currentQuestionKey)

            if (currentIndexInMainQuestions === -1) {
                setRenderQuestions(mainQuestions)
                return
            }

            const currentIndexInRenderArray = renderQuestions.findIndex(el => el.key === currentQuestionKey)
            const partArray = mainQuestions.slice(currentIndexInMainQuestions + 1)
            const tempRenderQuestions = renderQuestions.slice(0, currentIndexInRenderArray + 1).concat(partArray)

            let delta = 0

            const currentIndexInAllQuestionsArray = allQuestions.findIndex(el => el.key === currentQuestionKey)

            const indexOfRelatedQuestionInAll = allQuestions.findIndex(el => el.key === currentRelatedQuestions[0].key)

            if (indexOfRelatedQuestionInAll <= currentIndexInAllQuestionsArray) {
                alert('Not valid data: related question is in a head of current question!')
                throw new Error('Not valid data: related question is in a head of current question!')
            }
            console.log('indexOfRelatedQuestionInAll', indexOfRelatedQuestionInAll)

            if (indexOfRelatedQuestionInAll - currentIndexInAllQuestionsArray > 1) {
                //calculating skip questions
                const partArray = allQuestions.slice(currentIndexInAllQuestionsArray, indexOfRelatedQuestionInAll + 1)

                const mainAnswersToSkip = partArray.filter(x => !relatedQuestions.some(y => x.key === y.key)).length

                delta = mainAnswersToSkip - 1
            }
            const newRenderArray = tempRenderQuestions.toSpliced(
                currentIndexInRenderArray + 1,
                delta,
                ...currentRelatedQuestions,
            )
            setRenderQuestions(newRenderArray)

            return
        }

        console.log('render new related question')

        //processing add related questions to render array
        let delta = 0

        const currentIndexInRenderArray = renderQuestions.findIndex(el => el.key === currentQuestionKey)
        const currentIndexInAllQuestionsArray = allQuestions.findIndex(el => el.key === currentQuestionKey)

        const indexOfRelatedQuestionInAll = allQuestions.findIndex(el => el.key === currentRelatedQuestions[0].key)

        if (indexOfRelatedQuestionInAll <= currentIndexInAllQuestionsArray) {
            alert('Not valid data: related question is in a head of current question!')
            throw new Error('Not valid data: related question is in a head of current question!')
        }
        console.log('indexOfRelatedQuestionInAll', indexOfRelatedQuestionInAll)

        if (indexOfRelatedQuestionInAll - currentIndexInAllQuestionsArray > 1) {
            //calculating skip questions
            const partArray = allQuestions.slice(currentIndexInAllQuestionsArray, indexOfRelatedQuestionInAll + 1)

            const mainAnswersToSkip = partArray.filter(x => !relatedQuestions.some(y => x.key === y.key)).length

            delta = mainAnswersToSkip - 1
            console.log('delta', delta)
        }
        const newRenderArray = renderQuestions.toSpliced(
            currentIndexInRenderArray + 1,
            delta,
            ...currentRelatedQuestions,
        )
        setRenderQuestions(newRenderArray)
    }

    const formSubmitHandler = e => {
        e.preventDefault()
        const isCheckboxOrRadio = type => ['checkbox', 'radio'].includes(type)

        let previousQuestionId = ''
        let currentQuestionId = ''

        const answers = [
            // {
            //   question_id: activeQuestion.key,
            //   answers: activeQuestionAnswers.map((a) => {
            //     return a.custom_answer
            //       ? { custom_answer: a.custom_answer }
            //       : { answer_id: a.key };
            //   }),
            // },
        ]

        const answer = {
            question_id: null,
            answers: [],
        }

        for (let field of e.target.elements) {
            const { name } = field

            if (name) {
                currentQuestionId = name
                if (previousQuestionId === '') {
                    previousQuestionId = name
                }

                if (currentQuestionId !== previousQuestionId) {
                    answers.push({ ...answer })
                    answer.answers = []
                    previousQuestionId = name
                }

                answer.question_id = currentQuestionId

                const { type, checked, value } = field
                if (isCheckboxOrRadio(type)) {
                    if (value === name) {
                        continue
                    }
                    if (checked) {
                        answer.answers.push({ answer_id: value })
                    }
                }
                if (type === 'range') {
                    if (value) {
                        answer.answers.push({ custom_answer: +value })
                    }
                }
                if (field.tagName === 'TEXTAREA' && !field.disabled) {
                    answer.answers.push({ custom_answer: value.trim() })
                }
            }
            if (field.type === 'submit') {
                answers.push({ ...answer })
            }
            previousQuestionId = currentQuestionId
        }

        const errors = {}

        for (let el of answers) {
            if (el.answers.length === 0) {
                errors[el.question_id] = false
            }
        }

        if (Object.keys(errors).length > 0) {
            console.log('errors', errors)
            setFormErrors(errors)

            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

            return
        }
        setFormErrors({})

        onEndSurvey(answers)
    }

    return (
        <form onSubmit={formSubmitHandler}>
            {renderQuestions.map((el, index) => {
                return (
                    <SurveyQuestion
                        t={t}
                        errors={formErrors}
                        key={el.key}
                        lang={lang}
                        question={el}
                        number={index + 1}
                        onInputChange={(answerKey, type) => additionalQuestionHandler(answerKey, el.key, type)}
                        shouldRenderOptionalField={!!el.attributes.has_custom_answer}
                    />
                )
            })}
            <Button className={`survey-form__next-btn`} type={'submit'}>
                {t('btns.submit')}
            </Button>
        </form>
    )
}

SurveyQuestions.propTypes = {
    t: PropTypes.func.isRequired,
    surveyId: PropTypes.number.isRequired,
    onEndSurvey: PropTypes.func.isRequired,
    lang: PropTypes.oneOf(['en', 'ge']).isRequired,
    allQuestions: PropTypes.array.isRequired,
}

export default SurveyQuestions
