import React, {Component} from "react";
import {connect} from "react-redux";
import {setWrapperClassName} from "../../../../actions/common";
import RulesConfirm from "./RulesConfirm";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import {getCities} from "../../../../actions/citiesActions";
import defaultAxios from "../../../../axios";
import Start from "./Start";
import LangHOC from "../../../HoC/LangHOC";
import Step6 from "./Step6";
import {setLogin} from "../../../../actions/auth";
import Block from "./Block";
import RulesPopup from "../Signup/RulesPopup";

class ConfirmProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      cityList: [],
      formValues: null,
      phone: null,
      email: null,
      pidError: {},
      emailError: {},
      phoneError: {},
      user: {
        id: '',
        firstname: '',
        lastname: '',
        email: '',
        gender: '',
        city_id: '',
        city: null,
        phone: undefined,
        pid: '',
        birth_date: undefined,
        IdUrl: null
      },
      checkGender: null,
      popup: false,
      checkbox_rules: false
    };
  }

  componentDidMount() {
    this.getUser();
    this.props.getCities();
    this.props.setWrapperClassName("auth-container");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cities !== prevProps.cities) {
      this.setState({
        cityList: this.props.cities
      });
    }
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  getUser = () => {
    const token = this.props.userConfirmationData.token;
    const crmUserData = this.props.crmData;
    if (/*this.props.userConfirmationData.idUrl === 'not provided' &&*/ (this.props.userConfirmationData.required_empty_fields /*&&
      !this.props.userConfirmationData.required_empty_fields.hasOwnProperty('IdUrl')*/)) {
      this.handleChangeStep(1)
    }
    if (!token && !crmUserData) this.props.history.push('/signin');
    // if (this.props.userConfirmationData.required_empty_fields &&
    //     this.props.userConfirmationData.required_empty_fields.hasOwnProperty('new_rules_agreement') &&
    //     !this.props.userConfirmationData.required_empty_fields.new_rules_agreement) {
    //   this.handleChangeStep('block');
    //   return
    // }
    if (token) {
      defaultAxios.get('/api/front/v2/user-profile', {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token
        }
      })
          .then(response => {
            // if (response.data && !response.data.phone) {
            //   this.handleChangeStep('block');
            // }
            this.setState({
              user: response.data,
              checkGender: response.data.gender //:TODO after disabled radio btns gender, del this
            })
          })
          .catch(error => {})
    } else if (crmUserData) {
      this.setState({
        user: {...this.state.user, ...crmUserData}
      })
    }
  };

  handleNextStep = formData => {
    let object = {};
    formData.forEach(function(value, key){
      if (key === "new_rules_agreement") {
        object['rules_v2'] = '1'
      }
      object[key] = value;
    });

    if (this.state.step === 1 && !this.state.user.pid) {
      this.checkExists({"pid": object.pid})
    }
    else {
      this.setState({
        step: this.state.step + 1
      })
    }

    this.setState({
      formValues: Object.assign({}, this.state.formValues, object)
    })
  };

  handleChangeStep = (step) => {
    this.setState({
      step
    })
  };

  handleSubmit = async (formData) => {
    const {user} = this.state;
    const {userCode} = this.props;
    const locale = localStorage.getItem('language') || "en";
    const token = this.props.userConfirmationData.token;
    let newFormData = formData;
    const checkUniqEmail = await this.checkExists({"email": newFormData.get('email')});
    newFormData.append('locale', locale);
    for (let key in this.state.formValues) {
      newFormData.append(key, this.state.formValues[key]);
    }
    if (this.state.checkGender) {
      newFormData.delete('gender');
    }

    if (newFormData.get('new_rules_agreement')) {
      newFormData.set('rules_v2', '1');
    }

    if (token) {
      if (newFormData.get('phone')) {
        let phone = newFormData.get('phone').substring(5).replace(/[-_]/g, '');
        newFormData.set('phone', phone);
      }
      if (user.email === newFormData.get('email')) {
        newFormData.delete('email');
      }
      newFormData.delete('old_enough');
      newFormData.delete('password_repeat');
    } else {
      newFormData.append('confirmation_code', userCode);
    }
    /*let reqResults = new FormData();
    for (let [key, value] of newFormData.entries()) {
      if (key == 'userID' && this.props.userConfirmationData.required_empty_fields.hasOwnProperty('IdUrl')) {
        reqResults.append(key, value)
      } else if (Object.keys(this.props.userConfirmationData.required_empty_fields).includes(key)) reqResults.append(key, value)
    }*/
    if (!checkUniqEmail) {
    const apiUrl = token ? '/api/front/v2/user-update' : '/api/front/v2/create-user';
      defaultAxios.post(apiUrl, newFormData, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
          'Accept': 'application/json',
          ...(token && {"Authorization": "Bearer " + token,}),
        }
      })
          .then(response => {
            this.setState({
              step: 6
            })
            // if (response.data && response.data.data && response.data.data) {
            //   this.setState({
            //     step: 6
            //   })
            // }

            // if (response.data && response.data.data && response.data.data.user && response.data.data.user.status) {
            //   if (response.data.data.user.status.find(el=>el.id == 1) && this.state.user.IdUrl === 'not provided') {
            //     this.setState({
            //       step: 6
            //     })
            //   } else if (response.data.data.user.status.find(el=>el.id == 1)) {
            //     this.setState({
            //       step: 5
            //     })
            //   } else {
            //     this.setState({
            //       step: 4
            //     })
            //   }
            // } else {
            //   this.setState({
            //     step: 4
            //   })
            // }
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              if (error.response.data.errors.hasOwnProperty('email')) {
                this.setState({
                  'emailError': {'email': this.props.t('errors.email_used')}
                })
              } else if (error.response.data.errors.hasOwnProperty('phone')) {
                this.setState({
                  'phoneError': {'phone': this.props.t('errors.phone_used')}
                })
              }
            }
          })
    }
  };

  checkExists = (obj) => {
    return defaultAxios.post('/api/front/v2/is-data-exists', obj, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
        .then(response => {
          if (obj.hasOwnProperty('pid')) {
            if (response.data.pid) {
              this.setState({
                'pidError': {'pid': this.props.t('errors.pid_used')}
              })
            } else {
              this.setState({
                step: this.state.step + 1
              })
            }
          } else if (obj.hasOwnProperty('email')) {
            if (response.data.email) {
              this.setState({
                'emailError': {email: this.props.t('errors.email_used')}
              });
            }
            return response.data.email;
          }
        })
        .catch(error => {
        });
  };

  handleChangeField = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: undefined
    })
  };

  handleChangePhone = (value) => {
    if (this.state.phoneError) {
      this.setState({
        phoneError: {}
      })
    }
    this.setState({
      phone: value
    })
  };

  handlecheckboxRules = () => {
    this.setState({
      checkbox_rules: true
    })
  };

  openPopup = () => {
    window.scrollTo(0, 0);
    this.setState({
      popup: true,
      checkbox_rules: false
    }, () => {
      const upClassName = ["open-popup", this.props.wrapperClass].filter(e => !!e).join(" ");
      this.props.setWrapperClassName(upClassName);
    })
  };

  closePopup = () => {
    window.scrollTo(0, 0);
    this.setState({
      popup: false
    }, () => {
      const upClassName = this.props.wrapperClass.split("open-popup").pop();
      this.props.setWrapperClassName(upClassName);
    })
  };

  render() {
    const {step, popup} = this.state;
    if (step === 'block') return (
        <div className={`auth confirm-profile step-${step}`}>
          <Block {...this.props}/>
        </div>
    );
    return (
        <div className={`auth confirm-profile step-${step}`}>
          { step === 0 && <Start {...this.state} {...this.props} handleChangeStep={this.handleChangeStep}/> }
          {/*{ step === 1 && <RulesConfirm nextStep={this.handleNextStep} {...this.props} /> }*/}
          { popup && <RulesPopup closePopup={this.closePopup}
                                 handlecheckboxRules={this.handlecheckboxRules} {...this.props}/> }
          { step === 1 && <Step2 nextStep={this.handleNextStep}
                                 handleChangeField={this.handleChangeField}
                                 {...this.state} {...this.props}/> }
          { step === 2 && <Step3 handleSubmit={this.handleSubmit}
                                 handleChangeField={this.handleChangeField}
                                 handleChangePhone={this.handleChangePhone}
                                 openPopup={this.openPopup}
                                 {...this.state} {...this.props}/> }
          { step === 4 && <Step4 {...this.props}/> }
          { step === 5 && <Step5 {...this.props}/> }
          { step === 6 && <Step6 {...this.state} {...this.props}/> }
        </div>
    );
  }
}

const mapStateToProps = state => ({
  cities: state.cities.cities,
  userConfirmationData: state.auth.userConfirmationData,
  userCode: state.auth.userCode,
  crmData: state.auth.crmData,
  wrapperClass: state.common.wrapperClass
});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
  getCities: () => dispatch(getCities()),
  setLogin: (token, expiration) => dispatch(setLogin(token, expiration))
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(ConfirmProfile));
