import React from "react";
import product_img from "../../../../../../images/auth/id-card.svg";

const ImagePhotoId = (props) => {
  return (
    <div className={`open-tasks-desc-image`}>
      <div className={`open-tasks-desc-image__wrap`}>
        <p className={`open-tasks-desc-image__title`}>{props.t('open_task.census.desc_img_title')}:</p>
        <div className={`open-tasks-desc-image__product-img`}>
          <img src={product_img} ref={props.photoIdImg} alt="product"/>
        </div>
        <p className={`open-tasks-desc-image__text`}>{props.t('open_task.photo_id.desc_img_text')}</p>
      </div>
    </div>
  );
};

export default ImagePhotoId;
