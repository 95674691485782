import React from 'react';
import eye_icon from "../../../../../images/cabinet/eye.png";

const Task = (props) => {
  const {allUserTasks} = props;
  const itemsShow = props.expand ? props.allUserTasks.length : props.expandedItems;
  return (
      <div className={`tasks__list`}>
        {allUserTasks && allUserTasks.sort((a, b) => {
          return  new Date(b.task_start_date) - new Date(a.task_start_date)
        }).slice(0, itemsShow).map((item, i) => {
          let type = null;
          if (item['name_en'] == 'Census') {
            type = 'blue'
          } else if (item['name_en'] == 'Retail Audit' || item['name_en'] === 'in_store_audit') {
            type = 'orange'
          } else if (item['name_en'].toLowerCase().indexOf('survey_') > -1) {
            type = 'lightblue'
          } else if (item['name_en'].toLowerCase().indexOf('survey') > -1 && item['name_en'].toLowerCase().indexOf('survey_') === -1) {
            type = 'watermelon'
          } else if (item['name_en'] === 'id_upload') {
            type = 'lime'
          } else if (item['name_en'] === 'photo_shooting') {
            type = 'watermelon'
          }
          return (
              <div className={`task ${item['task_status'] == 'progress' ? 'active' : ''}`}
                   key={i} onClick={()=>props.openTask({...item})}>
                <div className={`task__title`}><span className={`task__type ${type}`}></span>
                  {item['name_en'].toLowerCase().indexOf('survey_') > -1 ? 'Survey' :
                      item['name_en'].toLowerCase().indexOf('survey') > -1 && item['name_en'].toLowerCase().indexOf('survey_') === -1 ? props.t('main_page.tasks.profiling_survey') :
                    item['name_en'] === 'id_upload' ? props.t('main_page.tasks.photo_id') :
                      item['name_en'] === 'in_store_audit' ? props.t('main_page.tasks.in_store_audit') :
                        item['name_en'] === 'photo_shooting' ? props.t('open_task.photo_shooting.type') :
                          item['name_en']}
                  <span className={`task__eye`}>
                    <img src={eye_icon} alt="eye icon"/>
                  </span>
                </div>
                <div className={`task__row`}>
                  <span className={`task__info`}>{props.t('cabinet.tasks.value')}:</span> {item['tasks_points']} {props.t('cabinet.tasks.points')}
                </div>
                {(item['name_en'].toLowerCase().indexOf('survey') === -1 && item['name_en'] != 'id_upload') && <div className={`task__row`}>
                  <span className={`task__info`}>{props.t('cabinet.tasks.location')}:</span> {item.address}
                </div>}
                <div className={`task__row`}>
                  <span className={`task__info`}>{props.t('cabinet.tasks.accepted')}:</span> {(item['name_en'].toLowerCase().indexOf('survey') === -1 && item['name_en'] != 'id_upload')
                  ? item.task_start_date : item.task_start_date.split(' ')[0]}
                </div>
                <div className={`task__row`}>
                  <span className={`task__info`}>{props.t('cabinet.tasks.status')}:</span> {item['task_status'] == 'done'
                    ? item['name_en'].toLowerCase().indexOf('survey') === -1 ? props.t('cabinet.tasks.moderated') : props.t('cabinet.tasks.completed') : item['task_status'] == 'progress' ? props.t('cabinet.tasks.pending') :
                        item['task_status'] == 'pending' ? props.t('cabinet.tasks.pending') :
                            item['task_status'] == 'Rejected' || item['task_status'] == 'canceled' ? <span className={`canceled`}>{props.t('cabinet.tasks.canceled')}</span> :
                                item['task_status'] == 'moderated' ? <span className={`completed`}>{props.t('cabinet.tasks.completed')}</span> : null}
                </div>
              </div>
          )
        })}
      </div>
  )
}

export default Task
