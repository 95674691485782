import React from "react";
import Form from "../../../../../form/Form";
import Radio from "../../../../../form/Radio";
import Checkbox from "../../../../../form/Checkbox";
import Textarea from "../../../../../form/Textarea";
import {replaceAllStrBySymbol} from "../../../../../../utils";
import Button from "../../../../../form/Button";
import Range from "../../../../../form/Range";
// import pres_img from "../../../../../../../images/open-tasks/survey/camel_research_v2.png";

const SurveyQuestions = (props) => {
  return (
      <div className="survey-wrap">
        <Form className={`survey-form`} handleSubmit={props.setQuizSubmit}
              serverErrorQuantity={props.serverErrorQuantity}>
          <div className={`survey-form__col`}>
            {
              props.questions && Array.isArray(props.questions) && props.questions.length > 0 && (
                  <React.Fragment>
                    {
                      props.questions[0] && [props.questions[0]].map((el) => {
                        return (
                            <div
                                className={`survey-form__field radio-btn ${props.errorRadio0 ? 'error' : ''}`}
                                key={el['question_id']}>
                              <p className={`survey-form__label`}>
                                <span className={`survey-form__count-label`}>1</span>
                                {el.description}
                              </p>
                              <Radio name={'Radio0'}
                                     className={`radio`}
                                     onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                              >
                                {el['answers'].sort((a, b) => {
                                  return a.id - b.id
                                }).map((answer) => {
                                  return <option key={answer.id}
                                                 value={answer.name_en}
                                                 id={el['question_id'] + answer.id}
                                                 label={answer.name}/>
                                })}
                              </Radio>
                              <div className={'error-element'}>{props.errorRadio0}</div>
                            </div>
                        )
                      })
                    }

                    {
                      props.questions[1] && [props.questions[1]].map((el) => {
                        return (
                            <div
                                className={`survey-form__field ${props.errorCheckbox0 || props.errorcommentCheckbox0 ? 'error' : ''}`}
                                key={el['question_id']}>
                              <p className={`survey-form__label specific`}>
                                <span className={`survey-form__count-label`}>2</span>
                                {/*{el.description}*/}
                                <span dangerouslySetInnerHTML={{ __html: replaceAllStrBySymbol(el.description, '(', ')') }}></span>
                              </p>
                              <div className={`survey-form__multi-checkbox`}>
                                {el['answers'].sort((a, b) => {
                                  return a.id - b.id
                                }).map((answer, i) => {
                                  return <Checkbox key={answer.id} id={answer.id}
                                                   name={'Checkbox0'}
                                                   label={answer.name}
                                                   data-name-en={answer.name_en}
                                                   data-name={el['answers'].length === i + 1 ? 'comment' : null}
                                                   onChange={(e) => props.handleCheckConsider(e, el['question_id'])}/>
                                })}
                              </div>
                              <Textarea name={`commentCheckbox0`} maxLength={250}
                                        className={`${props.errorcommentCheckbox0 ? 'error' : ''}`}
                                        placeholder={props.t('open_task.sobranie.textarea_placeholder')}
                                        disabled={!props.fieldCheckbox0}
                                        onChange={props.textAreaHandler}/>
                              <div className={'error-element'}>{props.errorCheckbox0 || props.errorcommentCheckbox0}</div>
                            </div>
                        )
                      })
                    }

                    {
                      props.questions[2] && [props.questions[2]].map((el) => {
                        return (
                            <div className={`survey-form__field`} key={el['question_id']}>
                              <p className={`survey-form__label`}>
                                <span className={`survey-form__count-label`}>3</span>
                                {el.description}
                              </p>
                              {el['answers'].sort((a, b) => {
                                return a.id - b.id
                              }).map((answer) => {
                                return (
                                    <div key={answer.id}
                                         className={`range range-full-width ${props.rangeErrors && props.rangeErrors.hasOwnProperty([`errorRange0`]) && props.rangeErrors[`errorRange0`] ? 'error' : ''}`}>
                                      <Range name={`Range0`}
                                             onChange={(name, value) => props.handleRange(name, value, el['question_id'], answer.id)}
                                      />
                                      <div
                                          className={'error-element'}>{props.rangeErrors && props.rangeErrors.hasOwnProperty([`errorRange0`]) && props.rangeErrors[`errorRange0`]}</div>
                                    </div>
                                )
                              })}
                            </div>
                        )
                      })
                    }

                    {/*{*/}
                      {/*props.questions[3] && [props.questions[3]].map((el) => {*/}
                        {/*return (*/}
                            {/*<div className={`survey-form__field`} key={el['question_id']}>*/}
                              {/*<p className={`survey-form__label`}>*/}
                                {/*<span className={`survey-form__count-label`}>4</span>*/}
                                {/*{el.description}*/}
                              {/*</p>*/}
                              {/*{el['answers'].sort((a, b) => {*/}
                                {/*return a.id - b.id*/}
                              {/*}).map((answer) => {*/}
                                {/*return (*/}
                                    {/*<div key={answer.id}*/}
                                         {/*className={`range range-full-width ${props.rangeErrors && props.rangeErrors.hasOwnProperty([`errorRange1`]) && props.rangeErrors[`errorRange1`] ? 'error' : ''}`}>*/}
                                      {/*<Range name={`Range1`}*/}
                                             {/*onChange={(name, value) => props.handleRange(name, value, el['question_id'], answer.id)}*/}
                                      {/*/>*/}
                                      {/*<div*/}
                                          {/*className={'error-element'}>{props.rangeErrors && props.rangeErrors.hasOwnProperty([`errorRange1`]) && props.rangeErrors[`errorRange1`]}</div>*/}
                                    {/*</div>*/}
                                {/*)*/}
                              {/*})}*/}
                            {/*</div>*/}
                        {/*)*/}
                      {/*})*/}
                    {/*}*/}

                    {
                      props.questions[3] && [props.questions[3]].map((el) => {
                        return (
                            <div
                                className={`survey-form__field radio-btn ${props.errorRadio1 || props.errorcommentRadio1 ? 'error' : ''}`}
                                key={el['question_id']}>
                              <p className={`survey-form__label`}>
                                <span className={`survey-form__count-label`}>4</span>
                                {el.description}
                              </p>
                              <Radio name={'Radio1'}
                                     className={`radio`}
                                     onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                              >
                                {el['answers'].sort((a, b) => {
                                  return a.id - b.id
                                }).map((answer, i) => {
                                  return <option key={answer.id}
                                                 data-name={el['answers'].length === i + 1 ? 'comment' : null}
                                                 value={answer.name_en}
                                                 id={el['question_id'] + answer.id}
                                                 label={answer.name}/>
                                })}
                              </Radio>
                              <Textarea name={`commentRadio1`} maxLength={250}
                                        className={`${props.errorcommentRadio1 ? 'error' : ''}`}
                                        placeholder={props.t('open_task.sobranie.textarea_placeholder_1')}
                                        disabled={!props.fieldRadio1}
                                        onChange={props.textAreaHandler}/>
                              <div className={'error-element'}>{props.errorRadio1 || props.errorcommentRadio1}</div>
                            </div>
                        )
                      })
                    }

                    {
                      props.questions[4] && [props.questions[4]].map((el) => {
                        return (
                            <div
                                className={`survey-form__field radio-btn ${props.errorRadio2 ? 'error' : ''}`}
                                key={el['question_id']}>
                              <p className={`survey-form__label`}>
                                <span className={`survey-form__count-label`}>5</span>
                                {el.description}
                              </p>
                              <Radio name={'Radio2'}
                                     className={`radio`}
                                     onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                              >
                                {el['answers'].sort((a, b) => {
                                  return a.id - b.id
                                }).map((answer, i, row) => {
                                  return <option key={answer.id}
                                                 value={answer.name_en}
                                                 id={i + 1 === row.length ? 'pur_no' : 'pur_yes'}
                                                 label={answer.name}/>
                                })}
                              </Radio>
                              <div className={'error-element'}>{props.errorRadio2}</div>
                            </div>
                        )
                      })
                    }

                    {(props.radioArr.find(el => el['Radio2']) && props.radioArr.find(el => el['Radio2']).hasOwnProperty('Radio2') &&
                        props.radioArr.find(el => el['Radio2'])['Radio2'].hasOwnProperty('answer_id_text') &&
                        props.radioArr.find(el => el['Radio2'])['Radio2'].answer_id_text === 'pur_no') &&
                    (
                        <React.Fragment>
                          {
                            props.questions[5] && [props.questions[5]].map((el) => {
                              return (
                                  <div
                                      className={`survey-form__field radio-btn ${props.errorRadio3 || props.errorcommentRadio3 ? 'error' : ''}`}
                                      key={el['question_id']}>
                                    <p className={`survey-form__label`}>
                                      <span className={`survey-form__count-label`}>6</span>
                                      {el.description}
                                    </p>
                                    <Radio name={'Radio3'}
                                           className={`radio`}
                                           onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                                    >
                                      {el['answers'].sort((a, b) => {
                                        return a.id - b.id
                                      }).map((answer, i) => {
                                        return <option key={answer.id}
                                                       data-name={el['answers'].length === i + 1 ? 'comment' : null}
                                                       value={answer.name_en}
                                                       id={el['question_id'] + answer.id}
                                                       label={answer.name}/>
                                      })}
                                    </Radio>
                                    <Textarea name={`commentRadio3`} maxLength={250}
                                              className={`${props.errorcommentRadio3 ? 'error' : ''}`}
                                              placeholder={props.t('open_task.sobranie.textarea_placeholder_1')}
                                              disabled={!props.fieldRadio3}
                                              onChange={props.textAreaHandler}/>
                                    <div className={'error-element'}>{props.errorRadio3 || props.errorcommentRadio3}</div>
                                  </div>
                              )
                            })
                          }
                        </React.Fragment>
                    )}

                    {(props.radioArr.find(el => el['Radio2']) && props.radioArr.find(el => el['Radio2']).hasOwnProperty('Radio2') &&
                        props.radioArr.find(el => el['Radio2'])['Radio2'].hasOwnProperty('answer_id_text') &&
                        props.radioArr.find(el => el['Radio2'])['Radio2'].answer_id_text === 'pur_yes') &&
                    (
                        <React.Fragment>
                          {
                            props.questions[6] && [props.questions[6]].map((el) => {
                              return (
                                  <div
                                      className={`survey-form__field radio-btn ${props.errorRadio4 || props.errorcommentRadio4 ? 'error' : ''}`}
                                      key={el['question_id']}>
                                    <p className={`survey-form__label`}>
                                      <span className={`survey-form__count-label`}>6</span>
                                      {el.description}
                                    </p>
                                    <Radio name={'Radio4'}
                                           className={`radio`}
                                           onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                                    >
                                      {el['answers'].sort((a, b) => {
                                        return a.id - b.id
                                      }).map((answer, i) => {
                                        return <option key={answer.id}
                                                       data-name={el['answers'].length === i + 1 ? 'comment' : null}
                                                       value={answer.name_en}
                                                       id={el['question_id'] + answer.id}
                                                       label={answer.name}/>
                                      })}
                                    </Radio>
                                    <Textarea name={`commentRadio4`} maxLength={250}
                                              className={`${props.errorcommentRadio4 ? 'error' : ''}`}
                                              placeholder={props.t('open_task.sobranie.textarea_placeholder_1')}
                                              disabled={!props.fieldRadio4}
                                              onChange={props.textAreaHandler}/>
                                    <div className={'error-element'}>{props.errorRadio4 || props.errorcommentRadio4}</div>
                                  </div>
                              )
                            })
                          }
                        </React.Fragment>
                    )}

                  </React.Fragment>
              )
            }
          </div>
          {props.questionsError && <div className={'error-element'}>{props.t('open_task.sobranie.global_error')}</div>}
          {props.serverSubmitAnswersError &&
          <div className={'error-element'}>{props.t('open_task.sobranie.global_submit_answer_error')}</div>}
          <Button className={`btn--v2`} type={`submit`} disabled={props.loading}>
            {props.t('btns.submit')}
          </Button>
        </Form>
      </div>
  );
};

export default SurveyQuestions;
