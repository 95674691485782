import ProfilingCard from "./ProfilingCard";
import VerificationCard from "./VerificationCard";
import ShortSurveyCard from "./ShortSurveyCard";
import LongSurveyCard from "./LongSurveyCard";
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import SurveyPopup from "./SurveyPopup";
import { useUrlParams } from "../../../../../hooks/useUrlParams";
import { useSurveys } from "../../../../../hooks/useSurveys";

const Cards = ({ setServerSurveyError, t }) => {
  const { urlParams } = useUrlParams();

  const { longSurvey, shortSurvey, isError: isGetSurveysError } = useSurveys();

  const [status, setStatus] = useState();
  const [points, setPoints] = useState();
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  const popupHandler = useCallback(
    (status, points) => {
      if (urlParams.notShowPopup) return;

      setStatus(status);
      setPoints(points);
      setShouldShowPopup(true);
    },
    [status]
  );

  useEffect(() => {
    setServerSurveyError(isGetSurveysError);
  }, [isGetSurveysError, setServerSurveyError]);

  return (
    <>
      <div className={`share-opinion__wrap`}>
        <ProfilingCard t={t} setServerSurveyError={setServerSurveyError} />
        <VerificationCard t={t} setServerSurveyError={setServerSurveyError} />
        <ShortSurveyCard
          survey={shortSurvey}
          t={t}
          popupHandler={popupHandler}
          setServerSurveyError={setServerSurveyError}
        />
        <LongSurveyCard
          survey={longSurvey}
          t={t}
          popupHandler={popupHandler}
          setServerSurveyError={setServerSurveyError}
        />
      </div>
      {shouldShowPopup && (
        <SurveyPopup
          t={t}
          status={status}
          points={points}
          onClose={() => setShouldShowPopup(false)}
        />
      )}
    </>
  );
};

Cards.propTypes = {
  t: PropTypes.func.isRequired,
  setServerSurveyError: PropTypes.func.isRequired,
};

export default Cards;
