import pres_2 from "../../../../../../images/surv-2.svg";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { verificationUrl } from "../../../../../constants/path";
import { getIsUserHasImageIdModerationStatusFromLS } from "../../../../../utils/storage";
import { useIsUserHasPhotoIdStatus } from "../../../../../hooks/useIsUserHasPhotoIdStatus";

const VerificationCard = ({ t, setServerSurveyError }) => {
  const history = useHistory();

  const isUserHasImageIdModerationStatus =
    getIsUserHasImageIdModerationStatusFromLS();

  const { isError, isUserHasPhotoIdStatus } = useIsUserHasPhotoIdStatus(
    isUserHasImageIdModerationStatus
  );

  useEffect(() => {
    setServerSurveyError(isError);
  }, [isError, setServerSurveyError]);

  return (
    <button
      className={`card yellow`}
      onClick={() => history.push(verificationUrl)}
      disabled={!isUserHasImageIdModerationStatus || isUserHasPhotoIdStatus}
    >
      <div className={`card__wrap`}>
        <div className={`card__content`}>
          <p className={`card__title`}>{t("main_page.tasks.photo_id")}</p>
          <p className={`card__text`}>
            1 {t("main_page.logged.section2.cardText3")}
          </p>
          <p className={`card__reward`}>
            <strong>+15</strong> {t("main_page.tasks.points")}
          </p>
        </div>
        <div className={`card__image`}>
          <img src={pres_2} alt="" />
        </div>
      </div>
    </button>
  );
};

VerificationCard.propTypes = {
  t: PropTypes.func.isRequired,
  setServerSurveyError: PropTypes.func.isRequired,
};

export default VerificationCard;
