import { useEffect, useState } from "react";
import { getIsUserCanTakeVerification } from "../api/requests";

export const useIsUserHasPhotoIdStatus = (isUserHasImageIdModerationStatus) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserHasPhotoIdStatus, setIsUserHasPhotoIdStatus] = useState(false);

  useEffect(() => {
    if (!isUserHasImageIdModerationStatus) return;

    setIsLoading(true);
    getIsUserCanTakeVerification()
      .then((res) => {
        setIsLoading(false);
        setIsUserHasPhotoIdStatus(!res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.dir(err);
        setIsError(true);
      });
  }, [isUserHasImageIdModerationStatus]);

  return { isError, isLoading, isUserHasPhotoIdStatus };
};
