import * as types from "../constants/ActionTypes";

export const setTaskPending = (task_pending) => ({
  type: types.SET_TASK_PENDING,
  task_pending,
});

export const setTaskTake = (task_take) => ({
  type: types.SET_TASK_TAKE,
  task_take,
});

export const setTaskTakeInfo = (task_take_info) => ({
  type: types.SET_TASK_TAKE_INFO,
  task_take_info,
});

export const setTaskStep = (step) => ({
  type: types.SET_TASK_STEP,
  step,
});

export const setTaskStatus = (status) => ({
  type: types.SET_TASK_STATUS,
  status,
});

export const setTaskVersion = (version) => ({
  type: types.SET_TASK_VERSION,
  version,
});

export const setStatusFirstResearch = (status) => ({
  type: types.SET_STATUS_FIRST_RESEARCH,
  status,
});

export const setStatusSecondResearch = (status) => ({
  type: types.SET_STATUS_SECOND_RESEARCH,
  status,
});

export const setStatusThirdResearch = (status) => ({
  type: types.SET_STATUS_THIRD_RESEARCH,
  status,
});

export const setFinishProfilingSurv = (status) => ({
  type: types.SET_FINISH_PROFILING_SURVEY,
  status,
});
