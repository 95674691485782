import React, { useState } from "react";

export const Context = React.createContext();

const DashboardProvider = props => {
  const aCallback = () => {
    setUsers(null);
    setCurrentPage(null);
    setAllPages(null);
  };

  const [users, setUsers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [allPages, setAllPages] = useState(null);
  const [userFilters, setUserFilters] = useState([]);
  const [comboFilter, setComboFilter] = useState(null);
  const [comboCurrentPage, setComboPage] = useState(1);

  return (
    <Context.Provider
      value={{
        users,
        currentPage,
        allPages,
        userFilters,
        comboFilter,
        comboCurrentPage,
        setUserFilters: filters => setUserFilters(filters),
        setComboFilter: filter => setComboFilter(filter),
        setComboPage: page => setComboPage(page),
        updateUsers: current => setUsers(current),
        updateCurrPage: page => setCurrentPage(page),
        updateAllPages: pages => setAllPages(pages),
        aCallback: aCallback
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default DashboardProvider;
