import React, { useState, useEffect, lazy } from 'react'
import { useHistory } from 'react-router-dom';
import {Route, Switch} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DashboardProvider from "./DashboardContext";

import Sidebar from "./parts/sidebar";
import {LazyLoad} from "../../HoC/LazyComponent";

// import Users from "./pages/Users";
// import Tasks from "./pages/Tasks";
// import InStore from "./pages/InStore";
// import PhotoShooting from "./pages/PhotoShooting";
// import TasksPhoto from "./pages/Tasks-photo";
// import UserEdit from './pages/UserEdit'
// import TaskEdit from './pages/TaskEdit'
// import PhotoTaskEdit from './pages/PhotoTaskEdit'
// import InStoreEdit from './pages/InStoreEdit'
// import PhotoShootingEdit from './pages/PhotoShootingEdit'
const Users =  lazy(() => import("./pages/Users"));
const Tasks = lazy(() => import("./pages/Tasks"));
const InStore = lazy(() => import("./pages/InStore"));
const PhotoShooting = lazy(() => import("./pages/PhotoShooting"));
const TasksPhoto = lazy(() => import("./pages/Tasks-photo"));
const UserEdit = lazy(() => import('./pages/UserEdit'));
const TaskEdit = lazy(() => import('./pages/TaskEdit'));
const PhotoTaskEdit = lazy(() => import('./pages/PhotoTaskEdit'));
const InStoreEdit = lazy(() => import('./pages/InStoreEdit'));
const PhotoShootingEdit = lazy(() => import('./pages/PhotoShootingEdit'));
const PhotoPack = lazy(() => import("./pages/PhotoPack"));
const PhotoPackEdit = lazy(() => import("./pages/PhotoPackEdit"));

const LazyUsers = (props) => <LazyLoad component={Users} {...props}/>;
const LazyTasks = (props) => <LazyLoad component={Tasks} {...props}/>;
const LazyInStore = (props) => <LazyLoad component={InStore} {...props}/>;
const LazyPhotoShooting = (props) => <LazyLoad component={PhotoShooting} {...props}/>;
const LazyTasksPhoto = (props) => <LazyLoad component={TasksPhoto} {...props}/>;
const LazyPhotoPack = (props) => <LazyLoad component={PhotoPack} {...props}/>;
const LazyPhotoPackEdit = (props) => <LazyLoad component={PhotoPackEdit} {...props}/>;
const LazyUserEdit = (props) => <LazyLoad component={UserEdit} {...props}/>;
const LazyTaskEdit = (props) => <LazyLoad component={TaskEdit} {...props}/>;
const LazyPhotoTaskEdit = (props) => <LazyLoad component={PhotoTaskEdit} {...props}/>;
const LazyInStoreEdit = (props) => <LazyLoad component={InStoreEdit} {...props}/>;
const LazyPhotoShootingEdit = (props) => <LazyLoad component={PhotoShootingEdit} {...props}/>;

export const Index = (props) => {

    const [user, setUser] = useState(null);
    const history = useHistory();

    useEffect(() => {
        const currUser = localStorage.getItem("user.me")
        ? JSON.parse(localStorage.getItem("user.me"))
        : null;
        if(currUser && (currUser.permissions.name === 'Admin' || currUser.permissions.name === 'Moderator')) {
            history.push('/dashboard/users')
        } else {
            history.push('/');
        }
        setUser(currUser);
    }, []);

    return (
        <DashboardProvider>
        <div className="w2ge-dashboard">
            <ToastContainer/>
            <Sidebar/>
            <div className="dashboard-main">
            <Switch>
              <Route path="/dashboard/users" exact component={LazyUsers}/>
              <Route path="/dashboard/tasks" exact component={LazyTasks}/>
              <Route path="/dashboard/instore" exact component={LazyInStore}/>
              <Route path="/dashboard/photoshooting" exact component={LazyPhotoShooting}/>
              <Route path="/dashboard/tasks-photo" exact component={LazyTasksPhoto}/>
              <Route path="/dashboard/users/:id" component={LazyUserEdit}/>
              <Route path="/dashboard/tasks/:id" component={LazyTaskEdit}/>
              <Route path="/dashboard/tasks-photo/:id" component={LazyPhotoTaskEdit}/>
              <Route path="/dashboard/instore/:id" component={LazyInStoreEdit}/>
              <Route path="/dashboard/photoshooting/:id" component={LazyPhotoShootingEdit}/>
              <Route path="/dashboard/photo-pack" exact component={LazyPhotoPack}/>
              <Route path="/dashboard/photo-pack/:id" component={LazyPhotoPackEdit}/>
            </Switch>
            </div>
        </div>
        </DashboardProvider>
    )
};

export default Index
