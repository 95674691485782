import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Button from "./Button";

class Upload extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    validating: PropTypes.bool,
    validate: PropTypes.array,
    handleValidating: PropTypes.func,
    handleChangeField: PropTypes.func,
    className: PropTypes.string,
    onChange: PropTypes.func,
    text: PropTypes.string
  };

  static defaultProps = {
    text: "UPLOAD PHOTO",
    className: "btn--blue",
    onChange: () => {}
  };

  constructor(props) {
    super();
    this.state = {
      value: props.value || '',
      errors: {}
    }
  }

  handleChange = (event) => {
    const {onChange, validate} = this.props;
    let value = event.target.files[0];

    this.setState({value});
    onChange(event);
    if (this.props.handleChangeField) {
      this.props.handleChangeField(event, this.props.name)
    }

    if (validate) {
      this.handleValidation();
    }
  };

  handleValidation = () => {
    const {errors} = this.state;
    const {validate, name} = this.props;

    validate.map(item => {
      if (item.hasOwnProperty('required')) {
        if (!this.fileUpload.files.length) {
          errors.required = item.message || 'The field is required'
        } else {
          delete errors.required;
        }
      } else if (item.hasOwnProperty('check_size')) {
        if (this.fileUpload.files && this.fileUpload.files[0] && (this.fileUpload.files[0].size/(1024*1024)).toFixed(2) > 10) {
          errors.check_size = item.message || 'The given data was invalid';
        } else {
          delete errors.check_size;
        }
      } else if (item.hasOwnProperty('check_type')) {
        if (this.fileUpload.files && this.fileUpload.files[0] && !this.isValidFileType(this.fileUpload.files[0].type)) {
          errors.check_type = item.message || 'The user id must be a file of type: jpeg, bmp, png';
        } else {
          delete errors.check_type;
        }
      }

      this.setState({
        errors
      })
    });

    this.props.handleValidating(name, Object.keys(errors).length);
  };

  isValidFileType = (fName) => {
    const extensionImages = ['image/jpeg', 'image/png', 'image/bmp', 'image/x-png']; //['jpg', 'bmp', 'png'];
    return extensionImages.find(el=>el == fName); //.indexOf(fName.split('.').pop().toLowerCase()) > -1;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.validating && this.props.validating !== prevProps.validating) {
      if (this.props.validate) {
        this.handleValidation();
      }
    }
  }

  render() {
    let errorMessage;

    if (Object.keys(this.state.errors).length) {
      errorMessage = (
          Object.keys(this.state.errors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.state.errors[item]}</div>
          ))
      )
    } else if (this.props.serverErrors && Object.keys(this.props.serverErrors).length) {
      errorMessage = (
          Object.keys(this.props.serverErrors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.props.serverErrors[item]}</div>
          ))
      )
    }
    return (
        <div className={"file-upload" + (Object.keys(this.state.errors).length || (this.props.serverErrors && Object.keys(this.props.serverErrors).length) ? " has-error" : "")}>
          <input name={this.props.name}
                 style={{display: 'none'}}
                 type="file"
                 onChange={this.handleChange}
                 accept="image/*"
                 ref={fileUpload => this.fileUpload = fileUpload}/>
          <Button className={this.props.className} handleClick={() => this.fileUpload.click()}>{this.props.text}</Button>
          {errorMessage}
        </div>
    )
  }
}

export default Upload;
