import React from 'react'
import LangHOC from "../../../HoC/LangHOC";
import Title from "../../../parts/Title";

function Topic(props) { 

    const currentLang = props.currentLanguage;
    const {topic} = props;

    function getAdditStyles(key){
        let keyLength = key.split('.').length;
        if(keyLength === 3) {
            return "second-child";
        } else if(keyLength === 4) {
            return "third-child";
        } else {
            return "";
        }
    }


    return (
                <div className="rules-page__content">
                    <Title title={topic[currentLang]['title']} size={170}/>
                    <p className="article-description" dangerouslySetInnerHTML={{__html: topic[currentLang]['description']}}></p>
                    {
                        Object.entries(topic[currentLang]['articles']).map(([key,item],i)=> {
                            return (
                                <div className="article-wrap" key={i}>
                                    <h2 className="article-title"><span>{key}. </span><span dangerouslySetInnerHTML={{__html: item.theme}}></span></h2>
                                    {
                                       Object.entries(item['parts']).map(([number,part],j)=> { 
                                           return (
                                               <div className="article-part" key={j}>
                                                   <span className={"article-part__number " +  getAdditStyles(number)}>{number}.</span>
                                                   <p className="article-part__text" dangerouslySetInnerHTML={{__html: part}}></p>
                                               </div>
                                           )
                                       }) 
                                    }
                                </div>
                            )
                        })
                    }
                </div>
    )
}

export default LangHOC(Topic)
