import React, { useState } from "react";
import UploadPhoto from "./UploadPhoto";
import AcceptTask from "./AcceptTask";
import PropTypes from "prop-types";

const PhotoStep = ({
  onSave,
  t,
  surveyId,
  points,
  surveyDescription,
  surveyImagePath,
  setIsServerError,
}) => {
  const [photoStep, setPhotoStep] = useState(0);

  return (
    <div className={`survey-wrap`}>
      {photoStep === 0 && (
        <AcceptTask
          surveyDescription={surveyDescription}
          points={points}
          t={t}
          surveyImagePath={surveyImagePath}
          onNext={() => setPhotoStep(1)}
        />
      )}
      {photoStep === 1 && (
        <UploadPhoto
          t={t}
          surveyDescription={surveyDescription}
          defaultImg={surveyImagePath}
          surveyId={surveyId}
          onSave={onSave}
          setIsServerError={setIsServerError}
        />
      )}
    </div>
  );
};

PhotoStep.propTypes = {
  onSave: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  surveyId: PropTypes.number.isRequired,
  surveyDescription: PropTypes.string.isRequired,
  surveyImagePath: PropTypes.string.isRequired,
  setIsServerError: PropTypes.func.isRequired,
  points: PropTypes.string,
};

export default PhotoStep;
