import * as types from "../constants/ActionTypes";

const initialState = {
    menuOpened: false
};

export default function menu(state = initialState, action) {
    switch (action.type) {
        case types.TOGGLE_MENU:
            return {
                ...state,
                menuOpened: !state.menuOpened
            };
        case types.CLOSE_MENU:
            return {
                ...state,
                menuOpened: false
            };
        default:
            return state;
    }
}
