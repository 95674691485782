import React from "react";
import Title from "../../../../parts/Title";
import {Link} from "react-router-dom";
import Button from "../../../../form/Button";
import done_img from "../../../../../../images/open-tasks/survey/survey-done.png";

const Done = (props) => {
  return (
      <div className={`open-tasks-finish done`}>
        <Title title={props.t('open_task.photo_pack.finish.doneTitle')} className={`page-title--v2`} size={170}/>
        <div className={`open-tasks-finish__wrap`}>
          <div className={`photo-pack-finish__img`}>
            <img src={done_img} alt="done pic"/>
          </div>
          <p className={`photo-pack-finish__text`}>
            <strong>+25</strong> {props.t('open_task.photo_pack.finish.doneText')}
          </p>
          <Button className={`open-tasks-finish__btn`}><Link to={'/tasks'}>{props.t('btns.cool')}</Link></Button>
        </div>
      </div>
  );
}

export default Done;
