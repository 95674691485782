import React from 'react'
import Radio from '../../../../form/Radio'
import CheckboxV2 from '../../../../form/CheckboxV2'
import Range from '../../../../form/Range'
import PropTypes from 'prop-types'
import { apiImgUrl } from '../../../../../api/constants'

const SurveyQuestionAnswers = ({ name, answers, lang, type, onInputChange }) => {
    switch (type) {
        case 'radio_button':
            return (
                <Radio name={name} className={'radio'} onChange={event => onInputChange(event)}>
                    {answers
                        .sort((a, b) => {
                            return a.key - b.key
                        })
                        .map(answer => {
                            return (
                                <option
                                    key={answer.key}
                                    value={answer.key}
                                    id={answer.key}
                                    label={
                                        answer.attributes.answer_image
                                            ? ''
                                            : lang === 'en' || !answer.attributes.answer_ge
                                            ? answer.attributes.answer_en
                                            : answer.attributes.answer_ge
                                    }
                                />
                            )
                        })}
                </Radio>
            )
        case 'checkbox':
        case 'image':
            return (
                <div className={type === 'image' ? 'checkboxes-images-wrapper' : ''}>
                    {answers
                        .sort((a, b) => {
                            return a.key - b.key
                        })
                        .map(answer => {
                            return (
                                <CheckboxV2
                                    key={answer.key}
                                    id={answer.key}
                                    name={name}
                                    label={
                                        lang === 'en' || !answer.attributes.answer_ge
                                            ? answer.attributes.answer_en
                                            : answer.attributes.answer_ge
                                    }
                                    handleChange={event => {
                                        onInputChange(event)
                                    }}
                                    image={
                                        answer.attributes.answer_image ? (
                                            <div
                                                className={'image-wrap'}
                                                title={
                                                    lang === 'en' || !answer.attributes.answer_ge
                                                        ? answer.attributes.answer_en
                                                        : answer.attributes.answer_ge
                                                }
                                                style={{
                                                    backgroundImage: `url(${apiImgUrl}/${answer.attributes.answer_image})`,
                                                }}
                                            />
                                        ) : (
                                            <></>
                                        )
                                    }
                                />
                            )
                        })}
                </div>
            )
        case 'scale':
        case 'radio-button':
            return <Range name={name} />
        default:
            return <></>
    }
}

SurveyQuestionAnswers.propTypes = {
    lang: PropTypes.oneOf(['en', 'ge']).isRequired,
    name: PropTypes.string.isRequired,
    answers: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['checkbox', 'radio_button', 'scale', 'image']),
    onInputChange: PropTypes.func.isRequired,
}

export default SurveyQuestionAnswers
