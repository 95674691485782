import React, { Component } from "react";
import PropTypes from "prop-types";

class Textarea extends Component {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    validating: PropTypes.bool,
    validate: PropTypes.array,
    handleValidating: PropTypes.func,
    handleChangeField: PropTypes.func,
    maxLength: PropTypes.number,
    value: PropTypes.any,
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    type: "text",
    className: "",
    placeholder: "",
    name: "textarea",
    onChange: () => {},
  };

  constructor(props) {
    super();
    this.inputEl = React.createRef();
    this.state = {
      value: props.value || "",
      errors: {},
    };
  }

  handleChange = (event) => {
    const { onChange, validate } = this.props;
    let value = event.target.value;

    this.setState({ value });
    onChange(event);
    if (this.props.handleChangeField) {
      this.props.handleChangeField(event, this.props.name);
    }

    if (validate) {
      this.handleValidation();
    }
  };

  handleValidation = () => {
    const { errors } = this.state;
    const { validate, name } = this.props;

    validate.map((item) => {
      if (item.hasOwnProperty("required")) {
        if (!this.inputEl.current.value.length) {
          errors.required = item.message || "The field is required";
        } else {
          delete errors.required;
        }
      } else if (item.hasOwnProperty("minLength")) {
        if (this.inputEl.current.value.length < item.minLength) {
          errors.minLength =
            item.message ||
            `The field must be longer than ${item.minLength} symbols`;
        } else {
          delete errors.minLength;
        }
      }

      this.setState({
        errors,
      });
    });

    this.props.handleValidating(name, Object.keys(errors).length);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.validating &&
      this.props.validating !== prevProps.validating
    ) {
      if (this.props.validate) {
        this.handleValidation();
      }
    }
  }

  render() {
    let errorMessage;

    if (Object.keys(this.state.errors).length) {
      errorMessage = Object.keys(this.state.errors).map((item, i) => (
        <div key={i} className={"error-element"}>
          {this.state.errors[item]}
        </div>
      ));
    } else if (
      this.props.serverErrors &&
      Object.keys(this.props.serverErrors).length
    ) {
      errorMessage = Object.keys(this.props.serverErrors).map((item, i) => (
        <div key={i} className={"error-element"}>
          {this.props.serverErrors[item]}
        </div>
      ));
    }
    return (
      <div
        className={
          "textarea-field" +
          (Object.keys(this.state.errors).length ||
          (this.props.serverErrors &&
            Object.keys(this.props.serverErrors).length)
            ? " has-error"
            : "")
        }
      >
        <textarea
          name={this.props.name}
          ref={this.inputEl}
          className={this.props.className}
          onChange={this.handleChange}
          value={this.state.value}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          maxLength={this.props.maxLength}
          autoFocus
        ></textarea>
        {errorMessage}
      </div>
    );
  }
}

export default Textarea;
