import React from "react";
import Title from "../../../../parts/Title";
import SurveyInfo from "../Survey/parts/SurveyInfo";

const SurveyTask = (props) => {
  const currTitle = props.title ? props.title : props.t('open_task.task_desc');
    return (
        <div className={`survey-task-description`}>
          <Title title={currTitle} size={170}/>
          <div className={`survey-wrap`}>
            <SurveyInfo {...props}/>
            <div className={`survey-form`}>
              {props.children}
            </div>
          </div>
        </div>
    );
};

export default SurveyTask;
