import React from 'react';
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "../../../form/Form";
import Input from "../../../form/Input";
import Button from "../../../form/Button";
import {googleRecaptchaKey} from "../../../../constants/Variables";

function Step1(props) {
  const {handleSubmit, handleChangePassword, handleChangeCaptcha, recaptchaRef,
    userName, serverErrors} = props;
  return (
      <div className={`auth__wrap`}>
        <div className={`auth__desc-wrap ${serverErrors && serverErrors.error_id == 2 ? 'form-error':''}`}>
          <div className={`user-greeting`}>{props.t('signin.meeting0')} {userName}! {props.t('signin.meeting1')}</div>
          <div className={`auth-desc`}>{props.t('signin.pass_label')} <Link to={{pathname: "/password-reset"}}>{props.currentLanguage === 'en'?'here':'აქ'}</Link></div>
          <div className={`auth-error`}>{props.t('signin.pass_error')}</div>
        </div>
        <Form handleSubmit={handleSubmit}>
          <div className={`auth__form`}>
            <Input name="password"
                       type={"password"}
                       placeholder='Enter your password'
                       onChange={handleChangePassword}
                       validate={[
                         {
                           required: true,
                           message: props.t('errors.password')
                         },
                         {minLength: 8, message: props.t('errors.password_min')}
                       ]}
            />
            <div className="auth__captcha-wrapper">
              <ReCAPTCHA
                  sitekey={googleRecaptchaKey}
                  onChange={handleChangeCaptcha}
                  ref={recaptchaRef}
                  size="invisible"
              />
            </div>
            <div className={`auth__btns`}>
              <Button type={`submit`}>{props.t('btns.enter')}</Button>
            </div>
          </div>
          {/*<div className={`auth__forgot`}>*/}
            {/*<Link to={{pathname: "/password-reset"}}>{props.t('signin.reset_pw')}</Link>*/}
          {/*</div>*/}
        </Form>
      </div>
  )
}

export default Step1
