import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import LangHOC from "../../HoC/LangHOC";
// import Research152 from "./Research/Research152/Research";
import Research156 from "./Research/Research156_a/Research";
import Research155 from "./Research/Research155/Research";
import Survey from "./Research/Survey/Survey";
import PhotoId from "./PhotoId/PhotoId";
import PhotoPack from "./PhotoPack";
import PageNotFound from "../PageNotFound/PageNotFound";
// import Census from "./Census/Census";
// import RetailAudit from "./RetailAudit/RetailAudit";
// import InStoreAudit from "./InStoreAudit/InStoreAudit";
// import PhotoShooting from "./PhotoShooting/PhotoShooting";
import { setUserRulesConfirm } from "../../../actions/common";
import ShortSurvey from "./Research/ShortSurvey";
import LongSurvey from "./Research/LongSurvey";
import ProfilingSurvey from "./Research/ProfilingSurvey";
import PhotoVerification from "./PhotoId/PhotoVerification";

class OpenTasks extends Component {
  componentDidMount() {
    const currentUser = localStorage.getItem("user.me")
      ? JSON.parse(localStorage.getItem("user.me"))
      : null;
    if (
      currentUser &&
      currentUser.hasOwnProperty("rules_v2") &&
      currentUser["rules_v2"] === "0"
    ) {
      this.props.setUserRulesConfirm(true);
    }
  }

  render() {
    const {
      first_research_status,
      second_research_status,
      third_research_status,
    } = this.props;
    return (
      <div className={`open-tasks`}>
        <Switch>
          {/*<Route path={`${this.props.match.path}/census`}>*/}
          {/*<Census {...this.props}/>*/}
          {/*</Route>*/}
          {/*<Route path={`${this.props.match.path}/retail_audit`}>*/}
          {/*<RetailAudit {...this.props}/>*/}
          {/*</Route>*/}
          <Route path={`${this.props.match.path}/photo-pack`}>
            <PhotoPack {...this.props} />
          </Route>
          {/*<Route path={`${this.props.match.path}/research`}>*/}
          {/*<Research155 {...this.props}/>*/}
          {/*</Route>*/}
          <Route
            path={`${this.props.match.path}/research`}
            render={() => {
              if (first_research_status) {
                //Sobranie
                return <Research155 {...this.props} />;
              }
              // else if (second_research_status) { // Winston
              //   return <Research152 {...this.props}/>
              // }
              else if (third_research_status) {
                // Camel
                return <Research156 {...this.props} />;
              } else {
                return <Redirect to="/" />;
              }
            }}
          ></Route>
          <Route path={`${this.props.match.path}/short-survey`}>
            <ShortSurvey {...this.props} />
          </Route>
          <Route path={`${this.props.match.path}/long-survey`}>
            <LongSurvey {...this.props} />
          </Route>
          <Route path={`${this.props.match.path}/photo-id`}>
            {/*<PhotoId {...this.props} />*/}
            <PhotoVerification {...this.props} />
          </Route>
          {/*<Route path={`${this.props.match.path}/in_store_audit`}>*/}
          {/*<InStoreAudit {...this.props}/>*/}
          {/*</Route>*/}
          {/*<Route path={`${this.props.match.path}/photo_shooting`}>*/}
          {/*<PhotoShooting {...this.props}/>*/}
          {/*</Route>*/}
          <Route path={`${this.props.match.path}/survey`}>
            {/*<Survey {...this.props} />*/}
            <ProfilingSurvey {...this.props} />
          </Route>
          <Route render={() => <PageNotFound />} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  first_research_status: state.task.first_research_status,
  second_research_status: state.task.second_research_status,
  third_research_status: state.task.third_research_status,
});

const mapDispatchToProps = (dispatch) => ({
  setUserRulesConfirm: (status) => dispatch(setUserRulesConfirm(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(OpenTasks));
