import React, {Component} from "react";
import {connect} from "react-redux";
import Button from "../../form/Button";
import logo from "../../../../images/w2g-logo.svg";
import LangHOC from "../../HoC/LangHOC";
import {setWrapperClassName} from "../../../actions/common";

class NotifyPopup extends Component {
  state = {
    popup: false
  };

  componentDidMount() {
    // this.openPopup()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.firstLoginStatus && this.props.firstLoginStatus !== prevProps.firstLoginStatus) {
      const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
      const userStatusLogin = localStorage.getItem("firstLogin") ? localStorage.getItem("firstLogin") : '0';
      if (currentUser && currentUser.hasOwnProperty('status') && currentUser.status &&
          currentUser.status.find(el=>el.id == 1) && userStatusLogin && userStatusLogin !== '1') {
        localStorage.setItem('firstLogin', '1');
        // this.openPopup()

      }
    }
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  openPopup = () => {
    window.scrollTo(0, 0);
    this.setState({
      popup: true
    }, () => {
      this.props.setWrapperClassName("open-notify");
    })
  };

  closePopup = () => {
    this.setState({
      popup: false
    }, () => {
      this.props.setWrapperClassName("");
      this.props.history.push('/shop');
    })
  };

  redirectToSurvey = () => {
    this.setState({
      popup: false
    }, () => {
      this.props.setWrapperClassName("");
      this.props.history.push('/shop');
    })
  };

  render() {
    const {popup} = this.state;
    const {t} = this.props;
    if (popup) {
      return (
        <div className={`notify-prize-popup notify-popup`}>
          <div className={`popup`}>
            <div className={`popup__close`} onClick={this.closePopup}></div>
            <div className={`container`}>
              <div className={`popup__wrap`}>
                <img src={logo} alt="done pic"/>
                <p className={`text`}>{t('notify_popup.text0')}</p>
                <p className={`text`}>{t('notify_popup.text1')}</p>
                <Button className={`popup__btn`} handleClick={this.redirectToSurvey}>{t('btns.got_it')}</Button>
              </div>
            </div>
          </div>
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = state => ({
  firstLoginStatus: state.auth.firstLoginStatus
});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className))
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(NotifyPopup))
