import React, { useState } from "react";

export const Context = React.createContext();

const Provider = props => {
  const aCallback = () => {
    setPage(null);
  };

  const [currentPage, setPage] = useState(0);

  return (
    <Context.Provider
      value={{
        currentPage,
        updatePage: current => setPage(current),
        aCallback: aCallback
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default Provider;
