import React from "react";
import Title from "../../../../parts/Title";
import DescriptionFormBtns from "../../parts/DescriptionFormBtns";
import ImagePhotoId from "./ImagePhotoId";

const Description = (props) => {
  return (
      <div className={`open-tasks-desc step-${props.step}`}>
        <Title title={props.t('open_task.task_desc')} size={170}/>
        <div className={`open-tasks-desc__wrap`}>
          <div className={`open-tasks-desc__col`}>
            <div className={`open-tasks__content`}>
              <p className={`open-tasks__title`}>{props.t('open_task.task_type')}:</p>
              <p className={`open-tasks__text`}>{props.t('main_page.tasks.photo_id')}</p>
            </div>
            <div className={`open-tasks__content`}>
              <p className={`open-tasks__title`}>{props.t('open_task.points_comp')}:</p>
              <p className={`open-tasks__text`}>15</p>
            </div>
            <div className={`open-tasks__content`}>
              <p className={`open-tasks__title`}>{props.t('open_task.task_desc')}:</p>
              <p className={`open-tasks__text`}>{props.t('open_task.photo_id.desc_text')}</p>
            </div>
          </div>
          <div className={`open-tasks-desc__col`}>
            <ImagePhotoId {...props}/>
          </div>
          <DescriptionFormBtns handleAcceptTask={props.handleAcceptTask} {...props} />
        </div>
      </div>
  );
};

export default Description;
