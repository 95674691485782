import * as types from "../constants/ActionTypes";

const initialState = {
    cities: [],
};

export default function cities(state = initialState, action) {
    switch (action.type) {
        case types.SET_CITIES:
            return {
                ...state,
                cities: action.cities,
            };

        default:
            return state;
    }
}