import React, {Component} from 'react'
import PropTypes from 'prop-types'
import LangHOC from "../HoC/LangHOC";

class Checkbox extends Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    validating: PropTypes.bool,
    validate: PropTypes.array,
    handleChange: PropTypes.func,
    handleValidating: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    className: "",
    onChange: () => {}
  };

  constructor(props) {
    super();
    this.inputEl = React.createRef();
    this.state = {
      value: props.value || '',
      errors: {}
    }
  }

  handleChange = (event) => {
    let ckecked = event.target.checked;
    this.setState({value: +ckecked});
    this.props.onChange(event);

    if (this.props.handleChangeField) {
      this.props.handleChangeField(event, this.props.name)
    }

    if (this.props.validate) {
      this.handleValidation();
    }
  };

  handleValidation = () => {
    const {errors} = this.state;
    const {validate, name} = this.props;

    validate.map(item => {
      if (item.hasOwnProperty('required')) {
        if (!this.inputEl.current.checked) {
          errors.required = item.message || 'The value is required'
        } else {
          delete errors.required;
        }
      }

      this.setState({
        errors
      })

    });

    this.props.handleValidating(name, Object.keys(errors).length);
  };

  componentDidUpdate(prevProps) {
    if (this.props.validating === true && this.props.validating !== prevProps.validating) {
      if (this.props.validate) {
        this.handleValidation();
      }
    }

    if (Object.keys(this.state.errors).length && this.props.currentLanguage !== prevProps.currentLanguage) {
      if (this.props.validate) {
        this.handleValidation();
      }
    }

    if(this.props.value != prevProps.value) {
      this.setState({
        value: this.props.value
      }, () => {
        this.handleValidation();
      });
    }
  }

  render() {
    return (
        <div className={"checkbox-wrapper" + (Object.keys(this.state.errors).length ? " has-error" : "")}>
          <label className="checkbox-container">
            <input type="checkbox"
                   className={this.props.className}
                   ref={this.inputEl}
                   checked={this.state.value}
                   value={this.state.value}
                   name={this.props.name}
                   onChange={this.handleChange}
                   disabled={this.props.disabled}
                   data-name={this.props['data-name']}
                   data-name-en={this.props['data-name-en']}
                   id={this.props.id && this.props.id}/>
            <span className="checkmark"></span>
          </label>

          {this.props.label ? <label className={`checkbox-text ${this.state.value?'active':''}`} htmlFor={this.props.id} dangerouslySetInnerHTML={{__html: this.props.label}}></label> : null}
          {Object.keys(this.state.errors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.state.errors[item]}</div>
          ))}
        </div>
    )
  }
}

export default LangHOC(Checkbox);
