import React, {Component} from "react";
import SurveyTask from "../parts/SurveyTask";
import Done from "../Survey/parts/Done";
import defaultAxios from "../../../../../axios";
import debounce from 'lodash/debounce';
import ErrorResponse from "../../../PageNotFound/ErrorResponse";
import SurveyQuestions from "./SurveyQuestions";

class Research extends Component {
  state = {
    step: 0,
    loading: false,
    questions: [],
    radioArr: [],
    serverErrorQuantity: 0,
    serverQuestionsError: false,
    questionsError: false,
    serverSubmitAnswersError: false
  };

  componentDidMount() {
    this.getQuizQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.getQuizQuestions();
    }
  }

  getQuizQuestions = () => {
    const lang = this.props.currentLanguage;
    defaultAxios.get(`/api/survey-search-tasks/v2?survey_type=survey_w_236&lang=${lang}`, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
      .then(res => {
        this.setState({
          questions: res.data.data.questions
        })
      })
      .catch(err => {
        this.setState({serverQuestionsError: true})
      })
  };

  setQuizSubmit = debounce((formData) => {
    const fd = new FormData();
    const {radioArr, commentRadio1, commentRadio2, commentRadio3} = this.state;
    fd.append('survey_type', 'survey_w_236');
    let invalid = null;

    radioArr.sort((a, b) => {
      return Object.values(a)[0].question_id - Object.values(b)[0].question_id
    }).forEach((value) => {
      for (let j in value) {
        if (value.hasOwnProperty(j)) {
          return fd.append(`${value[j].question_id}[${value[j].answer_id}]`, value[j].answer_id);
        }
      }
    });

    //radio errors
    [...Array(5)].forEach((arr, i) => {
      if (!radioArr.find(el=>el['Radio' + i])) {
        this.setState({
          ['errorRadio' + i]: this.props.t('open_task.sobranie.choice_error')
        });
        invalid = true
      } else {
        return false
      }
    });

    // console.log([...fd]);
    // console.log('status', invalid);

    if (invalid) {
        this.setState({questionsError: true});
        return false;
    }

    // return false;

    this.setState({loading: true});
    defaultAxios.post('/api/survey-search-tasks/v2/success', fd, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
      .then(res => {
        window.scrollTo(0, 0);
        this.setState({
          step: 1,
          loading: false
        })
      })
      .catch(err => {
        this.setState({loading: false});
        this.setState({serverSubmitAnswersError: true});
      })
  }, 1500, {leading: true, trailing: false});

  handleToggleRadio = (event, question_id) => {
    let newRadioArr = this.state.radioArr.filter(el => {
      return Object.keys(el)[0] !== event.target.name
    });

    const {serverSubmitAnswersError, questionsError} = this.state;
    if (questionsError) {
      this.setState({questionsError: false});
    } else if (serverSubmitAnswersError) {
      this.setState({serverSubmitAnswersError: false});
    }

    this.setState({
      radioArr: [...newRadioArr, {
        [event.target.name]: {
          'question_id': question_id,
          'answer_id': event.target.value,
          'answer_id_text': event.target.id
        }
      }],
      ['error' + event.target.name]: null
    });
  };

  render() {
    const {step, serverQuestionsError} = this.state;
    // console.log(this.state);
    if (serverQuestionsError) return <ErrorResponse/>;
    return (
      <div className={`survey`}>
        {step === 0 && <SurveyTask
            {...this.state} {...this.props}>
          <SurveyQuestions setQuizSubmit={this.setQuizSubmit}
                           handleToggleRadio={this.handleToggleRadio}
                           {...this.state} {...this.props}/>
        </SurveyTask>}
        {step === 1 && <Done {...this.props}/>}
      </div>
    );
  }
}

export default Research
