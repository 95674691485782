import * as types from "../constants/ActionTypes";

export const setWrapperClassName = (className) => ({
  type: types.SET_WRAPPER_CLASSNAME,
  className
});

export const currentHistoryRoute = route => ({
  type: types.CURRENT_HISTORY_ROUTE,
  route
});

export const setActiveLanguage = lang => ({
  type: types.SET_LANG,
  lang
});

export const setActiveMainSection = section => ({
  type: types.SET_ACTIVE_MAIN_SECTION,
  section
});

export const setUserRulesConfirm = statusRulesConfirm => ({
  type: types.SET_USER_RULES_CONFIRM,
  statusRulesConfirm
});
