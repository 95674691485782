import React from 'react';
import {Link} from "react-router-dom";
import Form from "../../../form/Form";
import Button from "../../../form/Button";
import NumberFormatInput from "../../../form/NumberFormatInput";
import ReCAPTCHA from "react-google-recaptcha";
import {googleRecaptchaKey} from "../../../../constants/Variables";

function Reset (props) {
  const {handleSubmit, handleChangePhone, step, serverErrors, handleChangeCaptcha, recaptchaRef} = props;
  return (
      <div className={`auth__wrap`}>
        <div className={`auth__desc-wrap`}>
          <div className={`auth-desc`}>
            {step === 'start' ? props.t('password_reset.phone_label') : props.t('password_reset.success')}
          </div>
        </div>
        <div className={`auth__form`}>
          <Form handleSubmit={handleSubmit}>
            <NumberFormatInput name={"phone"}
                               mask="_"
                               format={'+995 ###-##-##-##'}
                               allowEmptyFormatting
                               onChange={handleChangePhone}
                               serverErrors={serverErrors}
                               validate={[
                                 {
                                   required: true,
                                   message: props.t('errors.phone')
                                 },
                                 {
                                   minLength: 9
                                 }
                               ]}/>
            <div className="auth__captcha-wrapper">
              <ReCAPTCHA
                  sitekey={googleRecaptchaKey}
                  onChange={handleChangeCaptcha}
                  ref={recaptchaRef}
                  size="invisible"
              />
            </div>
            <div className={`auth__btns`}>
              <Button type={'submit'} disabled={props.loading}>
                {step === 'start' ? props.t('btns.enter') : props.t('btns.resend')}
              </Button>
              <Button className={`btn--blue`}>
                <Link to={{pathname: "/signin"}}>{props.t('btns.back_login')}</Link>
              </Button>
            </div>
          </Form>
        </div>
      </div>
  )
}

export default Reset
