import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../../parts/Title";
import {setWrapperClassName} from "../../../../actions/common";
import Finish from "./Finish";
import Start from "./Start";
// import BackgroundImage from "../../../svgItems/BackgroundImage";
import defaultAxios from "../../../../axios";
import LangHOC from "../../../HoC/LangHOC";

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 'start',
      token: null,
      oldUser: null,
      checkName: false
    };
  }

  componentDidMount() {
    const code = this.props.location.pathname.split('/password/reset/')[1]; // this.props.location.search;
    if (code) { //path && path[0]
      // let token = /token=(\w+)/.exec(path)[1];
      this.checkToken(code);
    } else {
      this.props.history.push('/signin')
    }
    this.props.setWrapperClassName("auth-container");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.checkName !== prevState.checkName && !this.state.oldUser) {
      this.checkToken(this.state.token);
    }
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  handleSubmit = (formData) => {
    const fd = formData;
    fd.append('token', this.state.token);
    defaultAxios.post('/api/auth/v2/reset/password', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept': 'application/json'
      }
    }).then(response => {
      if (response.status === 200) {
        this.setState({
          step: 'finish'
        })
      }
    })
        .catch(error => {})
  };

  checkToken = (token) => {
    let newToken = token;
    if (this.state.checkName) {
      newToken = token+'.name'
    }
    defaultAxios.post('/api/front/v2/is-token-exist', {"token": newToken}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
        .then(response => {
          if (response.data.isset) {
            this.setState({
              token
            })
          } else if (response.data.name) {
            this.setState({
              oldUser: response.data.name,
              token
            })
          } else if (!response.data.isset && this.state.checkName) {
            this.props.history.push('/signin')
          } else {
            this.setState({
              checkName: true,
              token
            })
            // this.props.history.push('/signin')
          }
        })
        .catch(error => {
        });
  };

  render() {
    const {step} = this.state;
    let currentTitle = step === 'start' && this.state.oldUser ? (this.props.t('new_password.old_user_title') + this.state.oldUser + '!') : this.props.t('new_password.title');
    return (
        <div className={`auth new-password ${step}`}>
          <Title title={currentTitle} size={220}/>
          {(step === 'start' && this.state.oldUser) && <div className={`auth__desc-wrap`}>
            <div className={`auth-desc`}>
              {this.props.t('new_password.old_user_text0')}
            </div>
            <div className={`auth-desc`}>
              {this.props.t('new_password.old_user_text1')}
            </div>
          </div>}
          { step === 'start' && <Start handleSubmit={this.handleSubmit} {...this.props} /> }
          { step === 'finish' && <Finish {...this.props}/> }
          {/*<BackgroundImage />*/}
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
});

export default connect(null, mapDispatchToProps)(LangHOC(PasswordReset));
