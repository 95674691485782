import pres_1 from "../../../../../../images/surv-1.svg";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIsProfilingFinished } from "../../../../../hooks/useIsProfilingFinished";
import { useHistory } from "react-router-dom";
import { surveyProfilingUrl } from "../../../../../constants/path";

const ProfilingCard = ({ t, setServerSurveyError }) => {
  const history = useHistory();

  const { isProfilingFinished, isError } = useIsProfilingFinished();

  useEffect(() => {
    setServerSurveyError(isError);
  }, [isError, setServerSurveyError]);

  return (
    <button
      className={`card grey`}
      onClick={() => history.push(surveyProfilingUrl)}
      disabled={isProfilingFinished}
    >
      <div className={`card__wrap`}>
        <div className={`card__content`}>
          <p className={`card__title`}>
            {t("main_page.tasks.profiling_survey")}
          </p>
          <p className={`card__text`}>
            {t("main_page.logged.section2.cardText1")}
          </p>
          <p className={`card__reward`}>
            <strong>+15</strong> {t("main_page.tasks.points")}
          </p>
        </div>
        <div className={`card__image`}>
          <img src={pres_1} alt="" />
        </div>
      </div>
    </button>
  );
};

ProfilingCard.propTypes = {
  setServerSurveyError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ProfilingCard;
