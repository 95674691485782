/*
const questionsArrCamel = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Camel Original?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Recommended price 6.2Gel",
          name_en: "Recommended price 6.2Gel"
        },
        {
          id: "1",
          name: "Pack design and format",
          name_en: "Pack design and format"
        },
        {
          id: "2",
          name: "KS Format",
          name_en: "KS Format"
        },
        {
          id: "3",
          name: "Rich Tobacco Taste",
          name_en: "Rich Tobacco Taste"
        },
        {
          id: "4",
          name: "Camel Brand image",
          name_en: "Camel Brand image"
        },
        {
          id: "5",
          name: "Suitable Tar Level",
          name_en: "Suitable Tar Level"
        },
        {
          id: "6",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "7",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "How do you like the convenient pack format and design of Camel Original?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "How do you like the Rich tobacco taste of Camel Original?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "Are you going to purchase Camel Original on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "Not",
          name_en: "Not"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "What is the main reason preventing you from regular consumption Camel Original?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "6", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "Don’t know", name_en: "Don’t know"},
        {id: "8", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია Camel Original-ის მომხმარებელი ხართ:",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "რეკომედებული ფასი 6.20 ₾ ",
          name_en: "Recommended price 6.2Gel"
        },
        {
          id: "1",
          name: "შეფუთვის დიზაინი და ფორმატი",
          name_en: "Pack design and format"
        },
        {
          id: "2",
          name: "კლასიკური ფორმატი",
          name_en: "KS Format"
        },
        {
          id: "3",
          name: "თამბაქოს ინტენსიური გემო",
          name_en: "Rich Tobacco Taste"
        },
        {
          id: "4",
          name: "ქემელის ბრენდის იმიჯი",
          name_en: "Camel Brand image"
        },
        {
          id: "5",
          name: "შესაფერისი სიძლიერე",
          name_en: "Suitable Tar Level"
        },
        {
          id: "6",
          name: "პასუხი არ მაქვს",
          name_en: "Don’t know"
        },
        {
          id: "7",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "როგორ მოგწონთ Camel Original-ის შეფუთვის ფორმატი და დიზაინი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ მოგწონთ Camel Original -ის თამბაქოს ინტენსიური გემო?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?(ჩაწერეთ)",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "აპირებთ თუ არა Camel Original -ის რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "Not"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც Camel Original -ი არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "6", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "8", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდის იმიჯი", name_en: "Brand image"},
        {id: "1", name: "სიგარეტის ფორმატი", name_en: "Cigarette format"},
        {id: "2", name: "ნიკოტინის შემცველობა", name_en: "Tar level"},
        {id: "3", name: "ფასი", name_en: "Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};

const questionsCamelV2 = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Camel Caster?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Premium tobacco blend",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "Center hole charcoal filter",
          name_en: "Center hole charcoal filter"
        },
        {
          id: "2",
          name: "LSS technology (less tobacco smoke smell around you)",
          name_en: "LSS technology (less tobacco smoke smell around you)"
        },
        {
          id: "3",
          name: "Price 6.10 GEL",
          name_en: "Price 6.10 GEL"
        },
        {
          id: "4",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "5",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "How do you like Premium Tobacco Blend Taste of Camel Caster?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "How do you like format of Camel Caster?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "Are you going to purchase Camel Caster on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "No",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "What is the main reason preventing you from regular consumption Camel Caster?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "6", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "Don’t know", name_en: "Don’t know"},
        {id: "8", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია ქემელკასტერის მომხმარებელი ხართ?",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "პრემიალური თამბაქოს ნაზავი",
          name_en: "Premium tobacco blend"
        },
        {
          id: "1",
          name: "ჩაღრმავებული ნახშირის ფილტრი",
          name_en: "Center hole charcoal filter"
        },
        {
          id: "2",
          name: "LSS ტექნოლოგია (ნაკლები თამბაქოს კვამლის სუნი შენს გარშემო)",
          name_en: "LSS technology (less tobacco smoke smell around you)"
        },
        {
          id: "3",
          name: "რეკომენდებული ფასი 6.10 ლარი",
          name_en: "Price 6.10 GEL"
        },
        {
          id: "4",
          name: "პასუხი არ მაქვს",
          name_en: "Don’t know"
        },
        {
          id: "5",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "როგორ მოგწონთ ქემელ კასტერის პრემიალური თამბაქოს  ნაზავის გემო?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ მოგწონთ ქემელ კასტერის ფორმატი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?(ჩაწერეთ)",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "5",
      "description": "აპირებთ თუ არა ქემელ კასტერის რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "6",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც ქემელ კასტერი არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "6", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "8", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდის იმიჯი", name_en: "Brand image"},
        {id: "1", name: "სიგარეტის ფორმატი", name_en: "Cigarette format"},
        {id: "2", name: "ნიკოტინის შემცველობა", name_en: "Tar level"},
        {id: "3", name: "ფასი", name_en: "Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};

const questionsCamelV3 = {
  "en": [
    {
      "question_id": "0",
      "description": "Please name the statements that for you correspond to brand Camel? (multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "International brand",
          name_en: "International brand"
        },
        {
          id: "1",
          name: "High quality",
          name_en: "High quality"
        },
        {
          id: "2",
          name: "Rich taste",
          name_en: "Rich taste"
        },
        {
          id: "3",
          name: "Value for money",
          name_en: "Value for money"
        },
        {
          id: "4",
          name: "All of them",
          name_en: "All of them"
        },
        {
          id: "5",
          name: "NONE OF THEM",
          name_en: "NONE OF THEM"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "Is Camel your regular brand?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "No",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "Which Camel offer do you smoke?",
      "answers": [
        {id: "0", name: "Camel Original Blue", name_en: "Camel Original Blue"},
        {id: "1", name: "Camel Original Yellow", name_en: "Camel Original Yellow"},
        {id: "2", name: "Camel Caster", name_en: "Camel Caster"},
        {id: "3", name: "Camel Caster Black", name_en: "Camel Caster Black"}
      ]
    },
    {
      "question_id": "3",
      "description": "How would you rate Camel Original offer product and packaging mix at recommended price 5.0?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "How would you rate Camel Caster offer product and packaging mix at recommended price 6.1?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "5",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {id: "0", name: "Brand", name_en: "Brand"},
        {id: "1", name: "Price", name_en: "Price"},
        {id: "2", name: "Format", name_en: "Format"},
        {id: "3", name: "Strength", name_en: "Strength"},
        {id: "4", name: "Pack design", name_en: "Pack design"},
        {id: "5", name: "Tobacco Taste", name_en: "Tobacco Taste"}
      ]
    },
    {
      "question_id": "6",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "What is the main reason preventing you from regular consumption of Camel?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format doesn’t suit me", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Brand image does not suit me", name_en: "Brand image does not suit me"},
        {id: "6", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "Don’t know", name_en: "Don’t know"},
        {id: "8", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "8",
      "description": "How did you know about Camel Original?",
      "answers": [
        {id: "0", name: "From my friend", name_en: "From my friend"},
        {id: "1", name: "From research", name_en: "From research"},
        {id: "2", name: "From shop assistant", name_en: "From shop assistant"},
        {id: "3", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "9",
      "description": "How did you know about Camel Caster?",
      "answers": [
        {id: "0", name: "From my friend", name_en: "From my friend"},
        {id: "1", name: "From research", name_en: "From research"},
        {id: "2", name: "From shop assistant", name_en: "From shop assistant"},
        {id: "3", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "ჩამოთვლილიდან, თქვენი აზრით რომელი გამოხატავს ყველაზე მეტად ბრენდ ქემელს? (შესაძლებელია რამოდენიმე პასუხიც)",
      "answers": [
        {
          id: "0",
          name: "საერთაშორისო ბრენდი",
          name_en: "International brand"
        },
        {
          id: "1",
          name: "მაღალი ხარისხი",
          name_en: "High quality"
        },
        {
          id: "2",
          name: "ძლიერი  გემო",
          name_en: "Rich taste"
        },
        {
          id: "3",
          name: "ფასთან შესაბამისობა",
          name_en: "Value for money"
        },
        {
          id: "4",
          name: "ჩამოთვლილთაგან ყველა",
          name_en: "All of them"
        },
        {
          id: "5",
          name: "არცერთი",
          name_en: "NONE OF THEM"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "ქემელი თქვენი რეგულარული  ბრენდია?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "ქემელის რომელ პროდუქტს ეწევით?",
      "answers": [
        {id: "0", name: "ქემელ ორიგინალ ლურჯს", name_en: "Camel Original Blue"},
        {id: "1", name: "ქემელ ორიგინალ ყვითელს", name_en: "Camel Original Yellow"},
        {id: "2", name: "ქემელ კასტერს", name_en: "Camel Caster"},
        {id: "3", name: "ქემელ კასტერ შავს", name_en: "Camel Caster Black"}
      ]
    },
    {
      "question_id": "3",
      "description": "როგორ შეაფასებდით ქემელ ორიგინალს და მის შეფუთვას, რომლის რეკომენდებული ფასი  არის 5.0 ₾?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "4",
      "description": "როგორ შეაფასებდით ქემელ კასტერს და მის შეფუთვას, რომლის რეკომენდებული ფასი  არის 6.1 ₾?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "5",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლოა რამდენიმე ვარიანტი)",
      "answers": [
        {id: "0", name: "ბრენდი", name_en: "Brand"},
        {id: "1", name: "ფასი", name_en: "Price"},
        {id: "2", name: "ფორმატი", name_en: "Format"},
        {id: "3", name: "სიძლიერე", name_en: "Strength"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack design"},
        {id: "5", name: "თამბაქოს გემო", name_en: "Tobacco Taste"}
      ]
    },
    {
      "question_id": "6",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?(ჩაწერეთ)",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "7",
      "description": "რა არის მთავარი მიზეზი, რომლის გამოც ქემელ კასტერი არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format doesn’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ბრენდის იმიჯი არ შემეფერება", name_en: "Brand image does not suit me"},
        {id: "6", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "7", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "8", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "8",
      "description": "საიდან გაიგეთ ქემელ ორიგინალის შესახებ?",
      "answers": [
        {id: "0", name: "მეგობრისგან", name_en: "From my friend"},
        {id: "1", name: "გამოკითხვიდან", name_en: "From research"},
        {id: "2", name: "მაღაზიის გამყიდველისგან", name_en: "From shop assistant"},
        {id: "3", name: "სხვა  (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "9",
      "description": "საიდან გაიგეთ ქემელ კასტერის  შესახებ?",
      "answers": [
        {id: "0", name: "მეგობრისგან", name_en: "From my friend"},
        {id: "1", name: "გამოკითხვიდან", name_en: "From research"},
        {id: "2", name: "მაღაზიის გამყიდველისგან", name_en: "From shop assistant"},
        {id: "3", name: "სხვა  (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};
*/

const questionsCamelV4 = {
  "en": [
    {
      "question_id": "0",
      "description": "How long do you smoke Camel Compact Black?",
      "answers": [
        {
          id: "0",
          name: "1st time",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "Less than 2 weeks",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "Less than 1 month",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "More than 1 month",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "What determines your choice? (Multiple answers possible)",
      "answers": [
        {
          id: "0",
          name: "Affordable price",
          name_en: "Affordable price"
        },
        {
          id: "1",
          name: "Fulfilling 8mg./cig. tar level",
          name_en: "Fulfilling 8mg./cig. tar level"
        },
        {
          id: "2",
          name: "Modern format and design ",
          name_en: "Modern format and design "
        },
        {
          id: "3",
          name: "Center-hole filter",
          name_en: "Center-hole filter"
        },
        {
          id: "4",
          name: "The brand",
          name_en: "The brand"
        },
        {
          id: "5",
          name: "Highest Camel quality",
          name_en: "Highest Camel quality"
        },
        {
          id: "6",
          name: "Don’t know",
          name_en: "Don’t know"
        },
        {
          id: "7",
          name: "Other (Write in)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "How do you like the convenient pack format and design of Camel Compact Black?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },
    {
      "question_id": "3",
      "description": "Which cigarettes have you smoked earlier /smoked now on regular basis?",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "Other (fill in)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "4",
      "description": "Are you going to purchase Camel Compact Black on a regular basis?",
      "answers": [
        {
          id: "0",
          name: "Yes",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "No",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "5",
      "description": "What is the main reason preventing you from regular consumption Camel Compact Black?",
      "answers": [
        {id: "0", name: "I am loyal to my Brand", name_en: "I am loyal to my Brand"},
        {id: "1", name: "Cigarette format don’t suit me", name_en: "Cigarette format don’t suit me"},
        {id: "2", name: "Seems too strong for me", name_en: "Seems too strong for me"},
        {id: "3", name: "Seems too light for me", name_en: "Seems too light for me"},
        {id: "4", name: "Not available at my usual place of cigarette purchase", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "Don’t like the pack’s overall design", name_en: "Don’t like the pack’s overall design"},
        {id: "6", name: "Don’t know", name_en: "Don’t know"},
        {id: "7", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "6",
      "description": "What is the main reason?",
      "answers": [
        {id: "0", name: "Brand image", name_en: "Brand image"},
        {id: "1", name: "Cigarette format", name_en: "Cigarette format"},
        {id: "2", name: "Tar level", name_en: "Tar level"},
        {id: "3", name: "Price", name_en: "Price"},
        {id: "4", name: "Pack’s design", name_en: "Pack’s design"},
        {id: "5", name: "Don’t know", name_en: "Don’t know"},
        {id: "6", name: "Other (Write in)", name_en: "Other (Write in)"}
      ]
    }
  ],
  "ge": [
    {
      "question_id": "0",
      "description": "რამდენი ხანია შავი ქემელ კომპაქტის მომხმარებელი ხართ?",
      "answers": [
        {
          id: "0",
          name: "ახლა პირველად გავსინჯე",
          name_en: "1st time"
        },
        {
          id: "1",
          name: "2 კვირაზე ნაკლები",
          name_en: "Less than 2 weeks"
        },
        {
          id: "2",
          name: "1 თვეზე ნაკლები",
          name_en: "Less than 1 month"
        },
        {
          id: "3",
          name: "1 თვეზე მეტია",
          name_en: "More than 1 month"
        }
      ]
    },
    {
      "question_id": "1",
      "description": "რა განსაზღვრავს თქვენს არჩევანს? (შესაძლებელია რამდენიმე პასუხი)",
      "answers": [
        {
          id: "0",
          name: "ხელმისაწვდომი ფასი",
          name_en: "Affordable price"
        },
        {
          id: "1",
          name: "8 მგ/სიგ კუპრი",
          name_en: "Fulfilling 8mg./cig. tar level"
        },
        {
          id: "2",
          name: "თანამედროვე ფორმატი და დიზაინი",
          name_en: "Modern format and design "
        },
        {
          id: "3",
          name: "ჩაღრმავებული ფილტრი",
          name_en: "Center-hole filter"
        },
        {
          id: "4",
          name: "ბრენდი",
          name_en: "The brand"
        },
        {
          id: "5",
          name: "ქემელის უმაღლესი ხარისხი",
          name_en: "Highest Camel quality"
        },
        {
          id: "6",
          name: "პასუხი არ მაქვს",
          name_en: "Don’t know"
        },
        {
          id: "7",
          name: "სხვა (ჩაწერეთ)",
          name_en: "Other (Write in)"
        }
      ]
    },
    {
      "question_id": "2",
      "description": "როგორ მოგწონთ შავი ქემელ კომპაქტის თანამედროვე შეფუთვის ფორმატი და დიზაინი?",
      "answers": [
        {id: "0", name: "range"}
      ]
    },

    {
      "question_id": "3",
      "description": "რომელ სიგარეტს ეწეოდით ადრე /ეწევით რეგულარულად?(ჩაწერეთ)",
      "answers": [
        {id: "0", name: "Sobranie", name_en: "Sobranie"},
        {id: "1", name: "Winston", name_en: "Winston"},
        {id: "2", name: "Camel", name_en: "Camel"},
        {id: "3", name: "Parliament", name_en: "Parliament"},
        {id: "4", name: "Marlboro", name_en: "Marlboro"},
        {id: "5", name: "Phillip Morris", name_en: "Phillip Morris"},
        {id: "6", name: "Kent", name_en: "Kent"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (fill in)"}
      ]
    },
    {
      "question_id": "4",
      "description": "აპირებთ თუ არა შავი ქემელ კომპაქტის რეგულარულად შეძენას?",
      "answers": [
        {
          id: "0",
          name: "კი",
          name_en: "Yes"
        },
        {
          id: "1",
          name: "არა",
          name_en: "No"
        }
      ]
    },
    {
      "question_id": "5",
      "description": "რა არის მთავარი მიზეზი, რომლის შავი ქემელ კომპაქტი არ არის თქვენი რეგულარული ბრენდი?",
      "answers": [
        {id: "0", name: "საკუთარი რეგულარული ბრენდის ერთგული ვარ", name_en: "I am loyal to my Brand"},
        {id: "1", name: "სიგარეტის ფორმატი არ შემესაბამება", name_en: "Cigarette format don’t suit me"},
        {id: "2", name: "ზედმეტად მძიმე / ძლიერი ჩანს ჩემთვის", name_en: "Seems too strong for me"},
        {id: "3", name: "ზედმეტად მსუბუქი/არადამაკმაყოფილებელი ჩანს ჩემთვის", name_en: "Seems too light for me"},
        {id: "4", name: "არ არის იქ სადაც მე ჩვეულებრივ  ვყიდულობ სიგარეტს", name_en: "Not available at my usual place of cigarette purchase"},
        {id: "5", name: "ზოგადად კოლოფის დიზაინი არ მომწონს", name_en: "Don’t like the pack’s overall design"},
        {id: "6", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "7", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    },
    {
      "question_id": "6",
      "description": "რა არის მთავარი მიზეზი?",
      "answers": [
        {id: "0", name: "ბრენდი", name_en: "The Brand"},
        {id: "1", name: "თანამედროვე კომპაქტური ფორმატი", name_en: "Modern compact format "},
        {id: "2", name: "ჩაღრმავებული ფილტრი", name_en: "Center-hole filter"},
        {id: "3", name: "ხელმისაწვდომი ფასი", name_en: "Affordable Price"},
        {id: "4", name: "კოლოფის დიზაინი", name_en: "Pack’s design"},
        {id: "5", name: "არ ვიცი", name_en: "Don’t know"},
        {id: "6", name: "სხვა (ჩაწერეთ)", name_en: "Other (Write in)"}
      ]
    }
  ]
};


export {questionsCamelV4}
