import React, {Component} from "react";
import defaultAxios from "../../../axios";
import {connect} from "react-redux";
import {setUserConfirm, setLogin} from "../../../actions/auth";

class SmsToken extends Component {
  componentDidMount() {
    //HOST_NAME/sender?token=nq0uxMhJy8&activity=sender_67
    const path = this.props.location.search.split('&');
    if (path && path[0]) {
      this.checkToken(path);
    } else {
      this.props.history.push('/')
    }
  }

  checkToken = (path) => {
    const jsonData = {};
    if (path && path[0]) {
      let token = path[0].split('=')[1];
      jsonData['token'] = token;
      if (path.length > 1) {
        let type = path[1].split('=')[0];
        let typeValue = path[1].split('=')[1];
        jsonData[type] = typeValue;
        if (path[2]) {
          let type = path[2].split('=')[0];
          let typeValue = path[2].split('=')[1];
          jsonData[type] = typeValue;
        }
        jsonData['mailingType'] = 'sms';
      }

      defaultAxios.post('/api/front/v2/get-user-from-mailing-token', jsonData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data) {
            if (response.data.required_empty_fields && Object.keys(response.data.required_empty_fields).length > 0) {
              this.props.setUserConfirm(response.data);
              this.props.history.push('/confirm-profile');
            } else {
              this.props.setLogin(response.data.token, response.data.expiration);
              this.props.history.push('/tasks');
            }
          }
        })
        .catch(error => {});
    }
  };

  render() {
    return (
      <div className={`sms-token`}>
        <div className={`preloader`}></div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setUserConfirm: (token) => dispatch(setUserConfirm(token)),
  setLogin: (token, expiration) => dispatch(setLogin(token, expiration))
});

export default connect(null, mapDispatchToProps)(SmsToken)