import React, {Fragment} from 'react';
import Form from "../../../form/Form";
import Input from "../../../form/Input";
import NumberFormatInput from "../../../form/NumberFormatInput";
import Dropdown from "../../../form/Dropdown";
import Textarea from "../../../form/Textarea";
import Button from "../../../form/Button";
import ReCAPTCHA from "react-google-recaptcha";
import {googleRecaptchaKey} from "../../../../constants/Variables";

function FeedbackForm(props) {
  return (
      <div className={`feedback__wrap ${props.isLogged ? 'logged' : ''}`}>
        <p className={`feedback__sub-title`}>
          {props.t('feedback.sub_title')} <a href="mailto:info@we2gether.ge">info@we2gether.ge</a>
        </p>
        <Form handleSubmit={props.handleSubmit}>
          <div className={`feedback__col`}>
            {!props.isLogged && (
                <Fragment>
                  <Input name={'name'}
                         placeholder={props.t('feedback.form.name')}
                         maxLength={50}
                         validate={[
                           {required: true,
                             message: props.t('errors.firstname')}
                         ]}/>
                  <NumberFormatInput name={"phone"}
                                     mask="_"
                                     format={'+995 ###-##-##-##'}
                                     allowEmptyFormatting
                                     validate={[
                                       {
                                         required: true,
                                         message: props.t('errors.phone')
                                       },
                                       {
                                         minLength: 9
                                       }
                                     ]}/>
                  <Input name="email"
                         placeholder={props.t('feedback.form.email')}
                         validate={[
                           {required: true,
                             message: props.t('errors.email')},
                           {email: true,
                             message: props.t('errors.email')}
                         ]}
                  />
                </Fragment>
            )}
            <Dropdown name={'subject'}
                      validate={[{required: true,
                        message: props.t('errors.select_topic')}]}
                      dataOptions={props.topicList}
                      placeholder={props.t('feedback.form.select')}/>
          </div>
          <div className={`feedback__col`}>
                <Textarea name={'body'}
                          maxLength={250}
                          placeholder={props.t('feedback.form.textarea')}
                          validate={[
                            {required: true},
                            {minLength: 4}
                          ]}
                />
            <div>
              <ReCAPTCHA
                  sitekey={googleRecaptchaKey}
                  onChange={props.handleChangeCaptcha}
                  ref={props.recaptchaRef}
                  size="invisible"
              />
            </div>
            <Button type={'submit'}>{props.t('btns.send')}</Button>
          </div>
        </Form>
      </div>
  )
}

export default FeedbackForm
