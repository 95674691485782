import * as types from "../constants/ActionTypes";
import defaultAxios from "../axios";

export const getCities = () => (
    dispatch => {
        return defaultAxios.get('/api/info/cities')
            .then(response => {
                if (response.data) {
                  let cityList = [];
                  response.data.data.data.map(item => cityList.push({label: item.name, value: item.id}));
                  dispatch(setCities(cityList));
                }
            })
            .catch(error => {});
    }
);

export const setCities = (cities) => ({
    type: types.SET_CITIES,
    cities
});
