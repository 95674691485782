import React, {useRef} from 'react';
// import card_id_logo from "../../../../../images/auth/id-card.svg";
import Form from "../../../form/Form";
import Button from "../../../form/Button";
import Input from "../../../form/Input";
import DatePickerInput from "../../../form/DatePicker";
import Radio from "../../../form/Radio";
// import Upload from "../../../form/Upload";
// import {getBase64} from "../../../../utils";

function Step1 (props) {
  const {nextStep, handleChangeField} = props;
  // const photoIdImg = useRef(null);

  // const uploadPhoto = (event) => {
  //   const photo = event.target.files[0];
  //   if (photo && isValidFileType(photo.type)) {
  //     getBase64(photo, (result) => {
  //       if (!photoIdImg) return false;
  //       const photoImg = photoIdImg.current;
  //       photoImg.src = result;
  //       // document.querySelector('.signup-image img').remove();
  //       // let img = document.createElement('img');
  //       // img.classList.add('signup-image__img');
  //       // img.src = result;
  //       // document.querySelector('.signup-image').insertBefore(img, null);
  //     });
  //   } else {
  //     if (!photoIdImg) return false;
  //     photoIdImg.current.src = card_id_logo
  //   }
  // };
  //
  // const isValidFileType = (fName) => {
  //   const extensionImages = ['image/jpeg', 'image/png', 'image/bmp', 'image/x-png']; //['jpg', 'bmp', 'png'];
  //   return extensionImages.find(el=>el == fName); //indexOf(fName.split('.').pop().toLowerCase()) > -1;
  // };

  return (
      <div className={`signup__wrap`}>
      <div className={`auth__form-wrap`}>
        <Form handleSubmit={nextStep}>
          {/*<div className={`auth-row`}>*/}
            {/*<div className={`auth-col`}>*/}
              {/*<div className={`auth-group-wrap`}>*/}
                {/*<div className={`signup-image`}>*/}
                  {/*<img src={card_id_logo}*/}
                       {/*ref={photoIdImg}*/}
                       {/*alt="card id"*/}
                  {/*/>*/}
                {/*</div>*/}
                {/*<Upload name={'userID'}*/}
                        {/*text={props.t('signup.photo_upload_id')}*/}
                        {/*onChange={uploadPhoto}*/}
                        {/*validate={[*/}
                          {/*{required: true,*/}
                            {/*message: props.t('errors.upload_photo')},*/}
                          {/*{check_size: true, message: 'The user id may not be greater than 10000 kilobytes'},*/}
                          {/*{check_type: true}*/}
                        {/*]}/>*/}
              {/*</div>*/}
            {/*</div>*/}
            <div className={`auth-col`}>
              <div className={`auth-group-wrap`}>
                <div className={`radio-content`}>
                  <p className={`radio-content__label`}>{props.t('signup.old_enough')}</p>
                  <Radio
                         name="old_enough"
                         validate={[{
                           exactValue: '1',
                           message: props.t('errors.req_field')
                         }]}>
                    <option value="1" id="checkbox_2-1"
                            label={props.t('btns.yes')}/>
                    <option value="0" id="checkbox_2-2"
                            label={props.t('btns.no')}/>
                  </Radio>
                </div>
                <Input name={'firstname'}
                       maxLength={60}
                       validate={[
                         {required: true,
                           message: props.t('errors.firstname')},
                         {onlyLatin: true}
                       ]}
                       placeholder={props.t('signup.firstname_placeholder')}/>
                <Input name={'lastname'}
                       maxLength={60}
                       validate={[
                         {required: true,
                           message: props.t('errors.lastname')},
                         {onlyLatin: true}
                       ]}
                       placeholder={props.t('signup.lastname_placeholder')}/>

                <DatePickerInput name={'birth_date'}
                                 validate={[
                                   {required: true,
                                     message: props.t('errors.birth_date')},
                                   {under18: true,
                                     message: props.t('errors.18old')}
                                 ]}
                                 placeholder={props.t('signup.birthdate_placeholder')} />

                <Input name={'pid'}
                       maxLength={11}
                       onChange={handleChangeField}
                       validate={[
                         {required: true,
                           message: props.t('errors.pid')},
                         {onlyNumbers: true},
                         {minLength: 11}
                       ]}
                       serverErrors={props.pidError}
                       placeholder={props.t('signup.pid_placeholder')}/>

              </div>
            </div>
          {/*</div>*/}
          {/*<div className={`auth-row`}>*/}
            {/*<div className={`auth-col`}>*/}
              {/*<div className={`auth-group-wrap`}>*/}
                {/*<Input name={'pid'}*/}
                       {/*maxLength={11}*/}
                       {/*onChange={handleChangeField}*/}
                       {/*validate={[*/}
                         {/*{required: true,*/}
                           {/*message: props.t('errors.pid')},*/}
                         {/*{onlyNumbers: true},*/}
                         {/*{minLength: 11}*/}
                       {/*]}*/}
                       {/*serverErrors={props.pidError}*/}
                       {/*placeholder={props.t('signup.pid_placeholder')}/>*/}
              {/*</div>*/}
            {/*</div>*/}
            {/*<div className={`auth-col`}>*/}
              {/*<div className={`auth-group-wrap`}>*/}
                {/*<DatePickerInput name={'birth_date'}*/}
                                 {/*validate={[*/}
                                   {/*{required: true,*/}
                                     {/*message: props.t('errors.birth_date')},*/}
                                   {/*{under18: true,*/}
                                     {/*message: props.t('errors.18old')}*/}
                                 {/*]}*/}
                                 {/*placeholder={props.t('signup.birthdate_placeholder')} />*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
          <div className={`auth__btns`}>
            <Button type={`submit`}>{props.t('btns.next')}</Button>
          </div>
        </Form>
      </div>
      </div>
  )
}

export default Step1
