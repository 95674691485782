import React, {useState} from 'react';
import male_icon from "../../../../../images/auth/male-icon.svg";
import female_icon from "../../../../../images/auth/female-icon.svg";
import Form from "../../../form/Form";
import Button from "../../../form/Button";
import Dropdown from "../../../form/Dropdown";
import Radio from "../../../form/Radio";
import Checkbox from "../../../form/Checkbox";
import {googleRecaptchaKey} from "../../../../constants/Variables";
import ReCAPTCHA from "react-google-recaptcha";

function Step3(props) {
  const {handleSubmit, t, openPopup, checkbox_rules, handleChangeCaptcha, recaptchaRef} = props;
  const [currentCity, setCurrentCity] = useState(null);
  const [otherCity, setOtherCity] = useState(false);
  const city_1 = {label: "Tbilisi", value: 1};
  const city_2 = {label: "Batumi", value: 2};
  const city_3 = {label: "Kutaisi", value: 3};
  const city_4 = {label: "Rustavi", value: 4};

  const chooseCity = (value) => {
    setCurrentCity(value);
    // setOtherCity(false);
  };

  const choiceOtherCity = (value) => {
    setCurrentCity(null);
    setOtherCity(value);
  };
  return (
      <div className={`signup__wrap`}>
      <div className={`auth__form-wrap`}>
        <Form handleSubmit={handleSubmit}>
          <div className={`auth-col`}>
            <div className={`signup-choose-btn city-wrap`}>
              <p className={`signup-choose-btn__label`}>{t('signup.city_label')}:</p>
              <div className="signup-choose-btn__btns">
                <Button className={`btn--blue ${currentCity && currentCity.value === 1?'active':''}`} handleClick={()=>chooseCity(city_1)}>
                  {t('signup.city0')}
                </Button>
                <Button className={`btn--blue ${currentCity && currentCity.value === 2?'active':''}`} handleClick={()=>chooseCity(city_2)}>
                  {t('signup.city1')}
                </Button>
                <Button className={`btn--blue ${currentCity && currentCity.value === 3?'active':''}`} handleClick={()=>chooseCity(city_3)}>
                  {t('signup.city2')}
                </Button>
                <Button className={`btn--blue ${currentCity && currentCity.value === 4?'active':''}`} handleClick={()=>chooseCity(city_4)}>
                  {t('signup.city3')}
                </Button>
              </div>
              <Dropdown name={'city_id'}
                        dataOptions={props.cityList}
                        validate={[{
                          required: true,
                          message: t('errors.city')
                        }]}
                        clearable={false}
                        menuHeight={200}
                        placeholder={t('signup.city_placeholder')}
                        value={currentCity} />
            </div>
            <div className={`radio-content`}>
              <p className={`radio-content__label`}>{t('signup.gender_label')}:</p>
              <Radio name="gender"
                     validate={[{
                       required: true,
                       message: t('errors.gender')
                     }]}>
                <option value="m" id="checkbox_2-1"
                        label={'Male'} icon={<img src={male_icon} alt="male icon"/>}/>
                <option value="f" id="checkbox_2-2"
                        label={'Female'} icon={<img src={female_icon} alt="female icon"/>}/>
              </Radio>
            </div>

            <div className={`checkbox-with-text`}>
              <Checkbox name={'new_rules_agreement'}
                        id={'rules_confirmation'}
                        validate={[{
                          required: true,
                          message: t('errors.req_field')}]}
                        value={checkbox_rules}
              />
              <p dangerouslySetInnerHTML={{__html: t('signup.rules_checkbox')}} onClick={openPopup}></p>
            </div>
            <div className="auth__captcha-wrapper">
              <ReCAPTCHA
                  sitekey={googleRecaptchaKey}
                  onChange={handleChangeCaptcha}
                  ref={recaptchaRef}
                  size="invisible"
              />
            </div>
          </div>

          <div className={`auth__btns`}>
            <Button type={`submit`}>
              {t('btns.next')}
            </Button>
          </div>
        </Form>
      </div></div>
  )
}

export default Step3
