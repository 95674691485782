import React from 'react';
import Form from "../../../form/Form";
import Input from "../../../form/Input";
import Button from "../../../form/Button";

const Start = (props) => {
  const {handleSubmit} = props;
  return (
      <div className={`auth__wrap`}>
        <div className={`auth__form`}>
          <Form handleSubmit={handleSubmit}>
            <Input name="password"
                   type={"password"}
                   placeholder={props.t('new_password.create_placeholder')}
                   validate={[
                     {required: true, message: props.t('errors.password')},
                     {minLength: 8, message: props.t('errors.password_min')}
                   ]}
            />
            <Input name="password_repeat"
                   type={"password"}
                   placeholder={props.t('new_password.repeat_placeholder')}
                   validate={[
                     {required: true, message: props.t('errors.password')},
                     {sameAsField: 'password', message: props.t('errors.password_repeat')}
                   ]}
            />
            <div className={`auth__btns`}>
              <Button type={`submit`}>{props.t('btns.submit')}</Button>
            </div>
          </Form>
        </div>
      </div>
  )
};

export default Start
