import React, {Fragment} from 'react';
import Button from "../../../form/Button";
import {Link} from "react-router-dom";

function Step6 (props) {
  return (
      <Fragment>
        <div className={`signup__wrap`}>
        <div className={`auth__form-wrap`}>
          <div className={`signup-finish`}>
            <p className={`signup-finish__title`}>{props.t('signup.finish_title')}, {props.userName}!</p>
            <p className={`signup-finish__text`}>{props.t('confirm_profile.old_user_text1')}</p>
            <p className={`signup-finish__text`}>{props.t('confirm_profile.old_user_text2')}</p>
            <p className={`signup-finish__text`}>{props.t('confirm_profile.old_user_text3')}</p>
            <Button type={'button'}><Link to={{pathname: "/signin"}}>{props.t('btns.signin')}</Link></Button>
          </div>
        </div>
        </div>
      </Fragment>
  )
}

export default Step6
