import React, {Component} from "react";
import Title from "../../../../parts/Title";
import Button from "../../../../form/Button";
import Form from "../../../../form/Form";
import Upload from "../../../../form/Upload";
import defaultAxios from "../../../../../axios";
import debounce from 'lodash/debounce';
import ImagePhotoId from "./ImagePhotoId";
import card_id_logo from "../../../../../../images/auth/id-card.svg";
import {getBase64} from "../../../../../utils";

class SubmittingResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoId: null
    };
    this.setQuizSubmit = debounce(this.setQuizSubmit, 1000, {leading: true, trailing: false});
    this.photoIdImg = React.createRef();
  }

  setQuizSubmit = (formData) => {
    if (!this.props.questions) return false;
    const fd = new FormData();
    fd.append('survey_type', 'id_upload');
    fd.append(`${this.props.questions[0].question_id}[${this.props.questions[0].answers[0].id}]`, this.props.questions[0].answers[0].id);
    fd.append(`file`, this.state.photoId);

    this.setState({loading: true});
    defaultAxios.post('/api/survey-search-tasks/v2/success', fd, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
      .then(res => {
        this.props.changeStep(2);
      })
      .catch(err => {
        this.setState({loading: false});
      })
  };

  uploadPhoto = (event) => {
    const photo = event.target.files[0];
    if (photo && this.isValidFileType(photo.type)) {
      getBase64(photo, (result) => {
        if (!this.photoIdImg) return false;
        this.photoIdImg.current.src = result;
        this.setState({
          photoId: photo
        })
      });
    } else {
      if (!this.photoIdImg) return false;
      this.photoIdImg.current.src = card_id_logo;
    }
  };

  isValidFileType = (fName) => {
    const extensionImages = ['image/jpeg', 'image/png', 'image/bmp', 'image/x-png'];
    return extensionImages.find(el => el == fName);
  };

  render() {
    return (
      <div className={`census-submitting`}>
        <Title title={this.props.t('open_task.census.form.title')} size={170}/>
        <div className={`census-submitting__wrap`}>
          <Form handleSubmit={this.setQuizSubmit}>
            <div className={`census-submitting__col`}>
              <ImagePhotoId {...this.props} photoIdImg={this.photoIdImg}/>
              <div className={`upload-photo-wrap`}>
                <Upload name={'outlet_img'}
                        onChange={this.uploadPhoto}
                        text={this.props.t('btns.upload_photo')}
                        className={`btn`}
                        validate={[
                          {required: true, message: this.props.t('errors.upload_photo')},
                          {check_size: true, message: 'The user id may not be greater than 10000 kilobytes'},
                          {check_type: true}
                        ]}/>
                <div className={`census-submitting__submit`}>
                  <Button className={`btn--blue`} type={'submit'}
                          disabled={this.state.loading}>{this.props.t('btns.submit')}</Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default SubmittingResult;
