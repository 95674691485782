import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types"

const Tab = (props) => {
  const {location, link, name} = props;
  return (
      <Link to={`${link}`} className={`cabinet-tabs__tab ${location.pathname.match(link)?'active':''}`} >
        <p className={`cabinet-tabs__tab-name`}>
          {name}
        </p>
      </Link>
  )
};

Tab.propTypes = {
  location: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default Tab
