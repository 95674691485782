import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../../parts/Title";
import {setWrapperClassName} from "../../../../actions/common";
import Reset from "./Reset";
// import BackgroundImage from "../../../svgItems/BackgroundImage";
import defaultAxios from "../../../../axios";
import LangHOC from "../../../HoC/LangHOC";
import debounce from 'lodash/debounce';

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: null,
      step: 'start',
      phone: null,
      serverErrors: null,
      loading: false
    };
    this.handleSubmit = debounce(this.handleSubmit, 1000, {leading: true, trailing:false});
    this.timer = null;
    this.timerDuration = 30000;
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.props.setWrapperClassName("auth-container");
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isLogged && this.props.isLogged && this.state.step === 'start') this.props.history.push('/cabinet/profile');
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
    if (this.timer) clearTimeout(this.timer);
  }

  handleSubmit = async (formData) => {
    this.setState({loading: true});
    const asyncTokenCaptha = await this.recaptchaRef.current.executeAsync();
    const locale = localStorage.getItem('language') || "en";
    const {phone, captcha} = this.state;
    let newCaptha = asyncTokenCaptha || captcha;
    let fd = new FormData();
    // let phone = formData.get('phone').substring(5).replace(/[-_]/g, '');
    fd.append('phone', phone);
    fd.append('locale', locale);
    fd.append('g-recaptcha-response', newCaptha);

    if (newCaptha) {
    defaultAxios.post('/api/auth/v2/reset/sms', fd, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
        .then(response => {
          // if (typeof response.data === 'object' && response.data !== null) {
          //   this.setState({
          //     serverErrors: {"phone": response.data.message},
          //     loading: false
          //   })
          // } else {
            this.setState({
              step: 'finish',
              // loading: false
            });
          // }
          this.timer = setTimeout(() => {
            this.recaptchaRef.current.reset();
            this.setState({ loading: false })
          }, this.timerDuration);
        })
        .catch(error => {
          // this.setState({loading: false})
          this.timer = setTimeout(() => {
            this.recaptchaRef.current.reset();
            this.setState({ loading: false })
          }, this.timerDuration);
        })
    } else {
      this.recaptchaRef.current.reset();
      this.setState({ loading: false })
    }
  };

  handleChangePhone = (value) => {
    this.setState({
      phone: value,
      serverErrors: null
    })
  };

  handleChangeCaptcha = (value) => {
    this.setState({
      captcha: value
    })
  };

  render() {
    const {step} = this.state;

    return (
        <div className={`auth password-reset ${step}`}>
          <Title title={this.props.t('password_reset.title')} />
          <Reset handleSubmit={this.handleSubmit}
                 handleChangePhone={this.handleChangePhone}
                 recaptchaRef={this.recaptchaRef}
                 handleChangeCaptcha={this.handleChangeCaptcha}
                 {...this.state} {...this.props}/>
          {/*<BackgroundImage />*/}
        </div>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged
});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(PasswordReset));
