import React, { Component } from "react";
import SubmittingResult from "./parts/SubmittingResult";
import defaultAxios from "../../../../axios";
import Done from "./parts/Done";
import Description from "./parts/Description";

class PhotoId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      questions: null,
    };
  }

  componentDidMount() {
    this.getQuizQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.getQuizQuestions();
    }
  }

  getQuizQuestions = () => {
    const lang = this.props.currentLanguage;
    defaultAxios
      .get(`/api/survey-search-tasks/v2?survey_type=id_upload&lang=${lang}`, {
        headers: {
          "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
          Accept: "application/json",
          Authorization: "bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.setState({
          questions: res.data.data.questions,
        });
      })
      .catch((err) => {});
  };

  changeStep = (step) => {
    window.scrollTo(0, 0);
    this.setState({
      step: step,
    });
  };

  handleAcceptTask = () => {
    this.changeStep(1);
  };

  render() {
    const { step } = this.state;

    return (
      <div className={`census photo-id`}>
        {step === 0 && (
          <Description
            {...this.props}
            {...this.state}
            changeStep={this.changeStep}
            handleAcceptTask={this.handleAcceptTask}
          />
        )}
        {step === 1 && (
          <SubmittingResult
            {...this.props}
            {...this.state}
            changeStep={this.changeStep}
          />
        )}
        {step === 2 && <Done {...this.props} />}
      </div>
    );
  }
}

export default PhotoId;
