import React from 'react';
import {Link} from "react-router-dom";
import Button from "../../../form/Button";

function Step2 (props) {
  const {userName} = props;
  return (
      <div className={`auth__wrap`}>
        <div className={`auth__desc-wrap`}>
          <div className={`user-greeting`}>{props.t('signin.meeting0')} {userName}! {props.t('signin.meeting1')}</div>
          <div className={`auth-desc`}>{props.t('signin.success_desc')}</div>
        </div>
        <div className={`auth__form`}>
          <div className={`auth__btns`}>
            <Button className={`btn--blue`}><Link to={{pathname: "/"}}>{props.t('btns.main')}</Link></Button>
          </div>
        </div>
      </div>
  )
}

export default Step2
