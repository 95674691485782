import React from "react";
import {Link} from "react-router-dom";
import Button from "../../../form/Button";

const FormBtns = (props) => {

  return (
      <div className={`photo-pack-form__btns`}>
        <Button type={`button`} className={`btn--v2 next-btn`} handleClick={() => props.changeStep(1)}>
          {props.t('btns.accept_task_new')}
        </Button>
        <Button className={`btn--blue btn--v2`}>
          <Link to={{pathname: "/tasks"}}>
            {props.t('btns.back')}
          </Link>
        </Button>
      </div>
  );
};

export default FormBtns;
