import * as types from "../constants/ActionTypes";

const initialState = {
  places: [],
  currentPlace: []
};

export default function gmap(state = initialState, action) {
  switch (action.type) {
    case types.SET_MAP_PLACES:
      return {
        ...state,
        places: action.places,
      };
    case types.SET_CURRENT_MAP_PLACE:
      return {
        ...state,
        currentPlace: action.place,
      };
    default:
      return state;
  }
}
