import { combineReducers } from 'redux'
import auth from "./auth";
import common from "./common";
import cities from "./cities";
import cigarette from "./cigarette";
import gmap from "./gmap";
import menu from "./menu";
import task from "./task";

export default combineReducers({
  auth,
  common,
  cities,
  cigarette,
  gmap,
  menu,
  task
})
