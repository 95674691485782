import React, {Component} from 'react'
import PropTypes from 'prop-types'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import calendar_icon from "../../../images/cabinet/calendar-icon.png";
// import moment from 'moment'

class DatePickerInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    validating: PropTypes.bool,
    validate: PropTypes.array,
    handleValidating: PropTypes.func,
    value: PropTypes.any,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    icon: PropTypes.bool,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    disabled: false,
    onChange: () => {}
  };

  constructor(props) {
    super();
    this.state = {
      date: props.date || '',
      errors: {}
    }
  }

  setDate = (date) => {
    const { onChange, validate /*, validating*/ } = this.props;

    this.setState({
      date: date
    });
    onChange(date);
    if (this.props.handleChangeField) {
      this.props.handleChangeField(date, this.props.name)
    }

    // if (validating === true) {
      if (validate) {
        this.handleValidation(date);
      }
    // }
  };

  handleValidation = (date) => {
    const {errors} = this.state;
    const {validate} = this.props;

    validate.map(item => {
      if (item.hasOwnProperty('required')) {
        if (!date) {
          errors.required = item.message || 'The value is required'
        } else {
          delete errors.required;
        }
      } else if (item.hasOwnProperty('under18')) {
        if (date && this.calcDate(date) < 6575) { // :TODO 6575 - days
          errors.smoker18 = item.message || `You must be over 18 year old to register`
        } else {
          delete errors.smoker18;
        }
      }

      this.setState({
        errors
      })
    });

    this.props.handleValidating(this.props.name, Object.keys(errors).length);
  };

  calcDate = (calendarDate) => {
    let currentCalendarDate = calendarDate ? new Date(calendarDate) : new Date();
    let diff = Math.floor(new Date().getTime() - currentCalendarDate.getTime());
    let day = 1000 * 60 * 60 * 24;
    let days = Math.floor(diff/day);
    return days
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.validating && this.props.validating !== prevProps.validating) {
      if (this.props.validate) {
        this.handleValidation(this.state.date);
      }
    }
    if (this.props.date !== prevProps.date) {
      this.setState({
        date: this.props.date
      })
    }
  }

  openDatepicker = () => this._calendar.setOpen(true);

  icon() {
    return (
        <div className={`date-picker-indicators`} onClick={this.openDatepicker}>
          <span className={`date-picker-indicators__separator`}></span>
          <span className={`date-picker-indicators__icon`}>
            <img src={calendar_icon} alt="calendar"/>
          </span>
        </div>
    )
  }

  render() {
    let errorMessage;

    if (Object.keys(this.state.errors).length) {
      errorMessage = (
          Object.keys(this.state.errors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.state.errors[item]}</div>
          ))
      )
    } else {
      this.props.serverErrors &&
      Object.keys(this.props.serverErrors).map((item, i) => {
        errorMessage = (
            <div key={i} className={'error-element'}>{this.props.serverErrors[item]}</div>
        )
      })
    }
    return (
        <div className={"form-field date-picker" + (Object.keys(this.state.errors).length || (this.props.serverErrors && Object.keys(this.props.serverErrors).length) ? " has-error" : "")}>
          <DatePicker
              selected={this.state.date?new Date(this.state.date): this.state.date} //?moment(this.state.date).toDate():this.state.date
              onChange={date => this.setDate(date)}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              // closeOnScroll={true}
              placeholderText={this.props.placeholder}
              dropdownMode="select"
              name={this.props.name}
              defaultValue={null}
              autoComplete={'off'}
              dateFormat="yyyy-MM-dd"
              ref={(c) => this._calendar = c}
              disabled={this.props.disabled}
              onFocus={(e) => e.target.readOnly = true}
          />
          {this.props.icon && this.icon()}
          {errorMessage}
        </div>
    )
  }
}

export default DatePickerInput;
