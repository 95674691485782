import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import Button from "../../../form/Button";
import Title from "../../../parts/Title";

function Block(props) {
  return (
      <Fragment>
        <Title title={'Profile Update'} size={208}/>
        <div className={`signup__wrap`}>
          <div className={`auth__form-wrap`}>
            <div className={`signup-block`}>
              <p className={`signup-block__text`}>{props.t('signup.block_text_0')}</p>
              <p className={`signup-block__text`}>{props.t('signup.block_text_1')}</p>
              <Button><Link to={{pathname: "/"}}>{props.t('btns.main')}</Link></Button>
            </div>
          </div>
        </div>
      </Fragment>
  )
}

export default Block
