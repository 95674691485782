import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiImgUrl } from "../../../../api/constants";
import ErrorResponse from "../../PageNotFound/ErrorResponse";
import SurveyQuestions from "./parts/SurveyQuestions";
import PropTypes from "prop-types";
import PageNotFound from "../../PageNotFound/PageNotFound";
import { saveSurveyResults } from "../../../../api/requests";
import { useLongSurveyStatus } from "../../../../hooks/useLongSurveyStatus";
import { useSurveys } from "../../../../hooks/useSurveys";
import { LONG_SURVEY_STATUS } from "../../../../constants/Tasks";
import { useIsSurveyAvailable } from "../../../../hooks/useIsSurveyAvailable";
import SurveyPopup from "../../MainPage/AllTasks/parts/SurveyPopup";
import { useUrlParams } from "../../../../hooks/useUrlParams";
import { tasksUrl } from "../../../../constants/path";
import UploadDone from "../PhotoPack/parts/UploadDone";
import PhotoStep from "./parts/LongSurveyPhoto";
import uniqueId from "lodash/uniqueId";
import Title from "../../../parts/Title";

const LongSurvey = ({ t, currentLanguage }) => {
  const history = useHistory();
  const { urlParams } = useUrlParams();

  const {
    longSurvey: survey,
    isLoading: isGetSurveysLoading,
    isError: isGetSurveysError,
  } = useSurveys();

  const {
    isAvailable,
    shouldShowPopup: shouldShowUnavailablePopup,
    status: unavailableStatus,
    isLoading: isCheckSurveyAvailableLoading,
    isError: isCheckSurveyAvailableError,
  } = useIsSurveyAvailable(survey?.id);

  const [step, setStep] = useState(0);
  const [isServerError, setIsServerError] = useState(false);
  const [shouldShowPopup, setShouldShowPopup] = useState(false);
  const [statusKey, setStatusKey] = useState(uniqueId());

  const {
    status,
    shouldShowPopup: shouldShowStatusPopup,
    isUnavailable: isSurveyUnavailableByStatus,
    isLoading: isGetSurveyStatusLoading,
    isError: isGetSurveyStatusError,
  } = useLongSurveyStatus(survey?.id, isAvailable, statusKey);

  useEffect(() => {
    if (
      (!shouldShowUnavailablePopup && !shouldShowStatusPopup) ||
      urlParams.notShowPopup
    )
      return;

    setShouldShowPopup(true);
  }, [shouldShowUnavailablePopup, shouldShowStatusPopup]);

  useEffect(() => {
    if (!status || shouldShowPopup) return;

    if (status === LONG_SURVEY_STATUS.questions) setStep(1);
  }, [status, shouldShowPopup]);

  useEffect(() => {
    setIsServerError(
      isGetSurveysError || isCheckSurveyAvailableError || isGetSurveyStatusError
    );
  }, [isGetSurveysError, isCheckSurveyAvailableError, isGetSurveyStatusError]);

  const isLoading = useMemo(() => {
    return (
      isGetSurveysLoading ||
      isCheckSurveyAvailableLoading ||
      isGetSurveyStatusLoading
    );
  }, [
    isGetSurveysLoading,
    isCheckSurveyAvailableLoading,
    isGetSurveyStatusLoading,
  ]);

  const closePopup = () => {
    setShouldShowPopup(false);
    if (shouldShowUnavailablePopup) history.push(tasksUrl);
    else history.push(`${tasksUrl}?notShowPopup=true`);
  };

  const onEndSurvey = (answers) => {
    const result = {
      lang: currentLanguage,
      survey_id: survey.id,
      survey_answers: answers,
    };

    saveSurveyResults(result, survey.type)
      .then(() => {
        setStep(2);
        setStatusKey(uniqueId());
      })
      .catch(() => {
        setIsServerError(true);
      });
  };

  if (isLoading) return <></>;
  else if (isServerError) return <ErrorResponse />;
  else if (
    step === 0 &&
    (!survey ||
      ((!isAvailable || isSurveyUnavailableByStatus) && !shouldShowPopup))
  )
    return <PageNotFound />;

  return (
    <>
      <div className={`survey survey-long`}>
        {step === 0 && (
          <PhotoStep
            onSave={() => {
              if (status === LONG_SURVEY_STATUS.photo_rejected) setStep(2);
              setStatusKey(uniqueId());
            }}
            t={t}
            surveyId={survey.id}
            points={survey.points}
            surveyImagePath={`${apiImgUrl}/${survey.imagePath}`}
            surveyDescription={
              currentLanguage === "en" || !survey.descriptionGe
                ? survey.descriptionEn
                : survey.descriptionGe
            }
            setIsServerError={setIsServerError}
          />
        )}
        {step === 1 && (
          <div className={`survey-wrap`}>
            <div className={`survey-form`}>
              <Title title={t("open_task.photo_pack.survey_desc")} size={205} />
              <SurveyQuestions
                surveyId={survey.id}
                onEndSurvey={onEndSurvey}
                allQuestions={survey.allQuestions}
                mainQuestions={survey.mainQuestions}
                relatedQuestions={survey.relatedQuestions}
                lang={currentLanguage}
                t={t}
              />
            </div>
          </div>
        )}
        {step === 2 && <UploadDone t={t} points={survey.points} />}
      </div>
      {shouldShowPopup && (
        <SurveyPopup
          t={t}
          status={unavailableStatus || status}
          onClose={closePopup}
          onPhotoUpload={() => setShouldShowPopup(false)}
          points={survey.points}
        />
      )}
    </>
  );
};

LongSurvey.propTypes = {
  t: PropTypes.func.isRequired,
  currentLanguage: PropTypes.oneOf(["en", "ge"]).isRequired,
};

export default LongSurvey;
