import { getLongSurveyStatus } from "../api/requests";
import { useEffect, useMemo, useState } from "react";
import { LONG_SURVEY_STATUS } from "../constants/Tasks";
import {
  getUserBlockedPopupFromLS,
  getUserConfirmedPopupFromLS,
  getUserFromLS,
  setUserBlockedPopup,
  setUserConfirmedPopup,
} from "../utils/storage";

export const useLongSurveyStatus = (id, isAvailable, key) => {
  const currentUser = getUserFromLS();
  const userBlockedPopup = getUserBlockedPopupFromLS();
  const userConfirmedPopup = getUserConfirmedPopupFromLS();

  const [status, setStatus] = useState();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowPopup, setShouldShowPopup] = useState(false);

  const isUnavailable = useMemo(() => {
    if (!status) return false;

    return (
      status === LONG_SURVEY_STATUS.photo_check ||
      status === LONG_SURVEY_STATUS.finished ||
      status === LONG_SURVEY_STATUS.unfinished
    );
  }, [status]);

  useEffect(() => {
    if (!status) return;

    switch (status) {
      case LONG_SURVEY_STATUS.photo_rejected:
        setShouldShowPopup(true);
        break;
      case LONG_SURVEY_STATUS.unfinished:
        if (!userBlockedPopup?.hasOwnProperty("userId" + currentUser?.id)) {
          setUserBlockedPopup();
          setShouldShowPopup(true);
        }
        break;
      case LONG_SURVEY_STATUS.finished:
        if (!userConfirmedPopup?.hasOwnProperty("userId" + currentUser?.id)) {
          setUserConfirmedPopup();
          setShouldShowPopup(true);
        }
        break;
      default:
    }
  }, [status]);

  useEffect(() => {
    if (!id || !isAvailable) return;

    setIsLoading(true);
    getLongSurveyStatus(id)
      .then((res) => {
        setStatus(res?.data?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setIsError(true);
        console.dir(err);
      });
  }, [id, isAvailable, key]);

  return { status, shouldShowPopup, isError, isUnavailable, isLoading };
};
