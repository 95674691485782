import React, { useEffect, useState } from "react";
import Title from "../../../parts/Title";
import Button from "../../../form/Button";
import { Link } from "react-router-dom";
import LangHOC from "../../../HoC/LangHOC";
import { setWrapperClassName } from "../../../../actions/common";
import { useDispatch } from "react-redux";
import ErrorResponse from "../../PageNotFound/ErrorResponse";
import Cards from "./parts/Cards";

const AllTasks = ({ t }) => {
  const dispatch = useDispatch();

  const [serverSurveyError, setServerSurveyError] = useState(false);

  useEffect(() => {
    dispatch(setWrapperClassName("wrapper-logged"));

    return () => {
      dispatch(setWrapperClassName(""));
    };
  }, [dispatch]);

  if (serverSurveyError) return <ErrorResponse />;

  return (
    <div className="main-page">
      <div className="main-tasks">
        <div className="logged">
          <div className="section active">
            <div className="share-opinion">
              <Title
                title={t("main_page.logged.section2.title")}
                className="page-title--v2"
                size={243}
              />
              <Cards t={t} setServerSurveyError={setServerSurveyError} />
              <div className="btns-wrap">
                <Button className="btn--v2">
                  <Link to={{ pathname: "/cabinet/points" }}>
                    {t("btns.check_balance")}
                  </Link>
                </Button>
                <Button className="btn--v2">
                  <Link to={{ pathname: "/shop" }}>{t("btns.bonus_shop")}</Link>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LangHOC(AllTasks);
