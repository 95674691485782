import * as types from "../constants/ActionTypes";

const initialState = {
    brandList: undefined,
    formatList: undefined,
};

export default function cigarette(state = initialState, action) {
    switch (action.type) {
        case types.SET_CIGARETTE_BRAND_LIST:
            return {
                ...state,
                brandList: action.brandList,
            };
        case types.SET_CIGARETTE_FORMAT_LIST:
            return {
                ...state,
                formatList: action.formatList,
            };
        default:
            return state;
    }
}
