import Title from "../../../../../parts/Title";
import Button from "../../../../../form/Button";
import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import { tasksUrl } from "../../../../../../constants/path";

const AcceptTask = ({
  surveyDescription,
  points,
  t,
  surveyImagePath,
  onNext,
}) => {
  return (
    <>
      <div className={`survey-task-description`}>
        <Title title={t("open_task.photo_pack.task_desc")} size={204} />
        <div className={`open-tasks__caption`}>
          <p className={`open-tasks__text`}>
            {t("open_task.photo_pack.text1")}
          </p>
          <p className={`open-tasks__text`}>
            {t("open_task.photo_pack.text2")}
          </p>
        </div>
      </div>
      <div className={"photo-pack-wrapper"}>
        <img
          src={surveyImagePath}
          className={"photo-pack-wrapper__image"}
          alt={surveyDescription}
        />
      </div>

      <p className="reward-title">
        {t("open_task.points_comp")}: <span>{points}</span>
      </p>

      <div className={`photo-pack-form__btns`}>
        <Button
          type={"button"}
          className={`btn--blue btn--v2`}
          disabled={false}
        >
          <Link to={{ pathname: tasksUrl }}>{t("btns.back")}</Link>
        </Button>
        <Button
          type={"button"}
          disabled={false}
          handleClick={onNext}
          className={`btn btn--v2`}
        >
          {t("btns.accept_task_new")}
        </Button>
      </div>
    </>
  );
};

AcceptTask.propTypes = {
  onNext: PropTypes.func.isRequired,
  surveyDescription: PropTypes.string.isRequired,
  surveyImagePath: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  points: PropTypes.string,
};

export default AcceptTask;
