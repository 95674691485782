import React from "react";
import Title from "../../../../parts/Title";
import Button from "../../../../form/Button";
import done_img from "../../../../../../images/open-tasks/attention.png";

const TryAgain = (props) => {
  return (
      <div className={`popup attention photo-pack-popup`}>
        <div className={`popup__close`} onClick={()=>props.closePopup()}></div>
        <div className={`container`}>
          <div className={`popup__wrap`}>
            <div className={`attention-img`}>
              <img src={done_img} alt="done pic"/>
            </div>
            <Title title={props.t('shop.attention.title')} className={`white white-v2`} size={170}/>
            <p className={`popup__text`}>{props.t('open_task.photo_pack.attention.text0')}</p>
            <p className={`popup__text`}>{props.t('open_task.photo_pack.attention.text1')}</p>
            <Button className={`open-tasks-finish__btn`} handleClick={()=>props.handleComboTask()}>
              {props.t('btns.upload_photo')}
            </Button>
          </div>
        </div>
      </div>

  );
};

export default TryAgain;
