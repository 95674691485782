import React from 'react'

export default function CheckboxV2({
    label,
    name,
    id,
    handleChange,
    className,
    disabled,

    image,
}) {
    return (
        <div className={'checkbox-wrapper'}>
            <label className="checkbox-container">
                <input
                    type="checkbox"
                    className={className}
                    name={name}
                    onChange={handleChange}
                    disabled={disabled}
                    id={id && id}
                    value={id}
                />
                {image}
                <span className="checkmark"></span>
            </label>

            {label ? (
                <label className={'checkbox-text'} htmlFor={id} dangerouslySetInnerHTML={{ __html: label }}></label>
            ) : null}
        </div>
    )
}
