import React, { useRef, useEffect }  from "react";
import Title from "../../../parts/Title";
import Button from "../../../form/Button";

const PopupVoucher = (props) => {
  const {closePopup, voucherCode, voucherValue} = props;
  // const [copySuccess, setCopyStatus] = useState(false);
  const copyText = useRef(null);
  const copyTextInput = useRef(null);

  useEffect(() => {
  }, [copyText, copyTextInput]);

  const copyToClipboard = () => {
    const copyTextField = copyText.current.textContent;
    let hiddenInputField = copyTextInput.current;
    hiddenInputField.value = copyTextField;
    hiddenInputField.select();
    document.execCommand('copy');
    // setCopyStatus(true);
  };
  return (
      <div className={`shop-popup`}>
        <div className={`shop-popup__close`} onClick={closePopup}></div>
        <div className={`container`}>
          <div className={`shop-popup__wrap`}>
            <Title title={props.t('shop.voucher.title')} className={`white`} size={208}/>
            <p className={`shop-popup__label`}>{props.t('shop.voucher.label0')} {voucherValue} {props.t('shop.voucher.label_tkt')}:</p>
            <p className={`shop-popup__code`} ref={copyText}>{`${voucherCode?voucherCode:'ХХХ-ХХХ-ХХХ'}`}</p>
            <div className={`shop-popup__hidden`}>
              <input type={'text'} ref={copyTextInput}/>
            </div>
            <Button handleClick={copyToClipboard} className={`shop-popup__btn`}>{props.t('shop.voucher.btn')}</Button>
          </div>
        </div>
      </div>
  )
};

export default PopupVoucher
