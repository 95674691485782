import React, {Component} from "react";
import defaultAxios from "../../../../../../axios";
import debounce from 'lodash/debounce';
import SurveyQuestions from "./SurveyQuestions";
import Title from "../../../../../parts/Title";
import {questionsCamelV4} from "./qustions";

class Research extends Component {
  state = {
    step: 0,
    loading: false,
    questions: [],
    checkedCheckbox0: [],
    commentCheckbox0: null,
    fieldCheckbox0: null,
    radioArr: [],
    commentRadio1: null,
    commentRadio3: null,
    commentRadio4: null,
    rangeArr: [],
    rangeErrors: {},
    serverErrorQuantity: 0,
    questionsError: false,
    serverSubmitAnswersError: false
  };

  componentDidMount() {
    this.getQuizQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.getQuizQuestions();
    }
  }

  getQuizQuestions = () => {
    const lang = this.props.currentLanguage;
    this.setState({
      questions: questionsCamelV4[lang]
    })
  };

  setQuizSubmit = debounce((formData) => {
    const fd = new FormData();
    const {radioArr, checkedCheckbox0, rangeArr, commentRadio1, commentRadio3, commentRadio4} = this.state;

    let invalid = null;


    radioArr.length > 0 && radioArr.sort((a, b) => {
      return Object.values(a)[0].question_id - Object.values(b)[0].question_id
    }).forEach((value) => {
      for (let j in value) {
        if (value.hasOwnProperty(j)) {
          if (j === 'Radio0') {
            return fd.append(`question_1`, value[j].answer_id);
          }
          if (j === 'Radio1') {
            if (value[j].answer_text && !commentRadio1) {
              this.setState({
                errorcommentRadio1: this.props.t('open_task.sobranie.textarea_error')
              });
              invalid = true
            }
            if (value[j].answer_text) {
              return fd.append(`question_4`, commentRadio1);
            } else {
              return fd.append(`question_4`, value[j].answer_id);
            }
          }
          if (j === 'Radio2') {
            return fd.append(`question_5`, value[j].answer_id);
          }
          if (j === 'Radio3') {
            if (value[j].answer_text && !commentRadio3) {
              this.setState({
                errorcommentRadio3: this.props.t('open_task.sobranie.textarea_error')
              });
              invalid = true
            }
            if (value[j].answer_text) {
              return fd.append(`question_6`, commentRadio3);
            } else {
              return fd.append(`question_6`, value[j].answer_id);
            }
          }
          if (j === 'Radio4') {
            if (value[j].answer_text && !commentRadio4) {
              this.setState({
                errorcommentRadio4: this.props.t('open_task.sobranie.textarea_error')
              });
              invalid = true
            }
            if (value[j].answer_text) {
              return fd.append(`question_6`, commentRadio4);
            } else {
              return fd.append(`question_6`, value[j].answer_id);
            }
          }
        }
      }
    });

    //radio errors
    [...Array(3)].forEach((arr, i) => {
      if (!radioArr.find(el => el['Radio' + i])) {
        this.setState({
          ['errorRadio' + i]: this.props.t('open_task.sobranie.choice_error')
        });
        invalid = true
      } else {
        return false
      }
    });

    if (radioArr.find(el => el['Radio2']) && radioArr.find(el => el['Radio2']).hasOwnProperty('Radio2') &&
        radioArr.find(el => el['Radio2'])['Radio2'].hasOwnProperty('answer_id_text') &&
        radioArr.find(el => el['Radio2'])['Radio2'].answer_id_text === 'pur_yes') {
      if (!radioArr.find(el => el['Radio4'])) {
        this.setState({
          ['errorRadio4']: this.props.t('open_task.sobranie.choice_error')
        });
        invalid = true
      }
    } else if (radioArr.find(el => el['Radio2']) && radioArr.find(el => el['Radio2']).hasOwnProperty('Radio2') &&
        radioArr.find(el => el['Radio2'])['Radio2'].hasOwnProperty('answer_id_text') &&
        radioArr.find(el => el['Radio2'])['Radio2'].answer_id_text === 'pur_no') {
      if (!radioArr.find(el => el['Radio3'])) {
        this.setState({
          ['errorRadio3']: this.props.t('open_task.sobranie.choice_error')
        });
        invalid = true
      }
    }

    if (checkedCheckbox0.length > 0) {
      let checkboxAnswers = [];
      [...checkedCheckbox0].sort((a, b) => {
        return a.answer_id - b.answer_id
      }).map((el) => {
        if (el.answer_text) {
          if (!this.state.commentCheckbox0) {
            this.setState({
              errorcommentCheckbox0: this.props.t('open_task.sobranie.textarea_error')
            });
            invalid = true
          } else {
            return checkboxAnswers.push(this.state.commentCheckbox0);
          }
        } else {
          return checkboxAnswers.push(el.answer_text_en)
        }
      });
      fd.append(`question_2`, JSON.stringify(checkboxAnswers));
    } else if (checkedCheckbox0.length === 0) {
      this.setState({
        errorCheckbox0: this.props.t('open_task.sobranie.choice_error')
      });
      invalid = true
    }

    rangeArr.length > 0 && rangeArr.sort((a, b) => {
      return Object.values(a)[0].answer_id - Object.values(b)[0].answer_id
    }).forEach((value) => {
      for (let j in value) {
        if (value.hasOwnProperty(j)) {
          if (j === 'Range0') {
            fd.append(`question_3`, value[j].value);
          }
          // if (j === 'Range1') {
          //   fd.append(`question_4`, value[j].value);
          // }
        }
      }
    });

    //range errors
    let rangeErrors = {};
    [...Array(1)].forEach((arr, i) => {
      if (!rangeArr.find(el => el['Range' + i])) {
        rangeErrors = {...rangeErrors, ['errorRange' + i]: this.props.t('open_task.sobranie.choice_error')};
        invalid = true
      } else {
        return false
      }
    });

    this.setState({
      rangeErrors: rangeErrors
    });

    fd.append(`question_7`, "0");

    // console.log([...fd]);
    // console.log('status', invalid);

    if (invalid) {
      this.setState({questionsError: true});
      return false;
    }
    // return false;
    this.setState({loading: true});
    defaultAxios.post('/api/combo-task/v2/questions', fd, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
        .then(res => {
          if (res?.data?.data && res?.data?.message === 'success') {
            window.scrollTo(0, 0);
            this.props.changeStep('upload-done');
          }
        })
        .catch(err => {
          this.setState({loading: false});
          this.setState({serverSubmitAnswersError: true});
        })
  }, 1500, {leading: true, trailing: false});

  handleCheckConsider = (e, question_id) => {
    const id = e.target.id;
    let isSelected = e.target.checked;
    const {serverSubmitAnswersError, questionsError} = this.state;
    if (questionsError) {
      this.setState({questionsError: false});
    } else if (serverSubmitAnswersError) {
      this.setState({serverSubmitAnswersError: false});
    }

    if (isSelected) {
      this.setState({
        ['checked' + e.target.name]: [...this.state['checked' + e.target.name], {
          'question_id': question_id,
          'answer_id': id,
          'answer_text': e.target.dataset.name ? e.target.dataset.name : null,
          'answer_text_en': e.target.dataset.nameEn ? e.target.dataset.nameEn : null
        }],
        ['field' + e.target.name]: e.target.dataset.name ? id : this.state['checked' + e.target.name].filter(item => this.state['field' + e.target.name] === item['answer_id']).length > 0
            ? this.state['field' + e.target.name] : null,
        ['error' + e.target.name]: null
      });
    } else {
      this.setState({
        ['checked' + e.target.name]: this.state['checked' + e.target.name].filter(item => id !== item['answer_id']),
        ['field' + e.target.name]: !e.target.dataset.name && this.state['checked' + e.target.name].filter(item => this.state['field' + e.target.name] === item['answer_id']).length > 0
            ? this.state['field' + e.target.name] : false
      })
    }
  };

  handleToggleRadio = (event, question_id) => {
    let newRadioArr = this.state.radioArr.filter(el => {
      return Object.keys(el)[0] !== event.target.name
    });

    const {serverSubmitAnswersError, questionsError} = this.state;
    if (questionsError) {
      this.setState({questionsError: false});
    } else if (serverSubmitAnswersError) {
      this.setState({serverSubmitAnswersError: false});
    }

    if (event.target.name === 'Radio2' && event.target.id && event.target.id === 'pur_yes') {
      this.setState({
        errorRadio3: null,
        fieldRadio3: null,
        commentRadio3: null,
        errorcommentRadio3: null,
      });
      newRadioArr = newRadioArr.filter(el => {
        return Object.keys(el)[0] !== 'Radio3'
      });
    } else if (event.target.name === 'Radio2' && event.target.id && event.target.id === 'pur_no') {
      this.setState({
        errorRadio4: null,
        fieldRadio4: null,
        commentRadio4: null,
        errorcommentRadio4: null,
      });
      newRadioArr = newRadioArr.filter(el => {
        return Object.keys(el)[0] !== 'Radio4'
      });
    }

    this.setState({
      radioArr: [...newRadioArr, {
        [event.target.name]: {
          'question_id': question_id,
          'answer_id': event.target.value,
          'answer_text': event.target.dataset && event.target.dataset.name ? event.target.dataset.name : null,
          'answer_id_text': event.target.id
        }
      }],
      ['field' + event.target.name]: event.target.dataset && event.target.dataset.name ? event.target.id : null,
      ['error' + event.target.name]: null,
      ['errorcomment' + event.target.name]: null
    });
  };

  textAreaHandler = (e) => {
    const {serverSubmitAnswersError, questionsError} = this.state;
    if (questionsError) {
      this.setState({questionsError: false});
    } else if (serverSubmitAnswersError) {
      this.setState({serverSubmitAnswersError: false});
    }
    this.setState({
      [e.target.name]: e.target.value,
      ['error' + e.target.name]: null
    });
  };

  handleRange = (name, value, question_id, answer_id) => {
    let newRangeArr = this.state.rangeArr.filter(range => {
      return Object.keys(range)[0] !== name
    });

    const {serverSubmitAnswersError, questionsError} = this.state;
    if (questionsError) {
      this.setState({questionsError: false});
    } else if (serverSubmitAnswersError) {
      this.setState({serverSubmitAnswersError: false});
    }

    this.setState({
      rangeArr: [...newRangeArr, {
        [name]: {
          'question_id': question_id,
          'answer_id': answer_id,
          'value': value
        }
      }]
    });
    if (['error' + name]) {
      const {['error' + name]: value, ...correctErrors} = this.state.rangeErrors;
      this.setState({
        rangeErrors: correctErrors
      })
    }
  };

  render() {
    // console.log(this.state);
    return (
        <div className={`survey`}>
          <Title title={this.props.t('open_task.photo_pack.survey_desc')} className={`page-title--v2`} size={170}/>
          <SurveyQuestions
              setQuizSubmit={this.setQuizSubmit}
              handleCheckConsider={this.handleCheckConsider}
              handleToggleRadio={this.handleToggleRadio}
              textAreaHandler={this.textAreaHandler}
              handleRange={this.handleRange}
              {...this.state} {...this.props}/>
        </div>
    );
  }
}

export default Research
