import React from "react";
import dictionary from "../../lang";
import { getValueByPath } from "../../utils"
import {compose} from "redux";
import {connect} from "react-redux";

const LangHOC = (WrappedComponent) => {
  return class extends React.Component {
    translate = (path) => {
      const translate = dictionary[this.props.currentLanguage];
      return getValueByPath(translate, path, 'translate missing')
    };
    render() {
      return <WrappedComponent {...this.props} t={this.translate}/>;
    }
  }
};

const mapStateToProps = state => ({
  currentLanguage: state.common.currentLanguage
});

const composeLangHOC = compose(
    connect(mapStateToProps, null),
    LangHOC
);

export default composeLangHOC
