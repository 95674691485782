import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../../parts/Title";
import BalancePoints from "./BalancePoints";
import CollectedPoints from "./CollectedPoints";
import Rewards from "./Rewards";
import {getProfileData} from "../../../../actions/profileActions";
// import {setMe} from "../../../../actions/auth";

class Points extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsToShowCollectPoints: 6,
      itemsToShowRewards: 3,
      expandPoints: false,
      expandRewards: false,
      user: {
        balance: 0,
        points: [],
        rewards: []
      }
    };
  }

  componentDidMount() {
    // this.props.setMe();
    // const user = localStorage.getItem("user.me")
    //     ? JSON.parse(localStorage.getItem("user.me"))
    //     : this.state.user;
    // this.setState({
    //   user: user
    // })
    this.props.getProfileData()
        .then(response => {
          this.setState({
            user: {...this.state.user, ...response}
          })
        })
        .catch(error => {
        })
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.props.meInfo && this.props.meInfo !== prevProps.meInfo) {
    //   const user = localStorage.getItem("user.me")
    //       ? JSON.parse(localStorage.getItem("user.me"))
    //       : this.state.user;
    //   this.setState({
    //     user: user
    //   })
    // }
  }

  showMoreCollected = () => {
    this.setState({
      expandPoints: !this.state.expandPoints
    })
  };

  showMoreRewards = () => {
    this.setState({
      expandRewards: !this.state.expandRewards
    })
  };

  render() {
    const {user} = this.state;
    return (
        <div className={`points`}>
          <div className={`col`}>
            <Title title={this.props.t('cabinet.points_balance')} className={'pink'} size={208}/>
            <BalancePoints balance={user['balance']} />
            <Title title={this.props.t('cabinet.points_collected')} className={'pink'} size={208}/>
            <CollectedPoints {...this.props} {...this.state} showMore={this.showMoreCollected} />
          </div>
          <div className={`col`}>
            <Title title={this.props.t('cabinet.points_rewards')} className={'pink'} size={208}/>
            <Rewards {...this.props} {...this.state} showMore={this.showMoreRewards} />
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  // meInfo: state.auth.meInfo
});

const mapDispatchToProps = dispatch => ({
  // setMe: () => dispatch(setMe()),
  getProfileData: () => dispatch(getProfileData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Points);
