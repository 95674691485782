import React from "react";
import Title from "../../../parts/Title";

const PopupMobileSuccess = (props) => {
  const {closePopup} = props;
  return (
      <div className={`shop-popup`}>
        <div className={`shop-popup__close`} onClick={closePopup}></div>
        <div className={`container`}>
          <div className={`shop-popup__wrap`}>
            <Title title={`${props.refill_value} ${props.t('shop.prize_type_mobile')}`} className={`white`} size={208}/>
            <p className={`shop-popup__text`}>{props.t('shop.success.text0')}</p>
            <p className={`shop-popup__text`}>{props.t('shop.success.text1')}</p>
            <p  className={`shop-popup__text`}>{props.t('shop.success.text2')}</p>
          </div>
        </div>
      </div>
  )
};

export default PopupMobileSuccess
