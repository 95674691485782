import { getIsSurveyAvailable } from "../api/requests";
import { useEffect, useMemo, useState } from "react";
import { SURVEY_UNAVAILABLE_STATUS } from "../constants/Tasks";

export const useIsSurveyAvailable = (id) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [status, setStatus] = useState();
  const [isAvailable, setIsAvailable] = useState(false);

  const shouldShowPopup = useMemo(() => {
    if (!status) return false;

    return (
      status ===
        SURVEY_UNAVAILABLE_STATUS.completed_the_available_number_of_surveys ||
      status === SURVEY_UNAVAILABLE_STATUS.id_need_approve
    );
  }, [status]);

  useEffect(() => {
    if (!id) return;

    setIsLoading(true);
    getIsSurveyAvailable(id)
      .then((res) => {
        setIsAvailable(res?.data?.data === true);

        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response?.status !== 403) setIsError(true);
        else setStatus(err.response?.data?.error);
        setIsLoading(false);
        console.dir(err);
      });
  }, [id]);

  return {
    isAvailable,
    status,
    shouldShowPopup,
    isError,
    isLoading,
  };
};
