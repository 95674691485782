import React from "react"
import {Route, Redirect} from "react-router-dom"
import {connect} from "react-redux"

import {checkLogin} from "../../actions/auth"
import {currentHistoryRoute} from "../../actions/common";

const PrivateRoute = ({component: Component, redirectUrl, ...rest}) => {
  const isLogged = rest.dispatch(checkLogin());

  if(redirectUrl) {
    window.location.href = redirectUrl
  }
  else if (isLogged) {
    return <Route {...rest} render={(props) => <Component {...props} />}/>
  } else {
    rest.dispatch(currentHistoryRoute(rest.location.pathname));
    return <Redirect to={'/signin'}/>
  }
};

export default connect()(PrivateRoute);
