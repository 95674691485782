import React, {Component} from 'react'
import PropTypes from 'prop-types';

class Button extends Component {
  static propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    handleClick: PropTypes.func,
    value: PropTypes.string
  };

  static defaultProps = {
    type: "button",
    className: "",
    disabled: false
  };

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    return (
        <button
            type={this.props.type}
            disabled={this.props.disabled}
            className={`btn ${this.props.className}`}
            onClick={this.props.handleClick}
            value={this.props.value}>{this.props.children}</button>
    )
  }
}

export default Button;
