import React from "react";
import Form from "../../../../form/Form";
import Radio from "../../../../form/Radio";
import FormBtns from "../Survey/parts/FormBtns";
import Checkbox from "../../../../form/Checkbox";
import square_icon_design from "../../../../../../images/open-tasks/survey/square_icon_design.svg";
import square_icon_center_hole from "../../../../../../images/open-tasks/survey/square_icon_center_hole.svg";
import square_icon_camel from "../../../../../../images/open-tasks/survey/square_icon_camel.svg";
import pres_img from "../../../../../../images/open-tasks/survey/camel_compact.png";
import {replaceAllStrBySymbol} from "../../../../../utils/index";

const SurveyQuestions = (props) => {
  return (
      <Form handleSubmit={props.setQuizSubmit} serverErrorQuantity={props.serverErrorQuantity}>
        <div className={`survey-form__col`}>
          {
            props.questions && Array.isArray(props.questions) && props.questions.length > 0 && (
                <React.Fragment>
                  {props.questions[0] && [props.questions[0]].map((el) => {
                    return (
                        <div
                            className={`survey-form__field radio-btn ${props.errorheard ? 'error' : ''}`}
                            key={el['question_id']}>
                          <p className={`survey-form__label`}>
                            <span className={`survey-form__count-label`}>1</span>
                            {el.description}
                          </p>
                          <div className={`survey-form__presentation-image`}>
                            <img src={pres_img} alt="presentation"/>
                          </div>
                          <Radio name={'heard'}
                              className={`radio`}
                                 onChange={(e) => props.handleToggleQuiz(e, el['question_id'])}
                          >
                            {el['answers'].sort((a, b) => {
                              return a.id - b.id
                            }).map((answer, i) => {
                              return <option key={answer.id}
                                             value={answer.id}
                                             id={i === 0 ? "heard_yes" : "heard_no"}
                                             label={answer.name}/>
                            })}
                          </Radio>
                          <div className={'error-element'}>{props.errorheard}</div>
                        </div>
                    )
                  })}

                  {(props.heard && props.heard.answer_text === 'heard_yes') && props.questions[1] && [props.questions[1]].map((el) => {
                    return (
                        <div
                            className={`survey-form__field radio-btn ${props.errortried ? 'error' : ''}`}
                            key={el['question_id']}>
                          <p className={`survey-form__label`}>
                            <span className={`survey-form__count-label`}>2</span>
                            {el.description}
                          </p>
                          <Radio name={'tried'}
                                 className={`radio`}
                                 onChange={(e) => props.handleToggleQuiz(e, el['question_id'])}
                          >
                            {el['answers'].sort((a, b) => {
                              return a.id - b.id
                            }).map((answer, i) => {
                              return <option key={answer.id}
                                             value={answer.id}
                                             id={i === 0 ? "tried_yes" : "tried_no"}
                                             label={answer.name}/>
                            })}
                          </Radio>
                          <div className={'error-element'}>{props.errortried}</div>
                        </div>
                    )
                  })}

                  {(props.heard && props.tried && props.tried.answer_text === 'tried_yes') && props.questions[2] && [props.questions[2]].map((el) => {
                    return (
                        <div
                            className={`survey-form__field ${props.errorCheckbox0 ? 'error' : ''}`}
                            key={el['question_id']}>
                          <p className={`survey-form__label specific`}>
                            <span className={`survey-form__count-label`}>3</span>
                            {/*{el.description}*/}
                            <span dangerouslySetInnerHTML={{__html: replaceAllStrBySymbol(el.description, '(', ')')}}></span>
                          </p>
                          <div className={`survey-form__multi-checkbox`}>
                            {el['answers'].sort((a, b) => {
                              return a.id - b.id
                            }).map((answer, i) => {
                              return <Checkbox key={answer.id} id={answer.id}
                                               name={'Checkbox0'}
                                               label={answer.name}
                                               onChange={(e) => props.handleCheckConsider(e, el['question_id'])}/>
                            })}
                          </div>
                          <div className={'error-element'}>{props.errorCheckbox0}</div>
                        </div>
                    )
                  })}

                  {(props.heard && (props.tried && props.tried.answer_text === 'tried_yes')) && props.questions[3] && [props.questions[3]].map((el) => {
                    return (
                        <div
                            className={`survey-form__field radio-btn ${props.errorRadio0 ? 'error' : ''}`}
                            key={el['question_id']}>
                          <p className={`survey-form__label`}>
                            <span className={`survey-form__count-label`}>
                              4
                            </span>
                            {el.description}
                          </p>
                          <div className={`survey-form__choice`}>
                            <div className={`survey-choice`}>
                              <span className={`survey-choice__img`}>
                                <img src={square_icon_camel} alt="choice icon"/>
                              </span>
                              <p className={`survey-choice__text`}>{props.t('open_task.sobranie.char37')}</p>
                            </div>

                            <div className={`survey-choice`}>
                              <span className={`survey-choice__img`}>
                                <img src={square_icon_design} alt="choice icon"/>
                              </span>
                              <p className={`survey-choice__text`}>{props.t('open_task.sobranie.char38')}</p>
                            </div>

                            <div className={`survey-choice`}>
                              <span className={`survey-choice__img`}>
                                <img src={square_icon_center_hole} alt="choice icon"/>
                              </span>
                              <p className={`survey-choice__text`}>{props.t('open_task.sobranie.char36')}</p>
                            </div>
                          </div>
                          <Radio name={'Radio0'}
                                 className={`radio`}
                                 onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                          >
                            {el['answers'].sort((a, b) => {
                              return a.id - b.id
                            }).map((answer) => {
                              return <option key={answer.id}
                                             value={answer.id}
                                             id={answer.id}
                                             label={answer.name}/>
                            })}
                          </Radio>
                          <div className={'error-element'}>{props.errorRadio0}</div>
                        </div>
                    )
                  })}

                  {(props.heard && (props.heard.answer_text === 'heard_no' || (props.tried && props.tried.answer_text === 'tried_no') )) && props.questions[4] && [props.questions[4]].map((el) => {
                    return (
                        <div
                            className={`survey-form__field radio-btn ${props.errorRadio1 ? 'error' : ''}`}
                            key={el['question_id']}>
                          <p className={`survey-form__label`}>
                            <span className={`survey-form__count-label`}>
                              {props.heard && props.heard.answer_text === 'heard_no' ? 2 : 3}
                            </span>
                            {el.description}
                          </p>
                          <div className={`survey-form__choice`}>
                            <div className={`survey-choice`}>
                                      <span className={`survey-choice__img`}>
                                        <img src={square_icon_design} alt="choice icon"/>
                                      </span>
                              <p className={`survey-choice__text`}>{props.t('open_task.sobranie.char35')}</p>
                            </div>

                            <div className={`survey-choice`}>
                                      <span className={`survey-choice__img`}>
                                        <img src={square_icon_center_hole} alt="choice icon"/>
                                      </span>
                              <p className={`survey-choice__text`}>{props.t('open_task.sobranie.char36')}</p>
                            </div>

                          </div>
                          <Radio name={'Radio1'}
                                 className={`radio`}
                                 onChange={(e) => props.handleToggleRadio(e, el['question_id'])}
                          >
                            {el['answers'].sort((a, b) => {
                              return a.id - b.id
                            }).map((answer) => {
                              return <option key={answer.id}
                                             value={answer.id}
                                             id={answer.id}
                                             label={answer.name}/>
                            })}
                          </Radio>
                          <div className={'error-element'}>{props.errorRadio1}</div>
                        </div>
                    )
                  })}

                </React.Fragment>
            )
          }
        </div>
        {props.questionsError && <div className={'error-element'}>{props.t('open_task.sobranie.global_error')}</div>}
        {props.serverSubmitAnswersError &&
        <div className={'error-element'}>{props.t('open_task.sobranie.global_submit_answer_error')}</div>}
        <FormBtns {...props} />
      </Form>
  );
};

export default SurveyQuestions;
