import React, {Component} from "react";
import SurveyTask from "../parts/SurveyTask";
import Done from "../Survey/parts/Done";
import defaultAxios from "../../../../../axios";
import debounce from 'lodash/debounce';
import ErrorResponse from "../../../PageNotFound/ErrorResponse";
import SurveyQuestions from "./SurveyQuestions";

class Research extends Component {
  state = {
    step: 0,
    loading: false,
    questions: [],
    heard: null,
    errorheard: null,
    tried: null,
    errortried: null,
    checkedCheckbox0: [],
    radioArr: [],
    serverErrorQuantity: 0,
    serverQuestionsError: false,
    questionsError: false,
    serverSubmitAnswersError: false
  };

  componentDidMount() {
    this.getQuizQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.getQuizQuestions();
    }
  }

  getQuizQuestions = () => {
    const lang = this.props.currentLanguage;
    defaultAxios.get(`/api/survey-search-tasks/v2?survey_type=survey_w_237&lang=${lang}`, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
      .then(res => {
        this.setState({
          questions: res.data.data.questions
        })
      })
      .catch(err => {
        this.setState({serverQuestionsError: true})
      })
  };

  setQuizSubmit = debounce((formData) => {
    const fd = new FormData();
    const {radioArr, heard, tried, checkedCheckbox0} = this.state;
    fd.append('survey_type', 'survey_w_237');
    let invalid = null;

    if (heard && heard.hasOwnProperty('answer_text') && heard.hasOwnProperty('question_id')) {
      fd.append(`${heard.question_id}[${heard.answer_id}]`, heard.answer_id);
    } else if (heard === null) {
      this.setState({
        errorheard: this.props.t('open_task.sobranie.choice_error')
      });
      invalid = true
    }

    if (heard && heard.answer_text === 'heard_yes') {
      if (tried && tried.hasOwnProperty('question_id') && tried.hasOwnProperty('answer_id')) {
        fd.append(`${tried.question_id}[${tried.answer_id}]`, tried.answer_id)
      } else if (tried === null) {
        this.setState({
          errortried: this.props.t('open_task.sobranie.choice_error')
        });
        invalid = true
      }

      if (tried) {
        if (tried.hasOwnProperty('answer_id') && tried.answer_text === 'tried_yes') {
          if (checkedCheckbox0.length > 0) {
            [...checkedCheckbox0].sort((a, b) => {
              return a.answer_id - b.answer_id
            }).forEach((el) => {
              return fd.append(`${el.question_id}[${el.answer_id}]`, el.answer_id);
            })
          } else if (checkedCheckbox0.length === 0) {
            this.setState({
              errorCheckbox0: this.props.t('open_task.sobranie.choice_error')
            });
            invalid = true
          }

          radioArr.sort((a, b) => {
            return Object.values(a)[0].question_id - Object.values(b)[0].question_id
          }).forEach((value) => {
            for (let j in value) {
              if (value.hasOwnProperty(j)) {
                if (j === 'Radio1') return false;
                return fd.append(`${value[j].question_id}[${value[j].answer_id}]`, value[j].answer_id);
              }
            }
          });

          //radio errors
          if (!radioArr.find(el => el['Radio0'])) {
            this.setState({
              errorRadio0: this.props.t('open_task.sobranie.choice_error')
            });
            invalid = true
          }
        } else if (tried.hasOwnProperty('answer_id') && tried.answer_text === 'tried_no') {
          radioArr.sort((a, b) => {
            return Object.values(a)[0].question_id - Object.values(b)[0].question_id
          }).forEach((value) => {
            for (let j in value) {
              if (value.hasOwnProperty(j)) {
                if (j === 'Radio0') return false;
                return fd.append(`${value[j].question_id}[${value[j].answer_id}]`, value[j].answer_id);
              }
            }
          });

          //radio errors
          if (!radioArr.find(el => el['Radio1'])) {
            this.setState({
              errorRadio1: this.props.t('open_task.sobranie.choice_error')
            });
            invalid = true
          }
        }
      }
    }
    else if (heard && heard.answer_text === 'heard_no') {
      radioArr.sort((a, b) => {
        return Object.values(a)[0].question_id - Object.values(b)[0].question_id
      }).forEach((value) => {
        for (let j in value) {
          if (value.hasOwnProperty(j)) {
            if (j === 'Radio0') return false;
            return fd.append(`${value[j].question_id}[${value[j].answer_id}]`, value[j].answer_id);
          }
        }
      });

      //radio errors
      if (!radioArr.find(el => el['Radio1'])) {
        this.setState({
          errorRadio1: this.props.t('open_task.sobranie.choice_error')
        });
        invalid = true
      }
    }

    // console.log([...fd]);
    // console.log('status', invalid);

    if (invalid) {
      this.setState({questionsError: true});
      return false;
    }
    // return false;
    this.setState({loading: true});
    defaultAxios.post('/api/survey-search-tasks/v2/success', fd, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
      .then(res => {
        window.scrollTo(0, 0);
        this.setState({
          step: 1,
          loading: false
        })
      })
      .catch(err => {
        this.setState({
          loading: false,
          serverSubmitAnswersError: true
        });
      })
  }, 1500, {leading: true, trailing: false});

  handleToggleQuiz = (event, question_id) => {
    const {serverSubmitAnswersError, questionsError, heard} = this.state;
    if (questionsError) {
      this.setState({questionsError: false});
    } else if (serverSubmitAnswersError) {
      this.setState({serverSubmitAnswersError: false});
    }

    if (event.target.name === 'heard' && event.target.id && event.target.id === 'heard_no') {
      this.setState({
        checkedCheckbox0: [],
        errorCheckbox0: null,
        tried: null,
        errortried: null,
        errorRadio0: null,
        radioArr: this.state.radioArr.filter(el => {
          return Object.keys(el)[0] !== 'Radio0'
        })
      })
    } else if (event.target.name === 'heard' && event.target.id && event.target.id === 'heard_yes') {
      this.setState({
        errorRadio1: null,
        radioArr: this.state.radioArr.filter(el => {
          return Object.keys(el)[0] !== 'Radio1'
        })
      });
    } else if (heard && heard.answer_text === 'heard_yes' && event.target.name === 'tried' &&
        event.target.id && event.target.id === 'tried_yes') {
      this.setState({
        errorRadio1: null,
        radioArr: this.state.radioArr.filter(el => {
          return Object.keys(el)[0] !== 'Radio1'
        })
      })
    } else if (heard && heard.answer_text === 'heard_yes' && event.target.name === 'tried' &&
        event.target.id && event.target.id === 'tried_no') {
      this.setState({
        checkedCheckbox0: [],
        errorCheckbox0: null,
        errorRadio0: null,
        radioArr: this.state.radioArr.filter(el => {
          return Object.keys(el)[0] !== 'Radio0'
        })
      })
    }

    this.setState({
      [event.target.name]: {
        'question_id': question_id,
        'answer_id': event.target.value,
        'answer_text': event.target.id
      },
      ['error' + event.target.name]: null
    })
  };

  handleCheckConsider = (e, question_id) => {
    const id = e.target.id;
    let isSelected = e.target.checked;
    const {serverSubmitAnswersError, questionsError} = this.state;

    if (questionsError) {
      this.setState({questionsError: false});
    } else if (serverSubmitAnswersError) {
      this.setState({serverSubmitAnswersError: false});
    }

    if (isSelected) {
      this.setState({
        ['checked' + e.target.name]: [...this.state['checked' + e.target.name], {
          'question_id': question_id,
          'answer_id': id
        }],
        ['error' + e.target.name]: null
      });
    } else {
      this.setState({
        ['checked' + e.target.name]: this.state['checked' + e.target.name].filter(item => id !== item['answer_id'])
      })
    }
  };

  handleToggleRadio = (event, question_id) => {
    let newRadioArr = this.state.radioArr.filter(el => {
      return Object.keys(el)[0] !== event.target.name
    });

    const {serverSubmitAnswersError, questionsError} = this.state;
    if (questionsError) {
      this.setState({questionsError: false});
    } else if (serverSubmitAnswersError) {
      this.setState({serverSubmitAnswersError: false});
    }

    this.setState({
      radioArr: [...newRadioArr, {
        [event.target.name]: {
          'question_id': question_id,
          'answer_id': event.target.value,
          'answer_id_text': event.target.id
        }
      }],
      ['error' + event.target.name]: null
    });
  };

  render() {
    const {step, serverQuestionsError} = this.state;
    // console.log(this.state);
    if (serverQuestionsError) return <ErrorResponse/>;
    return (
      <div className={`survey`}>
        {step === 0 && <SurveyTask
            {...this.state} {...this.props}>
          <SurveyQuestions setQuizSubmit={this.setQuizSubmit}
                           handleToggleQuiz={this.handleToggleQuiz}
                           handleCheckConsider={this.handleCheckConsider}
                           handleToggleRadio={this.handleToggleRadio}
                           {...this.state} {...this.props}/>
        </SurveyTask>}
        {step === 1 && <Done {...this.props}/>}
      </div>
    );
  }
}

export default Research
