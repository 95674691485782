import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Preview from "./preview";
import LangHOC from "../../../HoC/LangHOC";
import UploadDone from "./parts/UploadDone";
import Done from "./parts/Done";
import UploadPhoto from "./uploadPhoto";
import {setTaskStep} from "../../../../actions/taskActions";
import CamelResearch from "./survey/camel/Research";
import SobranieResearch from "./survey/sobranie/Research";
import WinstonResearch from "./survey/winston/Research";

const PhotoPack = (props) => {
  const {taskStep, taskStatus, taskVersion} = useSelector((state) => ({...state.task}));
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState('');

  useEffect(() => {
    return () => {
      dispatch(setTaskStep(null));
    }
  }, []);

  useEffect(() => {
    if (taskStep !== null) {
      setStep(taskStep);
    } else {
      history.push('/tasks');
    }
  }, [taskStep]);

  const changeStep = (step) => {
    window.scrollTo(0, 0);
    setStep(step);
  };

  return (
      <div className={`photo-pack`}>
        {step === 0 && <Preview changeStep={changeStep} step={step} {...props} taskVersion={taskVersion}/>}
        {step === 1 && <UploadPhoto changeStep={changeStep} step={step} {...props} taskStatus={taskStatus}/>}
        {/*{(step === 2 && taskVersion === "combo_v7") && <SobranieResearch changeStep={changeStep} step={step} {...props}/>}*/}
        {(step === 2 && taskVersion === "combo_v10") && <CamelResearch changeStep={changeStep} step={step} {...props}/>}
        {/*{(step === 2 && taskVersion === "combo_v9") && <WinstonResearch changeStep={changeStep} step={step} {...props}/>}*/}
        {step === 'upload-done' && <UploadDone {...props}/>}
        {step === 'done' && <Done {...props}/>}
      </div>
  );
};

export default LangHOC(PhotoPack)
