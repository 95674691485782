import React, { useState, useEffect } from "react";

export default function TexareaV2({
  disabled,
  name,
  maxLength,
  placeholder,
  t,
}) {
  const [value, setValue] = useState("");
  useEffect(() => {
    if (!disabled) return;
    if (disabled) {
      setValue("");
    }
  }, [disabled]);

  return (
    <div className={"textarea-field"}>
      <textarea
        type="text"
        onChange={(e) => setValue(e.target.value)}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
        name={name}
        autoFocus
      ></textarea>
      {!disabled && value.trim().length === 0 && (
        <p>{t("open_task.sobranie.textarea_error")}</p>
      )}
    </div>
  );
}
