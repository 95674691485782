import React from "react"
// import {Link} from "react-router-dom"
import {connect} from "react-redux"

const PageNotFound = (props) => {
  return (
      <div className={"not-found-page"}>
        <p>Resource is not available</p>
        {/*<Link className="link-default" to={"/"}>Back</Link>*/}
      </div>
  )
};

export default connect()(PageNotFound);
