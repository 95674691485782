import React from "react";
import {Link} from "react-router-dom";
import Form from "../../../form/Form";
import Checkbox from "../../../form/Checkbox";
import Button from "../../../form/Button";

const DescriptionFormBtns = (props) => {
  return (
      <Form className={'open-tasks-form'} handleSubmit={props.handleAcceptTask}>
        <Checkbox name={'rules_confirmation'}
                  id={'rules_confirmation'}
                  label={props.t('open_task.checkbox_label')}
                  validate={[{
                    required: true,
                    message: 'This field required'
                  }]}/>
        <div className={`open-tasks__desc-btns`}>
          <Button className={`btn--blue`}><Link to={{pathname: "/tasks"}}>{props.t('btns.keep_browser')}</Link></Button>
          <Button type={`submit`}>{props.type === 'photo-id' ? props.t('btns.upload_photo') : props.t('btns.accept_task')}</Button>
        </div>
      </Form>
  );
};

export default DescriptionFormBtns;
