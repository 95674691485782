import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Form from "../../../form/Form";
import Input from "../../../form/Input";
import NumberFormatInput from "../../../form/NumberFormatInput";
import DatePickerInput from "../../../form/DatePicker";
import Dropdown from "../../../form/Dropdown";
import Button from "../../../form/Button";
import Title from "../../../parts/Title";
import {getProfileData} from "../../../../actions/profileActions";
import {getCigaretteBrandList, getCigaretteFormatList} from "../../../../actions/cigaretteActions";
import {getCities} from "../../../../actions/citiesActions";
import defaultAxios from "../../../../axios";
import debounce from 'lodash/debounce';
import {Link} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {googleRecaptchaKey} from "../../../../constants/Variables";

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captcha: null,
      cityList: [],
      brandList: [],
      formatList: [],
      pidError: {},
      emailError: {},
      phoneError: {},
      user: {
        firstname: null,
        lastname: null,
        pid: null,
        email: null,
        phone: null,
        birth_date: null,
        gender: null,
        cigarette_brand_id: null,
        cigarette_format_id: null,
        city: null,
        phone_confirmed: null,
        phone_activated: null,
        imageIdModerationStatus: null
      },
      showField: false,
      loading: false,
      loadingValidateBtn: false
    };
    this.handleShowValidateField = debounce(this.handleShowValidateField, 1000, {leading: true, trailing:false});
    this.handleSubmit = debounce(this.handleSubmit, 1000, {leading: true, trailing:false});
    this.recaptchaRef = React.createRef();
  }

  componentDidMount() {
    this.props.getCities();
    this.props.getCigaretteBrandList();
    this.props.getCigaretteFormatList();
    this.props.getProfileData()
        .then(response => {
          this.setState({
            user: {...this.state.user, ...response}
          })
        })
        .catch(error => {
        })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.brandList !== prevProps.brandList) {
      this.setState({
        brandList: this.props.brandList
      });
    }
    if (this.props.formatList !== prevProps.formatList) {
      this.setState({
        formatList: this.props.formatList
      });
    }
    if (this.props.cities !== prevProps.cities) {
      this.setState({
        cityList: this.props.cities
      });
    }
  }

  handleSubmit = (formData) => {
    this.setState({loading: true});
    let fd = {};
    formData.forEach(function(value, key){
      if (key === "phone") {
        value = value.substring(5).replace(/[-_]/g, '');
      } else if (key === "cigarette_brand_id" && !value) {
        return
      } else if (key === "cigarette_format_id" && !value) {
        return
      }
      fd[key] = value;
    });
    defaultAxios.post('/api/v2/cabinet/user-cabinet-update', fd, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
        .then(response => {
          this.setState({loading: false})
          this.props.getProfileData()
              .then(response => {
                this.setState({
                  user: {...this.state.user, ...response}
                })
              })
              .catch(error => {
              })
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty('email')) {
              this.setState({
                'emailError': {'email': this.props.t('errors.email_used')}
              })
            } else if (error.response.data.errors.hasOwnProperty('phone')) {
              this.setState({
                'phoneError': {'phone': this.props.t('errors.phone_used')}
              })
            } else if (error.response.data.errors.hasOwnProperty('pid')) {
              this.setState({
                'pidError': {'pid': this.props.t('errors.pid_used')}
              })
            }
          }
          this.setState({loading: false})
        })
  };

  handleChangeField = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: {}
    })
  };

  handleChangePhone = (value) => {
    this.setState({
      phone: value,
      phoneError: {}
    })
  };

  handleShowValidateField = async () => {
    this.setState({loadingValidateBtn: true});
    const asyncTokenCaptha = await this.recaptchaRef.current.executeAsync();
    let newCaptha = asyncTokenCaptha || this.state.captcha;
    const locale = localStorage.getItem('language') || "en";
    let fd = new FormData();
    fd.append('locale', locale);
    fd.append('confirmation_phone', this.state.user.phone);
    fd.append('g-recaptcha-response', newCaptha);
    if (newCaptha) {
      defaultAxios.post('/api/front/v2/create-user', fd, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
          'Accept': 'application/json'

        }
      })
          .then(response => {
            this.recaptchaRef.current.reset();
            this.setState({
              showField: true,
              loadingValidateBtn: false
            })
          })
          .catch(error => {
            this.recaptchaRef.current.reset();
            this.setState({loadingValidateBtn: false})
          })
    } else {
      this.recaptchaRef.current.reset();
      this.setState({loadingValidateBtn: false})
    }
  };

  handleChangeCaptcha = (value) => {
    this.setState({
      captcha: value
    })
  };

  handleUpdatePhone = (validStatus) => {
    this.props.openPopup(validStatus)
  };

  render() {
    const {user, showField} = this.state;
    const {t} = this.props;
    return (
        <>
          <Title title={t('cabinet.profile_title')} className={'pink'} size={208}/>
          <Form handleSubmit={this.handleSubmit}>
            <Input name={'firstname'}
                   validate={[{required: true,
                     message: t('errors.firstname')}]}
                   placeholder={t('cabinet.profile.firstname')}
                   disabled={!!user.firstname}
                   value={user.firstname}/>
            <Input name={'lastname'}
                   validate={[{required: true,
                     message: t('errors.lastname')}]}
                   placeholder={t('cabinet.profile.lastname')}
                   disabled={!!user.lastname}
                   value={user.lastname}/>
            <Input name={'pid'}
                   validate={[{required: true,
                     message: t('errors.pid')}]}
                   placeholder={t('cabinet.profile.pid')}
                   onChange={this.handleChangeField}
                   serverErrors={this.state.pidError}
                   disabled={!!user.pid}
                   icon={user.imageIdModerationStatus && user.imageIdModerationStatus === 'Verified'?'success':'warning'}
                   value={user.pid}/>
            <Input name={'email'}
                   validate={[
                     {required: true, message: t('errors.email')},
                     {email: true, message: t('errors.email')}
                   ]}
                   placeholder={t('cabinet.profile.email')}
                   onChange={this.handleChangeField}
                   serverErrors={this.state.emailError}
                   value={user.email}/>
            <NumberFormatInput name={'phone'}
                               mask="_"
                               format={'+995 ###-##-##-##'}
                               onChange={this.handleChangePhone}
                               serverErrors={this.state.phoneError}
                               allowEmptyFormatting
                               validate={[{required: true, message: t('errors.phone')}]}
                               placeholder={t('cabinet.profile.phone')}
                               icon={user.phone_activated && user.phone_activated == '1'?'success':'warning'}
                               iconRetry
                               handleIcon={()=>this.handleUpdatePhone(false)}
                               disabled={!!user.phone}
                               value={user.phone}/>
            {(user.hasOwnProperty('phone_activated') && user.phone_activated == '0') && <div className={`profile-validate`}>
              {/*{showField ? (*/}
                  {/*<Input name="validation_code"*/}
                         {/*placeholder='Code from SMS'*/}
                         {/*maxLength={6}*/}
                         {/*validate={[*/}
                           {/*{required: true, message: t('errors.verification_code')},*/}
                           {/*{minLength: 6, message: t('errors.code_field')}*/}
                         {/*]}*/}
                  {/*/>*/}
              {/*) : (*/}
                  <Fragment>
                    <p className={`profile-validate__text`}>{t('cabinet.profile.validate_text')}</p>
                    <Button handleClick={()=>this.handleUpdatePhone(true)} disabled={this.state.loadingValidateBtn}>Validate</Button>
                  </Fragment>
              {/*)}*/}
            </div>}
            <div className="auth__captcha-wrapper">
              <ReCAPTCHA
                  sitekey={googleRecaptchaKey}
                  onChange={this.handleChangeCaptcha}
                  ref={this.recaptchaRef}
                  size="invisible"
              />
            </div>
            <DatePickerInput name={'birth_date'}
                             validate={[
                               {required: true,
                                 message: t('errors.birth_date')},
                               {under18: true,
                                 message: t('errors.18old')}
                             ]}
                             placeholder={t('cabinet.profile.birthdate')}
                             icon={true}
                             disabled={!!user.birth_date}
                             date={user.birth_date}/>
            <Dropdown name={'gender'}
                      validate={[{required: true,
                        message: t('errors.gender')}]}
                      dataOptions={[
                        {value: 'm', label: t('signup.male')},
                        {value: 'f', label: t('signup.female')}
                      ]}
                      placeholder={t('cabinet.profile.gender')}
                      value={user.gender ? user.gender == 'm' ? {'label': 'Male', 'value': 'm'}
                          : {'label': 'Female', 'value': 'f'} : null}/>
            <Dropdown name={'cigarette_brand_id'}
                      dataOptions={this.state.brandList}
                      placeholder={t('cabinet.profile.brand')}
                      minMenuHeight={200}
                      menuHeight={200}
                      value={user.cigarette_brand_id ? {label: user.cigarette_brand_id.brand, value: user.cigarette_brand_id.id}: null}/>
            <Dropdown name={'cigarette_format_id'}
                      dataOptions={this.state.formatList}
                      placeholder={t('cabinet.profile.format')}
                      minMenuHeight={200}
                      menuHeight={200}
                      value={user.cigarette_format_id ? {label: user.cigarette_format_id.name, value: user.cigarette_format_id.id}: null}/>
            <Dropdown name={'city_id'}
                      validate={[{required: true,
                        message: t('errors.city')}]}
                      dataOptions={this.state.cityList}
                      placeholder={t('cabinet.profile.city')}
                      minMenuHeight={200}
                      menuHeight={200}
                      value={user.city?{label: user.city.name, value: user.city.id}: null}/>
            {(user.status && !user.imageIdModerationStatus) &&
            <div className={`notify`}>
              <p>{t('cabinet.profile.verified_photo_msg0')}</p>
              <p>
                {t('cabinet.profile.verified_photo_msg1')}
                <Link to={{pathname: "/tasks"}}>{t('cabinet.profile.verified_photo_msg_link')}</Link>
                {t('cabinet.profile.verified_photo_msg2')}</p>
            </div>
            }

            <div className={`profile-submit`}>
              <Button type={'submit'} disabled={this.state.loading}>{t('btns.save')}</Button>
            </div>
          </Form>
        </>
    );
  }
}

const mapStateToProps = state => ({
  cities: state.cities.cities,
  brandList: state.cigarette.brandList,
  formatList: state.cigarette.formatList
});

const mapDispatchToProps = dispatch => ({
  getCities: () => dispatch(getCities()),
  getCigaretteBrandList: () => dispatch(getCigaretteBrandList()),
  getCigaretteFormatList: () => dispatch(getCigaretteFormatList()),
  getProfileData: () => dispatch(getProfileData())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
