import React from 'react';

const Rewards = (props) => {
  const itemShow = props.expandRewards ? props.user['rewards'].length : props.itemsToShowRewards;
  return (
      <div className={`points-reward`}>
        <div className={`cabinet-list`}>
          {props.user['rewards'] && props.user['rewards'].length > 0 && props.user['rewards'].sort((a, b) => {
            return  new Date(b.created_at) - new Date(a.created_at)
          }).slice(0, itemShow).map((item, i) => {
            return (
                <div className={`cabinet-list__item`} key={i}>
                  <div className={`cabinet-list__col`}>
                    <span className={`points-reward__points`}>-{item['points_spent']}</span>
                  </div>
                  <div className={`cabinet-list__col`}>
                    <div className={`points-reward__row`}>
                      <span className={`points-reward__title`}>{props.t('cabinet.rewards.reward')}: </span>{item.prize['prize_name']}
                    </div>
                    <div className={`points-reward__row`}>
                      <span className={`points-reward__title`}>{props.t('cabinet.rewards.ordered')}: </span>{item['created_at']}
                    </div>
                    {item.prize['prize_name'].search(/voucher/) != -1 ? <div className={`points-reward__row`}>
                      <span className={`points-reward__title`}>{props.t('cabinet.rewards.valid')}: </span>{item['expired_by']}
                    </div> : null}
                    {item.prize['prize_name'].search(/voucher/) != -1 ?
                        (<div className={`points-reward__row`}><span
                            className={`points-reward__title`}>{props.t('cabinet.rewards.code')}: </span>{item['code']}</div>)
                        :  null}
                    {/*<div className={`points-reward__row`}><span*/}
                        {/*className={`points-reward__title`}>Status: </span>not activated*/}
                    {/*</div>*/}
                  </div>
                </div>
            )
          })}
        </div>
        <div className={`points-more`}>
          <hr/>
          <span className={`more ${props.expandRewards?'active':''}`}
                onClick={props.showMore}>{props.t('cabinet.expand')}<span className={`triangle`}></span>
          </span>
        </div>
      </div>
  )
}

export default Rewards
