import pres_3 from "../../../../../../images/surv-3.svg";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIsSurveyAvailable } from "../../../../../hooks/useIsSurveyAvailable";
import { useHistory } from "react-router-dom";
import { shortSurveyUrl } from "../../../../../constants/path";

const ShortSurveyCard = ({ survey, popupHandler, setServerSurveyError, t }) => {
  const history = useHistory();

  const {
    isAvailable,
    shouldShowPopup: shouldShowPopupOnClick,
    status,
    isError: isCheckSurveyAvailableError,
  } = useIsSurveyAvailable(survey?.id);

  useEffect(() => {
    setServerSurveyError(isCheckSurveyAvailableError);
  }, [isCheckSurveyAvailableError, setServerSurveyError]);

  const clickHandler = () => {
    if (shouldShowPopupOnClick) popupHandler(status, survey.points);
    else history.push(shortSurveyUrl);
  };

  return (
    <button
      className={`card blue`}
      onClick={clickHandler}
      disabled={!survey || (!isAvailable && !shouldShowPopupOnClick)}
    >
      <div className={`card__wrap`}>
        <div className={`card__content`}>
          <p className={`card__title`}>
            {t("main_page.logged.section2.cardTitle1")}
          </p>
          <p className={`card__text`}>
            {t("main_page.logged.section2.cardText1")}
          </p>
          {!!survey?.points && (
            <p className={`card__reward`}>
              <strong>+{survey?.points}</strong> {t("main_page.tasks.points")}
            </p>
          )}
        </div>
        <div className={`card__image`}>
          <img src={pres_3} alt="" />
        </div>
      </div>
    </button>
  );
};

ShortSurveyCard.propTypes = {
  survey: PropTypes.object,
  popupHandler: PropTypes.func.isRequired,
  setServerSurveyError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ShortSurveyCard;
