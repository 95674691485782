import React, {Component}  from "react";
import debounce from 'lodash/debounce';
import Title from "../../parts/Title";
import ShopChoose from "./ShopChoose";
import PopupVoucher from "./Popup/PopupVoucher";
import PopupMobile from "./Popup/PopupMobile";
import PopupMobileSuccess from "./Popup/PopupMobileSuccess";
import PopupMobileAttention from "./Popup/PopupMobileAttention";
import {setUserRulesConfirm, setWrapperClassName} from "../../../actions/common";
import {connect} from "react-redux";
import LangHOC from "../../HoC/LangHOC";
import defaultAxios from "../../../axios";
import PopupPhotoIdAttention from "./Popup/PopupPhotoIdAttention";
import {getProfileData} from "../../../actions/profileActions";
import PopupLimit from "./Popup/PopupLimit";

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popup: false,
      prizes: null,
      user_points: null,
      prize_id: null,
      voucher_code: null,
      isMobile: false,
      refill_value: null,
      voucher_value: null,
      loading: false,
      type_value: null,
      user: {
        IdUrl: null
      },
      errMsg: null
    };
    this.handleSubmit = debounce(this.handleSubmit, 1500, {leading: true, trailing:false});
  }

  componentDidMount() {
    const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
    if (currentUser && currentUser.hasOwnProperty('rules_v2') && currentUser['rules_v2'] === "0") {
      this.props.setUserRulesConfirm(true);
    }
    this.props.getProfileData()
      .then(response => {
        this.setState({
          user: {...this.state.user, ...response}
        })
      })
      .catch(error => {});
    this.getPrize();
    this.setState({
      isMobile: window.innerWidth <= 767
    })
    window.addEventListener('resize', this.checkMobile);
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
    window.removeEventListener('resize', this.checkMobile);
  }

  checkMobile = () => {
    this.setState({
      isMobile: window.innerWidth <= 767
    })
  };

  getPrize = () => {
    defaultAxios.get('/api/shop/v2', {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
        .then(res => {
          this.setState({
            prizes: res.data.data.prizes,
            user_points: res.data.data.user_points
          })
        })
        .catch(err=>{})
  };

  orderMobileHandler = (prize_id, popup, value, type_value) => {
    if (this.state.user && (this.state.user.IdUrl === 'not provided' || this.state.user.IdUrl === null) && !this.state.user.imageIdModerationStatus) {
      this.openPopup('attention-photoId');
      return false;
    } else if (this.state.user && this.state.user.phone_activated != '1') {
      this.openPopup('attention');
      return false;
    }
    this.openPopup(popup);
    this.setState({
      type_value,
      prize_id,
      refill_value: value
    })
  };

  openPopup = (popup) => {
    if (popup === this.state.popup) return false;

    this.setState({
      popup: popup
    }, () => {
      this.props.setWrapperClassName("open-popup");
    })
  };

  closePopup = () => {
    this.setState({
      popup: false,
      prize_id: null,
      voucher_code: null
    }, () => {
      this.props.setWrapperClassName("");
    })
  };

  handleSubmit = (formData, prize_id, popup, voucher_value, type_value) => {
    const {user} = this.state;
    if (user && (user.IdUrl === 'not provided' || user.IdUrl === null) && !user.imageIdModerationStatus) {
      this.openPopup('attention-photoId');
      return false;
    } else if (this.state.user && this.state.user.phone_activated != '1') {
      this.openPopup('attention');
      return false;
    }
    this.setState({loading: true, errMsg: null, type_value: this.state.type_value || type_value});
    let fd = new FormData();
    fd.append("prize_id", this.state.prize_id || prize_id);
    // if (formData.get && formData.get('phone')) {
    //   fd.append("phone", formData.get('phone').substring(5).replace(/[-_]/g, ''));
    // }
    defaultAxios.post('/api/shop/v2', fd, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
        .then(res => {
          if (res.status === 203) {
            if (res.data.errors.includes('w2g validation false')) {
              this.openPopup('attention');
            }
          } else if (popup && popup === 'voucher') {
            if (res.data &&  res.data.data && res.data.data.code) {
              this.setState({
                voucher_code: res.data.data.code,
                voucher_value: voucher_value
              }, ()=>this.openPopup('voucher'))

            }
          } else {
            this.openPopup('success');
          }
          this.getPrize();
          this.setState({loading: false, type_value: null});
        })
        .catch(err=>{
          if (err.response && err.response.status === 404 && err.response.data && err.response.data.errors) {
            if (err.response.data.errors.some(function(v){ return v.toLowerCase().indexOf("this month limit has been exhausted")>=0 })) {
              this.openPopup('limit');
            }
          } else {
            this.setState({errMsg: true});
          }
          this.setState({loading: false, type_value: null});
        })
  };

  render() {
    const {popup} = this.state;
    return (
        <div className={`shop ${popup?popup:''}`}>
          <Title title={this.props.t('shop.title')} size={208}/>
          <ShopChoose openPopup={this.openPopup}
                      orderMobileHandler={this.orderMobileHandler}
                      handleSubmit={this.handleSubmit}
                      {...this.props} {...this.state}/>
          {popup === 'voucher' && <PopupVoucher closePopup={this.closePopup}
                                                voucherCode={this.state.voucher_code}
                                                voucherValue={this.state.voucher_value}
                                                {...this.props}/>}
          {popup === 'mobile' && <PopupMobile closePopup={this.closePopup}
                                              handleSubmit={this.handleSubmit}
                                              {...this.props} {...this.state}/>}
          {popup === 'success' && <PopupMobileSuccess closePopup={this.closePopup}
                                                      {...this.props} {...this.state}/>}
          {popup === 'attention' && <PopupMobileAttention closePopup={this.closePopup} {...this.props}/>}
          {popup === 'limit' && <PopupLimit closePopup={this.closePopup} {...this.props}/>}
          {popup === 'attention-photoId' && <PopupPhotoIdAttention closePopup={this.closePopup}
                                                                  {...this.props}/>}
        </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
  getProfileData: () => dispatch(getProfileData()),
  setUserRulesConfirm: (status) => dispatch(setUserRulesConfirm(status))
});

export default connect(null, mapDispatchToProps)(LangHOC(Shop));
