import React, {Fragment} from 'react';
import Form from "../../../form/Form";
import Button from "../../../form/Button";
import Input from "../../../form/Input";
import NumberFormatInput from "../../../form/NumberFormatInput";
import Dropdown from "../../../form/Dropdown";
import Radio from "../../../form/Radio";
import Title from "../../../parts/Title";
import male_icon from "../../../../../images/auth/male-icon.svg";
import female_icon from "../../../../../images/auth/female-icon.svg";
import Checkbox from "../../../form/Checkbox";

function Step3(props) {
  const {handleSubmit, handleChangePhone, handleChangeField, checkbox_rules, t, openPopup, userConfirmationData} = props;
  const getCityFromList = props.user.city_id ? props.cityList.filter(city => city.value === props.user.city_id) : '';
  let userCity = props.user.city && (typeof props.user.city === 'object' && props.user.city !== null) &&
  props.user.city.hasOwnProperty('id') && props.user.city.hasOwnProperty('name')
      ? {label: props.user.city.name, value: props.user.city.id} : getCityFromList && Array.isArray(getCityFromList) ?
          {label: getCityFromList[0].label, value: getCityFromList[0].value} : '';
  return (
      <Fragment>
        <Title title={`Profile Update`} size={208}/>
        <div className={`signup__wrap`}>
          <div className={`auth__form-wrap`}>
            <Form handleSubmit={handleSubmit}>
              <div className={`auth-row`}>
                <div className={`auth-col`}>
                  <NumberFormatInput name={"phone"}
                                     mask="_"
                                     format={'+995 ###-##-##-##'}
                                     allowEmptyFormatting
                                     onChange={handleChangePhone}
                                     serverErrors={props.phoneError}
                                     validate={[
                                       {
                                         required: true,
                                         message: props.t('errors.phone')
                                       },
                                       {
                                         minLength: 9
                                       }
                                     ]}
                                     disabled={!!props.user.phone}
                                     value={props.user.phone}/>
                  {(!userConfirmationData.token && !!props.user.phone) && <input type="hidden" name="phone" value={props.user.phone}/>}
                </div>
                <div className={`auth-col`}>
                  <Input name="email"
                         placeholder={props.t('signup.email_placeholder')}
                         serverErrors={props.emailError}
                         onChange={handleChangeField}
                         validate={[
                           {required: true,
                             message: props.t('errors.email')},
                           {email: true,
                             message: props.t('errors.email')}
                         ]}
                         // disabled={!!props.user.email}
                         value={props.user.email}
                  />
                </div>
              </div>
              <div className={`auth-row`}>
                <div className={`auth-col`}>
                  <Dropdown name={'city_id'}
                            dataOptions={props.cityList}
                            validate={[{
                              required: true,
                              message: props.t('errors.city')
                            }]}
                            clearable={false}
                            menuHeight={200}
                            placeholder={props.t('signup.city_placeholder')}
                            value={userCity}/>
                </div>
                <div className={`auth-col`}>
                  <Radio name="gender"
                         validate={[{
                           required: true,
                           message: props.t('errors.gender')
                         }]}
                         value={props.user.gender}>
                    <option value="m" id="checkbox_2-1"
                            label={'Male'} icon={<img src={male_icon} alt="male icon"/>}/>
                    <option value="f" id="checkbox_2-2"
                            label={'Female'} icon={<img src={female_icon} alt="female icon"/>}/>
                  </Radio>
                </div>
              </div>
              <div className={`auth-row`}>
                <div className={`auth-col`}>
                  <Input name="password"
                         type={"password"}
                         placeholder={props.t('signup.password_placeholder')}
                         autoComplete={'new-password'}
                         validate={[
                           {required: true,
                             message: props.t('errors.password')},
                           {minLength: 8, message: props.t('errors.password_min')}
                         ]}
                  />
                </div>
                <div className={`auth-col`}>
                  <Input name="password_repeat"
                         type={"password"}
                         placeholder={props.t('signup.repasword_placeholder')}
                         validate={[
                           {required: true,
                             message: props.t('errors.password')},
                           {sameAsField: 'password', message: props.t('errors.password_repeat')}
                         ]}
                  />
                </div>
              </div>
              <div className={`auth-row`}>
                <div className={`checkbox-with-text`}>
                  <Checkbox name={'new_rules_agreement'}
                            id={'rules_confirmation'}
                            validate={[{
                              required: true,
                              message: t('errors.req_field')}]}
                            value={checkbox_rules}
                  />
                  <p dangerouslySetInnerHTML={{__html: t('signup.rules_checkbox')}} onClick={openPopup}></p>
                </div>
              </div>
              <div className={`auth__btns`}>
                <Button type={`submit`}>
                  {props.t('btns.next')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Fragment>
  )
}

export default Step3
