import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../../parts/Title";
import Task from "./Task";
import defaultAxios from "../../../../axios";
import {setTaskStep, setTaskTake, setTaskTakeInfo} from "../../../../actions/taskActions";

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false,
      expandedItems: 4,
      allUserTasks: []
    };
  }

  componentDidMount() {
    this.getAllUserTasks()
  }

  getAllUserTasks = () => {
    defaultAxios.get('/api/tasks/v2/user-tasks', {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
        .then(res => {
          if (res.data && res.data.data && res.data.data.tasks) {
            this.setState({
              allUserTasks: res.data.data.tasks
            })
          }
        })
        .catch(err=>{})
  };

  openTask = (params) => {
    if (params['name_en'].toLowerCase().indexOf('survey') > -1 || params['name_en'] == 'id_upload') {
      return false;
    } else if (params['task_status'] !== 'progress') {
      return false;
    }
    let url = `/api/tasks/v2/one-task?task_type=${params['task_type']}&activity_id=${params['activity_id']}&user_task_id=${params['user_task_id']}`;
    defaultAxios.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
        .then(res => {
          if (res.data.data && res.data.data.task_info &&
              res.data.data.task_info[0] && res.data.data.task_info[0].task_status === 'progress') {
            this.props.setTaskStep(2);
            this.props.setTaskTakeInfo(res.data.data.task_info[0]);
            this.props.setTaskTake(res.data.data.task_questions);
            let path = res.data.data.task_info[0].task_type.toLowerCase();
            this.props.history.push(`/open-tasks/${path}`);
          }
        })
        .catch(err => {})
  };

  toggleMore = () => {
    this.setState({
      expand: !this.state.expand
    })
  };

  render() {
    return (
        <div className={`tasks`}>
          <Title title={'My Tasks'} className={'pink'} size={208}/>
          <Task {...this.state} {...this.props} openTask={this.openTask}/>
          {this.state.allUserTasks.length > 4 && <div className={`points-more`}>
            <hr/>
            <span className={`more ${this.state.expand ? 'active' : ''}`}
                  onClick={this.toggleMore}>{this.props.t('cabinet.expand')}<span className={`triangle`}></span>
            </span>
          </div>}
        </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  setTaskTake: (task) => dispatch(setTaskTake(task)),
  setTaskTakeInfo: (taskInfo) => dispatch(setTaskTakeInfo(taskInfo)),
  setTaskStep: (step) => dispatch(setTaskStep(step))
});

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
