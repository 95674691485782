import Upload from "../../../../../form/Upload";
import Button from "../../../../../form/Button";
import { Link } from "react-router-dom";
import Form from "../../../../../form/Form";
import React, { useRef, useState } from "react";
import { getBase64 } from "../../../../../../utils";
import PropTypes from "prop-types";
import { saveLongSurveyImage } from "../../../../../../api/requests";
import x_icon from "../../../../../../../images/open-tasks/icon-x.png";

const FormUpload = ({ surveyId, defaultImg, onSave, setIsServerError, t }) => {
  const photoIdImg = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [photo, setUploadPhoto] = useState(null);

  const uploadPhotoHandler = (event) => {
    const photoInp = event.target.files[0];
    if ((photoInp.size / (1024 * 1024)).toFixed(2) > 10) {
      setError("Photo may not be greater than 10000 kilobytes");
      return;
    }
    if (photoInp && isValidFileType(photoInp.type)) {
      getBase64(photoInp, (result) => {
        setError(null);
        if (!photoIdImg) return false;
        photoIdImg.current.src = result;
        setUploadPhoto(photoInp);
      });
    } else if (!isValidFileType(photoInp.type)) {
      setError("Photo must be a file of type: jpg, png, jpeg");
    } else {
      if (!photoIdImg) return false;
      photoIdImg.current.src = defaultImg;
    }
  };

  const savePhotoHandler = () => {
    setIsLoading(true);

    const fd = new FormData();
    fd.append("image", photo);

    saveLongSurveyImage(surveyId, fd)
      .then((res) => {
        setIsLoading(false);
        if (res.data.result === true) {
          onSave();
        }
      })
      .catch((err) => {
        console.dir(err);
        if (err.response.status === 422) {
          setIsLoading(false);
          setError("This image we can not upload. Try another one");
          photoIdImg.current.src = defaultImg;
          setUploadPhoto(null);
          return;
        }
        setIsLoading(false);
        setIsServerError(true);
      });
  };

  const removePhotoHandler = () => {
    photoIdImg.current.src = defaultImg;
    setUploadPhoto(null);
  };

  const isValidFileType = (fName) => {
    const extensionImages = ["image/jpeg", "image/png", "image/x-png"];
    return extensionImages.find((el) => el === fName);
  };

  return (
    <>
      <div
        className={`photo-pack-image photo-pack-upload ${
          photo ? "upload" : ""
        }`}
      >
        <div className={`photo-pack-upload__wrap`}>
          <button
            type={`button`}
            className={`photo-pack-upload__close`}
            onClick={removePhotoHandler}
          >
            <img src={x_icon} alt="icon-x" />
          </button>
          <img
            src={defaultImg}
            alt="product"
            ref={photoIdImg}
            className="photo-pack-wrapper__image"
          />
        </div>
      </div>
      <Form handleSubmit={savePhotoHandler} serverErrorQuantity={0}>
        <div className={`photo-pack-form__btns`}>
          {!photo ? (
            <Upload
              name={"pack_img"}
              onChange={uploadPhotoHandler}
              text={t("btns.upload_photo")}
              className={`btn btn--v2`}
            />
          ) : (
            <Button
              className={`btn--blue btn--v2`}
              type={"submit"}
              disabled={isLoading}
            >
              {t("btns.submit")}
            </Button>
          )}
          <Button className={`btn--blue btn--v2`}>
            <Link to={{ pathname: "/tasks" }}>{t("btns.back")}</Link>
          </Button>
          {error && <div className={"error-element"}>{error}</div>}
        </div>
      </Form>
    </>
  );
};

FormUpload.propTypes = {
  defaultImg: PropTypes.string.isRequired,
  surveyId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  setIsServerError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default FormUpload;
