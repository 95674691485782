import React, {Component} from "react"
import debounce from 'lodash/debounce';
import Button from "../../../form/Button";
import {Link} from "react-router-dom";
import presentation from "./../../../../../images/w2g-logo.svg";
import Footer from "../../../parts/Footer";
import {FirstSvgIcon, SecondSvgIcon, ThirdSvgIcon} from "./svgItems";
import Title from "../../../parts/Title";
import Slider from "react-slick";

class FullpageScroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: 0,
      currentSlide: 0,
      isMobile: false,
      maxContent: 1
    };
    this._swipe = {};
    this.minDistanceY = 150;
    this.minDistanceX = 100;
    this.scrollY = debounce(this.scrollY, 400, {leading: true, trailing: false});
    this.timer = null;
  }

  componentDidMount() {
    this.props.setWrapperClassName("main-page-container");

    this.setState({
      isMobile: window.innerWidth <= 767,
      maxContent: window.innerWidth <= 767 ? 2 : 1
    });
    window.addEventListener('wheel', this.scrollY);
    window.addEventListener('resize', this.checkMobile);
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.scrollY);
    window.removeEventListener('resize', this.checkMobile);
    document.body.classList.remove('fullpage-scroll');
    this.props.setWrapperClassName("");
    if (this.timer) clearTimeout(this.timer);
  }

  checkMobile = () => {
    if (window.innerWidth > 767) {
      if (this.state.activeSection === 2) {
        this.setState({
          activeSection: 0
        });
        this.props.setActiveMainSection(0);
      }
    }
    const isMobile = window.innerWidth <= 767;
    this.setState({
      isMobile: isMobile,
      maxContent: isMobile ? 2 : 1
    })
  };

  scrollY = (event) => {
    if (event.wheelDelta > 0 || event.detail < 0) {
      let currentSection = this.state.activeSection <= 0 ? 0 : this.state.activeSection - 1;
      this.props.setActiveMainSection(currentSection);
      this.setState({
        activeSection: currentSection
      })
    } else {
      let currentSection = this.state.activeSection >= this.state.maxContent ? this.state.maxContent : this.state.activeSection + 1;
      this.props.setActiveMainSection(currentSection);
      this.setState({
        activeSection: currentSection
      })
    }
  };

  onTouchStart = (e) => {
    if (window.innerWidth > 991) return false;
    document.body.classList.add('fullpage-scroll');
    const touch = e.touches[0];
    this._swipe = {y: touch.clientY, x: touch.clientX};
  };

  onTouchMove = (e) => {
    if (window.innerWidth > 991) return false;
    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0];
      this._swipe.swiping = true;
    }
  };

  onTouchEnd = (e) => {
    if (window.innerWidth > 991) return false;
    const touch = e.changedTouches[0];
    if ((touch.clientY - this._swipe.y) > this.minDistanceY && Math.abs(this._swipe.x - touch.clientX) < this.minDistanceX) {
      let currentSection = this.state.activeSection <= 0 ? 0 : this.state.activeSection - 1;
      this.props.setActiveMainSection(currentSection);
      this.setState({
        activeSection: currentSection
      })
    } else if ((touch.clientY - this._swipe.y) < -this.minDistanceY && Math.abs(this._swipe.x - touch.clientX) < this.minDistanceX) {
      let currentSection = this.state.activeSection >= this.state.maxContent ? this.state.maxContent : this.state.activeSection + 1;
      this.props.setActiveMainSection(currentSection);
      this.setState({
        activeSection: currentSection
      })
    }
    this._swipe = {};
  };

  render() {
    const {isMobile, activeSection, currentSlide} = this.state;
    const {currentLanguage, t} = this.props;

    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      draggable: true,
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ],
      afterChange: current => this.setState({ currentSlide: current })
    };
    return (
        <div className="full-page-container guest" onTouchStart={this.onTouchStart}
             onTouchMove={this.onTouchMove}
             onTouchEnd={this.onTouchEnd}>

          <div className={`section ${activeSection === 0 ? 'active' : ''}`}>
            <div className={`section-slider`}>
              <div className={`section-logo`}>
                <img src={presentation} alt="presentation"/>
              </div>
              <div className={`section-slider__slider-wrap`}>
                <Slider {...settings}>
                  <div className={`section-presentation grey`}>
                    <h3 className={`section-presentation__title`}>
                      {t('main_page.guest.text')}
                    </h3>
                    <div className={`section-presentation__image`}>
                      <FirstSvgIcon/>
                    </div>
                  </div>
                  <div className={`section-presentation yellow`}>
                    <h3 className={`section-presentation__title`}>
                      {t('main_page.guest.text')}
                    </h3>
                    <div className={`section-presentation__image`}>
                      <SecondSvgIcon/>
                    </div>
                  </div>
                  <div className={`section-presentation pink`}>
                    <h3 className={`section-presentation__title`}>
                      {t('main_page.guest.text')}
                    </h3>
                    <div className={`section-presentation__image`}>
                      <ThirdSvgIcon/>
                    </div>
                  </div>
                </Slider>
              </div>
              <h2 className={`section-title`}>
                {currentSlide === 0 && (<React.Fragment><span>1.</span> {t('main_page.guest.shareTitle')}</React.Fragment>)}
                {currentSlide === 1 && (<React.Fragment><span>2.</span> {t('main_page.guest.pointsTitle')}</React.Fragment>)}
                {currentSlide === 2 && (<React.Fragment><span>3.</span> {t('main_page.guest.rewardTitle')}</React.Fragment>)}
              </h2>
              <div className={`btns-wrap btns-wrap--row`}>
                {/*<Button className={'btn--blue btn--v2'}>*/}
                  {/*<Link to={{pathname: "/signup"}}>{t('btns.join')}</Link>*/}
                {/*</Button>*/}
                <Button className={'btn--v2'}>
                  <Link to={{pathname: "/signin"}}>{currentLanguage === 'en' ? t('btns.enter') : t('btns.signin')}</Link>
                </Button>
              </div>
            </div>
          </div>

          <div className={`section ${activeSection === 1 ? 'active' : ''}`}>
            <div className={`about-us`}>
              <Title title={t('main_page.logged.section1.title')} className={`page-title--v2`} size={243}/>
              <p className={`text text-1`}>{t('main_page.logged.section1.text1')}</p>
              <h3 className={`sub-title`}>{t('main_page.logged.section1.subTitle1')}</h3>
              <p className={`text text-2`}>{t('main_page.logged.section1.text2')}</p>
              <h3 className={`sub-title`}>{t('main_page.logged.section1.subTitle2')}</h3>
              <p className={`text text-3`}>{t('main_page.logged.section1.text3')}</p>
              <p className={`text text-4`}>{t('main_page.logged.section1.text4')}</p>
              {/*<Button className={'btn--v2'}>*/}
                {/*<Link to={{pathname: "/signup"}}>{t('btns.join_us')}</Link>*/}
              {/*</Button>*/}
              <Footer/>
            </div>
          </div>

          {/*<div className={`section ${this.state.activeSection === 1 ? 'active' : ''}`}>*/}
            {/*<div className={`points-activity`}>*/}
              {/*<div className={`section-logo`}>*/}
                {/*<img src={presentation} alt="presentation"/>*/}
              {/*</div>*/}
              {/*<div className={`section-presentation yellow`}>*/}
                {/*<h3 className={`section-presentation__title`}>*/}
                  {/*{t('main_page.guest.text')}*/}
                {/*</h3>*/}
                {/*<div className={`section-presentation__image`}>*/}
                  {/*<SecondSvgIcon/>*/}
                {/*</div>*/}
              {/*</div>*/}
              {/*<div className={`section-dots`}>*/}
                {/*<span className={`active`}></span>*/}
                {/*<span className={`active`}></span>*/}
                {/*<span></span>*/}
              {/*</div>*/}
              {/*<h2 className={`section-title`}>*/}
                {/*<span>2.</span> {t('main_page.guest.pointsTitle')}*/}
              {/*</h2>*/}
              {/*<div className={`btns-wrap btns-wrap--row`}>*/}
                {/*<Button className={'btn--blue btn--v2'}>*/}
                  {/*<Link to={{pathname: "/signup"}}>{t('btns.join')}</Link>*/}
                {/*</Button>*/}
                {/*<Button className={'btn--v2'}>*/}
                  {/*<Link to={{pathname: "/signin"}}>{t('btns.enter')}</Link>*/}
                {/*</Button>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}

          {/*<div className={`section ${this.state.activeSection === 2 ? 'active' : ''}`}>*/}
            {/*<div className={`collect-reward`}>*/}
              {/*<div className={`section-logo`}>*/}
                {/*<img src={presentation} alt="presentation"/>*/}
              {/*</div>*/}
              {/*<div className={`section-presentation pink`}>*/}
                {/*<h3 className={`section-presentation__title`}>*/}
                  {/*{t('main_page.guest.text')}*/}
                {/*</h3>*/}
                {/*<div className={`section-presentation__image`}>*/}
                  {/*<ThirdSvgIcon/>*/}
                {/*</div>*/}
              {/*</div>*/}
              {/*<div className={`section-dots`}>*/}
                {/*<span className={`active`}></span>*/}
                {/*<span className={`active`}></span>*/}
                {/*<span className={`active`}></span>*/}
              {/*</div>*/}
              {/*<h2 className={`section-title`}>*/}
                {/*<span>3.</span> {t('main_page.guest.rewardTitle')}*/}
              {/*</h2>*/}
              {/*<div className={`btns-wrap btns-wrap--row`}>*/}
                {/*<Button className={'btn--blue btn--v2'}>*/}
                  {/*<Link to={{pathname: "/signup"}}>{t('btns.join')}</Link>*/}
                {/*</Button>*/}
                {/*<Button className={'btn--v2'}>*/}
                  {/*<Link to={{pathname: "/signin"}}>{t('btns.enter')}</Link>*/}
                {/*</Button>*/}
              {/*</div>*/}
              {/*<Footer/>*/}
            {/*</div>*/}
          {/*</div>*/}

          {isMobile && <div className={`section section-footer ${this.state.activeSection === 2 ? 'active' : ''}`}>
            <Footer/>
          </div>}

        </div>
    )
  }
}

export default FullpageScroll;
