import React from 'react';
import Button from "../../../form/Button";
import Title from "../../../parts/Title";

function Start (props) {
  return (
      <div className={`confirm-profile-start`}>
        <Title title={`${props.t('confirm_profile.welcome')} ${props.user.firstname}`} size={208} />
        <p className={`confirm-profile-start__title`}>{props.t('confirm_profile.start_title')}</p>
        <p className={`confirm-profile-start__text`}>{props.t('confirm_profile.start_text')}</p>
        <div className={`confirm-profile-start__btn`}>
          <Button handleClick={()=>props.handleChangeStep(1)}>{props.t('btns.continue')}</Button>
        </div>
      </div>
  )
}

export default Start
