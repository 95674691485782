import React, { useState } from 'react'
import SurveyQuestionAnswers from './SurveyQuestionAnswers'
import TextareaV2 from '../../../../form/TextareaV2'
import PropTypes from 'prop-types'
import { apiImgUrl } from '../../../../../api/constants'

const SurveyQuestion = ({ question, number, lang, shouldRenderOptionalField, onInputChange, errors, t }) => {
    const [isTextAreaEnabled, setIsTextAreaEnabled] = useState(false)

    const inputHandler = e => {
        onInputChange(e.target.value, e.target.type)

        if (e.target.id === question.key) {
            setIsTextAreaEnabled(e.target.checked)
            return
        }
        if (e.target.type === 'radio' && e.target.id !== question.key) {
            setIsTextAreaEnabled(false)
        }
    }

    return (
        <div className={`survey-form__col`}>
            <div className={`survey-form__field radio-btn`}>
                <p className={`survey-form__label`}>
                    <span className={`survey-form__count-label`}>{number}</span>
                    {lang === 'en' || !question.attributes.question_ge
                        ? question.attributes.question_en
                        : question.attributes.question_ge}
                </p>
                {errors && errors[question.key] === false && (
                    <p style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }}>{t('errors.req_field')}</p>
                )}

                {question.attributes.question_image && (
                    <div className={`survey-form__presentation-image`}>
                        <img src={`${apiImgUrl}/${question.attributes.question_image}`} alt="" />
                    </div>
                )}

                <SurveyQuestionAnswers
                    type={question.layout}
                    name={question.key}
                    answers={question.attributes.answer || []}
                    onInputChange={inputHandler}
                    lang={lang}
                />
                {shouldRenderOptionalField && (
                    <>
                        <TextareaV2
                            t={t}
                            disabled={!isTextAreaEnabled}
                            name={question.key}
                            maxLength={300}
                            placeholder={'Max length 300 symbols'}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

SurveyQuestion.propTypes = {
    lang: PropTypes.oneOf(['en', 'ge']).isRequired,
    question: PropTypes.object.isRequired,
    number: PropTypes.number.isRequired,
    shouldRenderOptionalField: PropTypes.bool.isRequired,
    onInputChange: PropTypes.func.isRequired,
}

export default SurveyQuestion
