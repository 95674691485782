import React, {Component} from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format';
import warning_icon from "../../../images/cabinet/warning.svg";
import success_icon from "../../../images/cabinet/success.png";
import retry_icon from "../../../images/cabinet/retry.svg";

class NumberFormatInput extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    validating: PropTypes.bool,
    validate: PropTypes.array,
    handleValidating: PropTypes.func,
    maxLength: PropTypes.number,
    value: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    mask: PropTypes.string,
    format: PropTypes.string,
    icon: PropTypes.string,
    iconRetry: PropTypes.bool,
    handleIcon: PropTypes.func
  };

  static defaultProps = {
    className: "",
    placeholder: "",
    disabled: false,
    onChange: () => {}
  };

  constructor(props) {
    super();
    // this.inputEl = React.createRef();
    this.state = {
      value: props.value || '',
      errors: {}
    }
  }

  handleChange = (values) => {
    const { onChange, validate /*, validating*/ } = this.props;
    let value = values.value;

    this.setState({value});
    onChange(value);

    if (this.props.handleChangeField) {
      this.props.handleChangeField(value, this.props.name)
    }

    // if (validating === true) {
      if (validate) {
        this.handleValidation(value);
      }
    // }
  };

  handleValidation = (value) => {
    const {errors} = this.state;

    this.props.validate.map(item => {
      if (item.hasOwnProperty('required')) {
        if (!value) {
          errors.required = item.message || 'The value is required'
        }
        else if (value && value.search(/_/) !== -1) {
          errors.required = item.message || 'The value is required'
        }
        else {
          delete errors.required;
        }
      }

      if (item.hasOwnProperty('minLength')) {
        if (value && value.length < item.minLength) {
          errors.minLength = item.message || `The value must be longer than ${item.minLength} symbols`
        } else {
          delete errors.minLength;
        }
      }

      this.setState({
        errors
      })
    });

    this.props.handleValidating(this.props.name, Object.keys(errors).length);
  };

  componentDidUpdate(prevProps) {
    if (this.props.validating === true && this.props.validating !== prevProps.validating) {
      if (this.props.validate) {
        this.handleValidation(this.state.value);
      }
    }
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  icon() {
    if (this.props.icon === 'warning') {
     return (<span className={`form-field__icon`}>
        <img src={warning_icon} alt="warning"/>
      </span>)
    } else if (this.props.icon === 'success') {
      return (<span className={`form-field__icon`}>
        <img src={success_icon} alt="success"/>
      </span>)
    }
  }

  iconRetry() {
    return (
        <span className={`form-field__icon static`} onClick={this.props.handleIcon}>
          <img src={retry_icon} alt="retry icon"/>
        </span>
    )
  }

  render() {
    const {iconRetry} = this.props;
    let errorMessage;
    if(Object.keys(this.state.errors).length){
      errorMessage = (
          Object.keys(this.state.errors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.state.errors[item]}</div>
          ))
      )
    } else if (this.props.serverErrors && Object.keys(this.props.serverErrors).length) {
      errorMessage = (
          Object.keys(this.props.serverErrors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.props.serverErrors[item]}</div>
          ))
      )
    }

    const iconRetryClass = iconRetry ? "with-static-icon" : "";
    const errorClass = (Object.keys(this.state.errors).length ||
    (this.props.serverErrors && Object.keys(this.props.serverErrors).length) ? "has-error" : "");
    return (
        <div className={["form-field", "form-field-numeric", iconRetryClass, errorClass].filter(e => !!e).join(" ")}>
          <NumberFormat
              type={'tel'}
              mask={this.props.mask}
              format={this.props.format}
              allowEmptyFormatting={this.props.allowEmptyFormatting}
              placeholder={this.props.placeholder}
              name={this.props.name}
              // onChange={this.handleChange}
              onValueChange={this.handleChange}
              // getInputRef = {(el) => this.inputElem = el}
              // ref={this.inputEl}
              value={this.state.value}
              disabled={this.props.disabled}
          />
          {this.props.icon && this.icon()}
          {iconRetry && this.iconRetry()}
          {errorMessage}
        </div>
    )
  }
}

export default NumberFormatInput;
