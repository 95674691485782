export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export const SET_FIST_LOGIN_STATUS = "SET_FIST_LOGIN_STATUS";
export const SET_FIRST_REG_FROM_SMS = "SET_FIRST_REG_FROM_SMS";
export const SET_ME_STATE = "SET_ME_STATE";
export const LOGOUT = "LOGOUT";
export const SET_CONFIRM_DATA = "SET_CONFIRM_DATA";
export const SET_CRM_USER_DATA = "SET_CRM_USER_DATA";
export const SET_USER_PHONE = "SET_USER_PHONE";
export const SET_USER_CODE = "SET_USER_CODE";
export const SET_USER_RULES_CONFIRM = "SET_USER_RULES_CONFIRM";
export const SET_LANG = "SET_LANG";
export const SET_WRAPPER_CLASSNAME = "SET_WRAPPER_CLASSNAME";
export const CURRENT_HISTORY_ROUTE = "CURRENT_HISTORY_ROUTE";
export const SET_CITIES = "SET_CITIES";
export const SET_CIGARETTE_BRAND_LIST = "SET_CIGARETTE_BRAND_LIST";
export const SET_CIGARETTE_FORMAT_LIST = "SET_CIGARETTE_FORMAT_LIST";
export const SET_MAP_PLACES = "SET_MAP_PLACES";
export const SET_CURRENT_MAP_PLACE = "SET_CURRENT_MAP_PLACE";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const CLOSE_MENU = "CLOSE_MENU";
export const SET_TASK_PENDING = "SET_TASK_PENDING";
export const SET_TASK_TAKE = "SET_TASK_TAKE";
export const SET_TASK_TAKE_INFO = "SET_TASK_TAKE_INFO";
export const SET_TASK_STEP = "SET_TASK_STEP";
export const SET_TASK_STATUS = "SET_TASK_STATUS";
export const SET_TASK_VERSION = "SET_TASK_VERSION";
export const SET_ACTIVE_MAIN_SECTION = "SET_ACTIVE_MAIN_SECTION";
export const SET_STATUS_FIRST_RESEARCH = "SET_STATUS_FIRST_RESEARCH";
export const SET_STATUS_SECOND_RESEARCH = "SET_STATUS_SECOND_RESEARCH";
export const SET_STATUS_THIRD_RESEARCH = "SET_STATUS_THIRD_RESEARCH";
export const SET_FINISH_PROFILING_SURVEY = "SET_FINISH_PROFILING_SURVEY";
export const SET_SHORT_AND_LONG_SURVEYS = "SET_SHORT_AND_LONG_SURVEY";
export const CLEAR_SHORT_AND_LONG_SURVEYS = "CLEAR_SHORT_AND_LONG_SURVEYS";
