import React from "react";

const SurveyInfo = (props) => {
  const points = props.points ? props.points : 15;
  return (
      <div className={`survey-info`}>
        <div className={`survey-info__col`}>
          <div className={`open-tasks__content`}>
            <p className={`open-tasks__title`}>{props.t('open_task.task_type')}: </p>
            <p className={`open-tasks__text`}>{props.t('open_task.survey.type')}</p>
          </div>
          <div className={`open-tasks__content`}>
            <p className={`open-tasks__title`}>{props.t('open_task.points_comp')}: </p>
            <p className={`open-tasks__text`}>{points}</p>
          </div>
        </div>
        <div className={`survey-info__col`}>
          <div className={`open-tasks__content`}>
            <p className={`open-tasks__title`}>{props.t('open_task.task_desc')}: </p>
            <p className={`open-tasks__text`}>{props.t('open_task.survey.desc_text')}</p>
          </div>
        </div>
      </div>
  );
};

export default SurveyInfo;
