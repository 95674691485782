import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import PrivateRoute from "./HoC/PrivateRouteHOC";
import Header from "./parts/Header";
import Footer from "./parts/Footer";
import PageNotFound from "./page/PageNotFound/PageNotFound";
import Shop from "./page/Shop/Shop";
import Faq from "./page/Faq/Faq";
import Feedback from "./page/Feedback/Feedback";
import SignIn from "./page/Auth/SignIn/SignIn";
import PasswordReset from "./page/Auth/PasswordReset/PasswordReset";
import NewPassword from "./page/Auth/NewPassword/NewPassword";
import SignUp from "./page/Auth/Signup/SignUp";
import Cabinet from "./page/Cabinet/Cabinet";
import OpenTasks from "./page/OpenTasks/OpenTasks";
import MainPage from "./page/MainPage/MainPage";
import ConfirmProfile from "./page/Auth/ConfirmProfile/ConfirmProfile";
import HowTo from "./page/HowTo/HowTo";
// import Rating from "./page/Rating/Index";
import Rules from "./page/Rules/Index";
import { checkLogin } from "../actions/auth";
import ScrollToTop from "./parts/ScrollToTop";
import Dashboard from "./page/Dashboard/Index";
import TokenTask from "./parts/TokenTask/TokenTask";
import SmsToken from "./parts/SmsToken/SmsToken";
// import TasksContainer from "./page/MainPage/Tasks/index";
import TasksV2Container from "./page/MainPage/TasksV2/index";
import NotifyPrizePopup from "./parts/NotifyPrizePopup/NotifyPrizePopup";
import { setWrapperClassName } from "../actions/common";
import NotifyPopup from "./parts/NotifyPopup/NotifyPopup";
import NotifyRulesPopup from "./parts/NotifyRulesPopup/NotifyRulesPopup";
import AfterRegFirstLog from "./parts/NotifyRefillPopup/AfterRegFirstLog";
import AfterSurveyPopup from "./parts/NotifyRefillPopup/AfterSurveyPopup";
import AllTasks from "./page/MainPage/AllTasks/AllTasks";
import defaultAxios from "../axios";
import ComingSoon from "./parts/ComingSoon";
// import TechnicalWorkPopup from "./parts/TechnicalWorkPopup";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ios: false,
    };
  }

  componentDidMount() {
    this.props.checkLogin();
    let iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      this.setState({
        ios: true,
      });
    }
  }

  render() {
    const { wrapperClass, isLogged, currentLanguage, menuOpened } = this.props;
    return (
      <div
        className={`wrapper lang-${currentLanguage} ${wrapperClass} ${
          menuOpened ? "menu-opened" : ""
        }
        ${this.state.ios ? "ios" : ""} ${
          window.location.pathname === "/rules" ? "height-auto" : ""
        }`}
      >
        <ScrollToTop />
        <ComingSoon {...this.props}/>
        {/*<TechnicalWorkPopup {...this.props}/>*/}
        {/*<Header />*/}
        {/*<main className={`main`}>*/}
          {/*<Switch>*/}
            {/*<Route path="/" exact component={MainPage} />*/}
            {/*<Route path="/signin" component={SignIn} />*/}
            {/*<Route path="/confirm-profile" component={ConfirmProfile} />*/}
            {/*<Route path="/signup" component={SignUp} />*/}
            {/*<Route path="/password-reset" component={PasswordReset} />*/}
            {/*<Route path="/password/reset" component={NewPassword} />*/}
            {/*/!*<PrivateRoute path="/tasks" component={TasksContainer}/>*!/*/}
            {/*/!*<PrivateRoute path="/tasks" component={TasksV2Container} />*!/*/}
            {/*<PrivateRoute path="/tasks" component={AllTasks} />*/}
            {/*<PrivateRoute path="/cabinet" component={Cabinet} />*/}
            {/*<PrivateRoute path="/shop" component={Shop} />*/}
            {/*<PrivateRoute path="/open-tasks" component={OpenTasks} />*/}
            {/*<Route path="/feedback" component={Feedback} />*/}
            {/*<Route path="/faq" component={Faq} />*/}
            {/*<Route path="/howto" component={HowTo} />*/}
            {/*/!*<PrivateRoute path="/rating" component={Rating}/>*!/*/}
            {/*<Route path="/rules" component={Rules} />*/}
            {/*<PrivateRoute path="/dashboard" component={Dashboard} redirectUrl={`${defaultAxios.defaults.baseURL}admin`}/>*/}
            {/*/!*<PrivateRoute path="/dashboard" component={Dashboard} />*!/*/}
            {/*<Route path={"/token-task"} component={TokenTask} />*/}
            {/*<Route path={"/sender"} component={SmsToken} />*/}
            {/*<Route render={() => <PageNotFound />} />*/}
          {/*</Switch>*/}
        {/*</main>*/}
        {/*{!(!isLogged && wrapperClass === "main-page-container") && <Footer />}*/}
        {/*/!*<NotifyPrizePopup {...this.props}/>*!/*/}
        {/*<NotifyPopup {...this.props} />*/}
        {/*<NotifyRulesPopup {...this.props} />*/}
        {/*<AfterRegFirstLog {...this.props} />*/}
        {/*<AfterSurveyPopup {...this.props} />*/}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLogged: state.auth.isLogged,
  wrapperClass: state.common.wrapperClass,
  currentLanguage: state.common.currentLanguage,
  menuOpened: state.menu.menuOpened,
});

const mapDispatchToProps = (dispatch) => ({
  checkLogin: () => dispatch(checkLogin()),
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
