import React from "react";
import Title from "../../../parts/Title";
import Button from "../../../form/Button";
import Form from "../../../form/Form";
import NumberFormatInput from "../../../form/NumberFormatInput";

const PopupMobile = (props) => {
  const {closePopup, handleSubmit, user, errMsg, t} = props;
  return (
      <div className={`shop-popup`}>
        <div className={`shop-popup__close`} onClick={closePopup}></div>
        <div className={`container`}>
          <div className={`shop-popup__wrap`}>
            <Title title={`${props.refill_value} ${props.t('shop.prize_type_mobile')}`} className={`white`} size={208}/>
            <p className={`shop-popup__label`}>
              <span>{props.t('shop.mobile_label_0')}</span><br/>
              {props.t('shop.mobile_label')}
            </p>
            <Form handleSubmit={handleSubmit} serverErrorQuantity={0}>
              <NumberFormatInput name={"phone"}
                                 mask="_"
                                 format={'+995 ###-##-##-##'}
                                 allowEmptyFormatting
                                 // validate={[
                                 //   {
                                 //     required: true,
                                 //     message: props.t('errors.phone')
                                 //   },
                                 //   {
                                 //     minLength: 9
                                 //   }
                                 // ]}
                                 disabled={true}
                                 value={user.phone}
              />
              <Button type={'submit'} className={`shop-popup__btn`} disabled={props.loading}>{props.t('btns.confirm')}</Button>
              {errMsg && <div className={'error-element'}>{t('open_task.sobranie.global_submit_answer_error')}</div>}
            </Form>
          </div>
        </div>
      </div>
  )
};

export default PopupMobile
