import React, {Component} from "react";
import {connect} from "react-redux";
import Tabs from "./Tabs/Tabs";
import {Redirect, Route, Switch} from "react-router-dom";
import Title from "../../parts/Title";
import Profile from "./Profile/Profile";
import Points from "./Points/Points";
import {setUserRulesConfirm, setWrapperClassName} from "../../../actions/common";
import Tasks from "./Tasks/Tasks";
import LangHOC from "../../HoC/LangHOC";

class Cabinet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false
    };
  }

  componentDidMount() {
    const currentUser = localStorage.getItem("user.me") ? JSON.parse(localStorage.getItem("user.me")) : null;
    if (currentUser && currentUser.hasOwnProperty('rules_v2') && currentUser['rules_v2'] === "0") {
      this.props.setUserRulesConfirm(true);
    }
    this.props.setWrapperClassName("cabinet-container");
    this.setState({
      isMobile: window.innerWidth <= 767
    });
    window.addEventListener('resize', this.checkMobile);
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
    window.removeEventListener('resize', this.checkMobile);
  }

  checkMobile = () => {
    let isMobile = window.innerWidth <= 767;
    this.setState({
      isMobile: isMobile
    })
  };

  render() {
    return (
        <div className={`cabinet`}>
          <div className={`cabinet__left-side`}>
            <Title title={this.props.t('cabinet.title')} className={!this.state.isMobile?'white':''} size={167}/>
            <Tabs {...this.props}/>
          </div>
          <div className={`cabinet__right-side`}>
            <Switch>
              <Route path={`${this.props.match.path}/profile`}>
                <Profile {...this.props}/>
              </Route>
              <Route path={`${this.props.match.path}/points`}>
                <Points {...this.props}/>
              </Route>
              <Route path={`${this.props.match.path}/tasks`}>
                <Tasks {...this.props}/>
              </Route>
              <Redirect from="/cabinet" to="/cabinet/profile"/>
            </Switch>
          </div>
        </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
  setUserRulesConfirm: (status) => dispatch(setUserRulesConfirm(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(LangHOC(Cabinet));
