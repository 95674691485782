import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../parts/Title";
import GuestQuestions from "./parts/GuestQuestions";
import questions from "./questions.json";
import LoggedQuestions from "./parts/LoggedQuestions";
import LangHOC from "../../HoC/LangHOC";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openedIndex: null
    };
  }

  toggleQuestionHandler = (e, idx) => {
    if (idx === this.state.openedIndex) {
      this.setState({
        openedIndex: null
      })
    } else {
      this.setState({
        openedIndex: idx
      })
    }
  };

  render() {
    let {openedIndex} = this.state;
    const {isLogged} = this.props;
    let user = isLogged ? 'logged' : 'guest';
    return (
        <div className={`faq ${user}`}>
          <Title title={this.props.t('faq.title')} size={170}/>
          {!isLogged && <GuestQuestions user={user}
                                        questions={questions}
                                        openedIndex={openedIndex}
                                        toggleQuestionHandler={this.toggleQuestionHandler} {...this.props}/>}
          {isLogged && <LoggedQuestions user={user}
                                        questions={questions}
                                        openedIndex={openedIndex}
                                        toggleQuestionHandler={this.toggleQuestionHandler} {...this.props}/>}
        </div>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged,
  currentLanguage: state.common.currentLanguage
});

export default connect(mapStateToProps, null)(LangHOC(Faq));
