const prodBaseUrl = "https://iw.ge/";
const devBaseUrl = "https://winstonge2.adbakers.com.ua/";
export const apiUrl =
  window.location.hostname !== "we2gether.ge" ? devBaseUrl : prodBaseUrl;
export const apiImgUrl = `${apiUrl}/survey`;

export const getUserActiveSurveysApiPath =
  "/api/v2/survey/current-user/activities";

export const getSaveSurveyApiPath = (type) =>
  `/api/v2/survey/${type}/answers/store`;

export const getIsSurveyAvailableApiPath = (surveyId) =>
  `api/v2/survey/access/can-user-get-survey/${surveyId}`;

export const getLongSurveyStatusApiPath = (surveyId) =>
  `api/v2/survey/long/status?survey_id=${surveyId}`;

export const getSaveLongSurveyImageApiPath = (surveyId) =>
  `/api/v2/survey/long/answers/image/store?survey_id=${surveyId}`;

export const getUserTasksApiPath = "/api/tasks/v2/user-tasks";

export const isUserCanTakeVerificationApiPath =
  "/api/survey-search-tasks/v2/canTakeResearch?survey_type=id_upload";
