import React from "react"
import { Scrollbars } from 'react-custom-scrollbars';
import terms from "./../page/Rules/terms.json";
import annex from "./../page/Rules/annex.json";
import Topic from "./../page/Rules/parts/topic";

const Terms = (props) => {
  return (
      <Scrollbars>
        <Topic topic={terms} {...props}/>
        <Topic topic={annex} {...props}/>
      </Scrollbars>
  )
};

export default Terms
