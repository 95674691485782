import React, { Component } from "react";
import PropTypes from "prop-types";
import LangHOC from "../HoC/LangHOC";

class Radio extends Component {
  static propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    validating: PropTypes.bool,
    validate: PropTypes.array,
    handleChange: PropTypes.func,
    handleValidating: PropTypes.func,
    radioCol: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    radioCol: false,
    onChange: () => {},
    className: "btn btn--blue",
  };

  constructor(props) {
    super();
    this.state = {
      value: props.value || "",
      errors: {},
    };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });

    this.props.onChange(event);

    if (this.props.handleChangeField) {
      this.props.handleChangeField(event, this.props.name);
    }

    // if (this.props.validating === true) {
    if (this.props.validate) {
      this.handleValidation(event.target.value);
    }
    // }
  };

  handleValidation = (value) => {
    const { errors } = this.state;
    const { name } = this.props;

    this.props.validate.map((item) => {
      if (item.hasOwnProperty("required")) {
        if (!value) {
          errors.required = item.message || "The value is required";
        } else {
          delete errors.required;
        }
      }

      if (item.hasOwnProperty("exactValue")) {
        if (value !== item.exactValue) {
          errors.exactValue = item.message || "Please choose correct answer";
        } else {
          delete errors.exactValue;
        }
      }

      this.setState({
        errors,
      });
    });

    this.props.handleValidating(name, Object.keys(errors).length);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.validating === true &&
      this.props.validating !== prevProps.validating
    ) {
      if (this.props.validate) {
        this.handleValidation(this.state.value);
      }
    }
    if (
      this.props.validating === true &&
      this.state.value !== prevState.value
    ) {
      this.handleValidation(this.state.value);
    }

    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value,
      });
    }
  }

  render() {
    return (
      <div
        className={
          "radio-wrapper" +
          (this.props.radioWrapClass ? " " + this.props.radioWrapClass : "") +
          (this.props.radioCol ? " radio-wrapper-col" : "") +
          (Object.keys(this.state.errors).length ? " has-error" : "")
        }
      >
        {this.props.children.map((child) => {
          if (child.type === "option") {
            return (
              <div key={child.props.id}>
                <input
                  type="radio"
                  id={child.props.id}
                  value={child.props.value}
                  name={this.props.name}
                  checked={this.state.value === child.props.value}
                  onChange={(event) => this.handleChange(event)}
                  data-name={child.props["data-name"]}
                  data-relatedquestionid={child.props.relatedQuestionId}
                />

                <label
                  className={this.props.className}
                  onClick={child.props.call}
                  htmlFor={child.props.id}
                >
                  {child.props.icon}
                  <span>{child.props.label}</span>
                </label>
              </div>
            );
          }
        })}
        {Object.keys(this.state.errors).map((item, i) => (
          <div key={i} className={"error-element"}>
            {this.state.errors[item]}
          </div>
        ))}
      </div>
    );
  }
}

export default LangHOC(Radio);
