import React from "react";
import Title from "../../../parts/Title";
import Button from "../../../form/Button";

const PopupPhotoIdAttention = (props) => {
  const {closePopup} = props;
  return (
      <div className={`shop-popup`}>
        <div className={`shop-popup__close`} onClick={closePopup}></div>
        <div className={`container`}>
          <div className={`shop-popup__wrap`}>
            <Title title={props.t('shop.attention.title')} className={`white`} size={208}/>
            <p className={`shop-popup__text`}>{props.t('shop.attention_photo_id.text0')}</p>
            <p className={`shop-popup__text`}>{props.t('shop.attention_photo_id.text1')}</p>
            <p  className={`shop-popup__text`}>{props.t('shop.attention_photo_id.text2')}</p>
            <Button className={`shop-popup__btn`} handleClick={closePopup}>{props.t('btns.got_it')}</Button>
          </div>
        </div>
      </div>
  )
};

export default PopupPhotoIdAttention
