import React from 'react';
import {Link} from "react-router-dom";
import Button from "../../../form/Button";

function BlockUser (props) {
  const {userName} = props;
  return (
      <div className={`auth__wrap`}>
        <div className={`auth__desc-wrap`}>
          <div className={`user-greeting`}>{props.t('signin.meeting0')} {userName}! {props.t('signin.block_meeting')}!</div>
          <div className={`auth-desc`}>{props.t('signin.block_desc0')}.</div>
          <div className={`auth-desc`}>{props.t('signin.block_desc1')}.</div>
        </div>
        <div className={`auth__form`}>
          <div className={`auth__btns`}>
            <Button className={`btn--blue`}><Link to={{pathname: "/feedback"}}>{props.t('btns.contact_us')}</Link></Button>
          </div>
        </div>
      </div>
  )
}

export default BlockUser
