import ErrorResponse from "../../PageNotFound/ErrorResponse";
import React from "react";
import { useIsProfilingFinished } from "../../../../hooks/useIsProfilingFinished";
import PageNotFound from "../../PageNotFound/PageNotFound";
import Survey from "./Survey/Survey";

const ProfilingSurvey = ({ t, currentLanguage }) => {
  const { isProfilingFinished, isLoading, isError } = useIsProfilingFinished();

  if (isLoading) return <></>;
  else if (isError) return <ErrorResponse />;
  else if (isProfilingFinished) return <PageNotFound />;
  else return <Survey t={t} currentLanguage={currentLanguage} />;
};

export default ProfilingSurvey;
