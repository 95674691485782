import React, {Component} from "react";
import defaultAxios from "../../../axios";
import {setTaskStep, setTaskTake, setTaskTakeInfo} from "../../../actions/taskActions";
import {connect} from "react-redux";
import {setLogin} from "../../../actions/auth";

class TokenTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonData : {}
    };
  }

  componentDidMount() {
    //?token=5f84318b530ba2.34445008&activity=Census&user_task=100
    const path = this.props.location.search.split('&');
    if (path && path[0]) {
      this.checkToken(path);
    } else {
      this.props.history.push('/')
    }
  }

  checkToken = (path) => {
    const jsonData = {};
    if (path && path[0]) {
      let token = path[0].split('=')[1];
      jsonData['token'] = token;
      if (path.length > 1) {
        let type = path[1].split('=')[0];
        let typeValue = path[1].split('=')[1];
        jsonData[type] = typeValue;
        if (path[2]) {
          let type = path[2].split('=')[0];
          let typeValue = path[2].split('=')[1];
          jsonData[type] = typeValue;
        }
      }
      // this.setState({
      //   jsonData: jsonData
      // });
      defaultAxios.post('api/get-user-by-token/v2', jsonData, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (response.data && response.data.data) {
            this.props.setLogin(response.data.data.token, response.data.data.expiration);
            this.openTask(response.data.data)
          }
        })
        .catch(error => {
        });
    }
  };

  openTask = (params) => {
    let url = `/api/tasks/v2/one-task?task_type=${params['activity']}&activity_id=${params['activity_id']}&user_task_id=${params['user_task']}`;
    defaultAxios.get(url, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": `bearer ${params.token}`
      }
    })
      .then(res => {
        if (res.data.data && res.data.data.task_info && res.data.data.task_info[0] && res.data.data.task_questions) {
          this.props.setTaskStep(2);
          this.props.setTaskTakeInfo(res.data.data.task_info[0]);
          this.props.setTaskTake(res.data.data.task_questions);
          this.props.history.push('/open-tasks/census');
        }
      })
      .catch(err => {})
  };

  render() {
    return (
      <div className={`token-task`}>
        <div className={`preloader`}></div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setTaskTake: (task) => dispatch(setTaskTake(task)),
  setTaskTakeInfo: (taskInfo) => dispatch(setTaskTakeInfo(taskInfo)),
  setTaskStep: (step) => dispatch(setTaskStep(step)),
  setLogin: (token, expiration) => dispatch(setLogin(token, expiration))
});

export default connect(null, mapDispatchToProps)(TokenTask)
