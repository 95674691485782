import pres_4 from "../../../../../../images/surv-4.svg";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIsSurveyAvailable } from "../../../../../hooks/useIsSurveyAvailable";
import { useLongSurveyStatus } from "../../../../../hooks/useLongSurveyStatus";
import { useHistory } from "react-router-dom";
import { longSurveyUrl } from "../../../../../constants/path";

const LongSurveyCard = ({ survey, popupHandler, setServerSurveyError, t }) => {
  const history = useHistory();

  const {
    isAvailable,
    shouldShowPopup: shouldShowPopupOnClick,
    status: unavailableStatus,
    isError: isCheckSurveyAvailableError,
  } = useIsSurveyAvailable(survey?.id);

  const {
    status,
    shouldShowPopup: shouldShowPopupOnLoad,
    isUnavailable: isSurveyUnavailableByStatus,
    isError: isGetLongSurveyStatusError,
  } = useLongSurveyStatus(survey?.id, isAvailable);

  useEffect(() => {
    setServerSurveyError(
      isCheckSurveyAvailableError || isGetLongSurveyStatusError
    );
  }, [
    isCheckSurveyAvailableError,
    isGetLongSurveyStatusError,
    setServerSurveyError,
  ]);

  useEffect(() => {
    if (!shouldShowPopupOnLoad || !survey?.points) return;

    popupHandler(status, survey.points);
  }, [status, shouldShowPopupOnLoad, popupHandler]);

  const clickHandler = () => {
    if (shouldShowPopupOnClick) popupHandler(unavailableStatus, survey.points);
    else history.push(longSurveyUrl);
  };

  return (
    <button
      className={`card pink`}
      onClick={clickHandler}
      disabled={
        !survey ||
        (!isAvailable && !shouldShowPopupOnClick) ||
        isSurveyUnavailableByStatus
      }
    >
      <div className={`card__wrap`}>
        <div className={`card__content`}>
          <p className={`card__title`}>
            {t("main_page.logged.section2.cardTitle2")}
          </p>
          <p className={`card__text`}>
            {t("main_page.logged.section2.cardText2")}
          </p>
          {!!survey?.points && (
            <p className={`card__reward`}>
              <strong>+{survey?.points}</strong> {t("main_page.tasks.points")}
            </p>
          )}
        </div>
        <div className={`card__image`}>
          <img src={pres_4} alt="" />
        </div>
      </div>
    </button>
  );
};

LongSurveyCard.propTypes = {
  survey: PropTypes.object,
  popupHandler: PropTypes.func.isRequired,
  setServerSurveyError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default LongSurveyCard;
