import React from "react";
import Title from "../../../../parts/Title";
import Button from "../../../../form/Button";
import done_img from "../../../../../../images/open-tasks/survey/survey-done.png";

const Finished = (props) => {
  return (
    <div className={`popup confirm photo-pack-popup`}>
      <div className={`popup__close`} onClick={() => props.closePopup()}></div>
      <div className={`container`}>
        <div className={`popup__wrap`}>
          <Title
            title={props.t("open_task.photo_pack.finish.doneTitle")}
            className={`page-title--v2`}
            size={170}
          />
          <div className={`attention-img`}>
            <img src={done_img} alt="done pic" />
          </div>
          <p className={`popup__text`}>
            <strong>+{props.points}</strong>{" "}
            {props.t("open_task.photo_pack.finish.doneText")}
          </p>
          <Button
            className={`open-tasks-finish__btn`}
            handleClick={() => props.closePopup()}
          >
            {props.t("btns.cool")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Finished;
