import { useLocation } from "react-router";
import { useMemo } from "react";

export function useUrlParams() {
  const location = useLocation();

  const urlParams = useMemo(() => {
    const query = new URLSearchParams(location.search);
    const entries = query.entries();
    const result = { entries };
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }, [location.search]);

  return { urlParams };
}
