import React from 'react';
import PropTypes from 'prop-types';

const Title = (props) => {
  const divideWidth = props.size ? props.size+'px' : '100%';
  return (
      <div className={`page-title ${props.className}`}>
        {props.title}
        <span className={`page-title__divide`} style={{width: divideWidth}}></span>
      </div>
  )
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number
};

Title.defaultProps = {
  className: ''
};

export default Title
