import React from "react"
import {Link} from "react-router-dom"
import {connect} from "react-redux"

const ErrorResponse = (props) => {
  return (
      <div className={"not-found-page"}>
        <p>Something went wrong. <br/> Please try again later.</p>
        <Link className="link-default" to={"/tasks"}>Home page</Link>
      </div>
  )
};

export default connect()(ErrorResponse);
