import React from 'react';
import Form from "../../../form/Form";
import Button from "../../../form/Button";
import NumberFormatInput from "../../../form/NumberFormatInput";
import ReCAPTCHA from "react-google-recaptcha";
import {googleRecaptchaKey} from "../../../../constants/Variables";

function Step0(props) {
  const {handleSubmit, handleChangePhone, handleChangeCaptcha, recaptchaRef, phoneError} = props;
  return (
      <div className={`auth__wrap`}>
        <div className={`auth__desc-wrap`}>
          <div className={`auth-desc`}>{props.t('signin.phone_label')}</div>
        </div>
        <div className={`auth__form`}>
          <Form handleSubmit={handleSubmit} className={`${phoneError && phoneError.hasOwnProperty('phone') && phoneError.phone ? 'not-reg-error':''}`}>
            <NumberFormatInput name={"phone"}
                               mask="_"
                               format={'+995 ###-##-##-##'}
                               onChange={handleChangePhone}
                               allowEmptyFormatting
                               serverErrors={phoneError}
                               validate={[
                                 {
                                   required: true,
                                   message: props.t('errors.phone')
                                 },
                                 {
                                   minLength: 9
                                 }
                               ]}/>
            <div className="auth__captcha-wrapper">
              <ReCAPTCHA
                  sitekey={googleRecaptchaKey}
                  onChange={handleChangeCaptcha}
                  ref={recaptchaRef}
                  size="invisible"
              />
            </div>
            {(phoneError && phoneError.hasOwnProperty('phone') && phoneError.phone) && <p className='error-element'>{props.t('errors.not_registered')}</p>}
            <div className={`auth__btns`}>
              <Button type={`submit`}>{props.t('btns.enter')}</Button>
            </div>
          </Form>
        </div>
      </div>
  )
}

export default Step0
