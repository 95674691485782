import * as types from "../constants/ActionTypes";

const initialState = {
  userPhone: null,
  isLogged: false,
  firstLoginStatus: false,
  firstRegFromSms: false,
  meInfo: undefined,
  userConfirmationData: {token: null},
  crmData: null,
  userCode: null
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOGIN_STATE:
      return {
        ...state,
        isLogged: action.state
      };
    case types.SET_FIST_LOGIN_STATUS:
      return {
        ...state,
        firstLoginStatus: action.status
      };
    case types.SET_FIRST_REG_FROM_SMS:
      return {
        ...state,
        firstRegFromSms: action.status
      };
    case types.SET_ME_STATE:
      return {
        ...state,
        meInfo: action.state
      };
    case types.LOGOUT:
      return {
        ...state,
        isLogged: false
      };
    case types.SET_CONFIRM_DATA:
      return {
        ...state,
        userConfirmationData: action.userData
      };
    case types.SET_CRM_USER_DATA:
      return {
        ...state,
        crmData: action.crmData
      };
    case types.SET_USER_PHONE:
      return {
        ...state,
        userPhone: action.userPhone
      };
    case types.SET_USER_CODE:
      return {
        ...state,
        userCode: action.userCode
      };
    default:
      return state;
  }
}
