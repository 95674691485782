import React, {Component} from "react";
import {connect} from "react-redux";
import Title from "../../parts/Title";
import defaultAxios from "../../../axios";
import FeedbackForm from "./parts/FeedbackForm";
import Finish from "./parts/Finish";
import LangHOC from "../../HoC/LangHOC";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicList: [
        {label: props.t('feedback.questions.first'), value: 'Sign up problems'},
        {label: props.t('feedback.questions.second'), value: 'Issues with password'},
        {label: props.t('feedback.questions.third'), value: 'we2gether.ge rules questions'},
        {label: props.t('feedback.questions.fourth'), value: 'Website technical problems'},
        {label: props.t('feedback.questions.fifth'), value: 'Unsubscribe from mailings'},
        {label: props.t('feedback.questions.sixth'), value: 'Other questions'}
      ],
      stage: 'start',
      captcha: null
    };
    this.recaptchaRef = React.createRef();
  }

  handleSubmit = async (formData) => {
    let config = {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      }
    };
    if (this.props.isLogged) {
      config = {
        ...config,
        headers: {...config.headers, "Authorization": "bearer " + localStorage.getItem("token")}

      }
    }
    const captcha = await this.recaptchaRef.current.executeAsync();
    let fd = {};
    formData.forEach(function (value, key) {
      if (key === "phone") {
        value = value.substring(5).replace(/[-_]/g, '')
      }
      fd[key] = value;
    });
    fd["g-recaptcha-response"] = captcha;
    if (captcha) {
      defaultAxios.post('/api/front/v2/feedback', fd, config)
        .then(response => {
          this.setState({
            stage: 'finish'
          })
        })
        .catch(error => {
          this.recaptchaRef.current.reset();
        })
    } else {
      this.recaptchaRef.current.reset();
    }
  };

  handleChangeCaptcha = (value) => {
    this.setState({
      captcha: value
    })
  };

  render() {
    const {stage} = this.state;
    return (
      <div className={`feedback`}>
        <Title title={this.props.t('feedback.title')} size={170}/>
        {stage === 'start' && <FeedbackForm handleSubmit={this.handleSubmit}
                                            {...this.props}
                                            {...this.state}
                                            recaptchaRef={this.recaptchaRef}/>}
        {stage === 'finish' && <Finish {...this.props}/>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLogged: state.auth.isLogged
});

export default connect(mapStateToProps, null)(LangHOC(Feedback));
