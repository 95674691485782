import * as types from "../constants/ActionTypes";

const initialState = {
  languages: ['ge', 'en'],
  currentLanguage: localStorage.getItem('language') || 'en',
  wrapperClass: '',
  currentRouteLink: null,
  activeSection: 0,
  rulesConfirm: null
};

export default function common(state = initialState, action) {
  switch (action.type) {
    case types.SET_WRAPPER_CLASSNAME:
      return {
        ...state,
        wrapperClass: action.className,
      };
    case types.CURRENT_HISTORY_ROUTE:
      return {
        ...state,
        currentRouteLink: action.route
      };
    case types.SET_LANG:
      return {
        ...state,
        currentLanguage: action.lang
      };
    case types.SET_ACTIVE_MAIN_SECTION:
      return {
        ...state,
        activeSection: action.section
      };
    case types.SET_USER_RULES_CONFIRM:
      return {
        ...state,
        rulesConfirm: action.statusRulesConfirm
      };
    default:
      return state;
  }
}
