import React from 'react';
import Form from "../../../form/Form";
import Button from "../../../form/Button";
import Checkbox from "../../../form/Checkbox";
import Terms from "../../../parts/Terms";

function RulesConfirm (props) {
  const {nextStep, t} = props;
  return (
      <div className={`signup__wrap rules-terms`}>
      <div className={`signup-rules`}>
        <div className={`signup-rules__text`}>
          <Terms/>
        </div>
        <Form handleSubmit={nextStep}>
          <Checkbox name={'new_rules_agreement'}
                    id={'rules_confirmation'}
                    label={t('signup.rules_checkbox')}
                    validate={[{
                      required: true,
                      message: t('errors.req_field')}]}/>
          <div className={`auth__btns`}>
            <Button type={`submit`}>{props.t('btns.continue')}</Button>
          </div>
        </Form>
      </div>
      </div>
  )
}

export default RulesConfirm
