import React, {Component} from "react";
import SurveyTask from "./parts/SurveyTask";
import Done from "./parts/Done";
import defaultAxios from "../../../../../axios";
import debounce from 'lodash/debounce';
import {setWrapperClassName} from "../../../../../actions/common";
import {connect} from "react-redux";
import SurveyRefillPopup from "./parts/SurveyRefillPopup";
import {setFinishProfilingSurv} from "../../../../../actions/taskActions";
import ErrorResponse from "../../../PageNotFound/ErrorResponse";

class Survey extends Component {
  state = {
    step: 0,
    choiceBrand: null,
    choiceFormat: null,
    loading: false,
    radioArr: [],
    unemployed: null,
    checkedBuy: [],
    checkedPosition: [],
    selectArr: [],
    popup: false,
    currentFormat: null,
    serverQuestionsError: false,
  };

  componentDidMount() {
    this.getQuizQuestions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentLanguage !== prevProps.currentLanguage) {
      this.getQuizQuestions();
    }
  }

  componentWillUnmount() {
    this.props.setWrapperClassName("");
  }

  getQuizQuestions = () => {
    const lang = this.props.currentLanguage;
    defaultAxios.get(`/api/search-tasks/v2?locale=${lang}`, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
      .then(res => {
        this.setState({
          questions: res.data.data.questions
        })
      })
      .catch(err => {
        this.setState({serverQuestionsError: true})
      })
  };

  setQuizSubmit = debounce((formData) => {
    const {radioArr, unemployed, checkedBuy, checkedPosition} = this.state;
    const fd = new FormData();
    let invalid = null;

    for (let i = 0; i < [...formData].length; i++) {
      if (i === 5) {
        break;
      } else {
        fd.append(`${[...formData][i][0]}[${[...formData][i][1]}]`, [...formData][i][1]);
      }
    }
    // formData.forEach((value, key) => {
    //   return fd.append(`${key}[${value}]`, value);
    // });
    radioArr.sort((a, b) => {
      return Object.values(a)[0].question_id - Object.values(b)[0].question_id
    }).forEach((value) => {
      for (let j in value) {
        if (value.hasOwnProperty(j)) {
          if (j === 'Radio10' && unemployed && (unemployed.answer_text === 'unemployed2' ||
            unemployed.answer_text === 'unemployed3')) return false;
          if (value[j].answer_text) {
            return fd.append(`${value[j].question_id}[${value[j].answer_id}]`, this.state['comment' + j]);
          } else {
            return fd.append(`${value[j].question_id}[${value[j].answer_id}]`, value[j].answer_id);
          }
        }
      }
    });

    if (!radioArr.find(el=>el['Radio6'])) {
      this.setState({
        errorRadio6: this.props.t('open_task.sobranie.choice_error')
      });
      invalid = true
    }
    if (!radioArr.find(el=>el['Radio7'])) {
      this.setState({
        errorRadio7: this.props.t('open_task.sobranie.choice_error')
      });
      invalid = true
    }
    if (!radioArr.find(el=>el['Radio10']) && unemployed && (unemployed.answer_text === 'unemployed0' || unemployed.answer_text === 'unemployed1')) {
      this.setState({
        errorRadio10: this.props.t('open_task.sobranie.choice_error')
      });
      invalid = true
    }

    if (unemployed) {
      fd.append(`${unemployed.question_id}[${unemployed.answer_id}]`, unemployed.answer_id);
    } else {
      this.setState({
        errorunemployed: this.props.t('open_task.sobranie.choice_error')
      });
      invalid = true
    }

    if (checkedBuy.length > 0) {
      for (let key in checkedBuy) {
        if (checkedBuy[key].answer_text) {
          fd.append(`${checkedBuy[key].question_id}[${checkedBuy[key].answer_id}]`, this.state.commentBuy);
        } else {
          fd.append(`${checkedBuy[key].question_id}[${checkedBuy[key].answer_id}]`, checkedBuy[key].answer_id);
        }
      }
    } else if (checkedBuy.length === 0) {
      this.setState({
        errorBuy: this.props.t('open_task.sobranie.choice_error')
      });
      invalid = true
    }

    if (unemployed && (unemployed.answer_text === 'unemployed0' ||
      unemployed.answer_text === 'unemployed1') && checkedPosition.length > 0) {
      for (let key in checkedPosition) {
        if (checkedPosition[key].answer_text) {
          fd.append(`${checkedPosition[key].question_id}[${checkedPosition[key].answer_id}]`, this.state.commentPosition);
        } else {
          fd.append(`${checkedPosition[key].question_id}[${checkedPosition[key].answer_id}]`, checkedPosition[key].answer_id);
        }
      }
    } else if (unemployed && (unemployed.answer_text === 'unemployed0' || unemployed.answer_text === 'unemployed1')) {
      this.setState({
        errorPosition: this.props.t('open_task.sobranie.choice_error')
      });
      invalid = true
    }

    // console.log([...fd])
    if (invalid) return false;
    // return false;
    this.setState({loading: true});
    defaultAxios.post('/api/search-tasks/v2/success', fd, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=WebAppBoundary',
        'Accept': 'application/json',
        "Authorization": "bearer " + localStorage.getItem("token")
      }
    })
      .then(res => {
        window.scrollTo(0, 0);
        this.setState({
          step: 1,
          loading: false
        })
      })
      .catch(err => {
        this.setState({loading: false});
      })
  }, 1000, {leading: true, trailing: false});

  selectBrand = (select, idx) => {
    if (idx === '0') {
      this.setState({
        currentFormat: null,
        choiceFormat: null
      })
    }
    this.setState({
      selectArr: this.state.selectArr.indexOf(idx) === -1 ? [...this.state.selectArr, idx] : this.state.selectArr,
      choiceBrand: select
    })
  };

  selectFormat = (select, idx) => {
    this.setState({
      selectArr: this.state.selectArr.indexOf(idx) === -1 ? [...this.state.selectArr, idx] : this.state.selectArr,
      choiceFormat: select.value,
      currentFormat: {
        label: select.label,
        value: select.value
      }
    })
  };

  selectHandler = (select, idx) => {
    this.setState({
      selectArr: this.state.selectArr.indexOf(idx) === -1 ? [...this.state.selectArr, idx] : this.state.selectArr
    })
  };

  handleToggleRadio = (event, question_id) => {
    let newRadioArr = this.state.radioArr.filter(el => {
      return Object.keys(el)[0] !== event.target.name
    });
    this.setState({
      radioArr: [...newRadioArr, {
        [event.target.name]: {
          'question_id': question_id,
          'answer_id': event.target.value,
          'answer_text': event.target.dataset && event.target.dataset.name ? event.target.dataset.name : null,
          'answer_id_text': event.target.id
        }
      }],
      ['field' + event.target.name]: event.target.dataset && event.target.dataset.name ? event.target.id : null,
      ['error' + event.target.name]: null
    })
  };

  textAreaHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  handleToggleQuiz = (event, question_id) => {
    this.setState({
      [event.target.name]: {
        'question_id': question_id,
        'answer_id': event.target.value,
        'answer_text': event.target.id
      },
      errorunemployed: null
    })
  };

  handleCheckConsider = (e, question_id) => {
    const id = e.target.id;
    let isSelected = e.target.checked;
    if (isSelected) {
      this.setState({
        ['checked' + e.target.name]: [...this.state['checked' + e.target.name], {
          'question_id': question_id,
          'answer_id': id,
          'answer_text': e.target.dataset.name ? e.target.dataset.name : null
        }],
        ['field' + e.target.name]: e.target.dataset.name ? id : this.state['checked' + e.target.name].filter(item => this.state['field' + e.target.name] === item['answer_id']).length > 0
          ? this.state['field' + e.target.name] : null,
        ['error' + e.target.name]: null
      })
    } else {
      this.setState({
        ['checked' + e.target.name]: this.state['checked' + e.target.name].filter(item => id !== item['answer_id']),
        ['field' + e.target.name]: !e.target.dataset.name && this.state['checked' + e.target.name].filter(item => this.state['field' + e.target.name] === item['answer_id']).length > 0
          ? this.state['field' + e.target.name] : false
      })
    }
  };

  openPopup = () => {
    window.scrollTo(0, 0);
    this.setState({
      popup: true
    }, () => {
      this.props.setWrapperClassName("open-popup");
    })
  };

  closePopup = () => {
    this.props.setWrapperClassName("");
    this.props.history.push('/cabinet/points');
  };

  handleShowRefillPopup = () => {
    this.props.setFinishProfilingSurv(true);
    this.props.setWrapperClassName("");
    this.props.history.push('/tasks');
  };

  render() {
    const {step, popup, serverQuestionsError} = this.state;
    // console.log(this.state)
    if (serverQuestionsError) return <ErrorResponse/>;
    return (
      <div className={`survey`}>
        {step === 0 && <SurveyTask
          setQuizSubmit={this.setQuizSubmit}
          selectBrand={this.selectBrand}
          selectFormat={this.selectFormat}
          textAreaHandler={this.textAreaHandler}
          handleToggleRadio={this.handleToggleRadio}
          handleToggleQuiz={this.handleToggleQuiz}
          handleCheckConsider={this.handleCheckConsider}
          selectHandler={this.selectHandler}
          {...this.state} {...this.props}/>}
        {step === 1 && <Done {...this.props}
                             openPopup={this.openPopup} handleShowRefillPopup={this.handleShowRefillPopup}/>}
        {/*{popup && <SurveyRefillPopup {...this.state}*/}
                                     {/*{...this.props}*/}
                                     {/*closePopup={this.closePopup}/>}*/}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setWrapperClassName: (className) => dispatch(setWrapperClassName(className)),
  setFinishProfilingSurv: (status) => dispatch(setFinishProfilingSurv(status))
});

export default connect(null, mapDispatchToProps)(Survey)
