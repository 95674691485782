import React from 'react';
import Tab from "./Tab";

const Tabs = (props) => {
  return (
      <div className={`cabinet-tabs`}>
        <Tab location={props.location} link={'/cabinet/profile'} name={props.t('cabinet.tab_profile')} />
        <Tab location={props.location} link={'/cabinet/points'} name={props.t('cabinet.tab_points')} />
        <Tab location={props.location} link={'/cabinet/tasks'} name={props.t('cabinet.tab_task')} />
      </div>
  )
};

export default Tabs
