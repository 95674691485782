import defaultAxios from "../axios";

export const getProfileData = () => (
    dispatch => {
      const config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      };
      const url = `/api/front/v2/user-profile`;
      return defaultAxios.get(url, config).then(res => {
        if (res.data) {
          localStorage.setItem('user.me', JSON.stringify(res.data));
          return res.data
        }
      }).catch(err => {
      })
    });
