import React from "react";
import Title from "../../../parts/Title";
import calendar from "./../../../../../images/ICON_CALEND.svg";

const PopupCheckLimit = (props) => {
  return (
      <div className={`popup attention`}>
        <div className={`popup__close`} onClick={props.closePopup}></div>
        <div className={`container`}>
          <div className={`popup__wrap`}>
            <Title title={props.t('shop.attention.title')} className={`white`} size={208}/>
            <img className={`popup__img`} src={calendar} alt="calendar"/>
            <p  className={`popup__text`}>{props.t('main_page.popup_limit.text')}</p>
            <p  className={`popup__text`}>{props.t('main_page.popup_limit.text1')}</p>
          </div>
        </div>
      </div>
  )
};

export default PopupCheckLimit
