import React from "react";
import Title from "../../../../parts/Title";
import {Link} from "react-router-dom";
import Button from "../../../../form/Button";
import done_img from "../../../../../../images/open-tasks/survey/survey-done.png";

const Done = (props) => {
    return (
        <div className={`survey-done`}>
          <Title title={props.t('open_task.done')} size={170}/>
          <div className={`survey-done__wrap`}>
            <div className={`survey-done__img`}>
              <img src={done_img} alt="done pic"/>
            </div>
            <p className={`survey-done__thank-text`}>{props.t('open_task.photo_id.tnx')}</p>
            <p className={`survey-done__prize-text`}>{props.t('open_task.photo_id.prize')}</p>
            <Button className={`survey-done__btn`}><Link to={'/tasks'}>{props.t('btns.more_task')}</Link></Button>
          </div>
        </div>
    );
};

export default Done;
