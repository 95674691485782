import React, {Component} from 'react'
import PropTypes from 'prop-types'
import warning_icon from "../../../images/cabinet/warning.svg";
import success_icon from "../../../images/cabinet/success.png";

class Input extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    validating: PropTypes.bool,
    validate: PropTypes.array,
    handleValidating: PropTypes.func,
    handleChangeField: PropTypes.func,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    value: PropTypes.any,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    autoComplete: PropTypes.string,
    icon: PropTypes.string
  };

  static defaultProps = {
    type: "text",
    className: "",
    placeholder: "",
    disabled: false,
    onChange: () => {}
  };

  constructor(props) {
    super();
    this.inputEl = React.createRef();
    this.state = {
      value: props.value || '',
      errors: {}
    }
  }

  handleChange = (event) => {
    const { onChange, validate /*, validating*/ } = this.props;
    let value = event.target.value;

    this.setState({value});
    onChange(event);
    if (this.props.handleChangeField) {
      this.props.handleChangeField(event, this.props.name)
    }

    // if (validating === true) {
      if (validate) {
        this.handleValidation();
      }
    // }
  };

  handleValidation = () => {
    const {errors} = this.state;
    const {validate, name} = this.props;

    validate.map(item => {
      if (item.hasOwnProperty('required')) {
        if (!this.inputEl.current.value.length) {
          errors.required = item.message || 'The value is required'
        } else {
          delete errors.required;
        }
      } else if (item.hasOwnProperty('minLength')) {
        if (this.inputEl.current.value.length < item.minLength) {
          errors.minLength = item.message || `The value must be longer than ${item.minLength} symbols`
        } else {
          delete errors.minLength;
        }
      } else if (item.hasOwnProperty('maxLength')) {
        if (this.inputEl.current.value.length > item.maxLength) {
          errors.maxLength = item.message || `The value must be no longer than ${item.maxLength} symbols`
        } else {
          delete errors.maxLength;
        }
      } else if (item.hasOwnProperty('exactLength')) {
        if (this.inputEl.current.value.length === item.exactLength) {
          delete errors.exactLength;
        } else {
          errors.exactLength = item.message || `The value must be ${item.exactLength} symbols long`
        }
      } else if (item.hasOwnProperty('onlyLatin')) {
        const reg =/^[a-zA-Z- ]+$/g;
        if (reg.test(this.inputEl.current.value)) {
          delete errors.onlyLatin;
        } else {
          errors.onlyLatin = item.message || `The filed must contain only latin symbols`
        }
      } else if (item.hasOwnProperty('onlyLatinGeo')) {
        const reg =/^[\u0000-~Ⴀ-\u10fe]+$/g;
        if (reg.test(this.inputEl.current.value)) {
          delete errors.onlyLatinGeo;
        } else {
          errors.onlyLatinGeo = item.message || `The filed must contain only latin or georgian symbols`
        }
      } else if (item.hasOwnProperty('onlyNumbers')) {
        const reg = /^\d+$/g;
        if (reg.test(this.inputEl.current.value)) {
          delete errors.onlyNumbers;
        } else {
          errors.onlyNumbers = item.message || `The filed must contain only numbers`
        }
      } else if (item.hasOwnProperty('email')) {
        const reg = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
        if (this.inputEl.current.value.length > 0) {
          if (reg.test(this.inputEl.current.value)) {
            delete errors.email;
          } else {
            errors.email = item.message || `Please input valid email`
          }
        }
      } else if (item.hasOwnProperty('sameAsField')) {
        if (this.inputEl.current.value && document.querySelector(`[name=${item.sameAsField}]`).value &&
            this.inputEl.current.value === document.querySelector(`[name=${item.sameAsField}]`).value) {
          delete errors.sameAsField;
        } else {
          errors.sameAsField = item.message || `Please confirm ${item.sameAsField} field`
        }
      } else if (item.hasOwnProperty('notSymbols')) {
        const reg =/^[a-zA-Z0-9]+$/g;
        if (reg.test(this.inputEl.current.value)) {
          delete errors.notSymbols;
        } else {
          errors.notSymbols = item.message || `Only latin symbols or numbers`
        }
      }

      this.setState({
        errors
      })
    });

    this.props.handleValidating(name, Object.keys(errors).length);
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.validating && this.props.validating !== prevProps.validating) {
      if (this.props.validate) {
        this.handleValidation();
      }
    }
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  icon() {
    if (this.props.icon === 'warning') {
      return (<span className={`form-field__icon`}>
        <img src={warning_icon} alt="warning"/>
      </span>)
    } else if (this.props.icon === 'success') {
      return (<span className={`form-field__icon`}>
        <img src={success_icon} alt="success"/>
      </span>)
    }
  }

  render() {
    let errorMessage;

    if (Object.keys(this.state.errors).length) {
      errorMessage = (
          Object.keys(this.state.errors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.state.errors[item]}</div>
          ))
      )
    } else if (this.props.serverErrors && Object.keys(this.props.serverErrors).length) {
      errorMessage = (
          Object.keys(this.props.serverErrors).map((item, i) => (
              <div key={i} className={'error-element'}>{this.props.serverErrors[item]}</div>
          ))
      )
    }
    return (
        <div className={"form-field" + (Object.keys(this.state.errors).length || (this.props.serverErrors && Object.keys(this.props.serverErrors).length) ? " has-error" : "")}>
          <input ref={this.inputEl}
                 type={this.props.type}
                 value={this.state.value}
                 maxLength={this.props.maxLength}
                 minLength={this.props.minLength}
                 name={this.props.name}
                 onChange={this.handleChange}
                 placeholder={this.props.placeholder}
                 disabled={this.props.disabled}
                 autoComplete={this.props.autoComplete}
          />
          {this.props.icon && this.icon()}
          {errorMessage}
        </div>
    )
  }
}

export default Input;
